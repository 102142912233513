import React, { createContext, useContext, useEffect, useState } from "react";
import { DataProvider } from "../../api/data-provider";
import { Auth, Hub } from "aws-amplify";
import paletteConfig from "../../config/aws-palette-config.json";
import { analyticsLogger } from "../../common/utils/analyticsLogger";
// import { configureAwsSdk } from "../../common/utils"; 如果需要在客户端使用SDK，就可以在这里配置

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++ 1. Define Interface +++++++++++++++++++++++++++*/
interface UserProfileContextProviderProps {
  children: React.ReactNode;
}

interface UserProfileContextType {
  user: any;
  userLogin: string | null;
  isAdmin: boolean;
  fullName: string;
  today: string;
  setFullName: (fullName: string) => void;
  uiLanguage: string;
  setUiLanguage: (uiLanguage: string) => void;
  contentLanguage: string;
  setContentLanguage: (contentLanguage: string) => void;
  preferencesFetched: boolean | null;
  setPreferencesFetched: (fetched: boolean) => void;
}

/*++++++++++++++++++++++++++++ 2. Create Context ++++++++++++++++++++++++++++*/
export const UserProfileContext = createContext<UserProfileContextType>({
  user: null, // 你的初始 user 应该和你的状态初始化值保持一致
  userLogin: null,
  isAdmin: false,
  fullName: "",
  today: "",
  setFullName: () => {},
  uiLanguage: "en",
  setUiLanguage: () => {},
  contentLanguage: "en",
  setContentLanguage: () => {},
  preferencesFetched: false,
  setPreferencesFetched: () => {},
});

/*********************** 3. Create the Provider Component ********************/
export const UserProfileContextProvider: React.FC<UserProfileContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [userLogin, setUserLogin] = useState(null);
  const [uiLanguage, setUiLanguage] = useState("en");
  const [contentLanguage, setContentLanguage] = useState("en");
  const [fullName, setFullName] = useState("");
  const [defaultFullName, setDefaultFullName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [preferencesFetched, setPreferencesFetched] = useState(false);
  const [today, setToday] = useState(() => {
    // update the Today with string like "2023-12-08"
    const currentDate = new Date();
    return `${currentDate.getFullYear()}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-${("0" + currentDate.getDate()).slice(-2)}`;
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // checkAdminStatus用于验证用户是否是管理员身份
  const checkAdminStatus = (userData: any) => {
    const groups = userData.signInUserSession.idToken.payload["cognito:groups"] || [];
    DEBUG && console.log("%cCurrentLogin's Groups", "color: green; font-weight: bold;", groups);
    if (groups && groups.includes("admin")) {
      setIsAdmin(true);
    }
  };

  // getUserFullName将nikename的"Xu, Henry"转化为"Henry Xu"。defaultFullName将在用户没有配置自己的fullName时被缺省使用。只改前端显示，不改后端数据库。
  const getUserFullName = (userData: any) => {
    // 使用split将字符串分割成数组
    let parts = userData.attributes!.nickname.split(", ");
    // 使用数组的reverse方法将数组元素顺序反转，再使用join将数组元素连接成字符串
    parts.reverse();
    let result = parts.join(" ");
    setFullName(result);
    setDefaultFullName(result);
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /**
   * 1. Hub.listen监控用户的登录行为并设置状态，这只在页面首次加载的时候会运行，此后刷新页面不会被触发运行。
   * 2. Auth.currentAuthenticatedUser()用于检查当前用户信息，只要用户曾经登录过，在LocalStorage里面保留有信息，它就会获得当前用户信息。因为每次登录都会
   *    把用户信息写入LocalStorage，此时必须和首次登录一样，把数据写入到Context各个变量中。
   * 3. 如果在本地LocalStorage也没有发现用户信息，Auth.currentAuthenticatedUser()就会抛出Error，进而触发执行Auth.federatedSignIn。而这会触发一个
   *    事件，被Hub.listen监听到，并执行case "signIn"部分的代码。
   */
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          Auth.currentAuthenticatedUser().then((userData) => {
            // 这里是新用户登录，因为userLogin总会有变化，所以肯定会触发fetchUserPreferences()
            // analyticsLogger.setUserId(userData.attributes!.name);
            // analyticsLogger.log("login", { userLogin: userData.attributes!.name });
            setUser(userData);
            setUserLogin(userData.attributes!.name);
            checkAdminStatus(userData);
            getUserFullName(userData);
          });

          // setUserLogin(JSON.parse(data.attributes!.identities)[0].userId); //这样写也能取到Login
          // configureAwsSdk(); //如果需要配置Sdk可以在这里配置，但是不建议使用SDK，直接来操作AWS资源，应该通过API Gateway提出请求。
          break;
        case "signOut":
          setUser(null);
          setUserLogin(null);
          setIsAdmin(false);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    DEBUG && console.log("%ctoday", "color: green; font-weight: bold;", today);

    Auth.currentAuthenticatedUser()
      .then((currentUser) => {
        DEBUG && console.log("%c+++ Auth.currentAuthenticatedUser executed +++ (CurrentUser):", "color: black; font-weight: bold;", currentUser);
        // 当用户刷新页面的时候，这里的代码会被执行，通过setUser(currentUser)将null变为当前的currentUser
        setUser(currentUser);
        setUserLogin(currentUser.attributes!.name);
        checkAdminStatus(currentUser);
        getUserFullName(currentUser);
      })
      .catch(() => {
        console.log("Not signed in");
        // [Important Note] To enable auto sign in, we can add below line; But when user logged out, it will automatically login again.
        // This means users are forced to stay logged in. BTW, we don't provide button of logout.
        Auth.federatedSignIn({ customProvider: "AmazonEmployee" })
          .then(() => {
            DEBUG && console.log("%c*** Auth.federatedSignIn executed *** (Hub.listen triggered)", "color: black; font-weight: bold;");
          })
          .catch(() => console.log("Error catched when the user is logging in with federation."));
      });
  }, []);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 用户登录后，从后台数据库中读取其语言偏好和他自己配置的Full Name
  useEffect(() => {
    const fetchUserPreferences = async () => {
      // Fetch user preferences from the backend API
      const params = {
        userLogin,
      };

      const response = await new DataProvider().getUsers(params);
      const userPreferences = response[0];
      if (!userPreferences) {
        await new DataProvider().createUser({
          userLogin,
          fullName,
          uiLanguage,
          contentLanguage,
        });
      } else {
        // 如果用户自己没有配置自己的Full Name，那么就使用缺省的defaultFullName，这是phonetool里面的fullName
        if (userPreferences.fullName === "") {
          setFullName(defaultFullName);
        } else {
          setFullName(userPreferences.fullName);
        }

        setUiLanguage(userPreferences.uiLanguage);
        setContentLanguage(userPreferences.contentLanguage);
      }
      setPreferencesFetched(true);
    };

    // useEffect中执行下面的代码，上面是辅助函数。
    if (userLogin) {
      if (!preferencesFetched) {
        fetchUserPreferences();
      } else {
        DEBUG &&
          console.log("%c[Context] User_Profile: ", "color: navy; font-weight: bold;", {
            userLogin,
            fullName,
            isAdmin,
            uiLanguage,
            contentLanguage,
            user,
          });
      }
      // 埋点
      analyticsLogger.setUserId(userLogin);
      analyticsLogger.log("login", { userLogin: userLogin });
    }
  }, [userLogin, preferencesFetched]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const contentValue = {
    user,
    userLogin,
    isAdmin,
    fullName,
    today,
    setFullName,
    uiLanguage,
    setUiLanguage,
    contentLanguage,
    setContentLanguage,
    preferencesFetched,
    setPreferencesFetched,
  };

  return <UserProfileContext.Provider value={contentValue}>{children}</UserProfileContext.Provider>;
};

/*++++++++++++++++++++++++++ 4. Create Custom Hook ++++++++++++++++++++++++++*/
export const useUserProfile = () => {
  return useContext(UserProfileContext);
};

/* Backup: Old code block

    const checkAdminStatus = async () => {
      try {
        const session = await Auth.currentSession();
        DEBUG && console.log("%cAuthSession:", "color: crimson; font-weight: bold;", session);
        const groups = (session as any).idToken.payload["cognito:groups"] || "";
        console.log("[Dev] The user's Groups are:", groups);
        if (groups && groups.includes("admin")) {
          DEBUG && console.log("%cThe current User is Admin! setIsAdmin(true) now.", "color: green; font-weight: bold;");
          setIsAdmin(true);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

*/
