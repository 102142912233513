import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CollectionPreferences,
  Container,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  Popover,
  SpaceBetween,
  StatusIndicator,
  Table,
  TextFilter,
  Textarea,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { DataProvider } from "../../../api/data-provider";
import { TableEmptyState, TableNoMatchState, TableHeader } from "./tab-feature-requests-table-empty-nomatch-state";
import { useNotification, useUserProfile, useSolutions, LoadingSpinner } from "../../../components";
import { paginationLabels } from "../../../common/labels";
import { createColumnDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./tab-feature-requests-tableconfig";
import { getFilterCounterText } from "../../../common/tableCounterStrings";
import { addColumnSortLabels } from "../../../common/labels";
import { useColumnWidths } from "../../../common/use-column-widths";
import { useLocalStorage } from "../../../common/use-local-storage";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { v4 as uuidv4 } from "uuid";
import "./tab-feature-requests.css";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
function isValidUrlRegex(string) {
  const regex = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?" + // port
      "(\\/[-a-z\\d%_.~+]*)*" + // path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!regex.test(string);
}

function isValidNumberString(value) {
  // 使用 parseFloat 函数尝试将字符串转换为浮点数
  const number = parseInt(value);

  // 检查转换结果是否是 NaN，并且转换回字符串之后是否与原字符串相等
  // 这一步可以排除像 "123abc" 这样的字符串，因为它们可以被 parseInt 转换为数字，但显然不是有效的数字字符串
  return !isNaN(number) && number.toString() === value;
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
const AddOpportunity = ({ frIndex, onSubmitAddOpportunityHandler, visible, onClose }) => {
  const { t } = useTranslation();
  const { userLogin, fullName } = useUserProfile();
  const [sfdclinkDuplicate, setSfdclinkDuplicate] = useState(false);
  // opportunityObj 包含id属性，id由onSubmitAddOpportunityHandler来初始化为随机字符串。
  const [opportunityObj, setOpportunityObj] = useState({
    login: userLogin,
    fullName: fullName,
    customerName: "",
    arr: "",
    sfdcLink: "",
    id: "",
  });

  useEffect(() => {
    DEBUG && console.log("%copportunityObj:", "color: red; font-weight: bold;", opportunityObj);
  }, [opportunityObj]);

  const onChangeFormHandler = (attribute, value) => {
    setOpportunityObj({ ...opportunityObj, [attribute]: value });
  };

  return (
    <Modal
      onDismiss={onClose}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={onClose}>{t("Button.cancel")}</Button>
            <Button
              onClick={() => {
                onSubmitAddOpportunityHandler(frIndex, opportunityObj, setSfdclinkDuplicate, onClose);
              }}
              variant="primary"
              disabled={!opportunityObj.customerName || !isValidNumberString(opportunityObj.arr) || !isValidUrlRegex(opportunityObj.sfdcLink)}
            >
              {t("Button.ok")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t(`solutions:Modal.addOpportunities`)}
    >
      <SpaceBetween size="s">
        <FormField label={t(`solutions:SolutionDetails.tabs-content.customerName`)} description={t(`solutions:SolutionDetails.tabs-content.customerName_Des`)}>
          <Input
            value={opportunityObj.customerName}
            placeholder={t(`solutions:SolutionDetails.tabs-content.customerName_Placeholder`)}
            onChange={({ detail: { value } }) => onChangeFormHandler(`customerName`, value)}
          />
          {!opportunityObj.customerName && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertCustomerName")}</Alert>}
        </FormField>
        <FormField label={t(`solutions:SolutionDetails.tabs-content.arr`)} description={t(`solutions:SolutionDetails.tabs-content.arr_Des`)}>
          <Input
            value={opportunityObj.arr}
            placeholder={t(`solutions:SolutionDetails.tabs-content.arr_Placeholder`)}
            onChange={({ detail: { value } }) => onChangeFormHandler(`arr`, value)}
          />
          {!isValidNumberString(opportunityObj.arr) && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertArr")}</Alert>}
        </FormField>
        <FormField label={t(`solutions:SolutionDetails.tabs-content.sfdcLink`)} description={t(`solutions:SolutionDetails.tabs-content.sfdcLink_Des`)}>
          <Input
            value={opportunityObj.sfdcLink}
            placeholder={t(`solutions:SolutionDetails.tabs-content.sfdcLink_Placeholder`)}
            onChange={({ detail: { value } }) => onChangeFormHandler(`sfdcLink`, value)}
          />
          {!isValidUrlRegex(opportunityObj.sfdcLink) && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertSfdcLink")}</Alert>}
          {sfdclinkDuplicate && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertSfdcLinkValueDuplicated")}</Alert>}
        </FormField>
      </SpaceBetween>
    </Modal>
  );
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
const EditOpportunity = ({ selectedSolution, frIndex, onSubmitEditOpportunityHandler, visible, onClose }) => {
  const { t } = useTranslation();
  const { userLogin, isAdmin } = useUserProfile();
  const [isFormValid, setIsFormValid] = useState(true); // 控制是否要禁用提交表单的ok按钮

  // 如果用户是管理员，显示所有opportunities，否则只显示当前用户创建的opportunities，只需要在初始化userOpportunities时做出判断，其他地方不用动。
  const [userOpportunities, setUserOpportunities] = useState(null);
  const [userOpportunitiesInitialState, setUserOpportunitiesInitialState] = useState(null);
  // const [editedOpportunities, setEditedOpportunities] = useState([]); //这只用来记录被修改的元素。
  const [removedOpportunities, setRemovedOpportunities] = useState([]); //这只用来记录被删除的元素。
  const userOpportunitiesRef = useRef(userOpportunities);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    DEBUG && console.log("%cisFormValid", "color: blue; font-weight: bold;", isFormValid);
  }, [isFormValid]);

  useEffect(() => {
    DEBUG && console.log("%c+++++++++++++", "color: black; font-weight: bold;");
    DEBUG && console.log("%cuserOpportunitiesInitialState:", "color: red; font-weight: bold;", userOpportunitiesInitialState);
    DEBUG && console.log("%cuserOpportunities:", "color: red; font-weight: bold;", userOpportunities);
    DEBUG && console.log("%cremovedOpportunities:", "color: red; font-weight: bold;", removedOpportunities);

    // 根据userOpportunities === null，来进行初始化
    if (userOpportunities === null) {
      const initialState = isAdmin
        ? selectedSolution.featureRequests[frIndex].opportunities
        : selectedSolution.featureRequests[frIndex].opportunities.filter((opportunity) => opportunity.login === userLogin);
      setUserOpportunities(cloneDeep(initialState));
      setUserOpportunitiesInitialState(cloneDeep(initialState));
    }
    // 每次userOpportunities有变化，就更新userOpportunitiesRef.current
    userOpportunitiesRef.current = userOpportunities;
  }, [userOpportunities]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onRemoveOpportunityHandler = (index) => {
    let copyOfUserOpportunities = cloneDeep(userOpportunities);
    setRemovedOpportunities([...removedOpportunities, ...copyOfUserOpportunities.splice(index, 1)]);
    userOpportunitiesRef.current = copyOfUserOpportunities;
    setUserOpportunities([...copyOfUserOpportunities]);
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onChangeFormHandler = (attribute, value, index) => {
    // Check if attribute is one of the expected values
    if (["customerName", "arr", "sfdcLink"].includes(attribute)) {
      // Create a new copy of the userOpportunities array
      const updatedUserOpportunities = [...userOpportunities];

      // Update the specific attribute of the opportunity at the provided index
      updatedUserOpportunities[index] = {
        ...updatedUserOpportunities[index],
        [attribute]: value,
      };

      // 这里使用ref是为了确保调用onSubmitEditOpportunityHandler的时候所有数据是最新的，
      // 无需等待setUserOpportunities(updatedUserOpportunities)更新完成，后来测试中发现不用ref似乎也可以。
      // Update ref value
      userOpportunitiesRef.current = updatedUserOpportunities;

      // Use the setUserOpportunities function to update the state
      setUserOpportunities(updatedUserOpportunities);
    } else {
      console.error(`Unexpected attribute: ${attribute}`);
    }

    /*_______________________________________________________________________*/
    // 验证表单
    const isValid = userOpportunities.every(
      (opportunity) => opportunity.customerName && isValidNumberString(opportunity.arr) && isValidUrlRegex(opportunity.sfdcLink)
    );

    // 更新 isFormValid 状态，只有onChangeFormHandler需要验证数据。remove操作不需要。
    setIsFormValid(isValid);
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onButtonOkClickedHandler = () => {
    let editedOpportunities = [];

    // 使用id，而不是sfdcLink来识别修改过的opportunity，可以保证即使sfdcLink被修改，也不会被认为是新的机会。
    // 提醒：这里只有使用userOpportunitiesRef才能获得最新的userOpportunities状态，而不用等setUserOpportunities(updatedUserOpportunities)完成
    userOpportunitiesRef.current.forEach((opportunity) => {
      const initialOpportunity = userOpportunitiesInitialState.find((initialOpportunity) => initialOpportunity.id === opportunity.id);

      if (!isEqual(initialOpportunity, opportunity)) {
        editedOpportunities.push(opportunity);
      }
    });

    // 如果没有任何修改，就不调用onSubmitEditOpportunityHandler，从而避免不必要的api call
    /**
     * 在 JavaScript 中，当你试图比较一个数组是否等于一个新的空数组（[]），你总会得到 false，即使这个数组是空的。这是因为在 JavaScript 中，数组是对象，对象之间的比较是基于引用的，而不是基于值的。
     * 所以 [] === [] 在 JavaScript 中总是返回 false，因为每次你创建一个新的空数组，它总是在内存中新开辟一个空间，和其它的空数组不是同一个引用。
     * 解决你的问题，可以通过比较数组的 length 属性来判断它是否为空。如果 length 为 0，那么这个数组就是空的。
     */
    if (removedOpportunities.length !== 0 || editedOpportunities.length !== 0) {
      onSubmitEditOpportunityHandler(frIndex, removedOpportunities, editedOpportunities);
    }

    onClose();
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <Modal
      onDismiss={onClose}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              onClick={() => {
                setUserOpportunities(userOpportunitiesInitialState);
                onClose();
              }}
            >
              {t("Button.cancel")}
            </Button>
            <Button onClick={onButtonOkClickedHandler} variant="primary" disabled={!isFormValid}>
              {t("Button.ok")}
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={t(`solutions:Modal.editOpportunities`)}
    >
      <SpaceBetween size="s">
        {userOpportunities &&
          userOpportunities.map((opportunity, index) => (
            <Container
              key={index}
              header={
                <Header
                  variant="h2"
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        onClick={() => {
                          onRemoveOpportunityHandler(index);
                        }}
                      >
                        {t("Button.remove")}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  {t("Button.no_th")} {index + 1}
                </Header>
              }
            >
              <SpaceBetween size="s">
                <FormField
                  label={t(`solutions:SolutionDetails.tabs-content.customerName`)}
                  description={t(`solutions:SolutionDetails.tabs-content.customerName_Des`)}
                >
                  <Input
                    value={opportunity.customerName}
                    placeholder={t(`solutions:SolutionDetails.tabs-content.customerName_Placeholder`)}
                    onChange={({ detail: { value } }) => onChangeFormHandler(`customerName`, value, index)}
                  />
                  {!opportunity.customerName && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertCustomerName")}</Alert>}
                </FormField>
                <FormField label={t(`solutions:SolutionDetails.tabs-content.arr`)} description={t(`solutions:SolutionDetails.tabs-content.arr_Des`)}>
                  <Input
                    value={opportunity.arr}
                    placeholder={t(`solutions:SolutionDetails.tabs-content.arr_Placeholder`)}
                    onChange={({ detail: { value } }) => onChangeFormHandler(`arr`, value, index)}
                  />
                  {!isValidNumberString(opportunity.arr) && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertArr")}</Alert>}
                </FormField>
                <FormField label={t(`solutions:SolutionDetails.tabs-content.sfdcLink`)} description={t(`solutions:SolutionDetails.tabs-content.sfdcLink_Des`)}>
                  <Input
                    value={opportunity.sfdcLink}
                    placeholder={t(`solutions:SolutionDetails.tabs-content.sfdcLink_Placeholder`)}
                    onChange={({ detail: { value } }) => onChangeFormHandler(`sfdcLink`, value, index)}
                  />
                  {!isValidUrlRegex(opportunity.sfdcLink) && <Alert statusIconAriaLabel="Info">{t("solutions:Modal.alertSfdcLink")}</Alert>}
                </FormField>
              </SpaceBetween>
            </Container>
          ))}
      </SpaceBetween>
    </Modal>
  );
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
const PipelinesTable = ({
  selectedSolution,
  onSubmitAddOpportunityHandler,
  onSubmitEditOpportunityHandler,
  onDeleteFeatureRequestHandler,
  likeButtonHandler,
}) => {
  const { t } = useTranslation();
  const { userLogin, isAdmin } = useUserProfile();
  const { fetched, setFetched } = useSolutions();
  const [loading] = useState(false); // 不需要loading环境，所以设置为false
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);

  const COLUMN_DEFINITIONS = addColumnSortLabels(createColumnDefinitions(t, likeButtonHandler, userLogin));
  const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
  const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
  const DEFAULT_PREFERENCES = createDefaultPreferences();

  /**
   * 通过下面的两个DEBUG语句证明，React 的 key 属性实际上无法在组件内部访问。您需要使用额外的属性（例如 releaseIndex）来将索引传递给组件。
   * 所以，您需要保留 key={releaseIndex}，因为它是 React 列表渲染所需的，以便在重新渲染时正确识别元素。同时，确保使用
   * releaseIndex={releaseIndex} 将索引传递给 FeaturesTable 组件，然后在组件内部使用它。
   * 如果使用key={releaseIndex} 试图传递releaseIndex，就会发现在这里key是undefined.
   */

  const [columnDefinitions, saveWidths] = useColumnWidths(`React-Tab-FeatureRequests-Widths`, COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage(`React-Tab-FeatureRequests-Preferences`, DEFAULT_PREFERENCES);

  const [selectedItems, setSelectedItems] = useState([]);
  const isOnlyOneSelected = selectedItems.length === 1;
  // 当FeatureRequest没有Opportunity和它关联的时候（!hasOpportunityForTheFeatureRequest），直接禁用Edit Opportunities按钮
  const hasOpportunityForTheFeatureRequest = selectedItems.length > 0 && selectedItems[0].opportunities && selectedItems[0].opportunities.length > 0;
  // 当用户既不是FeatureRequest的创建者，也不是管理员身份，直接禁用Delete FeatureRequest按钮
  const noRightToDeleteTheFeatureRequest = selectedItems.length > 0 && selectedItems[0].login !== userLogin && !isAdmin;

  useEffect(() => {
    DEBUG && console.log("%cisOnlyOneSelected", "color: green; font-weight: bold;", isOnlyOneSelected);
    DEBUG && console.log("%chasOpportunityForTheFeatureRequest", "color: green; font-weight: bold;", hasOpportunityForTheFeatureRequest);
    DEBUG && console.log("%cnoRightToDeleteTheFeatureRequest", "color: green; font-weight: bold;", noRightToDeleteTheFeatureRequest);
  }, [isOnlyOneSelected, hasOpportunityForTheFeatureRequest, noRightToDeleteTheFeatureRequest]);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(selectedSolution.featureRequests, {
    filtering: {
      empty: <TableEmptyState resourceName="featurerequests" />,
      noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    selection: {},
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onCopyLoginsHandler = (frId) => {
    const frIndex = selectedSolution.featureRequests.findIndex((fr) => fr.id === frId);

    // 从 selectedSolution.featureRequests[frIndex].like 中获取所有的 login
    const logins = selectedSolution.featureRequests[frIndex].like;

    // 将每个 login 转换为 {login}@amazon.com 的形式，并用 ; 和空格连接起来
    const loginsString = logins.map((login) => `${login}@amazon.com`).join("; ");

    // 将 loginsString 复制到剪贴板
    navigator.clipboard.writeText(loginsString).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onCopyOpportunitiesHandler = (frId) => {
    // Check if selectedSolution and selectedSolution.featureRequests exist
    if (selectedSolution && selectedSolution.featureRequests) {
      const frIndex = selectedSolution.featureRequests.findIndex((fr) => fr.id === frId);

      // Check if selectedSolution.featureRequests[frIndex] and selectedSolution.featureRequests[frIndex].opportunities exist
      if (frIndex >= 0 && selectedSolution.featureRequests[frIndex] && selectedSolution.featureRequests[frIndex].opportunities) {
        // 从 selectedSolution.featureRequests[frIndex].opportunities 中获取所有的 opportunities
        const opportunities = selectedSolution.featureRequests[frIndex].opportunities;

        // 创建列名称
        const columnNames = "FullName\tLogin\tCustomerName\tARR($K)\tSFDC_Link\tID";

        // 将每个 opportunity 对象的属性用制表符连接起来
        const opportunitiesString = opportunities
          .map(
            (opportunity) =>
              `${opportunity.fullName}\t${opportunity.login}\t${opportunity.customerName}\t${opportunity.arr}\t${opportunity.sfdcLink}\t${opportunity.id}`
          )
          .join("\n");

        // 将列名称和 opportunitiesString 复制到剪贴板
        navigator.clipboard.writeText(`${columnNames}\n${opportunitiesString}`).then(
          function () {
            console.log("Async: Copying to clipboard was successful!");
          },
          function (err) {
            console.error("Async: Could not copy text: ", err);
          }
        );
      }
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <div>
      <Table
        {...collectionProps}
        loading={loading}
        loadingText={t("loadingTextFeatures")} /* the String that will be shown behind the Spinner */
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        items={items}
        selectionType="single"
        onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
        selectedItems={selectedItems}
        header={
          <TableHeader
            title="feature requests"
            selectedItems={selectedItems}
            totalItems={selectedSolution.featureRequests}
            actionButtons={
              <SpaceBetween direction="horizontal" size="xs">
                <Button iconName="refresh" loading={!fetched} onClick={() => setFetched(false)} />
                {isAdmin && (
                  <>
                    <Popover
                      size="small"
                      position="top"
                      triggerType="custom"
                      content={<StatusIndicator type="success">{t("solutions:SolutionDetails.tabs-content.copyLogins")}</StatusIndicator>}
                    >
                      <Button iconName="copy" disabled={!isOnlyOneSelected} onClick={() => onCopyLoginsHandler(selectedItems[0].id)}>
                        {t("PageHeader.buttonLogins")}
                      </Button>
                    </Popover>
                    <Popover
                      size="small"
                      position="top"
                      triggerType="custom"
                      content={<StatusIndicator type="success">{t("solutions:SolutionDetails.tabs-content.copyOpportunities")}</StatusIndicator>}
                    >
                      <Button iconName="copy" disabled={!isOnlyOneSelected} onClick={() => onCopyOpportunitiesHandler(selectedItems[0].id)}>
                        {t("PageHeader.buttonOpportunities")}
                      </Button>
                    </Popover>
                  </>
                )}
                <Button disabled={!isOnlyOneSelected} onClick={() => setShowModalAdd(true)}>
                  {t("PageHeader.buttonAddOpportunities")}
                </Button>
                <Button disabled={!hasOpportunityForTheFeatureRequest} onClick={() => setShowModalEdit(true)}>
                  {t("PageHeader.buttonEditOpportunities")}
                </Button>

                <Button
                  disabled={!isOnlyOneSelected || noRightToDeleteTheFeatureRequest}
                  onClick={() =>
                    onDeleteFeatureRequestHandler(
                      selectedSolution.featureRequests.findIndex((fr) => fr.title === selectedItems[0].title),
                      selectedItems[0].title
                    )
                  }
                >
                  {t("PageHeader.buttonDeleteFeatureRequests")}
                </Button>
              </SpaceBetween>
            }
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Find features"
            filteringPlaceholder={t("solutions:useCollection.findFeatures")}
            countText={getFilterCounterText(filteredItemsCount)}
            disabled={loading}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={t("solutions:CollectionPreferences.title")}
            confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
            cancelLabel={t("solutions:CollectionPreferences.cancel")}
            disabled={loading}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: t("solutions:CollectionPreferences.pageSize"),
              options: PAGE_SIZE_OPTIONS,
            }}
            visibleContentPreference={{
              title: t("solutions:CollectionPreferences.selectVisibleColumns"),
              options: VISIBLE_CONTENT_OPTIONS,
            }}
            wrapLinesPreference={{
              label: t("solutions:CollectionPreferences.wrapLinesLabel"),
              description: t("solutions:CollectionPreferences.wrapLinesDescription"),
            }}
            stripedRowsPreference={{
              label: t("solutions:CollectionPreferences.stripedRowsLabel"),
              description: t("solutions:CollectionPreferences.stripedRowsDescription"),
            }}
          />
        }
        ariaLabels={{
          itemSelectionLabel: (data, row) => `select ${row.name}`,
          allItemsSelectionLabel: () => "select all",
          selectionGroupLabel: "Casestudies selection",
        }}
      />
      {showModalAdd && (
        <AddOpportunity
          frIndex={selectedSolution.featureRequests.findIndex((fr) => fr.title === selectedItems[0].title)}
          onSubmitAddOpportunityHandler={onSubmitAddOpportunityHandler}
          visible={showModalAdd}
          onClose={() => setShowModalAdd(false)} // 将setShowModal函数传递给子组件，用于在子组件中关闭Modal
        />
      )}
      {showModalEdit && (
        <EditOpportunity
          selectedSolution={selectedSolution}
          frIndex={selectedSolution.featureRequests.findIndex((fr) => fr.title === selectedItems[0].title)}
          onSubmitEditOpportunityHandler={onSubmitEditOpportunityHandler}
          visible={showModalEdit}
          onClose={() => setShowModalEdit(false)} // 将setShowModal函数传递给子组件，用于在子组件中关闭Modal
        />
      )}
    </div>
  );
};

/*****************************************************************************/
export const TabFeatureRequests = ({ oneSolution }) => {
  const { t } = useTranslation();
  const { userLogin, fullName, isAdmin } = useUserProfile();
  const { fetched, setFetched } = useSolutions();
  const [loading, setLoading] = useState(true);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [needCallApiAfterOnPostHandler, setNeedCallApiAfterOnPostHandler] = useState(false);
  const [configUsedForApiCall, setConfigUsedForApiCall] = useState(null);
  const [onPostAlert, setOnPostAlert] = useState(false);
  const [inputData, setInputData] = useState({
    login: userLogin,
    fullName: fullName,
    title: "",
    content: "",
    opportunities: [],
    like: [],
    id: "",
  });
  const [apiCalled, setApiCalled] = useState(false);

  const apiCalledRef = useRef();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  /**
   * 不要使用const [selectedSolution, setSelectedSolution] = useState(oneSolution); 这会造成快速点击tab的时候，页面一直是<LoadSpinger />
   * 当 oneSolution 作为 prop 改变时，你在 useState(oneSolution) 这里只是为 selectedSolution 设置了初始值。这意味着，只有在组件的初始渲染阶段，
   * oneSolution 的值才会被用来初始化 selectedSolution。在后续的重新渲染中，即使 oneSolution 的值发生了改变，selectedSolution 的值也不会被更新，
   * 除非你在某个地方调用了 setSelectedSolution。
   *
   * 所以分开写，先const [selectedSolution, setSelectedSolution] = useState(); 然后用useEffect赋值就能解决这个问题。
   */
  const selectedSolutionRef = useRef();

  useEffect(() => {
    if (oneSolution) {
      setSelectedSolution({
        solutionNameAbbr: oneSolution.solutionNameAbbr,
        featureRequests: oneSolution.featureRequests,
      });
    }
  }, [oneSolution]);

  useEffect(() => {
    selectedSolutionRef.current = selectedSolution; // 更新 ref
    DEBUG && console.log("%cslectedSolution", "color: orange; font-weight: bold;", selectedSolutionRef.current);
    if (selectedSolution) {
      setLoading(false);
    }
  }, [selectedSolution]);

  useEffect(() => {
    DEBUG && console.log("%cinputData", "color: blue; font-weight: bold;", inputData);
  }, [inputData]);

  useEffect(() => {
    apiCalledRef.current = apiCalled;
  }, [apiCalled]);

  // 通过设置清理函数，是的每次组件卸载的时候都自动触发刷新最新的solutionsList
  useEffect(() => {
    return () => {
      if (apiCalledRef.current) {
        setFetched(false);
      }
    };
  }, []);

  useEffect(() => {
    if (!fetched) {
      // 当手工点击refresh button进行reload数据的时候，把apiCall设置为false，从而避免卸载组件的时候，重复load
      setApiCalled(false);
    }
  }, [fetched]);

  useEffect(() => {
    async function callApiAfterOnPostHandler() {
      // 前端已经进行了更改，现在请求api，修改后端数据库的数据。
      const eventBody = {
        solutionNameAbbr: oneSolution.solutionNameAbbr,
      };

      try {
        setNeedCallApiAfterOnPostHandler(false);
        DEBUG && console.log("%cconfigUsedForApiCall", "color: green; font-weight: bold;", configUsedForApiCall);
        const response = await new DataProvider().updateSolution(eventBody, configUsedForApiCall); // 传入config的action，利用action修改parsedBody
        DEBUG && console.log("%cApiCallCompleted, featureRequests[] updated.", "color: green; font-weight: bold;", response);
      } catch (error) {
        console.error("Update Solution featureRequests[] (add new feature request) failed:", error);
      }
    }

    if (needCallApiAfterOnPostHandler) {
      callApiAfterOnPostHandler();
    }
  }, [needCallApiAfterOnPostHandler]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // onPostHandler用于处理用户添加新的featurerequests
  const onPostHandler = async () => {
    setOnPostAlert(false); // 如果用户没有手动关闭告警，那么每次提交新的featureRequests的时候，自动把之前的清空。
    if (inputData.title === "") {
      // 字段为空，提示错误，不执行任何操作。因为界面上的Post按钮只有在两个字段的数据都不为空的时候才Enable，所以这部分代码事实上不会被执行。
      setOnPostAlert(true);
      return;
    }
    if (inputData.content === "") {
      // 字段为空，提示错误，不执行任何操作。因为界面上的Post按钮只有在两个字段的数据都不为空的时候才Enable，所以这部分代码事实上不会被执行。
      setOnPostAlert(true);
      return;
    }

    /*—————————————————————— 下面的代码才是会开始被执行的代码 ————————————————————————*/
    if (!selectedSolution.featureRequests) {
      // 下面的代码用于为后面根据状态call Api做准备
      setNeedCallApiAfterOnPostHandler(true);
      inputData.id = uuidv4();
      setConfigUsedForApiCall({
        params: {
          action: "add-feature-request-in-featurerequest-tab",
          userLogin: userLogin,
          newFeatureRequest: JSON.stringify(inputData), // 对象转换为字符串
          solutionNameAbbr: oneSolution.solutionNameAbbr,
        },
      });
      // 下面的修改selectedSolution.featureRequests只是为了客户端能立刻显示出变化
      setSelectedSolution((prevState) => ({
        ...prevState,
        featureRequests: [inputData],
      }));
    } else {
      setSelectedSolution((prevState) => {
        // Check if the inputData's title already exists in the featureRequests
        const titleExists = prevState.featureRequests.some((item) => item.title === inputData.title);

        if (!titleExists) {
          // If title doesn't exist, add the inputData to featureRequests
          DEBUG && console.log("%cAdd the new feature request to the list.", "color: green; font-weight: bold;");
          // 下面的代码用于为后面根据状态call Api做准备
          setNeedCallApiAfterOnPostHandler(true);
          inputData.id = uuidv4();
          setConfigUsedForApiCall({
            params: {
              action: "add-feature-request-in-featurerequest-tab",
              userLogin: userLogin,
              newFeatureRequest: JSON.stringify(inputData), // 对象转换为字符串
              solutionNameAbbr: oneSolution.solutionNameAbbr,
            },
          });
          // 下面的修改selectedSolution.featureRequests只是为了客户端能立刻显示出变化
          return {
            ...prevState,
            featureRequests: [...prevState.featureRequests, inputData],
          };
        } else {
          // If title exists, return the prevState without adding inputData
          DEBUG && console.log("%cThe feature with the title already exists. It won't be added to the list.", "color: green; font-weight: bold;");
          setOnPostAlert(true);
          return prevState;
        }
      });
    }

    // 在setConfigUsedForApiCall已经取得数据的情况下，清空inputData对象，使得屏幕上表单内容为空
    setInputData({
      login: userLogin,
      fullName: fullName,
      title: "",
      content: "",
      opportunities: [],
      like: [],
      id: "",
    });
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 这个handler在用户点击Modal的ok按钮的时候触发
  /**
   * 逻辑设计是
   * 1 所有新增加的opportunity对象，都会自动生成一个id，用于检索和定位这个对象。
   * 2 customerName, arr, sfdcLink都不能为空，且sfdcLink必须是唯一的。如果用户a创建了一个opportunity，任何用户（包括a)是无法再生成同一个sfdcLink的opportunity的。
   */
  const onSubmitAddOpportunityHandler = async (frIndex, newOpportunity, setSfdclinkDuplicate, onClose) => {
    // 首先使用 /\/+$/ 正则表达式移除 newOpportunity.sfdcLink 字符串末尾的一个或多个连续的"/"，然后使用 /^http:\/\// 正则表达式
    // 将字符串开始的 "http://" 替换为 "https://"。这样才能是的下面的URL是否相等在逻辑上更准确。
    newOpportunity.sfdcLink = newOpportunity.sfdcLink.replace(/\/+$/, "").replace(/^http:\/\//, "https://");

    let tempSolution = cloneDeep(selectedSolution);
    const opportunityIndex = tempSolution.featureRequests[frIndex].opportunities.findIndex((opportunity) => opportunity.sfdcLink === newOpportunity.sfdcLink);

    if (opportunityIndex !== -1) {
      // 如果找到了与新元素sfdcLink相同的元素，就不增加当前的oppoortunity，并提示用户sfdcLink不能重复
      setSfdclinkDuplicate(true);
      return;
    } else {
      // 如果没有找到与新元素sfdcLink相同的元素，就添加新元素
      // 为新元素随机生成一个id，未来edit的时候通过检索id来定位要修改的元素，而不是根据sfdcLink。如果根据sfdcLink来检索，当修改sfdcLink的时候就被认为是新元素。
      onClose(); //关闭屏幕上的modal
      newOpportunity.id = uuidv4();
      tempSolution.featureRequests[frIndex].opportunities.push(newOpportunity);
    }
    DEBUG && console.log("%ctempSolution", "color: blue; font-weight: bold;", tempSolution);
    // 更新状态
    setSelectedSolution(tempSolution);

    /*______________前端更新完毕，现在开始请求API更新后端的数据库_______________*/
    const eventBody = {
      solutionNameAbbr: oneSolution.solutionNameAbbr,
    };

    const config = {
      params: {
        action: "add-opportunity-in-featurerequest-tab",
        userLogin: userLogin,
        frId: tempSolution.featureRequests[frIndex].id, // 后端检索的时候不能通过frIndex，因为不同的用户同时修改的时候，frIndex可能不同步，而且有可能条目已经被别人删除了。
        newOpportunity: JSON.stringify(newOpportunity), // 对象转换为字符串，请求api的时候，对于复杂对象，必须先序列号
        solutionNameAbbr: oneSolution.solutionNameAbbr,
      },
    };

    try {
      setApiCalled(true);
      const response = await new DataProvider().updateSolution(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, featureRequests[] updated.", "color: green; font-weight: bold;", response);
    } catch (error) {
      console.error("Update Solution featureRequests[] (add new feature request) failed:", error);
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 这个handler在用户点击Modal的ok按钮的时候触发
  /**
   * 1. 普通用户只能看到，修改和删除自己创建的opportunities
   * 2. 管理员可以看到，修改和删除所有的opportunities
   */
  const onSubmitEditOpportunityHandler = async (frIndex, removedOpportunities, editedOpportunities) => {
    DEBUG && console.log("%cremovedOpportunities", "color: blue; font-weight: bold;", removedOpportunities);
    DEBUG && console.log("%ceditedOpportunities", "color: blue; font-weight: bold;", editedOpportunities);
    // userUpdatedOpportunities是最新的修改后的数据，可以直接用来更新前端页面，而无需从后端同步数据。但是用来call api的数据
    // 就必须使用removedOpportunities, editedOpportunities这两个数组。

    // 下面先修改前端页面的显示，前端修改简单，主要是后端代码修改复杂点。
    let solutionItem = cloneDeep(selectedSolution);

    // Remove opportunities
    const updatedOpportunities = solutionItem.featureRequests[frIndex].opportunities.filter((opportunity) => {
      // Only allow deletion if the current user is the owner of the opportunity or is an admin
      if (isAdmin) {
        return !removedOpportunities.some((removedOpportunity) => removedOpportunity.id === opportunity.id);
      } else {
        return !removedOpportunities.some((removedOpportunity) => removedOpportunity.id === opportunity.id && removedOpportunity.login === userLogin);
      }
    });

    // Edit opportunities
    editedOpportunities.forEach((editedOpportunity) => {
      const index = updatedOpportunities.findIndex((opportunity) => opportunity.id === editedOpportunity.id);

      if (index !== -1) {
        // Only allow editing if the current user is the owner of the opportunity or is an admin
        if (isAdmin || updatedOpportunities[index].login === userLogin) {
          updatedOpportunities[index] = editedOpportunity;
        }
      }
    });

    // 在JavaScript中，数组的.filter()和.map()方法都返回一个新的数组，它们不会改变原始数组。最后需要把 updatedOpportunities 保存到原来的数组。
    solutionItem.featureRequests[frIndex].opportunities = updatedOpportunities;

    setSelectedSolution(solutionItem);

    /*______________前端更新完毕，现在开始请求API更新后端的数据库_______________*/
    const eventBody = {
      solutionNameAbbr: oneSolution.solutionNameAbbr,
    };

    const config = {
      params: {
        action: "edit-opportunity-in-featurerequest-tab",
        userLogin: userLogin,
        frId: solutionItem.featureRequests[frIndex].id, // 后端检索的时候不能通过frIndex，因为不同的用户同时修改的时候，frIndex可能不同步，而且有可能条目已经被别人删除了。
        removedOpportunities: JSON.stringify(removedOpportunities), // 对象转换为字符串，请求api的时候，对于复杂对象，必须先序列号
        editedOpportunities: JSON.stringify(editedOpportunities), // 对象转换为字符串，请求api的时候，对于复杂对象，必须先序列号
        solutionNameAbbr: oneSolution.solutionNameAbbr,
      },
    };

    try {
      setApiCalled(true);
      const response = await new DataProvider().updateSolution(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, featureRequests[] updated.", "color: green; font-weight: bold;", response);
    } catch (error) {
      console.error("Update Solution featureRequests[] (edit feature request) failed:", error);
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 这个handler在点击容器右上角的delete按钮的时候触发
  const onDeleteFeatureRequestHandler = async (frIndex, frTitle) => {
    const featureRequestToDelete = selectedSolution.featureRequests[frIndex];

    // Only allow the user who created the featureRequest or an admin to delete it
    if (featureRequestToDelete.login === userLogin || isAdmin) {
      let tempSolution = cloneDeep(selectedSolution);

      // Remove the feature request at frIndex
      tempSolution.featureRequests.splice(frIndex, 1);

      setSelectedSolution(tempSolution);

      // 下面开始请求api，修改后端数据库
      const eventBody = {
        solutionNameAbbr: oneSolution.solutionNameAbbr,
      };

      const config = {
        params: {
          action: "delete-feature-request-in-featurerequest-tab",
          userLogin: userLogin,
          featureRequestTitle: frTitle,
          solutionNameAbbr: oneSolution.solutionNameAbbr,
        },
      };
      try {
        setApiCalled(true);
        const response = await new DataProvider().updateSolution(eventBody, config); // 传入config的action，利用action修改parsedBody
        DEBUG && console.log("%cApiCallCompleted, featureRequests[] updated.", "color: green; font-weight: bold;", response);
      } catch (error) {
        console.error("Update Solution featureRequests[] (add new feature request) failed:", error);
      }
    } else {
      console.error("You do not have permission to delete this feature request.");
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function likeButtonHandler(frId, userLogin) {
    // 首先，深拷贝整个 selectedSolution 对象。这里最终没有考虑使用深拷贝roadmap.releaseHistory + 浅拷贝其他属性来优化性能，是因为如果其他属性有嵌套数组
    // 结构，那么反而会出现其他属性在最后使用return newState的时候被错误修改。考虑到selectedSolution本身是个size很小的对象，我们还是执行整体的深拷贝吧。
    const newState = cloneDeep(selectedSolution);

    // 根据frId先找到目标featureRequest在数组中的index(frIndex)
    const frIndex = newState.featureRequests.findIndex((fr) => fr.id === frId);

    // 当like是undefined的情况下，初始化like为空数组，事实上不会存在这种情况。因为前面在添加新featureRequest的时候，以及自动初始化了like数组。
    // 但在这段代码在老数据结构迁移到新数据结构的时候是有用的，相当于老数据没有这个like字段，也不会出错，代码健壮性更好。
    if (!newState.featureRequests[frIndex].like) {
      newState.featureRequests[frIndex].like = [];
    }

    // 检查 like 数组中是否已经包含了 userLogin，和contentLanguage无关
    const index = newState.featureRequests[frIndex].like.indexOf(userLogin);

    if (index === -1) {
      // 把当前用户的登录信息添加到 like 数组中。
      newState.featureRequests[frIndex].like.push(userLogin);
    } else {
      // 从 like 数组中删除已存在的 userLogin
      newState.featureRequests[frIndex].like.splice(index, 1);
    }

    // 返回更新后的状态
    DEBUG && console.log("%cnewState", "color: blue; font-weight: bold;", newState);

    setSelectedSolution(newState);
    setApiCalled(true);

    // 直接请求api，不在前端修改数据，在云上通过lambda来修改DDB的数据。
    const eventBody = {
      solutionNameAbbr: oneSolution.solutionNameAbbr,
    };

    const config = {
      params: {
        action: "modify-likes-in-feature-request-tab",
        userLogin: userLogin,
        frId: frId, //featureRequest的id属性
        solutionNameAbbr: oneSolution.solutionNameAbbr,
      },
    };

    try {
      const response = await new DataProvider().updateSolution(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, likes updated.", "color: green; font-weight: bold;", response);
    } catch (error) {
      console.error("Update Solution(features' like Attribute) failed:", error);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const NoResources = () => {
    return (
      <Container>
        <center>
          <h3>{t("solutions:SolutionDetails.tabs-content.noResources")}</h3>
        </center>
      </Container>
    );
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const renderContent = () => {
    return (
      <>
        <SpaceBetween size="l">
          <div className="parent-container">
            <div>
              <FormField
                stretch
                label={t(`solutions:SolutionDetails.tabs-content.featureName`)}
                description={t(`solutions:SolutionDetails.tabs-content.featureName_Des`)}
              >
                <Input
                  value={inputData.title || ""}
                  placeholder={t(`solutions:SolutionDetails.tabs-content.featureName_Placeholder`)}
                  onChange={({ detail: { value } }) => setInputData({ ...inputData, title: value })}
                />
              </FormField>
            </div>
            <div style={{ flex: 1 }}>
              <FormField
                stretch
                label={t(`solutions:SolutionDetails.tabs-content.featureDescription`)}
                description={t(`solutions:SolutionDetails.tabs-content.featureDescription_Des`)}
              >
                <Textarea
                  value={inputData.content || ""}
                  placeholder={t(`solutions:SolutionDetails.tabs-content.featureDescription_Placeholder`)}
                  onChange={({ detail: { value } }) => setInputData({ ...inputData, content: value })}
                  rows="1"
                />
              </FormField>
            </div>
            <div>
              <FormField stretch label={t(`FormButton.post`)} description={t(`FormButton.post_Des`)}>
                <Button onClick={onPostHandler} disabled={inputData.title === "" || inputData.content === ""}>
                  {t(`FormButton.post`)}
                </Button>
              </FormField>
            </div>
          </div>
          {onPostAlert && (
            <Alert dismissible={true} onDismiss={() => setOnPostAlert(false)} statusIconAriaLabel="Info">
              {t(`solutions:SolutionDetails.tabs-content.featureName_AlertText`)}
            </Alert>
          )}
          {selectedSolution.featureRequests ? (
            <PipelinesTable
              selectedSolution={selectedSolution}
              onSubmitAddOpportunityHandler={onSubmitAddOpportunityHandler}
              onSubmitEditOpportunityHandler={onSubmitEditOpportunityHandler}
              onDeleteFeatureRequestHandler={onDeleteFeatureRequestHandler}
              likeButtonHandler={likeButtonHandler}
            />
          ) : (
            <SpaceBetween size="l">
              <NoResources />
            </SpaceBetween>
          )}
        </SpaceBetween>
      </>
    );
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return <>{loading ? <LoadingSpinner /> : renderContent()}</>;
};
