import { Header, SpaceBetween, Button } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const PageHeader = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function goBackHandler() {
    navigate(-1);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <Header
        variant="h1"
        description={t("PageHeader.askPalette_subheadline")}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={goBackHandler}>{t("PageHeader.buttonGoBack")}</Button>
          </SpaceBetween>
        }
      >
        {t("PageHeader.askPalette_headline")}
      </Header>
    </>
  );
};

export default PageHeader;
