import { useState, useEffect } from "react";
import { Button, Form, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import EditSolutionPanel from "./edit-solution-panel";
import { DataProvider } from "../../../api/data-provider";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { getFileExtension, generateRandomId } from "../../../common/utils";

import { LoadingSpinner, useCasestudies, useUserProfile } from "../../../components";
import { solutionNameDictionary, categoryDictionary, domainDictionary } from "../../../common/chinese-english-dictionary";
import {
  solutionNameOptions_en,
  solutionNameOptions_cn,
  categoryOptions_en,
  categoryOptions_cn,
  domainOptions_en,
  domainOptions_cn,
  yearOptions,
} from "../../../common/formOptions";
import cloneDeep from "lodash/cloneDeep";

import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
function generateTranslatedAttribute(attribute, value, dictionary) {
  const contentLanguage = attribute.endsWith("_en") ? "cn" : "en";
  const otherAttribute = attribute.slice(0, -2) + contentLanguage;

  const otherValue = value.map((v) => {
    const translatedValue = dictionary[v["label"]];
    if (!translatedValue) {
      DEBUG && console.error(`Invalid value in 'value' array: ${v}`);
    }
    return { label: translatedValue, value: translatedValue };
  });

  return {
    otherAttribute,
    otherValue,
  };
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export function FormHeader({ loadHelpPanelContent }) {
  const { t } = useTranslation();
  const { contentLanguage, setContentLanguage } = useUserProfile();
  const navigate = useNavigate();

  function goBackToSolutionsPageHandler() {
    navigate(-1);
  }

  const changeContentLanguage = () => {
    setContentLanguage(contentLanguage === "en" ? "cn" : "en");
  };

  return (
    <Header
      variant="h1"
      info={
        <Link onFollow={loadHelpPanelContent} variant="info">
          {t("casestudies:CasestudyEditForm.linkInfo")}
        </Link>
      }
      description={t("casestudies:CasestudyEditForm.description")}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button data-testid="header-btn-go-back" onClick={changeContentLanguage}>
            {contentLanguage === "en" ? t("PageHeader.buttonChangeLanguageCN") : t("PageHeader.buttonChangeLanguage")}
          </Button>
          <Button data-testid="header-btn-go-back" onClick={goBackToSolutionsPageHandler}>
            {t("PageHeader.buttonGoBack")}
          </Button>
        </SpaceBetween>
      }
    >
      {t("casestudies:CasestudyEditForm.headerTitle")}
    </Header>
  );
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
function BaseFormContent({ selectedCasestudy, content, errorText = null }) {
  const { t } = useTranslation();
  const { setCasestudiesFetched } = useCasestudies();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();
  const { userLogin, contentLanguage, today } = useUserProfile();

  const [renderFileUploader, setRenderFileUploader] = useState(false);
  const [randomId, setRandomId] = useState("");
  const [fileLogo, setFileLogo] = useState([]);
  const [fileCasestudyImage, setFileCasestudyImage] = useState([]);
  const [fileArchitecture, setFileArchitecture] = useState([]);
  const [errorTextLogo, setErrorTextLogo] = useState("");
  const [errorTextCasestudyImage, setErrorTextCasestudyImage] = useState("");
  const [errorTextArchitecture, setErrorTextArchitecture] = useState("");
  const [logoUploadProgress, setLogoUploadProgress] = useState(0);
  const [casestudyImageUploadProgress, setCasestudyImageUploadProgress] = useState(0);
  const [architectureUploadProgress, setArchitectureUploadProgress] = useState(0);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [isLogoUploadCompleted, setIsLogoUploadCompleted] = useState(false);
  const [isCasestudyImageUploadCompleted, setIsCasestudyImageUploadCompleted] = useState(false);
  const [isArchitectureUploadCompleted, setIsArchitectureUploadCompleted] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(true); // 在上传前的判断逻辑中没有出错，已经在上传，但是不是只传输完成
  const [isCasestudyCreateCompleted, setIsCasestudyCreateCompleted] = useState(false);

  // // Get the selected solution object passed through useNavigate before jumping to this page
  // const location = useLocation();
  // const selectedCasestudy = location.state?.selectedCasestudy;

  // 使用 JSON.parse() 和 JSON.stringify() 方法执行深拷贝，否则直接修改 formattedData 的属性或子属性，那么 selectedCasestudy 也会被修改，因为它们指向同一个对象（即浅拷贝）。
  // formattedData是DynamoDB的数据格式，用于更新后台数据库
  const [formattedData, setFormattedData] = useState(null);
  const [data, setData] = useState(null);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    if (selectedCasestudy) {
      setFormattedData(cloneDeep(selectedCasestudy));

      // 对部分属性值作格式转换以适应页面表格，并初始化Data。
      const tempData = cloneDeep(selectedCasestudy);
      const transformedTempData = {
        ...tempData,
        solutionsUsed_en: tempData.solutionsUsed_en
          ? tempData.solutionsUsed_en.map((item) => solutionNameOptions_en.find((option) => option.label === item))
          : [],
        solutionsUsed_cn: tempData.solutionsUsed_cn
          ? tempData.solutionsUsed_cn.map((item) => solutionNameOptions_cn.find((option) => option.label === item))
          : [],
        category_en: tempData.category_en ? tempData.category_en.map((item) => categoryOptions_en.find((option) => option.label === item)) : [],
        category_cn: tempData.category_cn ? tempData.category_cn.map((item) => categoryOptions_cn.find((option) => option.label === item)) : [],
        domain_en: tempData.domain_en ? tempData.domain_en.map((item) => domainOptions_en.find((option) => option.label === item)) : [],
        domain_cn: tempData.domain_cn ? tempData.domain_cn.map((item) => domainOptions_cn.find((option) => option.label === item)) : [],
        year: tempData.year ? yearOptions.find((option) => option.label === tempData.year) : "",
      };

      setData(transformedTempData);
    }
  }, [selectedCasestudy]);

  useEffect(() => {
    if (formattedData && data) {
      setLoading(false);
      DEBUG && console.log("%cformattedData (used to call API to create DynamoDB items):", "color: red; font-weight: bold;", formattedData);
      DEBUG && console.log("%cdata (used for the form):", "color: red; font-weight: bold;", data);
    }
  }, [formattedData, data]);

  useEffect(() => {
    setRandomId(generateRandomId());
  }, []);

  useEffect(() => {
    // For debug purpose
    DEBUG && console.log("%clogoUploadProgress", "color: blue; font-weight: bold;", logoUploadProgress);
    DEBUG && console.log("%ccasestudyImageUploadProgress", "color: blue; font-weight: bold;", casestudyImageUploadProgress);
    DEBUG && console.log("%carchitectureUploadProgress", "color: blue; font-weight: bold;", architectureUploadProgress);
  }, [logoUploadProgress, casestudyImageUploadProgress, architectureUploadProgress]);

  // 监控状态，一旦文件上传成功，并且上传完毕，就执行对DynamoDB的写操作，更新数据库。
  useEffect(() => {
    async function updateCasestudy() {
      DEBUG && console.log("%cuploadSuccessful", "color: green; font-weight: bold;", uploadSuccessful);
      DEBUG && console.log("%cisLogoUploadCompleted", "color: green; font-weight: bold;", isLogoUploadCompleted);
      DEBUG && console.log("%cisCasestudyImageUploadCompleted", "color: green; font-weight: bold;", isCasestudyImageUploadCompleted);
      DEBUG && console.log("%cisArchitectureUploadCompleted", "color: green; font-weight: bold;", isArchitectureUploadCompleted);

      if (uploadSuccessful && isLogoUploadCompleted && isCasestudyImageUploadCompleted && isArchitectureUploadCompleted) {
        try {
          const response = await new DataProvider().updateCasestudy(formattedData);
          DEBUG && console.log("%cApiCallCompleted, set 'isCasestudyCreateCompleted' to 'true' now.", "color: green; font-weight: bold;", response);
          setIsCasestudyCreateCompleted(true);
          const newMessage = {
            type: "success",
            content: t("notification.updateCasestudy"),
            dismissible: true,
            dismissLabel: "Dismiss message",
            statusIconAriaLabel: "success",
          };
          pushNewNotification(newMessage);
        } catch (error) {
          console.error("Create Casestudy failed:", error);
          const newMessage = {
            type: "error",
            content: t("notification.createOrUpdateCasestudyApiError"),
            dismissible: true,
            dismissLabel: "Dismiss message",
            statusIconAriaLabel: "error",
          };
          pushNewNotification(newMessage);
        } finally {
          setSubmitting(false);
        }
      }
    }

    updateCasestudy();
  }, [uploadSuccessful, isLogoUploadCompleted, isCasestudyImageUploadCompleted, isArchitectureUploadCompleted, formattedData]);

  // 监听上传状态，如果已经完成api call，创建了dynamodb的新条目，就立刻发出通知，并重新加载最新的case study list
  useEffect(() => {
    if (isCasestudyCreateCompleted) {
      setCasestudiesFetched(false);
      navigate("/casestudies");
    }
  }, [isCasestudyCreateCompleted]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onChangeCasestudyHandler = (attribute, value) => {
    DEBUG && console.log(`%c${attribute}:`, "color: blue; font-weight: bold;", value);

    switch (attribute) {
      case `solutionsUsed_${contentLanguage}`:
        const otherLanguage = contentLanguage === "en" ? "cn" : "en";
        const otherSolutionsUsedAttribute = `solutionsUsed_${otherLanguage}`;
        const otherSolutionsUsedValue = value.map((item) => ({
          label: solutionNameDictionary[item.label],
          value: item.value,
        }));

        const transformedValue = value.map((item) => item.label); // Transform the element from {} to String
        DEBUG && console.log("%csolutionNameDictionary", "color: blue; font-weight: bold;", solutionNameDictionary);
        const otherTransformedValue = transformedValue.map((item) => solutionNameDictionary[item]);
        DEBUG && console.log("%cotherTransformedValue", "color: blue; font-weight: bold;", otherTransformedValue);

        setData({ ...data, [attribute]: value, [otherSolutionsUsedAttribute]: otherSolutionsUsedValue });
        setFormattedData({ ...formattedData, [attribute]: transformedValue, [otherSolutionsUsedAttribute]: otherTransformedValue });
        break;

      case `category_${contentLanguage}`: // 自动生成另一种语言的Key/Value Pair
        const { otherAttribute: otherCategoryAttribute, otherValue: otherCategoryValue } = generateTranslatedAttribute(attribute, value, categoryDictionary);

        DEBUG && console.log("%cotherCategoryAttribute", "color: blue; font-weight: bold;", otherCategoryAttribute);
        DEBUG && console.log("%cotherCategoryValue", "color: blue; font-weight: bold;", otherCategoryValue);

        setData({ ...data, [attribute]: value, [otherCategoryAttribute]: otherCategoryValue });
        setFormattedData({
          ...formattedData,
          [attribute]: value.map((item) => item.value),
          [otherCategoryAttribute]: otherCategoryValue.map((item) => item.value),
        });
        break;

      case `domain_${contentLanguage}`:
        const { otherAttribute: otherDomainAttribute, otherValue: otherDomainValue } = generateTranslatedAttribute(attribute, value, domainDictionary);

        setData({ ...data, [attribute]: value, [otherDomainAttribute]: otherDomainValue });
        setFormattedData({
          ...formattedData,
          [attribute]: value.map((item) => item.value),
          [otherDomainAttribute]: otherDomainValue.map((item) => item.value),
        });
        break;

      case "year":
        setData({ ...data, [attribute]: value });
        setFormattedData({ ...formattedData, [attribute]: value.value });
        break;

      default:
        setFormattedData({ ...formattedData, [attribute]: value });
        break;
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onChangeFileHandler = (attribute, value) => {
    switch (attribute) {
      case "customerLogo":
        setFileLogo(value);
        break;

      case "casestudyImage":
        setFileCasestudyImage(value);
        break;

      case "techArchitectureImage":
        setFileArchitecture(value);
        break;

      default:
        break;
    }
  };

  const onChangeFileErrorHandler = (fileName, value) => {
    switch (fileName) {
      case "customerLogo":
        setErrorTextLogo(value);
        break;

      case "casestudyImage":
        setErrorTextCasestudyImage(value);
        break;

      case "techArchitectureImage":
        setErrorTextArchitecture(value);
        break;

      default:
        break;
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function handleFileUploads() {
    DEBUG && console.log("%cformattedData", "color: blue; font-weight: bold;", formattedData);

    const filePath = `store/casestudies/${formattedData.solutionNameAbbr}-${formattedData.customerNameAbbr}`;
    const newFormattedData = cloneDeep(formattedData);

    const updateNewFormattedData = (key, value) => {
      newFormattedData[key] = value;
    };

    const logoUploadPromise = fileLogo[0]
      ? (async () => {
          try {
            const ext = getFileExtension(fileLogo[0].name);
            const newFileName = `customer-logo-${today}-${randomId}.${ext}`;
            updateNewFormattedData("customerLogo", `${filePath}/${newFileName}`); // 不需要以'/'作为首字符，统一按照S3 Key的命名规则。我在渲染的时候会自动加上'/'，记住渲染的时候一定要加上，否则无法正常渲染
            await new DataProvider().uploadToS3(userLogin, fileLogo[0], filePath, setLogoUploadProgress, setIsLogoUploadCompleted, newFileName);
          } catch (error) {
            setUploadSuccessful(false);
            onChangeFileErrorHandler("customerLogo", t(`casestudies:CasestudyCreateForm.anErrorOccured`));
            console.error("Logo upload failed:", error);
          }
        })()
      : (async () => {
          DEBUG && console.log("%ccustomerLogo image file doesn't exist, set 'isLogoUploadCompleted' to 'true'.", "color: green; font-weight: bold;");
          setIsLogoUploadCompleted(true);
        })();

    const casestudyImageUploadPromise = fileCasestudyImage[0]
      ? (async () => {
          try {
            const ext = getFileExtension(fileCasestudyImage[0].name);
            const newFileName = `casestudy-image-${today}-${randomId}.${ext}`;
            updateNewFormattedData("casestudyImage", `${filePath}/${newFileName}`);
            await new DataProvider().uploadToS3(
              userLogin,
              fileCasestudyImage[0],
              filePath,
              setCasestudyImageUploadProgress,
              setIsCasestudyImageUploadCompleted,
              newFileName
            );
          } catch (error) {
            setUploadSuccessful(false);
            onChangeFileErrorHandler("casestudyImage", t(`casestudies:CasestudyCreateForm.anErrorOccured`));
            console.error("casestudyImage upload failed:", error);
          }
        })()
      : (async () => {
          DEBUG && console.log("%ccasestudy image file doesn't exist, set 'isCasestudyImageUploadCompleted' to 'true'.", "color: green; font-weight: bold;");
          setIsCasestudyImageUploadCompleted(true);
        })();

    const architectureUploadPromise = fileArchitecture[0]
      ? (async () => {
          try {
            const ext = getFileExtension(fileArchitecture[0].name);
            const newFileName = `tech-architecture-${today}-${randomId}.${ext}`;
            updateNewFormattedData("techArchitectureImage", `${filePath}/${newFileName}`);
            await new DataProvider().uploadToS3(
              userLogin,
              fileArchitecture[0],
              filePath,
              setArchitectureUploadProgress,
              setIsArchitectureUploadCompleted,
              newFileName
            );
          } catch (error) {
            setUploadSuccessful(false);
            onChangeFileErrorHandler("techArchitectureImage", t(`casestudies:CasestudyCreateForm.anErrorOccured`));
            console.error("techArchitecture image file upload failed:", error);
          }
        })()
      : (async () => {
          DEBUG &&
            console.log("%ctechArchitecture ImageFile doesn't exist, set 'isArchitectureUploadCompleted' to 'true'.", "color: green; font-weight: bold;");
          setIsArchitectureUploadCompleted(true);
        })();

    await Promise.all([logoUploadPromise, casestudyImageUploadPromise, architectureUploadPromise]);
    setFormattedData(newFormattedData);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      handleFileUploads(); // 调用新的handleFileUploads函数处理文件上传
    } catch (error) {
      console.error("One or more files failed to upload. New case study hasn't been put into DynamoDB. Try again on the webpage.");
      const newMessage = {
        type: "error",
        content: t("notification.filesUploadFailed"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
    }
  };

  const onCancelClick = () => {
    navigate("/casestudies");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("casestudies:CasestudyCreateForm.cancelButton")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler} loading={submitting} disabled={submitting}>
              {submitting ? t("casestudies:CasestudyCreateForm.createCasestudyButtonSubmitting") : t("casestudies:CasestudyCreateForm.createCasestudyButton")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({
          onChangeCasestudyHandler,
          data,
          formattedData,
          setFormattedData,
          renderFileUploader,
          onChangeFileHandler,
          onChangeFileErrorHandler,
          fileLogo,
          fileCasestudyImage,
          fileArchitecture,
          errorTextLogo,
          errorTextCasestudyImage,
          errorTextArchitecture,
          logoUploadProgress,
          casestudyImageUploadProgress,
          architectureUploadProgress,
        })}
      </Form>
    </form>
  );
}

/*****************************************************************************/
export function FormContent({ selectedCasestudy, loadHelpPanelContent }) {
  return (
    <BaseFormContent
      selectedCasestudy={selectedCasestudy}
      content={({
        onChangeCasestudyHandler,
        data,
        formattedData,
        setFormattedData,
        renderFileUploader,
        onChangeFileHandler,
        onChangeFileErrorHandler,
        fileLogo,
        fileCasestudyImage,
        fileArchitecture,
        errorTextLogo,
        errorTextCasestudyImage,
        errorTextArchitecture,
        logoUploadProgress,
        casestudyImageUploadProgress,
        architectureUploadProgress,
      }) => (
        <SpaceBetween size="l">
          <EditSolutionPanel
            loadHelpPanelContent={loadHelpPanelContent}
            onChangeCasestudyHandler={onChangeCasestudyHandler}
            data={data}
            formattedData={formattedData}
            setFormattedData={setFormattedData}
            renderFileUploader={renderFileUploader}
            onChangeFileHandler={onChangeFileHandler}
            onChangeFileErrorHandler={onChangeFileErrorHandler}
            fileLogo={fileLogo}
            fileCasestudyImage={fileCasestudyImage}
            fileArchitecture={fileArchitecture}
            errorTextLogo={errorTextLogo}
            errorTextCasestudyImage={errorTextCasestudyImage}
            errorTextArchitecture={errorTextArchitecture}
            logoUploadProgress={logoUploadProgress}
            casestudyImageUploadProgress={casestudyImageUploadProgress}
            architectureUploadProgress={architectureUploadProgress}
          />
        </SpaceBetween>
      )}
    />
  );
}
