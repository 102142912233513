import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CollectionPreferences, Container, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState, TableNoMatchState, TableHeader } from "./tab-gtm-materials-table-empty-nomatch-state";
import { useUserProfile, LoadingSpinner } from "../../../components";
import { paginationLabels } from "../../../common/labels";
import { createColumnDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./tab-gtm-materials-tableconfig";
import { getFilterCounterText } from "../../../common/tableCounterStrings";
import { addColumnSortLabels } from "../../../common/labels";
import { useColumnWidths } from "../../../common/use-column-widths";
import { useLocalStorage } from "../../../common/use-local-storage";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
const MaterialsTable = ({ gtmMaterialsArray }) => {
  const { t } = useTranslation();

  const COLUMN_DEFINITIONS = addColumnSortLabels(createColumnDefinitions(t));
  const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
  const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
  const DEFAULT_PREFERENCES = createDefaultPreferences();

  const [columnDefinitions, saveWidths] = useColumnWidths(`React-Tab-Gtm-Materials-Widths`, COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage(`React-Tab-Gtm-Materials-Preferences`, DEFAULT_PREFERENCES);

  const [selectedItems, setSelectedItems] = useState([]);
  // const isOnlyOneSelected = selectedItems.length === 1; /* No button in the table header, so comment this line out. */

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(gtmMaterialsArray, {
    filtering: {
      empty: <TableEmptyState resourceName="GTMmaterials" />,
      noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    selection: {},
  });

  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        items={items}
        selectionType="single"
        onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
        selectedItems={selectedItems}
        header={<TableHeader title={t("solutions:SolutionDetails.tabs-content.materials")} selectedItems={selectedItems} totalItems={gtmMaterialsArray} />}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Find materials"
            filteringPlaceholder={t("solutions:useCollection.findMaterials")}
            countText={getFilterCounterText(filteredItemsCount)}
            disabled={false}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={t("solutions:CollectionPreferences.title")}
            confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
            cancelLabel={t("solutions:CollectionPreferences.cancel")}
            disabled={false}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: t("solutions:CollectionPreferences.pageSize"),
              options: PAGE_SIZE_OPTIONS,
            }}
            visibleContentPreference={{
              title: t("solutions:CollectionPreferences.selectVisibleColumns"),
              options: VISIBLE_CONTENT_OPTIONS,
            }}
            wrapLinesPreference={{
              label: t("solutions:CollectionPreferences.wrapLinesLabel"),
              description: t("solutions:CollectionPreferences.wrapLinesDescription"),
            }}
            stripedRowsPreference={{
              label: t("solutions:CollectionPreferences.stripedRowsLabel"),
              description: t("solutions:CollectionPreferences.stripedRowsDescription"),
            }}
          />
        }
        ariaLabels={{
          itemSelectionLabel: (data, row) => `select ${row.name}`,
          allItemsSelectionLabel: () => "select all",
          selectionGroupLabel: "Casestudies selection",
        }}
      />
    </div>
  );
};

/*****************************************************************************/
export const TabGtmPackages = ({ oneSolution }) => {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();
  // const { setFetched } = useSolutions();
  const [loading, setLoading] = useState(true);
  const [selectedSolution, setSelectedSolution] = useState();
  // const [prevSolution, setPrevSolution] = useState(null);

  /**
   * 不要使用const [selectedSolution, setSelectedSolution] = useState(oneSolution); 这会造成快速点击tab的时候，页面一直是<LoadSpinger />
   * 当 oneSolution 作为 prop 改变时，你在 useState(oneSolution) 这里只是为 selectedSolution 设置了初始值。这意味着，只有在组件的初始渲染阶段，
   * oneSolution 的值才会被用来初始化 selectedSolution。在后续的重新渲染中，即使 oneSolution 的值发生了改变，selectedSolution 的值也不会被更新，
   * 除非你在某个地方调用了 setSelectedSolution。
   *
   * 所以分开写，先const [selectedSolution, setSelectedSolution] = useState(); 然后用useEffect赋值就能解决这个问题。
   */
  useEffect(() => {
    if (oneSolution) {
      setSelectedSolution(oneSolution);
    }
  }, [oneSolution]);

  useEffect(() => {
    DEBUG && console.log("%cselectedSolution", "color: blue; font-weight: bold;", selectedSolution);
    if (!selectedSolution) {
      return;
    } else {
      setLoading(false);
    }
  }, [selectedSolution]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const NoResources = () => {
    return (
      <Container>
        <center>
          <h3>{t("solutions:SolutionDetails.tabs-content.noResources")}</h3>
        </center>
      </Container>
    );
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const renderContent = () => {
    if (selectedSolution["gtmMaterials_" + contentLanguage] && selectedSolution["gtmMaterials_" + contentLanguage].length > 0) {
      return (
        <SpaceBetween size="l">
          <MaterialsTable gtmMaterialsArray={selectedSolution["gtmMaterials_" + contentLanguage]} />
        </SpaceBetween>
      );
    } else {
      return (
        <SpaceBetween size="l">
          <NoResources />
        </SpaceBetween>
      );
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return <>{loading ? <LoadingSpinner /> : renderContent()}</>;
};
