import { Button } from "@cloudscape-design/components";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onReset: () => void;
}

export const ResetChat: FC<Props> = ({ onReset }) => {
  const { t } = useTranslation();
  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-mt-6 tw-mb-2">
      <Button onClick={() => onReset()}>{t("AskPalette.buttonReset")}</Button>
    </div>
  );
};
