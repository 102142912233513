import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Amplify } from "aws-amplify";
import updatedAwsConfig from "./config/awsConfig";
import { Authenticator } from "@aws-amplify/ui-react";
import { UserProfileContextProvider } from "./components/Context/UserProfileContext";
import { SolutionsContextProvider } from "./components/Context/SolutionsContext";
import { CasestudiesContextProvider } from "./components/Context/CasestudiesContext";
import { AppManagementContextProvider } from "./components/Context/AppManagementContext";
import { NotificationContextProvider } from "./components/Context/NotificationContext";
import { BrowserRouter } from "react-router-dom";
import { Spinner } from "@cloudscape-design/components";
import { ClickstreamAnalytics } from "@aws/clickstream-web";
import paletteConfig from "./config/aws-palette-config.json";
import App from "./App";
import "./i18next";
import "@cloudscape-design/global-styles/index.css";
import "./styles/index.scss";
import "./styles/palette.scss";
import "./styles/tailwind-chatbot-with-prefix.css";
// import "./styles/globals.css";

Amplify.configure(updatedAwsConfig);

const DEBUG = paletteConfig.debugMode;

ClickstreamAnalytics.init({
  appId: "palette",
  endpoint: "https://clickstream.on.gcr.solutions.aws.dev/collect",
  isLogEvents: DEBUG,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <UserProfileContextProvider>
        <SolutionsContextProvider>
          <CasestudiesContextProvider>
            <AppManagementContextProvider>
              <NotificationContextProvider>
                <BrowserRouter>
                  <Suspense fallback={<Spinner />}>
                    <App />
                  </Suspense>
                </BrowserRouter>
              </NotificationContextProvider>
            </AppManagementContextProvider>
          </CasestudiesContextProvider>
        </SolutionsContextProvider>
      </UserProfileContextProvider>
    </Authenticator.Provider>
  </React.StrictMode>
);

/**
 * 如果在项目中没有使用 React.lazy 或其他可能返回 Promise 的组件，那么在 index.js 中使用 React.Suspense 没有任何效果，你可以安全地移除它。
 * 因为suspense要和lazy配合使用。
 * 但是我的项目中使用了i18next，在 i18next 中你可以选择使用 Suspense 来处理异步加载翻译资源的情况。如果在i18next 配置中启用了 useSuspense
 * 选项（默认情况下是启用的），那么在翻译资源加载完成之前，任何尝试使用这些资源的组件都会导致一个 Suspense 边界被触发，显示其 fallback。
 * 所以我在使用 i18next 并且想要在翻译资源加载期间显示一个加载状态，就在<App />外包裹了 React.Suspense 组件，并提供一个 fallback UI。
 */
