import { Box, Button, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { getHeaderCounterText, getServerHeaderCounterText } from "./tableCounterStrings";
import { InfoLink } from "../../../components";
import { useTranslation } from "react-i18next";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const TableNoMatchState = (props) => {
  const { t } = useTranslation();
  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>{t("common:tableFilter.noMatch")}</b>
          <Box variant="p" color="inherit">
            {t("common:tableFilter.weCantFindAMatch")}
          </Box>
        </div>
        <Button onClick={props.onClearFilter}>Clear filter</Button>
      </SpaceBetween>
    </Box>
  );
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const TableEmptyState = ({ resourceName }) => {
  const { t } = useTranslation();

  // 获取 resourceName 的翻译
  const translatedResourceName = t(`common:resourceName.${resourceName}`);

  // console.log(translatedResourceName);

  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>{t("common:tableFilter.noResourcesTitle", { resourceName: translatedResourceName })}</b>
          <Box variant="p" color="inherit">
            {t("common:tableFilter.noResourcesDescription", { resourceName: translatedResourceName })}
          </Box>
        </div>
        {/* <Button onClick={onClickHandler}>{t("common:tableFilter.CreateResource", { resourceName: translatedResourceName })}</Button> */}
      </SpaceBetween>
    </Box>
  );
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
function getCounter(props) {
  if (props.counter) {
    return props.counter;
  }
  if (!props.totalItems) {
    return null;
  }
  if (props.serverSide) {
    return getServerHeaderCounterText(props.totalItems, props.selectedItems);
  }
  return getHeaderCounterText(props.totalItems, props.selectedItems); // This is what we actually return. xuhi@
}

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const TableHeader = (props) => {
  return (
    <Header
      variant={props.variant}
      counter={getCounter(props)}
      info={props.loadHelpPanelContent && <InfoLink onFollow={props.loadHelpPanelContent} ariaLabel={`Information about ${props.title}.`} />}
      description={props.description}
      actions={props.actionButtons}
    >
      {props.title}
    </Header>
  );
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const CounterLink = ({ children }) => {
  return (
    <Link variant="awsui-value-large" href="#">
      {children}
    </Link>
  );
};
