import { Button, Header, SpaceBetween, Link } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { LinkProps } from "@cloudscape-design/components/link";
import { useNavigate } from "react-router-dom";

interface HomeHeaderProps {
  loadHelpPanelContent?: LinkProps["onFollow"];
}

const HomeHeader = ({ loadHelpPanelContent }: HomeHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <SpaceBetween direction="vertical" size="xs">
      <Header
        variant="h1"
        info={
          loadHelpPanelContent && (
            <Link onFollow={loadHelpPanelContent} variant="info">
              {t("PageHeader.linkInfo")}
            </Link>
          )
        }
      >
        {t("name")}
      </Header>
      <div style={{ color: "#fff", width: "60%" }}>
        <h3>{t("App.HomepageHeader_headline")}</h3>
        <p>{t("App.HomepageHeader_subheadline")}</p>
      </div>

      <SpaceBetween direction="horizontal" size="xs">
        <Button
          onClick={() => {
            navigate("/solutions");
          }}
        >
          {t("Button.explore_solutions")}
        </Button>
        <Button
          onClick={() => {
            navigate("/casestudies");
          }}
        >
          {t("Button.explore_casestudies")}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            navigate(`/app`);
          }}
        >
          {t("Button.appManagement")}
        </Button>
      </SpaceBetween>
    </SpaceBetween>
  );
};

export default HomeHeader;
