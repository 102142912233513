import {
  Button,
  Container,
  DatePicker,
  ExpandableSection,
  FileUpload,
  FormField,
  Header,
  Input,
  ProgressBar,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useUserProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import { addForm, removeForm, updateForm, moveForm, getFileUrl } from "../../../common/utils";
import { v4 as uuidv4 } from "uuid";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

function FileUploaderFooter({ formattedData, bannerImageFiles, setBannerImageFiles, setSubmitDisabled, item }) {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function isFileValid(fileSelected) {
    const file = fileSelected[0];
    if (!file) {
      DEBUG && console.log("The file hasn't been selected.");
      setSubmitDisabled(false);
      return "valid";
    }

    // 检查文件类型是否符合条件
    const acceptedFileTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-powerpoint",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "image/jpeg",
      "image/png",
      "image/gif",
      "image/bmp",
      "image/webp",
      "image/svg+xml",
      "video/mp4",
    ];
    const isAcceptedFileType = acceptedFileTypes.includes(file.type);

    // 检查文件大小是否小于 100MB
    const isSizeValid = file.size <= 100 * 1024 * 1024;

    let validationResult;

    if (isAcceptedFileType && isSizeValid) {
      DEBUG && console.log("文件类型和大小均有效");
      setSubmitDisabled(false);
      validationResult = "valid";
    } else {
      if (!isAcceptedFileType && !isSizeValid) {
        DEBUG && console.log("文件类型无效，请上传文档或图片文件；文件大小超过(100MB)，请上传较小的文件");
        validationResult = "typeAndSizeError";
      } else if (!isAcceptedFileType) {
        DEBUG && console.log("文件类型无效，请上传文档或图片文件");
        validationResult = "typeError";
      } else if (!isSizeValid) {
        DEBUG && console.log("文件大小超过(100MB)，请上传较小的文件");
        validationResult = "sizeError";
      }
      setSubmitDisabled(true);
    }

    return validationResult;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onChangeFileHandler = (newFileValue, itemId) => {
    // 检查文件是否有效
    const validationResult = isFileValid(newFileValue);
    const fileIsValid = validationResult === "valid";

    let updatedErrorText = "";
    if (validationResult === "typeAndSizeError") {
      updatedErrorText = t("FileUpload.fileTypeErrorAndSizeError");
    } else if (validationResult === "typeError") {
      updatedErrorText = t("FileUpload.fileTypeError");
    } else if (validationResult === "sizeError") {
      updatedErrorText = t("FileUpload.fileSizeError");
    }
    setBannerImageFiles((prevGtmFiles) => {
      // 检查是否存在具有匹配 id 的对象
      const existingFileObj = prevGtmFiles.find((fileObj) => fileObj.id === itemId);

      // 如果存在，则更新该对象
      if (existingFileObj) {
        const updatedGtmFiles = prevGtmFiles.map((fileObj) => {
          if (fileObj.id === itemId) {
            return { ...fileObj, file: fileIsValid ? newFileValue : fileObj.file, errorText: updatedErrorText };
          }
          return fileObj;
        });
        return updatedGtmFiles;
      } else {
        // 如果不存在，且文件有效，创建一个新对象并将其添加到 prevGtmFiles 中
        const newFileObj = { id: itemId, file: fileIsValid ? newFileValue : [], errorText: updatedErrorText, progress: 0, complete: false };
        return [...prevGtmFiles, newFileObj];
      }
    });
  };

  const theCurrentFileKey = formattedData.bannerImages.find((Obj) => Obj.id === item.id).fileKey;
  const uploadButtonText = theCurrentFileKey ? theCurrentFileKey : t("solutions:SolutionForm.fileText");

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <ExpandableSection headerText={t("FormButton.additional_settings_uploadfiles")} variant="footer">
      <SpaceBetween size="l">
        <FormField label={t(`solutions:SolutionForm.fileLabel`)} description={t(`solutions:SolutionForm.fileLabel_Des`)}>
          <FileUpload
            value={bannerImageFiles.find((Obj) => Obj.id === item.id)?.file || []}
            onChange={({ detail }) => {
              onChangeFileHandler(detail.value, item.id);
            }}
            errorText={bannerImageFiles.find((Obj) => Obj.id === item.id)?.errorText || ""}
            constraintText={t(`solutions:SolutionForm.file_Constrain`)}
            i18nStrings={{
              uploadButtonText: (e) => (e ? "Choose files" : uploadButtonText), // multiple文件的时候，e=true，否则为false
              dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
              removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
              limitShowFewer: "Show fewer files",
              limitShowMore: "Show more files",
              errorIconAriaLabel: "Error",
            }}
            showFileLastModified
            showFileSize
            showFileThumbnail
          />
          <ProgressBar
            value={bannerImageFiles.find((Obj) => Obj.id === item.id)?.progress || 0}
            label={t(`casestudies:CasestudyCreateForm.progressBarLabel`)}
          />
        </FormField>
      </SpaceBetween>
    </ExpandableSection>
  );
}

/*****************************************************************************/
export default function BannerImagePanel({ formattedData, setFormattedData, bannerImageFiles, setBannerImageFiles, setSubmitDisabled }) {
  const { contentLanguage } = useUserProfile();
  const { t } = useTranslation();

  return (
    <>
      <SpaceBetween size="l">
        {/* The 2nd container: Materials ++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <>
          {(formattedData?.bannerImages || []).map((item, itemIndex) => (
            <div key={item.id}>
              <Container
                header={
                  <Header variant="h2" description={`<${itemIndex + 1}>`}>
                    {t("App.header_containerBannerImage")}
                  </Header>
                }
                footer={
                  <FileUploaderFooter
                    formattedData={formattedData}
                    bannerImageFiles={bannerImageFiles}
                    setBannerImageFiles={setBannerImageFiles}
                    setSubmitDisabled={setSubmitDisabled}
                    item={item}
                  />
                }
              >
                <SpaceBetween size="l">
                  <SpaceBetween size="l" direction="horizontal">
                    <FormField label={t(`App.formfield_name`)} description={t(`App.formfield_name_Des`)}>
                      <Input
                        value={item.name || ""}
                        onChange={({ detail: { value } }) => {
                          updateForm(setFormattedData, `bannerImages`, "name", value, item.id);
                        }}
                      />
                    </FormField>

                    <FormField label={t(`App.formfield_updateDate`)} description={t(`App.formfield_updateDate_Des`)}>
                      <DatePicker
                        placeholder="YYYY/MM/DD"
                        value={item.updateDate}
                        onChange={({ detail: { value } }) => {
                          updateForm(setFormattedData, `bannerImages`, "updateDate", value, item.id);
                        }}
                      />
                    </FormField>
                  </SpaceBetween>
                  <FormField label={t(`App.formfield_image`)} description={t(`App.formfield_image_Des`)}>
                    <img src={getFileUrl(item.fileKey)} alt="Description of the image" />
                  </FormField>
                  {/* add up/down/remove buttons */}
                  <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des`)}>
                    <SpaceBetween size="s" direction="horizontal">
                      <Button
                        iconName="angle-up"
                        onClick={(event) => {
                          event.preventDefault();
                          moveForm(setFormattedData, `bannerImages`, "up", itemIndex);
                        }}
                      />
                      <Button
                        iconName="angle-down"
                        onClick={(event) => {
                          event.preventDefault();
                          moveForm(setFormattedData, `bannerImages`, "down", itemIndex);
                        }}
                      />
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          removeForm(setFormattedData, `bannerImages`, item.id);
                        }}
                      >
                        {t(`FormButton.removeButton`)}
                      </Button>
                    </SpaceBetween>
                  </FormField>
                </SpaceBetween>
              </Container>
            </div>
          ))}
          <Button
            onClick={(event) => {
              event.preventDefault();
              const randomId = uuidv4();
              addForm(setFormattedData, `bannerImages`, {
                name: "", // 这只是为了在banners tab中能选择到对应的image。内部使用，不需要使用中英文两个名字。
                // Below attributes will be automatically generated
                fileKey: "",
                id: randomId,
                updateDate: "",
              });
            }}
          >
            {t(`FormButton.addButton`)}
          </Button>
        </>
      </SpaceBetween>
    </>
  );
}
