import { Navigate, useLocation } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { useState, useEffect } from "react";

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => setIsLoading(false))
      .catch(() => {
        return <Navigate to="/login" state={{ from: location }} replace />;
      });
  }, []);

  if (isLoading) return;

  return children;

  console.log(route); //It will show state of "idle", and then quickly change to "authenticated".

  // if (route !== 'authenticated') {
  //     return <Navigate to='/login' state={{ from: location }} replace />;
  // }
  // return children;
};
