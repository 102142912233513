import { useState, useEffect } from "react";
import { Container, DatePicker, FormField, Header, Input, Multiselect, RadioGroup, SpaceBetween, Textarea } from "@cloudscape-design/components";
import InfoLink from "../../../components/InfoLink/info-link";
import { useTranslation } from "react-i18next";

/*****************************************************************************/
export default function EditUserPreferences({
  loadHelpPanelContent,
  onChangeUiLanguageHandler,
  onChangeContentLanguageHandler,
  onChangeFullNameHandler,
  uiLanguage,
  contentLanguage,
  fullNameForForm,
}) {
  const { t } = useTranslation();
  return (
    <Container header={<Header variant="h2">{t("UserPreferences.NewPreferenceSettings")}</Header>}>
      <SpaceBetween size="l">
        <FormField label={t("UserPreferences.fullName")} description={t("UserPreferences.fullName_Des")}>
          <Input
            value={fullNameForForm || ""}
            placeholder={t(`UserPreferences.fullName_Placeholder`)}
            onChange={({ detail: { value } }) => onChangeFullNameHandler(value)}
          />
        </FormField>
        <FormField label={t("UserPreferences.uiLanguage")} description={t("UserPreferences.uiLanguage_Des")}>
          <RadioGroup
            onChange={({ detail: { value } }) => onChangeUiLanguageHandler(value)}
            value={uiLanguage || "en"}
            items={[
              {
                value: "en",
                label: t("UserPreferences.English"),
              },
              {
                value: "cn",
                label: t("UserPreferences.Chinese"),
              },
            ]}
          />
        </FormField>
        <FormField label={t("UserPreferences.contentLanguage")} description={t("UserPreferences.contentLanguage_Des")}>
          <RadioGroup
            onChange={({ detail: { value } }) => onChangeContentLanguageHandler(value)}
            value={contentLanguage || "en"}
            items={[
              {
                value: "en",
                label: t("UserPreferences.English"),
              },
              {
                value: "cn",
                label: t("UserPreferences.Chinese"),
              },
            ]}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}
