/**
 * 最新修改后的代码，你不用担心path是否以/开头，以及baseUrl是否以/结尾，都能正确返回你需要的绝对路径和相对路径。
 * 如果是相对路径，那么会以/开头。
 *
 * 尽管代码现在能容错各种情况，还是建议在dynamodb里面保持的path不要以/开头，因为S3的key的首字符不是/
 */

export function getFileUrl(path) {
  const useAbsolutePath = process.env.REACT_APP_ABSOLUTE_PATH === "yes";
  const defaultNoImagePath = process.env.REACT_APP_DEFAULT_NO_IMAGE_PATH;
  const baseUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  if (!path || typeof path !== "string" || path === "") {
    path = defaultNoImagePath;
  }

  // 检查路径是否已经是一个 URL（绝对路径）
  try {
    new URL(path);
    // 如果是一个有效的 URL，直接返回
    return path;
  } catch (error) {
    // 如果不是一个有效的 URL，那么说明传入的参数path是一个相对路径，继续处理
  }

  // 如果 baseUrl 以 '/' 结尾，去掉 '/'
  const formattedBaseUrl = baseUrl.endsWith("/") ? baseUrl.slice(0, -1) : baseUrl;

  // 确保 path 以 '/' 开始
  const formattedPath = path.startsWith("/") ? path : `/${path}`;

  if (useAbsolutePath) {
    return `${formattedBaseUrl}${formattedPath}`;
  } else {
    return formattedPath;
  }
}
