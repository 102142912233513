import { Header, Box, Modal, SpaceBetween, Button, Link } from "@cloudscape-design/components";
import { useState } from "react";
import ReactPlayer from "react-player";
import { getFileUrl } from "../../../common/utils";
import { useUserProfile } from "../../../components";
import { t } from "i18next";

const LatestUpdateItem = ({ item }) => {
  const { uiLanguage } = useUserProfile(); // 提醒：只有在Homepage渲染的时候，使用uiLanguage而不是contentLanguage来渲染内容。
  const [visible, setVisible] = useState(false);
  const [playVideoUrl, setPlayVideoUrl] = useState("");
  const [playVideoType, setPlayVideoType] = useState("");
  const [playerKey, setPlayerKey] = useState(Date.now()); // 设置一个初始的key

  return (
    <div className="home-box">
      <div
        className="home-image"
        onClick={() => {
          if (item.isVideo) {
            setPlayVideoUrl(item.videoUrl || "");
            setPlayVideoType(item.videoType || "");
            setVisible(true);
          }
        }}
      >
        {item.isVideo ? (
          <div>
            {item.isVideo && <div className="video-mask"></div>}
            {item.videoType === "youtube" && (
              <iframe
                title={item[`title_` + uiLanguage]}
                src={item.videoUrl}
                width="100%"
                height="300"
                allowFullScreen
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            )}
            {item.videoType === "broadcast" && (
              <iframe
                title={item[`title_` + uiLanguage]}
                src={item.videoUrl}
                width="100%"
                height="300"
                allowFullScreen
                sandbox="allow-scripts allow-popups allow-presentation allow-same-origin"
                loading="lazy"
                frameBorder="0"
              ></iframe>
            )}
          </div>
        ) : (
          <img
            onClick={() => {
              if (item.isVideo) {
                setPlayVideoUrl(item.videoUrl || "");
                setVisible(true);
              }
            }}
            alt=""
            width="100%"
            src={getFileUrl(item.fileKey)}
          />
        )}
      </div>

      <Box variant="div" padding={{ top: "l", left: "l", right: "l", bottom: "l" }}>
        <Header variant="h3">{item[`title_` + uiLanguage]}</Header>
        <p>
          {item[`content_` + uiLanguage]}{" "}
          {item.readmoreUrl && (
            <Link external href={item.readmoreUrl}>
              {t("Button.readmore")}
            </Link>
          )}
        </p>
      </Box>

      <Modal
        size="large"
        onDismiss={() => {
          setVisible(false);
          setPlayVideoUrl("");
          // 更新key来重新渲染播放器
          setPlayerKey(Date.now());
        }}
        visible={visible}
        closeAriaLabel="Close modal"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={() => {
                  setVisible(false);
                  setPlayVideoUrl("");
                }}
              >
                {t("Button.close")}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={item[`title_` + uiLanguage]}
      >
        {playVideoType === "youtube" && (
          <div className="player-wrapper">
            <ReactPlayer key={playerKey} className="react-player" url={playVideoUrl} width="100%" height="100%" />
          </div>
        )}
        {playVideoType === "broadcast" && (
          <iframe
            title={item.title_en}
            sandbox="autoplay allow-scripts allow-popups allow-presentation allow-same-origin"
            src={playVideoUrl}
            loading="lazy"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        )}
      </Modal>
    </div>
  );
};

export default LatestUpdateItem;
