import { Button, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../../components";

export function FormHeader({ loadHelpPanelContent }) {
  const { t } = useTranslation();
  const { contentLanguage, setContentLanguage } = useUserProfile();
  const navigate = useNavigate();

  function goBackToSolutionsPageHandler() {
    navigate(-1);
  }

  const changeContentLanguage = () => {
    setContentLanguage(contentLanguage === "en" ? "cn" : "en");
  };

  return (
    <Header
      variant="h1"
      info={
        <Link onFollow={loadHelpPanelContent} variant="info">
          {t("solutions:SolutionEditForm.linkInfo")}
        </Link>
      }
      description={t("solutions:SolutionEditForm.description")}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button data-testid="header-btn-go-back" onClick={changeContentLanguage}>
            {contentLanguage === "en" ? t("PageHeader.buttonChangeLanguageCN") : t("PageHeader.buttonChangeLanguage")}
          </Button>
          <Button data-testid="header-btn-go-back" onClick={goBackToSolutionsPageHandler}>
            {t("PageHeader.buttonGoBack")}
          </Button>
        </SpaceBetween>
      }
    >
      {t("solutions:SolutionEditForm.headerTitle")}
    </Header>
  );
}
