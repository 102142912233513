import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { DataProvider } from "../../api/data-provider";

/*+++++++++++++++++++++++++++ 1. Define Interface +++++++++++++++++++++++++++*/
interface AppManagementContextProviderProps {
  children: React.ReactNode;
}

interface AppManagementContextType {
  homepage: Record<string, unknown>; // 如果你不确定或者这个对象的属性是动态的，那么可以使用Record<string, unknown>来表示一个任意属性的对象。
  setHomepage: (data: Record<string, unknown>) => void; // 修改为 Record<string, unknown>
  homepageFetched: boolean;
  setHomepageFetched: (fetchedStatus: boolean) => void;
  modernAppCourse: Record<string, unknown>; // 如果你不确定或者这个对象的属性是动态的，那么可以使用Record<string, unknown>来表示一个任意属性的对象。
  setModernAppCourse: (data: Record<string, unknown>) => void; // 修改为 Record<string, unknown>
  modernAppCourseFetched: boolean;
  setModernAppCourseFetched: (fetchedStatus: boolean) => void;
}

/*++++++++++++++++++++++++++++ 2. Create Context ++++++++++++++++++++++++++++*/
export const AppManagementContext = createContext<AppManagementContextType>({
  homepage: {},
  setHomepage: () => {},
  homepageFetched: false,
  setHomepageFetched: () => {},
  modernAppCourse: {},
  setModernAppCourse: () => {},
  modernAppCourseFetched: false,
  setModernAppCourseFetched: () => {},
});

/*********************** 3. Create the Provider Component ********************/
export const AppManagementContextProvider: React.FC<AppManagementContextProviderProps> = ({ children }) => {
  const [homepage, setHomepage] = useState<Record<string, unknown>>({});
  const [homepageFetched, setHomepageFetched] = useState(false);
  const [modernAppCourse, setModernAppCourse] = useState<Record<string, unknown>>({});
  const [modernAppCourseFetched, setModernAppCourseFetched] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (!homepageFetched && user) {
      new DataProvider()
        .getApp({
          itemName: "homepage-data",
        })
        .then((data) => {
          setHomepage(data[0]); // data是一个列表，尽管其中只有一个元素，要取出第一个对象元素来赋值给homepage。
          setHomepageFetched(true);
        });
    }

    const timer = setTimeout(() => {
      setHomepageFetched(false);
    }, 12 * 60 * 60 * 1000); // 12 Hours

    return () => {
      clearTimeout(timer);
    };
  }, [user, homepageFetched]);

  useEffect(() => {
    if (!modernAppCourseFetched && user) {
      new DataProvider()
        .getApp({
          itemName: "modernAppCourse",
        })
        .then((data) => {
          setModernAppCourse(data[0]); // data是一个列表，尽管其中只有一个元素，要取出第一个对象元素来赋值给homepage。
          setModernAppCourseFetched(true);
        });
    }

    const timer = setTimeout(() => {
      setModernAppCourseFetched(false);
    }, 12 * 60 * 60 * 1000); // 12 Hours

    return () => {
      clearTimeout(timer);
    };
  }, [user, modernAppCourseFetched]);

  const contentValue = {
    homepage,
    setHomepage,
    homepageFetched,
    setHomepageFetched,
    modernAppCourse,
    setModernAppCourse,
    modernAppCourseFetched,
    setModernAppCourseFetched,
  };

  return <AppManagementContext.Provider value={contentValue}>{children}</AppManagementContext.Provider>;
};

/*++++++++++++++++++++++++++ 4. Create Custom Hook ++++++++++++++++++++++++++*/
export const useAppManagement = () => {
  return useContext(AppManagementContext);
};
