import { useEffect, useRef, useState } from "react";
import { Chat, Message, Role } from "../../../components/ChatbotUI";
import paletteConfig from "../../../config/aws-palette-config.json";
import { DataProvider } from "../../../api/data-provider";
import { analyticsLogger } from "../../../common/utils/analyticsLogger";

const DEBUG = paletteConfig.debugMode;

export default function OurOwnBot() {
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const messagesEndRef = useRef<HTMLDivElement>(null);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // const handleSend = async (message: Message) => {
  //   const updatedMessages = [...messages, message];

  //   setMessages(updatedMessages);
  //   setLoading(true);

  //   // 限制请求API的字符串不超过500

  //   try {
  //     const res = await new DataProvider().ourownbot(message);

  //     DEBUG && console.log("%cres from DataProvider", "color: blue; font-weight: bold;", res);
  //     DEBUG && console.log("%cnewMessageFrom ourownbot:", "color: blue; font-weight: bold;", res.choices[0].message.content);

  //     const assistantMessage = {
  //       role: "assistant" as Role,
  //       content: res.choices[0].message.content,
  //     };

  //     setMessages((messages) => [...messages, assistantMessage]);

  //     // 将响应中的文本添加到对话中
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(`An error occurred while processing the response: ${error}`);
  //     setLoading(false);
  //   }
  // };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const handleSend = async (message: Message) => {
    const updatedMessages = [...messages, message];
    analyticsLogger.log("ask_palette");
    setMessages(updatedMessages);
    setLoading(true);

    // 限制请求API的字符串不超过2000
    // 如果超过2000，就截取最后的不超过2000个字符的对话
    const charLimit = 2000;
    let charCount = 0;
    let messagesToSend = [];

    DEBUG && console.log("updatedMessages:", updatedMessages);

    for (let i = updatedMessages.length - 1; i >= 0; i--) {
      const theMessage = updatedMessages[i];
      if (charCount + theMessage.content.length > charLimit) {
        break;
      }
      charCount += theMessage.content.length;
      messagesToSend.unshift(theMessage);
    }

    try {
      const res = await new DataProvider().ourownbot_multi_messages(messagesToSend);

      DEBUG && console.log("%cres from DataProvider", "color: blue; font-weight: bold;", res);
      DEBUG && console.log("%cnewMessageFromChatGpt:", "color: blue; font-weight: bold;", res.choices[0].message.content);

      // 将响应中的文本添加到对话中
      setMessages((messages) => [...messages, res.choices[0].message]);

      setLoading(false);
    } catch (error) {
      console.error(`An error occurred while processing the response: ${error}`);
      setLoading(false);
    }
  };
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const handleReset = () => {
    setMessages([
      {
        role: "assistant",
        content: `Hi there! I'm Palette, an AI assistant. I've upgraded myself to version 2023.08.31. I can help you with things like answering questions, providing information, and helping with tasks. How can I help you?`,
      },
    ]);
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    setMessages([
      {
        role: "assistant",
        content: `Hi there! I'm Palette, an AI assistant. I've upgraded myself to version 2023.08.31. I can help you with things like answering questions, providing information, and helping with tasks. How can I help you?`,
      },
    ]);
  }, []);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <div className="flex flex-col h-screen">
        <div className="flex-1 overflow-auto sm:px-10 pb-4 sm:pb-10">
          <div className="max-w-[800px] mx-auto mt-4 sm:mt-12">
            <Chat messages={messages} loading={loading} onSend={handleSend} onReset={handleReset} />
            <div ref={messagesEndRef} />
          </div>
        </div>
      </div>
    </>
  );
}
