import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Button, CollectionPreferences, Container, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { DataProvider } from "../../../api/data-provider";
import { TableEmptyState, TableNoMatchState, TableHeader } from "./tab-roadmap-table-empty-nomatch-state";
import { LoadingSpinner, useUserProfile, useSolutions } from "../../../components";
import { paginationLabels } from "../../../common/labels";
import { createColumnDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./tab-roadmap-tableconfig";
import { getFilterCounterText } from "../../../common/tableCounterStrings";
import { addColumnSortLabels } from "../../../common/labels";
import { useColumnWidths } from "../../../common/use-column-widths";
import { useLocalStorage } from "../../../common/use-local-storage";
import cloneDeep from "lodash/cloneDeep";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
const FeaturesTable = ({ release, releaseId, featureList, solutionName, likeButtonHandler }) => {
  const { t } = useTranslation();
  const { userLogin } = useUserProfile();

  const [selectedItems, setSelectedItems] = useState([]);
  const isOnlyOneSelected = selectedItems.length === 1;

  const COLUMN_DEFINITIONS = addColumnSortLabels(createColumnDefinitions(t, likeButtonHandler, releaseId, userLogin));
  const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
  const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
  const DEFAULT_PREFERENCES = createDefaultPreferences();

  /**
   * 通过下面的两个DEBUG语句证明，React 的 key 属性实际上无法在组件内部访问。您需要使用额外的属性（例如 releaseIndex）来将索引传递给组件。
   * 所以，您需要保留 key={releaseIndex}，因为它是 React 列表渲染所需的，以便在重新渲染时正确识别元素。同时，确保使用
   * releaseIndex={releaseIndex} 将索引传递给 FeaturesTable 组件，然后在组件内部使用它。
   * 如果使用key={releaseIndex} 试图传递releaseIndex，就会发现在这里key是undefined.
   */
  // DEBUG && console.log("%creleaseIndex", "color: green; font-weight: bold;", releaseIndex);

  const [columnDefinitions, saveWidths] = useColumnWidths(`React-Tab-Roadmap-Widths-${releaseId}`, COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage(`React-Tab-Roadmap-Preferences-${releaseId}`, DEFAULT_PREFERENCES);

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(featureList, {
    filtering: {
      empty: <TableEmptyState resourceName="features" />,
      noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
    selection: {},
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={columnDefinitions}
        visibleColumns={preferences.visibleContent}
        resizableColumns={true}
        onColumnWidthsChange={saveWidths}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        items={items}
        selectionType="single"
        onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
        selectedItems={selectedItems}
        header={
          <TableHeader
            // title的这段涉及当初是为了用于ai solution kit展示13个不同的模型，可以用modelName@version的格式来区分不同的模型。
            title={
              release.version.includes("@")
                ? release.version.split("@")[0].trim() + " - " + release.version.split("@")[1].trim()
                : solutionName + " - " + release.version + " (" + (release.releaseDate || "ToBeUpdated") + ")"
            }
            selectedItems={selectedItems}
            totalItems={featureList}
            actionButtons={
              // 这里我用userLogin === "_Hidden"将这个Button隐藏，将来需要的时候只要去掉这个判断条件就可以。或者修改为userLogin === "xuhi"，使得只有xuhi能看到它。
              userLogin === "_Hidden" && (
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    disabled={!isOnlyOneSelected}
                    onClick={(event) => {
                      event.preventDefault(); // 其实是不需要的，因为这个button没有和form一起用，点击不会引起页面刷新等
                      likeButtonHandler(releaseId, selectedItems[0].id, userLogin);
                    }}
                  >
                    {t("PageHeader.buttonLike")}
                  </Button>
                </SpaceBetween>
              )
            }
          />
        }
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel="Find features"
            filteringPlaceholder={t("solutions:useCollection.findFeatures")}
            countText={getFilterCounterText(filteredItemsCount)}
          />
        }
        pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
        preferences={
          <CollectionPreferences
            title={t("solutions:CollectionPreferences.title")}
            confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
            cancelLabel={t("solutions:CollectionPreferences.cancel")}
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: t("solutions:CollectionPreferences.pageSize"),
              options: PAGE_SIZE_OPTIONS,
            }}
            visibleContentPreference={{
              title: t("solutions:CollectionPreferences.selectVisibleColumns"),
              options: VISIBLE_CONTENT_OPTIONS,
            }}
            wrapLinesPreference={{
              label: t("solutions:CollectionPreferences.wrapLinesLabel"),
              description: t("solutions:CollectionPreferences.wrapLinesDescription"),
            }}
            stripedRowsPreference={{
              label: t("solutions:CollectionPreferences.stripedRowsLabel"),
              description: t("solutions:CollectionPreferences.stripedRowsDescription"),
            }}
          />
        }
        ariaLabels={{
          itemSelectionLabel: (data, row) => `select ${row.name}`,
          allItemsSelectionLabel: () => "select all",
          selectionGroupLabel: "Casestudies selection",
        }}
      />
    </div>
  );
};

/*****************************************************************************/
export const TabRoadmap = ({ oneSolution }) => {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();
  const { setFetched } = useSolutions();
  const [loading, setLoading] = useState(true);
  const [selectedSolution, setSelectedSolution] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);

  const apiCalledRef = useRef();

  /**
   * 使用selectedSolution来关联前端页面，对selectedSolution的修改会立刻在前端页面显示。
   * 每次点击，立刻调用API，更改后端的数据库的，但是前端页面不fetch更新后的solution数据，避免用户体验到延迟。
   * 当用户从当前页面离开（访问本网站的其他链接，或者其他Tab），通过setFetch(false)自动触发一次从后端读取最新的解决方案数据。
   * 此时用户不会感觉到任何延迟，但是其他路由的页面需要添加对fetch的状态检查，如果是false就设置网页为loading状态。
   */

  useEffect(() => {
    if (oneSolution) {
      setSelectedSolution({
        solutionNameAbbr: oneSolution.solutionNameAbbr,
        solutionName_en: oneSolution.solutionName_en, // 渲染的时候用到了solutionName
        solutionName_cn: oneSolution.solutionName_cn, // 渲染的时候用到了solutionName
        roadmap: oneSolution.roadmap,
      });
    }
  }, [oneSolution]);

  useEffect(() => {
    if (selectedSolution) {
      setLoading(false);
    }
  }, [selectedSolution]);

  useEffect(() => {
    apiCalledRef.current = apiCalled;
  }, [apiCalled]);

  useEffect(() => {
    return () => {
      if (apiCalledRef.current) {
        setFetched(false);
      }
    };
  }, []);
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function likeButtonHandler(releaseId, featureId, userLogin) {
    // 首先，深拷贝整个 selectedSolution 对象。这里最终没有考虑使用深拷贝roadmap.releaseHistory + 浅拷贝其他属性来优化性能，是因为如果其他属性有嵌套数组
    // 结构，那么反而会出现其他属性在最后使用return newState的时候被错误修改。考虑到selectedSolution本身是个size很小的对象，我们还是执行整体的深拷贝吧。
    const newState = cloneDeep(selectedSolution);

    const releaseIndex = newState.roadmap.releaseHistory.findIndex((release) => release.id === releaseId);

    DEBUG && console.log("%creleaseIndex", "color: red; font-weight: bold;", releaseIndex);
    DEBUG && console.log("%cfeatureId", "color: red; font-weight: bold;", featureId);
    DEBUG &&
      console.log(
        "%cnewState.roadmap.releaseHistory[releaseIndex].feature_en",
        "color: blue; font-weight: bold;",
        newState.roadmap.releaseHistory[releaseIndex].feature_en
      );
    DEBUG &&
      console.log(
        "%cnewState.roadmap.releaseHistory[releaseIndex].feature_cn",
        "color: blue; font-weight: bold;",
        newState.roadmap.releaseHistory[releaseIndex].feature_cn
      );
    // 先找到目标元素在features数组中的index，英文和中文的数组元素的顺序是一样的，所以只需要查找一次。
    // const item = newState.roadmap.releaseHistory[releaseIndex]["features_" + contentLanguage].find((feature) => feature.title === featureTitle);
    const featureIndex_en = newState.roadmap.releaseHistory[releaseIndex].features_en.findIndex((feature) => feature.id === featureId);
    const featureIndex_cn = newState.roadmap.releaseHistory[releaseIndex].features_cn.findIndex((feature) => feature.id === featureId);

    // DEBUG && console.log("%citem", "color: blue; font-weight: bold;", item);
    DEBUG && console.log("%cfeatureId", "color: blue; font-weight: bold;", featureId);
    DEBUG && console.log("%cfeatureIndex_en", "color: blue; font-weight: bold;", featureIndex_en);

    // 当like是undefined的情况下，初始化like为空数组; 我已经修改了solutions-edit代码，每次添加新的feature的时候，自动都初始化对应的like。
    if (!newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex_en].like) {
      newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex_en].like = [];
    }

    if (!newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex_cn].like) {
      newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex_cn].like = [];
    }

    // 检查 like 数组中是否已经包含了 userLogin，同时更新features_en和features_cn
    const index_en = newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex_en].like.indexOf(userLogin);
    const index_cn = newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex_cn].like.indexOf(userLogin);

    // 只检查index_en，原则上不可能出现一个语言的数组有userLogin，另一个语言的数组没有。果真出现这种情况，我们只判断index_en，也可以让两个语言的like数组同步回来。
    if (index_en === -1) {
      // 把当前用户的登录信息添加到 like 数组中。如果index_en/cn !== -1，说明like数组中已经包含元素，就不要再push，而是用splice删除
      index_en === -1 && newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex_en].like.push(userLogin);
      index_cn === -1 && newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex_cn].like.push(userLogin);
    } else {
      // 从 like 数组中删除已存在的 userLogin
      index_en !== -1 && newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex_en].like.splice(index_en, 1);
      index_cn !== -1 && newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex_cn].like.splice(index_cn, 1);
    }

    // 返回更新后的状态
    DEBUG && console.log("%cnewState", "color: blue; font-weight: bold;", newState);

    setSelectedSolution(newState);
    setApiCalled(true);

    // 直接请求api，不在前端修改数据，在云上通过lambda来修改DDB的数据。
    const eventBody = {
      solutionNameAbbr: oneSolution.solutionNameAbbr,
    };

    const config = {
      params: {
        action: "modify-likes-in-roadmap-tab",
        userLogin: userLogin,
        releaseId: releaseId,
        featureId: featureId,
        solutionNameAbbr: oneSolution.solutionNameAbbr,
      },
    };

    try {
      const response = await new DataProvider().updateSolution(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, likes updated.", "color: green; font-weight: bold;", response);
    } catch (error) {
      console.error("Update Solution(features' like Attribute) failed:", error);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const NoResources = () => {
    return (
      <Container>
        <center>
          <h3>{t("solutions:SolutionDetails.tabs-content.noResources")}</h3>
        </center>
      </Container>
    );
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const renderContent = () => {
    if (selectedSolution.roadmap?.releaseHistory && selectedSolution.roadmap.releaseHistory.length > 0) {
      return (
        <SpaceBetween size="l">
          {selectedSolution.roadmap.releaseHistory.map((release, releaseIndex) => (
            <div key={releaseIndex}>
              <FeaturesTable
                // key={releaseIndex}，下面这个key的写法可以保证在点击了like后，页面重新渲染的时候，之前被选择的项目清空，右上角Like button变成disable
                key={`${releaseIndex}_${release["features_" + contentLanguage].reduce((acc, feature) => acc + (feature.like ? feature.like.length : 0), 0)}`}
                release={release}
                // releaseIndex={releaseIndex}
                releaseId={release.id} // 后端api用release.id而不是releaseIndex来识别要修改的release会100%准确，而不会出错。用releaseIndex会在并发修改时出错
                featureList={release["features_" + contentLanguage]}
                solutionName={selectedSolution["solutionName_" + contentLanguage]}
                likeButtonHandler={likeButtonHandler}
              />
            </div>
          ))}
        </SpaceBetween>
      );
    } else {
      return (
        <SpaceBetween size="l">
          <NoResources />
        </SpaceBetween>
      );
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return <>{loading ? <LoadingSpinner /> : renderContent()}</>;
};

// 老的代码实现如下，这里指的参考的就是api只在离开当前页面（组件卸载）的时候才调用，所以切换到其他页面或者tab的时候会自动请求api来更新数据。但是这种处理有两个缺陷：
// 第一，因为数据是现在前端更新，造成两个人提交申请的时候，后一个人的数据会覆盖前一个人的数据。
// 第二，当用户直接关闭tab，或者在浏览器地址栏输入其他网址的时候，api不会被请求，造成数据没有更新。

// import { useState, useEffect, useRef } from "react";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import { Button, CollectionPreferences, Container, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
// import { useCollection } from "@cloudscape-design/collection-hooks";
// import { DataProvider } from "../../../api/data-provider";
// import { TableEmptyState, TableNoMatchState, TableHeader } from "./tab-roadmap-table-empty-nomatch-state";
// import { LoadingSpinner, useNotification, useUserProfile, useSolutions } from "../../../components";
// import { paginationLabels } from "../../../common/labels";
// import { createColumnDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./tab-roadmap-tableconfig";
// import { getFilterCounterText } from "../../../common/tableCounterStrings";
// import { addColumnSortLabels } from "../../../common/labels";
// import { useColumnWidths } from "../../../common/use-column-widths";
// import { useLocalStorage } from "../../../common/use-local-storage";
// import _ from "lodash";
// import paletteConfig from "../../../config/aws-palette-config.json";

// const DEBUG = paletteConfig.debugMode;

// /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
// const FeaturesTable = ({ release, releaseIndex, featureList, solutionName, likeButtonHandler }) => {
//   const { t } = useTranslation();
//   const { userLogin, contentLanguage } = useUserProfile();
//   const navigate = useNavigate();

//   const COLUMN_DEFINITIONS = addColumnSortLabels(createColumnDefinitions(t, navigate));
//   const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
//   const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
//   const DEFAULT_PREFERENCES = createDefaultPreferences();

//   /**
//    * 通过下面的两个DEBUG语句证明，React 的 key 属性实际上无法在组件内部访问。您需要使用额外的属性（例如 releaseIndex）来将索引传递给组件。
//    * 所以，您需要保留 key={releaseIndex}，因为它是 React 列表渲染所需的，以便在重新渲染时正确识别元素。同时，确保使用
//    * releaseIndex={releaseIndex} 将索引传递给 FeaturesTable 组件，然后在组件内部使用它。
//    * 如果使用key={releaseIndex} 试图传递releaseIndex，就会发现在这里key是undefined.
//    */
//   // DEBUG && console.log("%creleaseIndex", "color: green; font-weight: bold;", releaseIndex);

//   const [columnDefinitions, saveWidths] = useColumnWidths(`React-Tab-Roadmap-Widths-${releaseIndex}`, COLUMN_DEFINITIONS);
//   const [preferences, setPreferences] = useLocalStorage(`React-Tab-Roadmap-Preferences-${releaseIndex}`, DEFAULT_PREFERENCES);

//   const [selectedItems, setSelectedItems] = useState([]);
//   const isOnlyOneSelected = selectedItems.length === 1;

//   const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(featureList, {
//     filtering: {
//       empty: <TableEmptyState resourceName="features" />,
//       noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
//     },
//     pagination: { pageSize: preferences.pageSize },
//     sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
//     selection: {},
//   });

//   /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//   return (
//     <div>
//       <Table
//         {...collectionProps}
//         columnDefinitions={columnDefinitions}
//         visibleColumns={preferences.visibleContent}
//         resizableColumns={true}
//         onColumnWidthsChange={saveWidths}
//         wrapLines={preferences.wrapLines}
//         stripedRows={preferences.stripedRows}
//         items={items}
//         selectionType="single"
//         onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
//         selectedItems={selectedItems}
//         header={
//           <TableHeader
//             title={
//               release.version.includes("@")
//                 ? release.version.split("@")[0].trim() + " - " + release.version.split("@")[1].trim()
//                 : solutionName + " - " + release.version
//             }
//             selectedItems={selectedItems}
//             totalItems={featureList}
//             actionButtons={
//               <SpaceBetween direction="horizontal" size="xs">
//                 <Button
//                   disabled={!isOnlyOneSelected}
//                   onClick={(event) => {
//                     event.preventDefault(); // 其实是不需要的，因为这个button没有和form一起用，点击不会引起页面刷新等
//                     // DEBUG && console.log("%creleaseIndex", "color: crimson; font-weight: bold;", releaseIndex);
//                     // DEBUG && console.log("%cselectedItem[0].title", "color: blue; font-weight: bold;", selectedItems[0].title);
//                     // DEBUG && console.log("%cuserLogin", "color: crimson; font-weight: bold;", userLogin);
//                     // DEBUG && console.log("%ccontentLanguage", "color: crimson; font-weight: bold;", contentLanguage);
//                     likeButtonHandler(releaseIndex, selectedItems[0].title, userLogin, contentLanguage);
//                   }}
//                 >
//                   {t("PageHeader.buttonLike")}
//                 </Button>
//               </SpaceBetween>
//             }
//           />
//         }
//         filter={
//           <TextFilter
//             {...filterProps}
//             filteringAriaLabel="Find features"
//             filteringPlaceholder={t("solutions:useCollection.findFeatures")}
//             countText={getFilterCounterText(filteredItemsCount)}
//           />
//         }
//         pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
//         preferences={
//           <CollectionPreferences
//             title={t("solutions:CollectionPreferences.title")}
//             confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
//             cancelLabel={t("solutions:CollectionPreferences.cancel")}
//             preferences={preferences}
//             onConfirm={({ detail }) => setPreferences(detail)}
//             pageSizePreference={{
//               title: t("solutions:CollectionPreferences.pageSize"),
//               options: PAGE_SIZE_OPTIONS,
//             }}
//             visibleContentPreference={{
//               title: t("solutions:CollectionPreferences.selectVisibleColumns"),
//               options: VISIBLE_CONTENT_OPTIONS,
//             }}
//             wrapLinesPreference={{
//               label: t("solutions:CollectionPreferences.wrapLinesLabel"),
//               description: t("solutions:CollectionPreferences.wrapLinesDescription"),
//             }}
//             stripedRowsPreference={{
//               label: t("solutions:CollectionPreferences.stripedRowsLabel"),
//               description: t("solutions:CollectionPreferences.stripedRowsDescription"),
//             }}
//           />
//         }
//         ariaLabels={{
//           itemSelectionLabel: (data, row) => `select ${row.name}`,
//           allItemsSelectionLabel: () => "select all",
//           selectionGroupLabel: "Casestudies selection",
//         }}
//       />
//     </div>
//   );
// };

// /*****************************************************************************/
// export const TabRoadmap = ({ oneSolution }) => {
//   const { t } = useTranslation();
//   const { contentLanguage } = useUserProfile();
//   const { pushNewNotification } = useNotification();
//   const { setFetched } = useSolutions();
//   const [loading, setLoading] = useState(true);
//   const [selectedSolution, setSelectedSolution] = useState(null);
//   const [selectedSolutionInitialState, setSelectedSolutionInitialState] = useState(null);

//   // 使用selectedSolutionRef可以保证在组件卸载的时候，执行updateSolution的api的时候可以获取到最新的selectedSolution的值。不使用这种方法，
//   // 当你的useEffect钩子函数首次执行时（也就是当callApi发生变化时），它会“记住”这次运行时上下文中selectedSolution的值。在React中，函数组件的
//   // 每一次渲染都有其自己的道具和状态，这就形成了一个"闭包"。从而造成更新Api时候的数据不是最新的数据。
//   // 清理函数中需要使用useRef来获得最新的值
//   const selectedSolutionRef = useRef();
//   const selectedSolutionInitialStateRef = useRef();

//   useEffect(() => {
//     if (oneSolution) {
//       setSelectedSolution({
//         solutionNameAbbr: oneSolution.solutionNameAbbr,
//         solutionName_en: oneSolution.solutionName_en, // 渲染的时候用到了solutionName
//         solutionName_cn: oneSolution.solutionName_cn, // 渲染的时候用到了solutionName
//         roadmap: oneSolution.roadmap,
//       });
//       setSelectedSolutionInitialState({
//         solutionNameAbbr: oneSolution.solutionNameAbbr,
//         solutionName_en: oneSolution.solutionName_en,
//         solutionName_cn: oneSolution.solutionName_cn,
//         roadmap: oneSolution.roadmap,
//       });
//     }
//   }, [oneSolution]);

//   useEffect(() => {
//     selectedSolutionInitialStateRef.current = selectedSolutionInitialState; // 更新 ref，只更新一次
//   }, [selectedSolutionInitialState]);

//   useEffect(() => {
//     selectedSolutionRef.current = selectedSolution; // 更新 ref，频繁更新
//     DEBUG && console.log("%cslectedSolution", "color: orange; font-weight: bold;", selectedSolutionRef.current);
//     DEBUG && console.log("%cselectedSolutionInitialStateRef", "color: orange; font-weight: bold;", selectedSolutionInitialStateRef.current);
//     if (selectedSolution && selectedSolutionInitialState) {
//       setLoading(false);
//     }
//   }, [selectedSolution, selectedSolutionInitialState]);

//   /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//   useEffect(() => {
//     // 触发 API 请求以保存更改
//     async function updateSelectedSolution() {
//       const solution = {
//         solutionNameAbbr: selectedSolutionRef.current.solutionNameAbbr,
//         roadmap: selectedSolutionRef.current.roadmap,
//       };

//       const config = {
//         params: {
//           action: "add like",
//           login: "joes",
//         },
//       };

//       try {
//         const response = await new DataProvider().updateSolution(solution, config);
//         DEBUG && console.log("%cApiCallCompleted, likes updated.", "color: green; font-weight: bold;", response);
//         // Push Message to Notification Component
//         const newMessage = {
//           type: "success",
//           content: t("notification.updateLikesSuccess"),
//           dismissible: true,
//           dismissLabel: "Dismiss message",
//           statusIconAriaLabel: "success",
//         };
//         pushNewNotification(newMessage);
//         setFetched(false);
//       } catch (error) {
//         console.error("Update Solution(features' like Attribute) failed:", error);
//         // Push Message to Notification Component
//         const newMessage = {
//           type: "error",
//           content: t("notification.updateLikesError"),
//           dismissible: true,
//           dismissLabel: "Dismiss message",
//           statusIconAriaLabel: "error",
//         };
//         pushNewNotification(newMessage);
//       }
//     }

//     // 这个useEffect是一个清理函数，因为没有依赖项，所以只在组件卸载的时候才执行，而且只执行return中的代码，return外面的(上方)代码不会执行。
//     // 如果useEffect有依赖项，那么在依赖项变化的时候，也会执行一次清理函数。此处必须使用allApiRef.current，才能获得callApi的最新值。
//     // [这里有个闭包的概念]
//     return () => {
//       if (
//         selectedSolutionRef.current &&
//         selectedSolutionInitialStateRef.current &&
//         !isEqual(selectedSolutionRef.current, selectedSolutionInitialStateRef.current)
//       ) {
//         updateSelectedSolution();
//       }
//     };
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//   function likeButtonHandler(releaseIndex, featureTitle, userLogin, contentLanguage) {
//     DEBUG && console.log("%creleaseIndex", "color: crimson; font-weight: bold;", releaseIndex);
//     DEBUG && console.log("%cfeatureTitle", "color: crimson; font-weight: bold;", featureTitle);
//     DEBUG && console.log("%cuserLogin", "color: crimson; font-weight: bold;", userLogin);
//     DEBUG && console.log("%ccontentLanguage", "color: crimson; font-weight: bold;", contentLanguage);

//     // 首先，深拷贝整个 selectedSolution 对象。这里最终没有考虑使用深拷贝roadmap.releaseHistory + 浅拷贝其他属性来优化性能，是因为如果其他属性有嵌套数组
//     // 结构，那么反而会出现其他属性在最后使用return newState的时候被错误修改。考虑到selectedSolution本身是个size很小的对象，我们还是执行整体的深拷贝吧。
//     const newState = cloneDeep(selectedSolution);

//     // 先找到目标元素在features数组中的index，英文和中文的数组元素的顺序是一样的，所以只需要查找一次。
//     // const item = newState.roadmap.releaseHistory[releaseIndex]["features_" + contentLanguage].find((feature) => feature.title === featureTitle);
//     const featureIndex = newState.roadmap.releaseHistory[releaseIndex]["features_" + contentLanguage].findIndex((feature) => feature.title === featureTitle);

//     // DEBUG && console.log("%citem", "color: blue; font-weight: bold;", item);
//     DEBUG && console.log("%cfeatureTitle", "color: blue; font-weight: bold;", featureTitle);
//     DEBUG && console.log("%cfeatureIndex", "color: blue; font-weight: bold;", featureIndex);

//     // 当like是undefined的情况下，初始化like为空数组
//     if (!newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex].like) {
//       newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex].like = [];
//     }

//     if (!newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex].like) {
//       newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex].like = [];
//     }

//     // 检查 like 数组中是否已经包含了 userLogin，同时更新features_en和features_cn
//     const index = newState.roadmap.releaseHistory[releaseIndex]["features_" + contentLanguage][featureIndex].like.indexOf(userLogin);
//     const index_en = newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex].like.indexOf(userLogin);
//     const index_cn = newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex].like.indexOf(userLogin);

//     // 使用index，而不是index_en/index_cn来作为条件判断，可以保证即使en/cn列表出现不一致的错误，通过index条件可以让两个列表做同样的操作，
//     // 回到一致的状态，即都点赞或都不点赞。而index_en 和index_cn只用来锁定位置，用于删除操作。
//     if (index === -1) {
//       // 把当前用户的登录信息添加到 like 数组中。如果index_en/cn !== -1，说明like数组中以及包含元素，就不要再push
//       index_en === -1 && newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex].like.push(userLogin);
//       index_cn === -1 && newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex].like.push(userLogin);
//     } else {
//       // 从 like 数组中删除已存在的 userLogin
//       index_en !== -1 && newState.roadmap.releaseHistory[releaseIndex].features_en[featureIndex].like.splice(index_en, 1);
//       index_cn !== -1 && newState.roadmap.releaseHistory[releaseIndex].features_cn[featureIndex].like.splice(index_cn, 1);
//     }

//     // 返回更新后的状态
//     DEBUG && console.log("%cnewState", "color: blue; font-weight: bold;", newState);

//     setSelectedSolution(newState);
//   }

//   /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//   const NoResources = () => {
//     return (
//       <Container>
//         <center>
//           <h3>{t("solutions:SolutionDetails.tabs-content.noResources")}</h3>
//         </center>
//       </Container>
//     );
//   };

//   /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//   const renderContent = () => {
//     if (selectedSolution.roadmap?.releaseHistory) {
//       return (
//         <SpaceBetween size="l">
//           {selectedSolution.roadmap.releaseHistory.map((release, releaseIndex) => (
//             <div key={releaseIndex}>
//               <FeaturesTable
//                 // key={releaseIndex}，下面这个key的写法可以保证在点击了like后，页面重新渲染的时候，之前被选择的项目清空，Like button变成disable
//                 key={`${releaseIndex}_${release["features_" + contentLanguage].reduce((acc, feature) => acc + (feature.like ? feature.like.length : 0), 0)}`}
//                 release={release}
//                 releaseIndex={releaseIndex}
//                 featureList={release["features_" + contentLanguage]}
//                 solutionName={selectedSolution["solutionName_" + contentLanguage]}
//                 likeButtonHandler={likeButtonHandler}
//               />
//             </div>
//           ))}
//         </SpaceBetween>
//       );
//     } else {
//       return (
//         <SpaceBetween size="l">
//           <NoResources />
//         </SpaceBetween>
//       );
//     }
//   };

//   /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
//   return <>{loading ? <LoadingSpinner /> : renderContent()}</>;
// };
