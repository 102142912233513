import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Link, Spinner } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useAppManagement, useCasestudies, useSolutions, useUserProfile } from "./components";
import { ProtectedRoute } from "./components/Route/ProtectedRoute";
import { LoggedOut } from "./components/Auth/LoggedOut";
import { getFileUrl } from "./common/utils";
import { passiveSupport } from "passive-events-support/src/utils";
import paletteConfig from "./config/aws-palette-config.json";

import HeaderEn from "./components/Layout/HeaderEn";
import FooterEn from "./components/Layout/FooterEn";
import Login from "./components/Auth/Login";

import HomeStarting from "./pages/home/HomeStarting";
import UserPreferences from "./pages/user-preferences/UserPreferences";

import SolutionStarting from "./pages/solutions/SolutionsStarting";
import SolutionCreateStarting from "./pages/solutions-create/SolutionsCreateStarting";
import SolutionEditStarting from "./pages/solutions-edit/SolutionsEditStarting";
import SolutionDetailsTabStarting from "./pages/solutions-details-tabs/SolutionsDetailsTabStarting";

import CasestudiesStarting from "./pages/casestudies/CasestudiesStarting";
import CasestudiesCreateStarting from "./pages/casestudies-create/CasestudiesCreateStarting";
import CasestudiesEditStarting from "./pages/casestudies-edit/CasestudiesEditStarting";
import CasestudiesDetailsStarting from "./pages/casestudies-details/CasestudiesDetailsStarting";

import AskPalette from "./pages/ask-palette";
import BuildingClassStarting from "./pages/building-class/BuildingClassStarting";
import AppManagementStarting from "./pages/app-management/AppManagementStarting";
import NewslettersStarting from "./pages/newsletters/NewslettersStarting";

/**
 * 在将HomeStarting之外的组件使用Lazy Loading，即`const UserPreferences = lazy(() => import("./pages/user-preferences/UserPreferences"));`后
 * 首页加载过程中的最大的那个8.6MB的文件变成了7.9MB，虽然从分拆加载的角度看文件变小了，但是变化不太大。而且加载过程中有可能会
 * 出现`ChunkLoadError: Loading chunk 443 failed`的错误（再次加载后消失），另外一个问题是发现在访问未加载的页面时速度慢，
 * 体验反而不好，所以决定放弃这种Suspense/Lazy的加载方式来处理我自己写的任何组件。
 *
 * 另一方面，因为我已经使用了Create React App创建项目，缺省已经自动支持Tree Shaking，总的来说已经没有可以优化的空间。
 */

/**
 * The following code section that uses "passiveSupport" refers to information from the following two URLs.
 * https://stackoverflow.com/questions/59282213/react-warning-non-passive-event-listener-to-a-scroll-blocking-touchstart/70811715#70811715
 * https://www.npmjs.com/package/passive-events-support
 * The main purpose is to eliminate the Chrome console warning "[Violation] Added non-passive event listener."
 * However, it didn't work. Anyway it's not a big deal.
 */

// passiveSupport({ debug: true });
passiveSupport({
  debug: false,
  // listeners: [
  //   {
  //     element: "div",
  //     event: "touchend",
  //   },
  //   {
  //     element: "document",
  //     event: "touchstart",
  //   },
  // ],
});

const DEBUG = paletteConfig.debugMode;

/******************************************************************************
 * Here comes the App();
 *****************************************************************************/
const App = () => {
  const { i18n } = useTranslation();
  const { fullName, uiLanguage, setUiLanguage, preferencesFetched } = useUserProfile();
  const { fetched } = useSolutions();
  const { casestudiesFetched } = useCasestudies();
  const { homepage, homepageFetched, modernAppCourse, modernAppCourseFetched } = useAppManagement();
  const [initialLoading, setInitialLoading] = useState(true);

  // 这行代码用于识别每次用户浏览的页面是否是最新版本的代码，用于调试的目的。
  console.log("%c+++ Version: 5.0810 +++", "color: blue; font-weight: bold;");

  // 在组件加载时自动切换到 uiLanguage
  useEffect(() => {
    i18n.changeLanguage(uiLanguage);
  }, [uiLanguage, i18n]);

  // 当改变语言偏好的时候，立刻配置界面语言和i18n的语言。不需要使用setContentLanguage(language)，界面和内容是不同的语言处理方式。
  const languageChangeHandler = (language: string) => {
    setUiLanguage(language);
    i18n.changeLanguage(language);
  };

  // 当首次登录(initialLoading=true)的时候，需要等Context都有值了后，才开始渲染App组件。这样的好处是在子组件中无需判断Context是否已经就绪，减少了代码，而且页面切换更流畅，不需要Loading的切换过程。在后续Context数据更新的情况下，页面不需要再渲染Loading的大Logo。
  useEffect(() => {
    if (preferencesFetched && fetched && casestudiesFetched && homepageFetched && modernAppCourseFetched) {
      setInitialLoading(false);
    }
    DEBUG && console.log("%chomepage", "color: green; font-weight: bold;", homepage);
    DEBUG && console.log("%cmodernAppCourse", "color: green; font-weight: bold;", modernAppCourse);
  }, [preferencesFetched, fetched, casestudiesFetched, homepageFetched, modernAppCourseFetched]);

  if (initialLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          margin: "0.5em",
          flexDirection: "column",
        }}
      >
        <Link variant="awsui-value-large">Palette</Link>
        <img src={getFileUrl("store/default/palette.png")} alt="Loading" style={{ marginBottom: "10px" }} />
        <Spinner size="large" />
      </div>
    );
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <HeaderEn amazonLogin={fullName} languageChangeHandler={languageChangeHandler} />
      <Routes>
        {/* <Route path="/store/*" element={<StoreAsset />} />
        <Route path="/img/*" element={<ImgAsset />} /> */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <HomeStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/app"
          element={
            <ProtectedRoute>
              <AppManagementStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/preferences"
          element={
            <ProtectedRoute>
              <UserPreferences />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solutions"
          element={
            <ProtectedRoute>
              <SolutionStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solutions/create"
          element={
            <ProtectedRoute>
              <SolutionCreateStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solutions/edit"
          element={
            <ProtectedRoute>
              <SolutionEditStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/solutions/details"
          element={
            <ProtectedRoute>
              <SolutionDetailsTabStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/casestudies"
          element={
            <ProtectedRoute>
              <CasestudiesStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/casestudies/create"
          element={
            <ProtectedRoute>
              <CasestudiesCreateStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/casestudies/edit"
          element={
            <ProtectedRoute>
              <CasestudiesEditStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/casestudies/details"
          element={
            <ProtectedRoute>
              <CasestudiesDetailsStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/ask-palette"
          element={
            <ProtectedRoute>
              <AskPalette />
            </ProtectedRoute>
          }
        />
        <Route
          path="/building-class"
          element={
            <ProtectedRoute>
              <BuildingClassStarting />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newsletters"
          element={
            <ProtectedRoute>
              <NewslettersStarting />
            </ProtectedRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/loggedout" element={<LoggedOut />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <FooterEn />
    </>
  );
};

export default App;

// const StoreAsset: React.FC<RouteProps> = () => {
//   return null;
// };

// const ImgAsset: React.FC<RouteProps> = () => {
//   return null;
// };

/* 这个代码是在让用户自己点击button的时候才Login/Logout的Handler，现在已经使用自动登录，不提供Logout，就不需要下面的代码了。
// signInHandler is to enable user login manually by clicking the "Login" button.
  const signInHandler = () => {
    Auth.federatedSignIn({ customProvider: "AmazonEmployee" })
      .then(() => {
        console.log("User is logging in with federation.");
      })
      .catch(() => console.log("Error catched when the user is logging in with federation."));
  };

  const signOutHandler = () => {
    Auth.signOut()
      .then(() => {
        console.log("User was logged out.");
      })
      .catch(() => console.log("Error catched when the user is logging out."));
  };

*/
