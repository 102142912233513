import { Header, SpaceBetween, Button, Popover, StatusIndicator } from "@cloudscape-design/components";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner, useAppManagement, useUserProfile } from "../../../components";
import { DataProvider } from "../../../api/data-provider";
import _ from "lodash";
import paletteConfig from "../../../config/aws-palette-config.json";
import { analyticsLogger } from "../../../common/utils/analyticsLogger";

const DEBUG = paletteConfig.debugMode;

export const PageHeader = () => {
  const { t } = useTranslation();
  const { modernAppCourse, setModernAppCourseFetched } = useAppManagement();
  const { userLogin, isAdmin } = useUserProfile();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [subscribe, setSubscribe] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const apiCalledRef = useRef();

  useEffect(() => {
    DEBUG && console.log("%cmodernAppCourse", "color: blue; font-weight: bold;", modernAppCourse);

    if (modernAppCourse) {
      const userLoginFound = modernAppCourse.subscription.find((item) => item === userLogin);
      // 有匹配则返回userLogin，无匹配返回undefined
      setSubscribe(!!userLoginFound); // 真正初始化subscribe的值
      setLoading(false);
    }
  }, [modernAppCourse]);

  useEffect(() => {
    DEBUG && console.log("%csubscribe", "color: blue; font-weight: bold;", subscribe);
  }, [subscribe]);

  // 下面两个useEffect的目的是只在组件下载的时候才去后台拉最新的数据到本地（不是每次api call完成都立刻更新数据）。
  useEffect(() => {
    apiCalledRef.current = apiCalled;
  }, [apiCalled]);

  useEffect(() => {
    return () => {
      if (apiCalledRef.current) {
        setModernAppCourseFetched(false);
      }
    };
  }, []);
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function goBackHandler() {
    navigate(-1);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onCopyLoginsHandler = () => {
    // 从 modernAppCourse.subscription 中获取所有的 login
    const logins = modernAppCourse.subscription;

    // 将每个 login 转换为 {login}@amazon.com 的形式，并用 ; 和空格连接起来
    const loginsString = logins.map((login) => `${login}@amazon.com`).join("; ");

    // 将 loginsString 复制到剪贴板
    navigator.clipboard.writeText(loginsString).then(
      function () {
        console.log("Async: Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Async: Could not copy text: ", err);
      }
    );
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubscribeHandler = async () => {
    analyticsLogger.log("building_class_subscribe", {
      status: !subscribe,
      itemName: modernAppCourse.itemName
    });
    // 第一步：修改前端状态
    setSubscribe((prev) => !prev);

    // 第二步，修改后端数据库
    setApiCalled(true);
    const eventBody = {
      itemName: modernAppCourse.itemName,
    };

    const config = {
      params: {
        action: "modify-subscription-list",
        userLogin: userLogin,
        itemName: modernAppCourse.itemName,
      },
    };

    try {
      const response = await new DataProvider().updateApp(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, likes updated.", "color: green; font-weight: bold;", response);
    } catch (error) {
      console.error("Update modernAppCourse item failed:", error);
    }
  };

  if (loading) {
    return <LoadingSpinner text="Loading building class..." />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <Header
        variant="h1"
        description={t("PageHeader.build_app_on_aws_subheadline")}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={goBackHandler}>{t("PageHeader.buttonGoBack")}</Button>
            {isAdmin && (
              <>
                <Popover
                  size="small"
                  position="top"
                  triggerType="custom"
                  content={<StatusIndicator type="success">{t("solutions:SolutionDetails.tabs-content.copyLogins")}</StatusIndicator>}
                >
                  <Button iconName="copy" onClick={() => onCopyLoginsHandler()}>
                    {t("PageHeader.buttonLogins")}
                  </Button>
                </Popover>
              </>
            )}
            {subscribe ? (
              <Button variant="primary" onClick={onSubscribeHandler}>
                {t("PageHeader.buttonAlreadySubscribed")}
              </Button>
            ) : (
              <Button variant="normal" onClick={onSubscribeHandler}>
                {t("PageHeader.buttonSubscribe")}
              </Button>
            )}
          </SpaceBetween>
        }
      >
        {t("PageHeader.build_app_on_aws_headline")}
      </Header>
    </>
  );
};

export default PageHeader;
