import { useState, useEffect } from "react";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import SynopsisPanel from "./tab-synopsis-panel";
import { DataProvider } from "../../../api/data-provider";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { LoadingSpinner, useSolutions, useUserProfile } from "../../../components";
import { categoryDictionary, domainDictionary } from "../../../common/chinese-english-dictionary";
import { getFileExtension, generateRandomId } from "../../../common/utils";
import cloneDeep from "lodash/cloneDeep";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function generateTranslatedAttribute(attribute, value, dictionary) {
  const contentLanguage = attribute.endsWith("_en") ? "cn" : "en";
  const otherAttribute = attribute.slice(0, -2) + contentLanguage;

  const otherValue = value.map((v) => {
    const translatedValue = dictionary[v["label"]];
    if (!translatedValue) {
      DEBUG && console.error(`Invalid value in 'value' array: ${v}`);
    }
    return { label: translatedValue, value: translatedValue };
  });

  return {
    otherAttribute,
    otherValue,
  };
}

/*****************************************************************************/
function FormInputBaseFormContent({ content, selectedSolution, errorText = null }) {
  const { t } = useTranslation();
  const { setFetched } = useSolutions();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();
  const { userLogin, contentLanguage, today } = useUserProfile();

  const [randomId, setRandomId] = useState("");
  const [fileLogo, setFileLogo] = useState([]);
  const [fileTechnicalArchitecture, setFileTechnicalArchitecture] = useState([]);
  const [errorTextLogo, setErrorTextLogo] = useState("");
  const [errorTextTechnicalArchitecture, setErrorTextTechnicalArchitecture] = useState("");
  const [logoUploadProgress, setLogoUploadProgress] = useState(0);
  const [technicalArchitectureUploadProgress, setTechnicalArchitectureUploadProgress] = useState(0);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [submitDisabled, setSubmitDisabled] = useState(false); // 文件不和要求的时候，disable submit button
  const [isLogoUploadCompleted, setIsLogoUploadCompleted] = useState(false);
  const [isTechnicalArchitectureUploadCompleted, setIsTechnicalArchitectureUploadCompleted] = useState(false);
  const [uploadSuccessful, setUploadSuccessful] = useState(true); // 在上传前的判断逻辑中没有出错，已经在上传，但是不是指传输完成
  const [isSolutionUpdateCompleted, setIsSolutionUpdateCompleted] = useState(false);

  // Get the selected solution object passed through useNavigate before jumping to this page
  // 被选择的解决方案在上一个页面使用navigate("/solutions/edit", { state: { selectedSolution } });跳转过来的时候，可以使用下面两行来读取到selectedSolution.
  // 被选择的解决方案已经通过useNavigate在上个页面跳转过来的时候通过state传递过来
  // const location = useLocation();
  // const selectedSolution = location.state?.selectedSolution;

  DEBUG && console.log("%cselectedSolution_toEdit", "color: red; font-weight: bold;", selectedSolution);
  const tier = selectedSolution.tier ? { label: `Tier ${selectedSolution.tier}`, value: selectedSolution.tier } : { label: `Tier 3`, value: "3" };
  const category_en = selectedSolution.category_en ? selectedSolution.category_en.map((item) => ({ label: item, value: item })) : [];
  const category_cn = selectedSolution.category_cn ? selectedSolution.category_cn.map((item) => ({ label: item, value: item })) : [];
  const domain_en = selectedSolution.domain_en ? selectedSolution.domain_en.map((item) => ({ label: item, value: item })) : [];
  const domain_cn = selectedSolution.domain_cn ? selectedSolution.domain_cn.map((item) => ({ label: item, value: item })) : [];
  const keyServices = selectedSolution.keyServices ? selectedSolution.keyServices.map((item) => ({ label: item, value: item })) : [];

  const [formattedData, setFormattedData] = useState(null);
  // map()函数会创建一个新的数组，而不是对原始数组进行修改。这意味着它们已经是selectedSolution的副本，并且在data中对它们的修改不会影响selectedSolution。
  const [data, setData] = useState({
    tier,
    category_en,
    category_cn,
    domain_en,
    domain_cn,
    keyServices,
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    if (selectedSolution) {
      const { roadmap, gtmMaterials_en, gtmMaterials_cn, ...rest } = selectedSolution;
      setFormattedData(cloneDeep({ ...rest }));
    }
  }, [selectedSolution]);

  useEffect(() => {
    if (formattedData) {
      setLoading(false);
      DEBUG && console.log("%cformattedData (used to call API to create DynamoDB items):", "color: red; font-weight: bold;", formattedData);
    }
  }, [formattedData]);

  // 当用户没有输入Partition Key和Sort Key的时候，是不在页面上渲染上传图片的Formfields的。要保证图片信息写到dynamodb的时候，有Primary Key可以查询到对应的item。
  useEffect(() => {
    DEBUG && console.log("%cdata", "color: red; font-weight: bold;", data);
  }, [data]);

  useEffect(() => {
    DEBUG && console.log("%cfileLogo Detected:", "color: red; font-weight: bold;", fileLogo);
  }, [fileLogo]);

  useEffect(() => {
    DEBUG && console.log("%cfileTechnicalArchitecture Detected:", "color: red; font-weight: bold;", fileTechnicalArchitecture);
  }, [fileTechnicalArchitecture]);

  useEffect(() => {
    // For debug purpose
    DEBUG && console.log("%clogoUploadProgress", "color: blue; font-weight: bold;", logoUploadProgress);
    DEBUG && console.log("%ctechnicalArchitectureUploadProgress", "color: blue; font-weight: bold;", technicalArchitectureUploadProgress);
  }, [logoUploadProgress, technicalArchitectureUploadProgress]);

  useEffect(() => {
    setRandomId(generateRandomId());
  }, []);

  // 监控状态，一旦文件上传成功，并且上传完毕，就执行对DynamoDB的写操作，更新数据库。
  useEffect(() => {
    async function updateNewSolution() {
      DEBUG && console.log("%cuploadSuccessful", "color: green; font-weight: bold;", uploadSuccessful);
      DEBUG && console.log("%cisLogoUploadCompleted", "color: green; font-weight: bold;", isLogoUploadCompleted);
      DEBUG && console.log("%cisTechnicalArchitectureUploadCompleted", "color: green; font-weight: bold;", isTechnicalArchitectureUploadCompleted);
      if (uploadSuccessful && isLogoUploadCompleted && isTechnicalArchitectureUploadCompleted) {
        try {
          const config = {
            params: {
              action: "solution-edit-synopsis",
            },
          };

          const response = await new DataProvider().updateSolution(formattedData, config);
          DEBUG && console.log("%cApiCallCompleted, set 'isSolutionUpdateCompleted' to 'true' now.", "color: green; font-weight: bold;", response);
          setIsSolutionUpdateCompleted(true);
          const newMessage = {
            type: "success",
            content: t("notification.updateSolution"),
            dismissible: true,
            dismissLabel: "Dismiss message",
            statusIconAriaLabel: "success",
          };
          pushNewNotification(newMessage);
        } catch (error) {
          console.error("Create Solution failed:", error);
          const newMessage = {
            type: "error",
            content: t("notification.createOrUpdateSolutionApiError"),
            dismissible: true,
            dismissLabel: "Dismiss message",
            statusIconAriaLabel: "error",
          };
          pushNewNotification(newMessage);
        } finally {
          setSubmitting(false);
        }
      }
    }

    updateNewSolution();
  }, [uploadSuccessful, isLogoUploadCompleted, isTechnicalArchitectureUploadCompleted, formattedData]);

  // 监听上传状态，如果已经完成api call，创建了dynamodb的新条目，就立刻发出通知，并重新加载最新的solution list
  useEffect(() => {
    if (isSolutionUpdateCompleted) {
      setFetched(false);
      navigate("/solutions");
    }
  }, [isSolutionUpdateCompleted]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onChangeFormHandler = (attribute, value) => {
    DEBUG && console.log(`%c${attribute}:`, "color: blue; font-weight: bold;", value);

    switch (attribute) {
      case `tier`:
        DEBUG && console.log("%cattribute", "color: blue; font-weight: bold;", attribute);
        DEBUG && console.log("%cvalue", "color: blue; font-weight: bold;", value);
        setData({ ...data, [attribute]: value });
        setFormattedData({
          ...formattedData,
          [attribute]: value.value,
        });
        break;

      case `category_${contentLanguage}`: // 自动生成另一种语言的Key/Value Pair
        const { otherAttribute: otherCategoryAttribute, otherValue: otherCategoryValue } = generateTranslatedAttribute(attribute, value, categoryDictionary);

        DEBUG && console.log("%cotherCategoryAttribute", "color: blue; font-weight: bold;", otherCategoryAttribute);
        DEBUG && console.log("%cotherCategoryValue", "color: blue; font-weight: bold;", otherCategoryValue);

        setData({ ...data, [attribute]: value, [otherCategoryAttribute]: otherCategoryValue });
        setFormattedData({
          ...formattedData,
          [attribute]: value.map((item) => item.value),
          [otherCategoryAttribute]: otherCategoryValue.map((item) => item.value),
        });
        break;

      case `domain_${contentLanguage}`:
        const { otherAttribute: otherDomainAttribute, otherValue: otherDomainValue } = generateTranslatedAttribute(attribute, value, domainDictionary);

        setData({ ...data, [attribute]: value, [otherDomainAttribute]: otherDomainValue });
        setFormattedData({
          ...formattedData,
          [attribute]: value.map((item) => item.value),
          [otherDomainAttribute]: otherDomainValue.map((item) => item.value),
        });
        break;

      case `keyServices`:
        setData({ ...data, [attribute]: value });
        setFormattedData({
          ...formattedData,
          [attribute]: value.map((item) => item.value),
        });
        break;

      default:
        setFormattedData({ ...formattedData, [attribute]: value });
        break;
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  function isFileValid(fileSelected) {
    const file = fileSelected[0];
    if (!file) {
      console.log("The file hasn't been selected.");
      setSubmitDisabled(false);
      return true;
    }

    const isImage = /^image\//.test(file.type);
    const isSizeValid = file.size <= 5 * 1024 * 1024;

    if (isImage && isSizeValid) {
      console.log("文件类型和大小均有效");
      setSubmitDisabled(false);
      return true;
    } else {
      if (!isImage) {
        console.log("文件类型无效，请上传图片文件");
        setSubmitDisabled(true);
        return false;
      }
      if (!isSizeValid) {
        console.log("文件大小超过(5MB)，请上传较小的文件");
        setSubmitDisabled(true);
        return false;
      }
    }
  }

  const onChangeFileHandler = (attribute, value) => {
    switch (attribute) {
      case "solutionLogo":
        setFileLogo(value);
        if (!isFileValid(value)) {
          setErrorTextLogo(t("solutions:SolutionForm.anErrorFileTypeOrSize"));
        } else {
          setErrorTextLogo("");
        }
        break;

      case "techArchitectureImage":
        setFileTechnicalArchitecture(value);
        if (!isFileValid(value)) {
          setErrorTextTechnicalArchitecture(t("solutions:SolutionForm.anErrorFileTypeOrSize"));
        } else {
          setErrorTextTechnicalArchitecture("");
        }
        break;

      default:
        break;
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function handleFileUploads() {
    DEBUG && console.log("%cformattedData", "color: blue; font-weight: bold;", formattedData);

    const filePath = `store/solutions/${formattedData.solutionNameAbbr}`;
    const newFormattedData = cloneDeep(formattedData);

    const updateNewFormattedData = (key, value) => {
      newFormattedData[key] = value;
    };

    const logoUploadPromise = fileLogo[0]
      ? (async () => {
          try {
            const ext = getFileExtension(fileLogo[0].name);
            const newFileName = `solution-logo-${today}-${randomId}.${ext}`;
            updateNewFormattedData("solutionLogo", `${filePath}/${newFileName}`); // 不需要以'/'作为首字符，统一按照S3 Key的命名规则。我在渲染的时候会自动加上'/'，记住渲染的时候一定要加上，否则无法正常渲染
            await new DataProvider().uploadToS3(userLogin, fileLogo[0], filePath, setLogoUploadProgress, setIsLogoUploadCompleted, newFileName);
          } catch (error) {
            setUploadSuccessful(false);
            setSubmitting(false);
            console.error("Logo upload failed:", error);
          }
        })()
      : (async () => {
          DEBUG && console.log("%ccustomerLogo image file doesn't exist, set 'isLogoUploadCompleted' to 'true'.", "color: green; font-weight: bold;");
          setIsLogoUploadCompleted(true);
        })();

    const architectureUploadPromise = fileTechnicalArchitecture[0]
      ? (async () => {
          try {
            const ext = getFileExtension(fileTechnicalArchitecture[0].name);
            const newFileName = `tech-architecture-${today}-${randomId}.${ext}`;
            updateNewFormattedData("techArchitectureImage", `${filePath}/${newFileName}`);
            await new DataProvider().uploadToS3(
              userLogin,
              fileTechnicalArchitecture[0],
              filePath,
              setTechnicalArchitectureUploadProgress,
              setIsTechnicalArchitectureUploadCompleted,
              newFileName
            );
          } catch (error) {
            setUploadSuccessful(false);
            setSubmitting(false);
            console.error("techArchitecture image file upload failed:", error);
          }
        })()
      : (async () => {
          DEBUG &&
            console.log(
              "%ctechArchitecture ImageFile doesn't exist, set 'isTechnicalArchitectureUploadCompleted' to 'true'.",
              "color: green; font-weight: bold;"
            );
          setIsTechnicalArchitectureUploadCompleted(true);
        })();

    await Promise.all([logoUploadPromise, architectureUploadPromise]);
    setFormattedData(newFormattedData);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      handleFileUploads(); // 调用新的handleFileUploads函数处理文件上传
    } catch (error) {
      console.error("One or more files failed to upload. New case study hasn't been put into DynamoDB. Try again on the webpage.");
      const newMessage = {
        type: "error",
        content: t("notification.filesUploadFailed"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
    }
  };

  const onCancelClick = () => {
    navigate("/solutions");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("common:Button.cancel")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler} loading={submitting} disabled={!formattedData.solutionNameAbbr || submitting || submitDisabled}>
              {submitting ? t("common:Button.submitting") : t("common:Button.submit")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({
          onChangeFormHandler,
          data,
          formattedData,
          setFormattedData,
          onChangeFileHandler,
          fileLogo,
          fileTechnicalArchitecture,
          errorTextLogo,
          errorTextTechnicalArchitecture,
          logoUploadProgress,
          technicalArchitectureUploadProgress,
        })}
      </Form>
    </form>
  );
}

/*****************************************************************************/
export function SynopsisFormContent({ loadHelpPanelContent, selectedSolution }) {
  return (
    <FormInputBaseFormContent
      selectedSolution={selectedSolution}
      content={({
        onChangeFormHandler,
        data,
        formattedData,
        setFormattedData,
        onChangeFileHandler,
        fileLogo,
        fileTechnicalArchitecture,
        errorTextLogo,
        errorTextTechnicalArchitecture,
        logoUploadProgress,
        technicalArchitectureUploadProgress,
      }) => (
        <SpaceBetween size="l">
          <SynopsisPanel
            loadHelpPanelContent={loadHelpPanelContent}
            onChangeFormHandler={onChangeFormHandler}
            data={data}
            formattedData={formattedData}
            setFormattedData={setFormattedData}
            onChangeFileHandler={onChangeFileHandler}
            fileLogo={fileLogo}
            fileTechnicalArchitecture={fileTechnicalArchitecture}
            errorTextLogo={errorTextLogo}
            errorTextTechnicalArchitecture={errorTextTechnicalArchitecture}
            logoUploadProgress={logoUploadProgress}
            technicalArchitectureUploadProgress={technicalArchitectureUploadProgress}
          />
        </SpaceBetween>
      )}
    />
  );
}
