import React, { useState } from "react";
import { Box, Grid, Link, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "../../../components";
import { getFileUrl } from "../../../common/utils";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

export function CasestudyDetails({ oneCasestudyObj }) {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();
  const [casestudyImageLoadError, setCasestudyImageLoadError] = useState(false);
  const [techArchitectureImageLoadError, setTechArchitectureImageLoadError] = useState(false);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const opportunityString = t("casestudies:CasestudyDetails.opportunity");
  const opportunityTitleString = oneCasestudyObj["opportunityHeadline_" + contentLanguage];
  const combinedOpportunityString = opportunityTitleString ? `${opportunityString} | ${opportunityTitleString}` : opportunityString;

  const solutionString = t("casestudies:CasestudyDetails.solution");
  const solutionTitleString = oneCasestudyObj["solutionHeadline_" + contentLanguage];
  const combinedSolutionString = solutionTitleString ? `${solutionString} | ${solutionTitleString}` : solutionString;

  const outcomeString = t("casestudies:CasestudyDetails.outcome");
  const outcomeTitleString = oneCasestudyObj["outcomeHeadline_" + contentLanguage];
  const combinedOutcomeString = outcomeTitleString ? `${outcomeString} | ${outcomeTitleString}` : outcomeString;

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 替换"\n"为<br/>, 最后一个段落后面不需要换行符
  function renderText(text) {
    if (!text) {
      return null;
    }

    const paragraphs = text.split(/\n\n+/);

    const renderedText = paragraphs.map((paragraph, index) => (
      <React.Fragment key={index}>
        {paragraph}
        {index !== paragraphs.length - 1 && (
          <>
            <br />
            <br />
          </>
        )}
      </React.Fragment>
    ));

    return <>{renderedText}</>;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const handleImageLoadError = () => {
    setCasestudyImageLoadError(true);
  };

  const handleTechArchitectureImageLoadError = () => {
    setTechArchitectureImageLoadError(true);
  };

  /* 使用renderOverview，在存在casestudyImage且其可以渲染的情况下，在页面上渲染该图片 */
  const renderOverview = () => {
    if (oneCasestudyObj.casestudyImage && oneCasestudyObj.casestudyImage !== "") {
      DEBUG && console.log("%ccasestudyImageLoadError", "color: blue; font-weight: bold;", casestudyImageLoadError);
      if (casestudyImageLoadError) {
        return <Box variant="p">{renderText(oneCasestudyObj["overview_" + contentLanguage])}</Box>;
      } else {
        return (
          <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
            <Box variant="p">{renderText(oneCasestudyObj["overview_" + contentLanguage])}</Box>
            <div style={{ display: "inline-block", verticalAlign: "middle", marginRight: "10px" }}>
              <img width="100%" src={getFileUrl(oneCasestudyObj.casestudyImage)} alt="Image for the Casestudy" onError={handleImageLoadError} />
            </div>
          </Grid>
        );
      }
    } else {
      return <Box variant="p">{renderText(oneCasestudyObj["overview_" + contentLanguage])}</Box>;
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <SpaceBetween size="xl">
        <SpaceBetween size="xs">
          <Box variant="h3">{t("casestudies:CasestudyDetails.overview")}</Box>
          {renderOverview()}
        </SpaceBetween>
        <SpaceBetween size="xs">
          <Box variant="h3">{combinedOpportunityString}</Box>
          <Box variant="p">{renderText(oneCasestudyObj["opportunity_" + contentLanguage])}</Box>
        </SpaceBetween>
        <SpaceBetween size="xs">
          <Box variant="h3">{combinedSolutionString}</Box>
          <Box variant="p">{renderText(oneCasestudyObj["solution_" + contentLanguage])}</Box>
        </SpaceBetween>
        <SpaceBetween size="xs">
          <Box variant="h3">{combinedOutcomeString}</Box>
          <Box variant="p">{renderText(oneCasestudyObj["outcome_" + contentLanguage])}</Box>
        </SpaceBetween>
        {oneCasestudyObj.techArchitectureImage && !techArchitectureImageLoadError && (
          <SpaceBetween size="xs">
            <Box variant="h3">{t("casestudies:CasestudyDetails.architecture")}</Box>
            <div style={{ textAlign: "center", padding: "20px" }}>
              <img
                src={getFileUrl(oneCasestudyObj.techArchitectureImage)}
                width="100%"
                alt="Tech Architecture Image"
                onError={handleTechArchitectureImageLoadError}
              />
            </div>
          </SpaceBetween>
        )}
        {oneCasestudyObj["customerProfile_" + contentLanguage] && (
          <SpaceBetween size="xs">
            <Box variant="h3">{t("casestudies:CasestudyDetails.about") + " " + `${oneCasestudyObj["customerName_" + contentLanguage]}`}</Box>
            <Box variant="p">{renderText(oneCasestudyObj["customerProfile_" + contentLanguage])}</Box>
          </SpaceBetween>
        )}
        {oneCasestudyObj["salesReps"] && oneCasestudyObj["salesReps"].length >= 1 && (
          <>
            <Box variant="h3">{t("casestudies:CasestudyDetails.salesReps")}</Box>
            <SpaceBetween size="xs" direction="horizontal">
              {oneCasestudyObj.salesReps.map((item) => (
                <Box key={item.login}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img alt="" className="custom-avatar" src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${item.login}`} />
                    &nbsp;
                    <Link external externalIconAriaLabel="Opens in a new tab" href={`https://phonetool.amazon.com/users/${item.login}`}>
                      <span style={{ marginLeft: "2px" }}>{item.name}</span>
                      <span style={{ marginLeft: "2px" }}>({item.role})</span>
                    </Link>
                    &nbsp; &nbsp; &nbsp;
                  </span>
                </Box>
              ))}
            </SpaceBetween>
          </>
        )}
      </SpaceBetween>
    </>
  );
}

export default CasestudyDetails;

/*
       {oneCasestudyObj["salesReps"] && oneCasestudyObj["salesReps"].length >= 1 && (
          <>
            <Box variant="h3">{t("casestudies:CasestudyDetails.salesReps")}</Box>
            <SpaceBetween size="xs" direction="horizontal">
              {oneCasestudyObj.salesReps.map((item) => (
                <Box key={item.login}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <img alt="" className="custom-avatar" src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${item.login}`} />
                    &nbsp;
                    <Link external externalIconAriaLabel="Opens in a new tab" href={`https://phonetool.amazon.com/users/${item.login}`}>
                      <span style={{ marginLeft: "2px" }}>{item.name}</span>
                      <span style={{ marginLeft: "2px" }}>({item.role})</span>
                    </Link>
                    &nbsp; &nbsp; &nbsp;
                  </span>
                </Box>
              ))}
            </SpaceBetween>
          </>
        )}
*/
