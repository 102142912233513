import { AxiosError, AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from "axios";
import { Message, OpenAIModel } from "../components/ChatbotUI";
import apiClient from "./apiClient";
import paletteConfig from "../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/**
 * 在DynamoDB 中，Create 和 Update 操作都可以用来更新现有项或创建新项，但它们之间有区别。当表中已经存在对应的主键时，该操作
 * 将被视为对现有条目的更新。假设原来的item有10个attributes，使用Create进行更新的时候，如果假设只提供了3个attribute的值，那么
 * 就会把这3个attributes的值进行更新，同时把另外7个attributes的值设置为空值。而用update的时候，另外7个的值会保持不变。
 *
 * 所以，当用户通过Create页面创建新的casestudies/solutions的时候，我们的逻辑是认为用户就是想用新值覆盖旧值，所以是允许的。
 */

type Solution = any;
type User = any;
type Casestudy = any;
type App = any;

interface GptParams {
  model: string;
  messages: Message[];
  max_tokens: number;
  temperature: number;
  stream: boolean;
}

// interface DataProviderResponse {
//   id: string;
//   object: string;
//   created: number;
//   model: string;
//   usage: { prompt_tokens: number; completion_tokens: number; total_tokens: number };
//   choices: { text: string }[];
// }

export class DataProvider {
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async createSolution(solution: Solution) {
    try {
      const response: AxiosResponse = await apiClient.post("solutions", solution);
      DEBUG && console.log("%cSolution_Created", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async getAllSolutions() {
    try {
      const response = await apiClient.get("solutions");
      DEBUG && console.log("%cSolutions_Fetched", "color: crimson; font-weight: bold;", response);
      return response.data;
      // 如果我们的原来对象中有一个data属性，它是一个日期字符串，希望转换为Date对象，让您在前端更方便地处理日期，例如格式化或计算日期差等。
      // return response.data.map((it:any) => ({ ...it, date: new Date(it.date) }))
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async getOneSolution(valueOfSolutionNameAbbr: string) {
    try {
      const response: AxiosResponse = await apiClient.get("solutions", {
        params: {
          solutionNameAbbr: valueOfSolutionNameAbbr, // use the primary partition key to do query
        },
      });
      DEBUG && console.log("%cSolutions_Fetched", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  // 我们在React网页中建议只使用getSolutions，因为它涵盖了所有的场景，包括读取1个，N个满足条件的，或全部Items。
  // getSolutions() === getAllSolutions;       Here there is no query.
  // getSolutions({solutionNameAbbr: valueOfSolutionNameAbbr}) === getOneSolution(valueOfSolutionNameAbbr)
  public async getSolutions(query?: any) {
    try {
      const response: AxiosResponse = await apiClient.get("solutions", {
        params: query,
      });
      DEBUG && console.log("%cSolutions_Fetched", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async updateSolution(solution: Solution, config?: AxiosRequestConfig) {
    try {
      const response: AxiosResponse = await apiClient.put("solutions", solution, config);
      DEBUG && console.log("%cSolutions_Updated", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async deleteSolutions(items: { solutionNameAbbr: string; sortKey?: string }[]) {
    try {
      const response: AxiosResponse = await apiClient.delete("solutions", {
        data: items,
      });
      DEBUG && console.log("%cSolutions_Deleted", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async createUser(user: User) {
    try {
      const response: AxiosResponse = await apiClient.post("users", user);
      DEBUG && console.log(response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async getUsers(query?: any) {
    try {
      const response: AxiosResponse = await apiClient.get("users", {
        params: query,
      });
      DEBUG && console.log("%cUsersFetched", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async updateUser(user: User) {
    try {
      const response: AxiosResponse = await apiClient.put("users", user);
      DEBUG && console.log(response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async deleteUsers(items: { userLogin: string; sortKey?: string }[]) {
    try {
      const response: AxiosResponse = await apiClient.delete("users", {
        data: items,
      });
      DEBUG && console.log(response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async createApp(app: App) {
    try {
      const response: AxiosResponse = await apiClient.post("app", app);
      DEBUG && console.log(response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async getApp(query?: any) {
    try {
      const response: AxiosResponse = await apiClient.get("app", {
        params: query,
      });
      DEBUG && console.log("%cApp_Table_Fetched", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async updateApp(app: App, config?: AxiosRequestConfig) {
    try {
      const response: AxiosResponse = await apiClient.put("app", app, config);
      DEBUG && console.log("%cApp_Table_Updated", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async deleteApp(items: { itemName: string; sortKey?: string }[]) {
    try {
      const response: AxiosResponse = await apiClient.delete("app", {
        data: items,
      });
      DEBUG && console.log(response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async uploadToS3(
    awsLogin: string,
    file: File,
    path: string,
    setProgress: (progress: number) => void,
    setCompleted: (isCompleted: boolean) => void,
    newFileName?: string
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // Convert file to base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const base64File = reader.result?.toString().split(",")[1];
          const payload = {
            filename: newFileName || file.name,
            file: base64File,
            contentType: file.type,
            userLogin: awsLogin,
            path: path,
          };

          const config: AxiosRequestConfig = {
            onUploadProgress: (progressEvent: AxiosProgressEvent) => {
              const percentage = progressEvent.total ? Math.round((progressEvent.loaded / progressEvent.total) * 100) : 0;
              DEBUG && console.log("%cUpload Progress: ", "color: green; font-weight: bold;", percentage);
              setProgress(percentage);
            },
          };

          try {
            const response = await apiClient.post("upload", payload, config);
            DEBUG && console.log("%cFile uploaded successfully, response is: ", "color: crimson; font-weight: bold;", response);
            if (response.status === 200) {
              setCompleted(true);
              resolve();
            } else {
              reject(new Error("File upload failed"));
            }
          } catch (error) {
            DEBUG && console.log("%cError caught while uploading file: ", "color: crimson; font-weight: bold;", error);
            setProgress(0);
            reject(new Error("Error caught while uploading file!"));
          }
        };
        reader.onerror = () => {
          reject(new Error("FileReader error"));
        };
      } catch (error) {
        console.error(`Response error: ${(error as AxiosError).response?.status}`);
        reject(new Error(`Response error: ${(error as AxiosError).response?.status}`));
      }
    });
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async chatgpt(messagesToSend: Message[]) {
    try {
      const params: GptParams = {
        model: OpenAIModel.DAVINCI_TURBO,
        messages: [
          {
            role: "system",
            content: `You are a helpful, friendly, assistant...`,
          },
          ...messagesToSend, // Use messageToSend
        ],
        max_tokens: 800,
        temperature: 0.0,
        stream: false,
      };

      const response: AxiosResponse = await apiClient.post("chatgpt", params);
      DEBUG && console.log("%cchatgptapi response:", "color: crimson; font-weight: bold;", response);

      if (response.status !== 200) {
        throw new Error(`Unexpected response code: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async ourownbot(singleMessageToSend: Message) {
    try {
      const params = {
        model: "knowledge_qa",
        messages: [singleMessageToSend],
        // messages: [{ role: message.role, content: message.content }],
        temperature: 0.1,
      };

      DEBUG && console.log("%cparams:", "color: crimson; font-weight: bold;", params);

      const response: AxiosResponse = await apiClient.post("ourownbot", params);
      DEBUG && console.log("%courownbot api response:", "color: crimson; font-weight: bold;", response);

      if (response.status !== 200) {
        throw new Error(`Unexpected response code: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async ourownbot_multi_messages(messagesToSend: Message[]) {
    try {
      const params = {
        model: "knowledge_qa",
        messages: messagesToSend,
        // messages: [{ role: message.role, content: message.content }],
        temperature: 0.7,
      };

      DEBUG && console.log("%cparams:", "color: crimson; font-weight: bold;", params);

      const response: AxiosResponse = await apiClient.post("ourownbot", params);
      DEBUG && console.log("%courownbot api response:", "color: crimson; font-weight: bold;", response);

      if (response.status !== 200) {
        throw new Error(`Unexpected response code: ${response.status}`);
      }

      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }
  // public async uploadToS3(awsLogin: string, file: File, path: string, setProgress: (progress: number) => void, setCompleted: (isCompleted: boolean) => void, newFileName?: string ) {
  //   try {
  //     // Convert file to base64
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = async () => {
  //       const base64File = reader.result?.toString().split(",")[1];
  //       const payload = {
  //         filename: newFileName || file.name,
  //         file: base64File,
  //         contentType: file.type,
  //         userLogin: awsLogin,
  //         path: path,
  //       };

  //       const config: AxiosRequestConfig = {
  //         onUploadProgress: (progressEvent: AxiosProgressEvent) => {
  //           const percentage = progressEvent.total ? Math.round((progressEvent.loaded / progressEvent.total) * 100) : 0;
  //           console.log("%cUpload Progress: ", "color: green; font-weight: bold;", percentage);
  //           setProgress(percentage);
  //         },
  //       };

  //       try {
  //         const response = await apiClient.post('upload', payload, config);
  //         console.log("%cFile uploaded successfully, response is: ", "color: crimson; font-weight: bold;", response);
  //         if (response.status === 200) {
  //           setCompleted(true);
  //         }
  //         return true;
  //       } catch (error) {
  //         console.log("%cError caught while uploading file: ", "color: crimson; font-weight: bold;", error);
  //         setProgress(0);
  //         throw new Error(`Error caught while uploading file!`);
  //       }
  //     };
  //   } catch (error) {
  //     console.error(`Response error: ${(error as AxiosError).response?.status}`);
  //     throw new Error(`Response error: ${(error as AxiosError).response?.status}`);

  //   }
  // }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  public async createCasestudy(casestudy: Casestudy) {
    try {
      const response: AxiosResponse = await apiClient.post("casestudies", casestudy);
      DEBUG && console.log("%cCasestudy_Created", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async getCasestudies(query?: any) {
    try {
      const response: AxiosResponse = await apiClient.get("casestudies", {
        params: query,
      });
      DEBUG && console.log("%cCasestudies_Fetched", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async updateCasestudy(casestudy: Casestudy, config?: AxiosRequestConfig) {
    try {
      const response: AxiosResponse = await apiClient.put("casestudies", casestudy, config);
      DEBUG && console.log("%cCasestudy_Updated", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }

  public async deleteCasestudies(items: { solutionNameAbbr: string; customerNameAbbr: string }[]) {
    try {
      const response: AxiosResponse = await apiClient.delete("casestudies", {
        data: items,
      });
      DEBUG && console.log("%cCasestudies_Deleted", "color: crimson; font-weight: bold;", response);
      return response.data;
    } catch (error) {
      console.error(`Response error: ${(error as AxiosError).response?.status}`);
      throw new Error(`Response error: ${(error as AxiosError).response?.status}`);
    }
  }
}
