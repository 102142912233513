import { Link, StatusIndicator } from "@cloudscape-design/components";

const headlineSortingComparator = (contentLanguage) => (item1, item2) => {
  const headline1 = item1[`headline_${contentLanguage}`];
  const headline2 = item2[`headline_${contentLanguage}`];

  if (headline1 === headline2) return 0; // handles both are undefined/null/empty string

  if (!headline1 || headline1 === "") return -1; // item1 is undefined/null/empty string, so it's "smaller"

  if (!headline2 || headline2 === "") return 1; // item2 is undefined/null/empty string, so it's "smaller"

  return headline1.localeCompare(headline2);
};

const customerNameSortingComparator = (contentLanguage) => (item1, item2) => {
  const name1 = item1[`customerName_${contentLanguage}`];
  const name2 = item2[`customerName_${contentLanguage}`];

  if (name1 === name2) return 0; // handles both are undefined/null/empty string

  if (!name1 || name1 === "") return -1; // item1 is undefined/null/empty string, so it's "smaller"

  if (!name2 || name2 === "") return 1; // item2 is undefined/null/empty string, so it's "smaller"

  return name1.localeCompare(name2);
};

const yearSortingComparator = (item1, item2) => parseInt(item1.year) - parseInt(item2.year);
const publicSortingComparator = (item1, item2) => (item1.publicReference ? 1 : 0) - (item2.publicReference ? 1 : 0);

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createColumnDefinitions = (t, navigate, contentLanguage) => {
  return [
    {
      id: "headline",
      sortingField: "headline",
      minWidth: 500,
      header: t("solutions:SolutionDetails.tabs-content.casestudiesTableHeadline"),
      sortingComparator: headlineSortingComparator,
      cell: (item) => (
        <Link
          onFollow={(e) => {
            e.preventDefault();
            navigate(`/casestudies/details?solutionNameAbbr=${item.solutionNameAbbr}&customerNameAbbr=${item.customerNameAbbr}`);
          }}
        >
          {item[`headline_${contentLanguage}`]}
        </Link>
      ),
    },
    {
      id: "customer_name",
      sortingField: "customer_name",
      minWidth: 180,
      header: t("solutions:SolutionDetails.tabs-content.casestudiesTableCustomerName"),
      sortingComparator: customerNameSortingComparator,
      cell: (item) => item[`customerName_${contentLanguage}`],
    },
    {
      id: "year",
      sortingField: "year",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.casestudiesTableYear"),
      sortingComparator: yearSortingComparator,
      cell: (item) => item.year,
    },
    {
      id: "public",
      sortingField: "public",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.casestudiesTablePublicReference"),
      sortingComparator: publicSortingComparator,
      cell: (item) => <StatusIndicator type={item.publicReference ? "success" : "error"}>{item.publicReference ? "Yes" : "No"}</StatusIndicator>,
    },
  ];
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:SolutionDetails.tabs-content.visibleContentConfigLabel"),
    options: [
      { id: "headline", label: t("solutions:SolutionDetails.tabs-content.casestudiesTableHeadline") },
      { id: "customer_name", label: t("solutions:SolutionDetails.tabs-content.casestudiesTableCustomerName") },
      { id: "year", label: t("solutions:SolutionDetails.tabs-content.casestudiesTableYear") },
      { id: "public", label: t("solutions:SolutionDetails.tabs-content.casestudiesTablePublicReference") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 30,
  visibleContent: ["headline", "customer_name", "year", "public"],
  wrapLines: false,
  stripedRows: false,
});
