import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import { getCounter } from "../../../common/tableCounterStrings";
import { DataProvider } from "../../../api/data-provider";
import { useNotification, useSolutions, useUserProfile } from "../../../components";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
export const PageHeader = (props) => {
  const { t } = useTranslation();
  const { contentLanguage, setContentLanguage, isAdmin } = useUserProfile();
  const navigate = useNavigate();
  const { pushNewNotification } = useNotification();
  const { fetched, setFetched } = useSolutions();

  const isOnlyOneSelected = props.selectedItems.length === 1;
  const noSelectedItems = props.selectedItems.length === 0;
  const headerTitle = t("PageHeader.headerTitleSolutions");

  /* For debug's purpose
  console.log("[Dev] SelectedSolutionItems are:", props.selectedItems);
  console.log("[Dev] TotalSolutionItems are:", props.totalItems);
   */

  // function viewDetailsSolutionHandler() {
  //   // The array of props.selectedItems has only one object selectedItems[0], otherwise the button will be disabled.
  //   const selectedSolution = props.selectedItems[0];
  //   navigate(`/solutions/details?solutionNameAbbr=${selectedSolution.solutionNameAbbr}`);
  // }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function editSolutionHandler() {
    // The array of props.selectedItems has only one object selectedItems[0], otherwise the button will be disabled.
    const selectedSolution = props.selectedItems[0];
    navigate(`/solutions/edit?solutionNameAbbr=${selectedSolution.solutionNameAbbr}`);
    // 下面这种使用state的方式也是可行的，但是有一点缺陷，当浏览器出现丢失state信息时，通过刷新页面是无法成功渲染正确页面的。
    // navigate("/solutions/edit", { state: { selectedSolution } });
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function createSolutionHandler() {
    // The array of props.selectedItems has only one object selectedItems[0], otherwise the button will be disabled.
    navigate("/solutions/create");
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function deleteSlectedSolutionsHandler() {
    new DataProvider()
      .deleteSolutions(props.selectedItems.map((item) => ({ solutionNameAbbr: item.solutionNameAbbr })))
      .then((response) => {
        DEBUG && console.log("%cresponse from Api Gateway", "color: green; font-weight: bold;", response);

        const newMessage = {
          type: "success",
          content: t("notification.deleteSolutions"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "success",
        };
        pushNewNotification(newMessage);
      })
      .catch((error) => {
        console.error(error);
        const newMessage = {
          type: "error",
          content: t("notification.deleteSolutionsError"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "error",
        };
        pushNewNotification(newMessage);
      })
      .finally(() => {
        // 在更新完了后端dynamodb数据库后，我们用下面这行代码触发前端重新拉取最新的solutionsList。
        setFetched(false);
      });
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function publishSolutionsHandler() {
    const eventBody = {
      solutionNameAbbr: props.selectedItems[0].solutionNameAbbr,
    };

    const config = {
      params: {
        action: "toggle-publish-solution",
        solutionNameAbbr: props.selectedItems[0].solutionNameAbbr,
      },
    };

    try {
      const response = await new DataProvider().updateSolution(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, isPublished status updated.", "color: green; font-weight: bold;", response);
      const newMessage = {
        type: "success",
        content: t("notification.updateSolution"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "success",
      };
      pushNewNotification(newMessage);

      // 在更新完了后端dynamodb数据库后，我们用下面这行代码触发前端重新拉取最新的solutionsList。
      setFetched(false);
    } catch (error) {
      console.error("Failed to toggle the status of isPublished:", error);
      const newMessage = {
        type: "error",
        content: t("notification.createOrUpdateSolutionApiError"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const goBackToSolutionsPageHandler = () => {
    navigate(-1);
  };

  const changeContentLanguage = () => {
    setContentLanguage(contentLanguage === "en" ? "cn" : "en");
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const buttonForAdmin = (
    <>
      <Button data-testid="header-btn-publish" variant="primary" disabled={!isOnlyOneSelected || !isAdmin} onClick={publishSolutionsHandler}>
        {t("PageHeader.buttonPublish")}
      </Button>
      <Button data-testid="header-btn-edit" disabled={!isOnlyOneSelected || !isAdmin} onClick={editSolutionHandler}>
        {t("PageHeader.buttonEdit")}
      </Button>
      <Button data-testid="header-btn-delete" disabled={noSelectedItems || !isAdmin} onClick={deleteSlectedSolutionsHandler}>
        {t("PageHeader.buttonDelete")}
      </Button>
      <Button data-testid="header-btn-create" variant="primary" disabled={!isAdmin} onClick={createSolutionHandler}>
        {t("PageHeader.buttonCreate")}
      </Button>
    </>
  );

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <Header
      variant="awsui-h1-sticky"
      counter={getCounter(props)}
      info={
        props.loadHelpPanelContent && (
          <Link onFollow={props.loadHelpPanelContent} variant="info">
            {t("PageHeader.linkInfo")}
          </Link>
        )
      }
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="refresh" loading={!fetched} onClick={() => setFetched(false)} />
          <Button data-testid="header-btn-go-back" onClick={changeContentLanguage}>
            {contentLanguage === "en" ? t("PageHeader.buttonChangeLanguageCN") : t("PageHeader.buttonChangeLanguage")}
          </Button>
          <Button data-testid="header-btn-go-back" onClick={goBackToSolutionsPageHandler}>
            {t("PageHeader.buttonGoBack")}
          </Button>
          {/* <Button data-testid="header-btn-view-details" disabled={!isOnlyOneSelected} onClick={viewDetailsSolutionHandler}>
            {t("PageHeader.buttonViewDetails")}
          </Button> */}
          {isAdmin && buttonForAdmin}
        </SpaceBetween>
      }
    >
      {headerTitle}
    </Header>
  );
};

export default PageHeader;

/*=================== Start of checking if the user isAdmin ===============*/
/* 下面这部分代码不需要了，因为已经使用了useUserProfile这个hook
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAdminStatus = async () => {
      try {
        const session = await Auth.currentSession();
        const groups = session.idToken.payload["cognito:groups"];
        console.log("[Dev] The user's Groups are:", groups);
        if (groups && groups.includes("admin")) {
          setIsAdmin(true);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAdminStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  */
/*=================== End of checking if the user isAdmin ==============*/
