import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ContentLayout, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, LoadingSpinner, Navigation, Notification, useSolutions, useUserProfile } from "../../components";
import ToolsContent from "../solutions/sub-components/ToolsContent";
import { FormHeader } from "./sub-components/FormHeader";
import { SynopsisFormContent } from "./sub-components/tab-synopsis-form-content";
import { RoadmapFormContent } from "./sub-components/tab-roadmap-form-content";
import { GtmFormContent } from "./sub-components/tab-gtm-form-content";
import paletteConfig from "../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function SolutionEditStarting() {
  const { t } = useTranslation();
  const { solutionsList } = useSolutions();
  const { contentLanguage } = useUserProfile();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [oneSolution, setOneSolution] = useState(null);
  const [loading, setLoading] = useState(true);
  const appLayout = useRef();

  // 当我们使用/solutions/edit/xxx来跳转时，可以通过下面这行代码获得xxx的值，并赋给solutionNameAbbr。
  // const { solutionNameAbbr } = useParams();
  // 但是我们在项目中使用SearchParams的方法传参数。URL的结构是/solutions/details?solutionNameAbbr=xxx
  // 下面这段代码的作用是获取当前页面的查询参数中名为solutionNameAbbr的值。如果该查询参数不存在，则solutionNameAbbr的值将为null。
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const solutionNameAbbr = searchParams.get("solutionNameAbbr");
  DEBUG && console.log("%clocation object:", "color: green; font-weight: bold;", location);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 根据solutionNameAbbr从solutionsList中获取对应的solution对象
  useEffect(() => {
    if (solutionsList) {
      const solutionToEdit = solutionsList.find((solution) => solution.solutionNameAbbr === solutionNameAbbr);
      setOneSolution(solutionToEdit);
    }
  }, [solutionNameAbbr, solutionsList]);

  useEffect(() => {
    if (oneSolution) {
      setLoading(false);
    }
  }, [oneSolution]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Solutions"),
      href: "/solutions",
    },
    {
      text: oneSolution?.[`solutionName_${contentLanguage}`],
      href: `/solutions/details?solutionNameAbbr=${solutionNameAbbr}`,
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const tabs = [
    {
      label: t("solutions:SolutionEditTabs.synopsis"),
      id: "synopsis",
      content: <SynopsisFormContent loadHelpPanelContent={loadHelpPanelContent} selectedSolution={oneSolution} />,
    },
    {
      label: t("solutions:SolutionEditTabs.roadmap"),
      id: "roadmap",
      content: <RoadmapFormContent loadHelpPanelContent={loadHelpPanelContent} selectedSolution={oneSolution} />,
    },
    {
      label: t("solutions:SolutionEditTabs.gtm"),
      id: "jsonToCreate",
      content: <GtmFormContent loadHelpPanelContent={loadHelpPanelContent} selectedSolution={oneSolution} />,
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }
  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      contentType="form"
      content={
        <ContentLayout disableOverlap header={<FormHeader loadHelpPanelContent={loadHelpPanelContent} />}>
          <SpaceBetween size="l">
            <Tabs tabs={tabs} />
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/solutions" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, this component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default SolutionEditStarting;
