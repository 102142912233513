import {
  Button,
  Container,
  DatePicker,
  FileUpload,
  FormField,
  Grid,
  Header,
  Input,
  ProgressBar,
  Select,
  Multiselect,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { useUserProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import {
  categoryOptions_en,
  categoryOptions_cn,
  domainOptions_en,
  domainOptions_cn,
  keyServicesOptions,
  productManagerOptions,
  solutionDevelopmentManagerOptions,
  csdcMemberOptions,
  tierOptions,
} from "../../../common/formOptions";
import { addForm, removeForm, updateForm, moveForm, addFormNested, removeFormNested, updateFormNested, moveFormNested } from "../../../common/utils";

/*****************************************************************************/
export default function SynopsisPanel({
  onChangeFormHandler,
  data,
  formattedData,
  setFormattedData,
  onChangeFileHandler,
  fileLogo,
  fileTechnicalArchitecture,
  errorTextLogo,
  errorTextTechnicalArchitecture,
  logoUploadProgress,
  technicalArchitectureUploadProgress,
}) {
  const { contentLanguage } = useUserProfile();
  const { t } = useTranslation();

  // 下面几行代码是为了在界面切换语言的时候，能动态渲染正确的FormField组件
  // const solutionName = `solutionName_${uiLanguage}`;
  const categoryOptions = contentLanguage === "en" ? categoryOptions_en : categoryOptions_cn;
  const domainOptions = contentLanguage === "en" ? domainOptions_en : domainOptions_cn;

  const logo_uploadButtonText = formattedData.solutionLogo ? formattedData.solutionLogo : t("solutions:SolutionForm.fileLogoText");
  const architecture_uploadButtonText = formattedData.techArchitectureImage
    ? formattedData.techArchitectureImage
    : t(`solutions:SolutionForm.fileArchitectureText`);

  return (
    <>
      <SpaceBetween size="l">
        {/* The 1st container: Basic Information ++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.containerBasicInformation")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`solutions:SolutionForm.solutionName`)} description={t(`solutions:SolutionForm.solutionName_Des`)}>
              <Input
                value={formattedData["solutionName_" + contentLanguage]}
                onChange={({ detail: { value } }) => onChangeFormHandler(`solutionName_${contentLanguage}`, value)}
                readOnly
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.tier`)} description={t(`solutions:SolutionForm.tier_Des`)}>
              <Select
                selectedOption={data.tier && data.tier}
                onChange={({ detail }) => onChangeFormHandler(`tier`, detail.selectedOption)}
                options={tierOptions}
                selectedAriaLabel="Selected"
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.category`)} description={t(`solutions:SolutionForm.category_Des`)}>
              <Multiselect
                selectedOptions={data["category_" + contentLanguage] || []}
                onChange={({ detail }) => onChangeFormHandler(`category_${contentLanguage}`, detail.selectedOptions)}
                options={categoryOptions}
                placeholder={t(`solutions:SolutionForm.category_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.domain`)} description={t(`solutions:SolutionForm.domain_Des`)}>
              <Multiselect
                selectedOptions={data["domain_" + contentLanguage] || []}
                onChange={({ detail }) => onChangeFormHandler(`domain_${contentLanguage}`, detail.selectedOptions)}
                options={domainOptions}
                placeholder={t(`solutions:SolutionForm.domain_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.keyServices`)} description={t(`solutions:SolutionForm.keyServices_Des`)}>
              <Multiselect
                selectedOptions={data.keyServices}
                onChange={({ detail }) => onChangeFormHandler("keyServices", detail.selectedOptions)}
                options={keyServicesOptions}
                placeholder={t(`solutions:SolutionForm.keyServices_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.useCase`)} description={t(`solutions:SolutionForm.useCase_Des`)}>
              <Input
                value={formattedData["useCase_" + contentLanguage] || ""}
                placeholder={t(`solutions:SolutionForm.useCase_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler(`useCase_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.currentVersion`)} description={t(`solutions:SolutionForm.currentVersion_Des`)}>
              <Input
                value={formattedData.currentVersion}
                placeholder={t(`solutions:SolutionForm.currentVersion_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("currentVersion", value)}
              />
            </FormField>
            <FormField
              label={t(`solutions:SolutionForm.releaseDate`)}
              description={t(`solutions:SolutionForm.releaseDate_Des`)}
              constraintText={t(`solutions:SolutionForm.releaseDate_ConstrainText`)}
            >
              <DatePicker
                placeholder="YYYY/MM/DD"
                value={formattedData.releaseDate}
                onChange={({ detail: { value } }) => onChangeFormHandler("releaseDate", value)}
              />
            </FormField>
            <FormField
              label={t(`solutions:SolutionForm.nextReleaseDate`)}
              description={t(`solutions:SolutionForm.nextReleaseDate_Des`)}
              constraintText={t(`solutions:SolutionForm.nextReleaseDate_ConstrainText`)}
            >
              <DatePicker
                placeholder="YYYY/MM/DD"
                value={formattedData.nextReleaseDate}
                onChange={({ detail: { value } }) => onChangeFormHandler("nextReleaseDate", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.ttLink`)} description={t(`solutions:SolutionForm.ttLink_Des`)}>
              <Input
                value={formattedData.ttLink}
                placeholder={t(`solutions:SolutionForm.ttLink_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("ttLink", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.cnLink`)} description={t(`solutions:SolutionForm.cnLink_Des`)}>
              <Input
                value={formattedData.cnLink}
                placeholder={t(`solutions:SolutionForm.cnLink_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("cnLink", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.wwLink`)} description={t(`solutions:SolutionForm.wwLink_Des`)}>
              <Input
                value={formattedData.wwLink}
                placeholder={t(`solutions:SolutionForm.wwLink_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("wwLink", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.demo`)} description={t(`solutions:SolutionForm.demo_Des`)}>
              <Input
                value={formattedData.demo}
                placeholder={t(`solutions:SolutionForm.demo_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("demo", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.sourceCode`)} description={t(`solutions:SolutionForm.sourceCode_Des`)}>
              <Input
                value={formattedData.sourceCode}
                placeholder={t(`solutions:SolutionForm.sourceCode_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("sourceCode", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.solutionId`)} description={t(`solutions:SolutionForm.solutionId_Des`)}>
              <Input
                value={formattedData.solutionId}
                placeholder={t(`solutions:SolutionForm.solutionId_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("solutionId", value)}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.campaignId`)} description={t(`solutions:SolutionForm.campaignId_Des`)}>
              <Input
                value={formattedData.campaignId}
                placeholder={t(`solutions:SolutionForm.campaignId_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler("campaignId", value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 2nd container: Builders +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.builderTeam")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`solutions:SolutionForm.productManager`)} description={t(`solutions:SolutionForm.productManager_Des`)}>
              <Select
                selectedOption={formattedData.productManager && formattedData.productManager}
                onChange={({ detail }) => onChangeFormHandler(`productManager`, detail.selectedOption)}
                options={productManagerOptions}
                placeholder={t(`solutions:SolutionForm.productManager_Placeholder`)}
                selectedAriaLabel="Selected"
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.solutionDevelopmentManager`)} description={t(`solutions:SolutionForm.solutionDevelopmentManager_Des`)}>
              <Select
                selectedOption={formattedData.solutionDevelopmentManager && formattedData.solutionDevelopmentManager}
                onChange={({ detail }) => onChangeFormHandler(`solutionDevelopmentManager`, detail.selectedOption)}
                options={solutionDevelopmentManagerOptions}
                placeholder={t(`solutions:SolutionForm.solutionDevelopmentManager_Placeholder`)}
                selectedAriaLabel="Selected"
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.technicalWriter`)} description={t(`solutions:SolutionForm.technicalWriter_Des`)}>
              <Multiselect
                selectedOptions={formattedData.technicalWriter && formattedData.technicalWriter}
                onChange={({ detail }) => onChangeFormHandler(`technicalWriter`, detail.selectedOptions)}
                options={csdcMemberOptions}
                placeholder={t(`solutions:SolutionForm.technicalWriter_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.builders`)} description={t(`solutions:SolutionForm.builders_Des`)}>
              <Multiselect
                selectedOptions={formattedData.builders && formattedData.builders}
                onChange={({ detail }) => onChangeFormHandler(`builders`, detail.selectedOptions)}
                options={csdcMemberOptions}
                placeholder={t(`solutions:SolutionForm.builders_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionForm.contributors`)} description={t(`solutions:SolutionForm.contributors_Des`)}>
              <>
                <SpaceBetween size="l">
                  {(formattedData.contributors || []).map((form, index) => (
                    <div key={index}>
                      <SpaceBetween size="l" direction="horizontal">
                        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                          <FormField label={t(`solutions:SolutionForm.contributorsName`)} description={t(`solutions:SolutionForm.contributorsName_Des`)}>
                            <Input
                              value={form.name || ""}
                              placeholder={t(`solutions:SolutionForm.contributorsName_Placeholder`)}
                              onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `contributors`, "name", value, index)}
                            />
                          </FormField>
                          <FormField label={t(`solutions:SolutionForm.contributorsLogin`)} description={t(`solutions:SolutionForm.contributorsLogin_Des`)}>
                            <Input
                              value={form.login || ""}
                              placeholder={t(`solutions:SolutionForm.contributorsLogin_Placeholder`)}
                              onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `contributors`, "login", value, index)}
                            />
                          </FormField>
                          <FormField label={t(`solutions:SolutionForm.contributorsRole`)} description={t(`solutions:SolutionForm.contributorsRole_Des`)}>
                            <Input
                              value={form.role || ""}
                              placeholder={t(`solutions:SolutionForm.contributorsRole_Placeholder`)}
                              onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `contributors`, "role", value, index)}
                            />
                          </FormField>

                          <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des_Short`)}>
                            <SpaceBetween size="s" direction="horizontal">
                              <Button
                                iconName="angle-up"
                                onClick={(event) => {
                                  event.preventDefault();
                                  moveFormNested(setFormattedData, `contributors`, "up", index);
                                }}
                              />
                              <Button
                                iconName="angle-down"
                                onClick={(event) => {
                                  event.preventDefault();
                                  moveFormNested(setFormattedData, `contributors`, "down", index);
                                }}
                              />
                              <Button
                                iconName="close"
                                onClick={(event) => {
                                  event.preventDefault();
                                  removeFormNested(setFormattedData, `contributors`, index);
                                }}
                              ></Button>
                            </SpaceBetween>
                          </FormField>
                        </Grid>
                      </SpaceBetween>
                    </div>
                  ))}
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      addFormNested(setFormattedData, `contributors`, { name: "", login: "", role: "" });
                    }}
                  >
                    {t(`FormButton.addButton`)}
                  </Button>
                </SpaceBetween>
              </>
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 3rd container: Overview +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.containerOverview")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`solutions:SolutionForm.overview`)} description={t(`solutions:SolutionForm.overview_Des`)}>
              <Textarea
                textarea
                value={formattedData["overview_" + contentLanguage] || ""}
                placeholder={t(`solutions:SolutionForm.overview_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeFormHandler(`overview_${contentLanguage}`, value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 4th container: Benefits +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.containerBenefits")}</Header>}>
          <>
            <SpaceBetween size="l">
              {(formattedData["benefits_" + contentLanguage] || []).map((form, index) => (
                <div key={form.id}>
                  <SpaceBetween size="l" direction="horizontal">
                    <FormField label={t(`solutions:SolutionForm.benefitsTitle`)} description={t(`solutions:SolutionForm.benefitsTitle_Des`)}>
                      <Textarea
                        value={form.title || ""}
                        placeholder={t(`solutions:SolutionForm.benefitsTitle_Placeholder`)}
                        onChange={({ detail: { value } }) => updateForm(setFormattedData, `benefits_${contentLanguage}`, "title", value, form.id)}
                      />
                    </FormField>

                    <FormField label={t(`solutions:SolutionForm.benefitsContent`)} description={t(`solutions:SolutionForm.benefitsContent_Des`)}>
                      <Textarea
                        value={form.content || ""}
                        placeholder={t(`solutions:SolutionForm.benefitsContent_Placeholder`)}
                        onChange={({ detail: { value } }) => updateForm(setFormattedData, `benefits_${contentLanguage}`, "content", value, form.id)}
                      />
                    </FormField>
                    <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des`)}>
                      <SpaceBetween size="s" direction="horizontal">
                        <Button
                          iconName="angle-up"
                          onClick={(event) => {
                            event.preventDefault();
                            // 这里不能将index替换成form.id，在这段代码中，index 是指当前表单的索引位置，而 form.id 是该表单的唯一标识符。如果将 form.id 用作参数，它将不起作用，因为 moveForm 函数需要一个表示表单在数组中的位置的整数索引。
                            moveForm(setFormattedData, `benefits_en`, "up", index);
                            moveForm(setFormattedData, `benefits_cn`, "up", index);
                          }}
                        />
                        <Button
                          iconName="angle-down"
                          onClick={(event) => {
                            event.preventDefault();
                            // 这里不能将index替换成form.id，在这段代码中，index 是指当前表单的索引位置，而 form.id 是该表单的唯一标识符。如果将 form.id 用作参数，它将不起作用，因为 moveForm 函数需要一个表示表单在数组中的位置的整数索引。
                            moveForm(setFormattedData, `benefits_en`, "down", index);
                            moveForm(setFormattedData, `benefits_cn`, "down", index);
                          }}
                        />
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            removeForm(setFormattedData, `benefits_en`, form.id);
                            removeForm(setFormattedData, `benefits_cn`, form.id);
                          }}
                        >
                          {t(`FormButton.removeButton`)}
                        </Button>
                      </SpaceBetween>
                    </FormField>
                  </SpaceBetween>
                </div>
              ))}
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  addForm(setFormattedData, `benefits_en`, { title: "", content: "" });
                  addForm(setFormattedData, `benefits_cn`, { title: "", content: "" });
                }}
              >
                {t(`FormButton.addButton`)}
              </Button>
            </SpaceBetween>
          </>
        </Container>

        {/* The 5th container: Technical Details +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.containerTechnicalDetails")}</Header>}>
          <SpaceBetween size="l">
            <>
              {((formattedData.technicalDetails && formattedData.technicalDetails["techSteps_" + contentLanguage]) || []).map((form, index) => (
                <div key={form.id}>
                  <SpaceBetween size="l" direction="horizontal">
                    <FormField label={t(`solutions:SolutionForm.techStepsTitle`)} description={t(`solutions:SolutionForm.techStepsTitle_Des`)}>
                      <Textarea
                        value={form.title || ""}
                        placeholder={t(`solutions:SolutionForm.techStepsTitle_Placeholder`)}
                        onChange={({ detail: { value } }) =>
                          updateForm(setFormattedData, `technicalDetails.techSteps_${contentLanguage}`, "title", value, form.id)
                        }
                      />
                    </FormField>

                    <FormField label={t(`solutions:SolutionForm.techStepsContent`)} description={t(`solutions:SolutionForm.techStepsContent_Des`)}>
                      <Textarea
                        value={form.content || ""}
                        placeholder={t(`solutions:SolutionForm.techStepsContent_Placeholder`)}
                        onChange={({ detail: { value } }) =>
                          updateForm(setFormattedData, `technicalDetails.techSteps_${contentLanguage}`, "content", value, form.id)
                        }
                      />
                    </FormField>
                    <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des`)}>
                      <SpaceBetween size="s" direction="horizontal">
                        <Button
                          iconName="angle-up"
                          onClick={(event) => {
                            event.preventDefault();
                            moveForm(setFormattedData, `technicalDetails.techSteps_en`, "up", index);
                            moveForm(setFormattedData, `technicalDetails.techSteps_cn`, "up", index);
                          }}
                        />
                        <Button
                          iconName="angle-down"
                          onClick={(event) => {
                            event.preventDefault();
                            moveForm(setFormattedData, `technicalDetails.techSteps_en`, "down", index);
                            moveForm(setFormattedData, `technicalDetails.techSteps_cn`, "down", index);
                          }}
                        />
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            removeForm(setFormattedData, `technicalDetails.techSteps_en`, form.id);
                            removeForm(setFormattedData, `technicalDetails.techSteps_cn`, form.id);
                          }}
                        >
                          {t(`FormButton.removeButton`)}
                        </Button>
                      </SpaceBetween>
                    </FormField>
                  </SpaceBetween>
                </div>
              ))}
              <Button
                onClick={(event) => {
                  event.preventDefault();
                  addForm(setFormattedData, `technicalDetails.techSteps_en`, { title: "", content: "" });
                  addForm(setFormattedData, `technicalDetails.techSteps_cn`, { title: "", content: "" });
                }}
              >
                {t(`FormButton.addButton`)}
              </Button>
            </>
          </SpaceBetween>
        </Container>

        {/* The 6th container: Upload Images +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.containerUploadImages")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`solutions:SolutionForm.fileLogoLabel`)} description={t(`solutions:SolutionForm.fileLogoLabel_Des`)}>
              <FileUpload
                value={fileLogo}
                onChange={({ detail }) => {
                  onChangeFileHandler("solutionLogo", detail.value);
                }}
                errorText={errorTextLogo}
                constraintText={t(`solutions:SolutionForm.fileLogo_Constrain`)}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : logo_uploadButtonText), // multiple文件的时候，e=true，否则为false
                  dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
              />
              <ProgressBar value={logoUploadProgress} label={t(`casestudies:CasestudyCreateForm.progressBarLabel`)} />
            </FormField>

            <FormField label={t(`solutions:SolutionForm.fileArchitectureLabel`)} description={t(`solutions:SolutionForm.fileArchitecture_Des`)}>
              <FileUpload
                value={fileTechnicalArchitecture}
                onChange={({ detail }) => {
                  onChangeFileHandler("techArchitectureImage", detail.value);
                }}
                errorText={errorTextTechnicalArchitecture}
                constraintText={t(`solutions:SolutionForm.fileArchitecture_Constrain`)}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : architecture_uploadButtonText), // multiple文件的时候，e=true，否则为false
                  dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
              />
              <ProgressBar value={technicalArchitectureUploadProgress} label={t(`solutions:SolutionForm.progressBarLabel`)} />
            </FormField>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </>
  );
}
