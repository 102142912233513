import { ClickstreamAnalytics } from "@aws/clickstream-web";

export const analyticsLogger = {
  log(eventName, attributes) {
    ClickstreamAnalytics.record({
      name: eventName,
      attributes: attributes,
    })
  },
  setUserId(userId){
    ClickstreamAnalytics.setUserId(userId)
  }
}
