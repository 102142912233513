import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { createNavHeader, createNavItems } from './nav-items-config';

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

interface NavigationProps {
  activeHref?: string;
  header?: SideNavigationProps['header'];
  items?: SideNavigationProps['items'];
  onFollowHandler?: SideNavigationProps['onFollow'];
}

/*****************************************************************************/
export function Navigation({
  activeHref,
  onFollowHandler,
}: NavigationProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navHeader = createNavHeader(t);
  const navItems = createNavItems(t);

  // defaultOnFollowHandler使用navigate进行页面跳转，性能提升，瞬间切换页面，不需要每次跳转新页面都重新渲染。
  const defaultOnFollowHandler: SideNavigationProps['onFollow'] = event => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  return <SideNavigation items={navItems} header={navHeader} activeHref={activeHref} onFollow={onFollowHandler || defaultOnFollowHandler} />;
}
