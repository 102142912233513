import { Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "../../../components";
import BenefitsBlock from "./BenefitsBlock";

export const TabOverview = ({ oneSolution }) => {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();

  const overview = oneSolution?.[`overview_${contentLanguage}`];

  return (
    <>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">{t("solutions:SolutionDetails.tabs-content.Overview")}</Header>}>
          <p dangerouslySetInnerHTML={{ __html: overview }} />
        </Container>
        <Container header={<Header variant="h2">{t("solutions:SolutionDetails.tabs-content.Benefits")}</Header>}>
          <BenefitsBlock benefits={oneSolution?.[`benefits_${contentLanguage}`]} />
        </Container>
      </SpaceBetween>
    </>
  );
};
