import { useTranslation } from "react-i18next";
import { HelpPanel, Icon } from "@cloudscape-design/components";
import { ExternalLinkItem } from "../../pages-common/external-link-item";

const ToolsFooter = () => {
  // 函数名称改为首字母大写
  const { t } = useTranslation();
  return (
    <>
      <h3>
        {t("Button.learnmore")}{" "}
        <span role="img" aria-label="Icon external Link">
          <Icon name="external" />
        </span>
      </h3>
      <ul>
        <li>
          <ExternalLinkItem href="https://aws.amazon.com/solutions/" text="WW Solutions Library" />
        </li>
        <li>
          <ExternalLinkItem href="https://www.amazonaws.cn/en/solutions/?nc1=h_ls" text="GCR Solutions Library" />
        </li>
        <li>
          <ExternalLinkItem href="https://w.amazon.com/bin/view/AWS/GCRSolutionCenter" text="GCR Solutions Team (a.k.a., CSDC)" />
        </li>
      </ul>
    </>
  );
};

export const ToolsContent = () => {
  const { t } = useTranslation();
  return (
    <HelpPanel footer={<ToolsFooter />} header={<h2>{t("ToolsContent.buildingClass_header")}</h2>}>
      <p>{t("ToolsContent.buildingClass_content")}</p>
    </HelpPanel>
  );
};

export default ToolsContent;
