import { Button, SpaceBetween } from "@cloudscape-design/components";
import { getFileUrl } from "../../../common/utils";
import { DownloadButton } from "../../../components";

/**
 *  React 在进行比较时，如果发现 sortingComparator 函数在每次渲染后都是一个新的实例（即使函数体是相同的），那么它会认为函数已经改变。
 *  这是因为函数在 JavaScript 中是引用类型，所以即使两个函数的行为完全相同，如果它们是两个不同的实例，那么它们在 JavaScript 中仍然被视为不同的。
 *  把sortingcomparator移动到createColumDefinitions的外面，可以避免每次React出现下面的告警：
 *  “[AwsUi] [Table] Currently active sorting comparator was not found in any columns. Make sure to provide the same comparator function instance on each render. ”
 */

/*
// 下面的这种排序函数的写法无法处理item1或者item2是undefined/null/空字符串的场景，会报告TyperError，修改为下面重新写的排序函数。
const gtmCategorySortingComparator = (item1, item2) => item1.category?.label.localeCompare(item2.category?.label);
const gtmNameSortingComparator = (item1, item2) => item1.name.localeCompare(item2.name);
const gtmUpdateDateSortingComparator = (item1, item2) => item1.updateDate.localeCompare(item2.updateDate);
*/

const gtmCategorySortingComparator = (item1, item2) => {
  const label1 = item1.category?.label;
  const label2 = item2.category?.label;

  if (label1 === label2) return 0; // handles both are undefined/null/empty string

  if (!label1 || label1 === "") return -1; // item1 is undefined/null/empty string, so it's "smaller"

  if (!label2 || label2 === "") return 1; // item2 is undefined/null/empty string, so it's "smaller"

  return label1.localeCompare(label2);
};

/**
 * 也可以写成下面的版本
 const gtmCategorySortingComparator = (item1, item2) => {
  const label1 = item1.category?.label || "";
  const label2 = item2.category?.label || "";

  return label1.localeCompare(label2);
};
 */

const gtmNameSortingComparator = (item1, item2) => {
  const name1 = item1.name;
  const name2 = item2.name;

  if (name1 === name2) return 0; // handles both are undefined/null/empty string

  if (!name1 || name1 === "") return -1; // item1 is undefined/null/empty string, so it's "smaller"

  if (!name2 || name2 === "") return 1; // item2 is undefined/null/empty string, so it's "smaller"

  return name1.localeCompare(name2);
};

const gtmUpdateDateSortingComparator = (item1, item2) => {
  const date1 = item1.updateDate;
  const date2 = item2.updateDate;

  if (date1 === date2) return 0; // handles both are undefined/null/empty string

  if (!date1 || date1 === "") return -1; // item1 is undefined/null/empty string, so it's "smaller"

  if (!date2 || date2 === "") return 1; // item2 is undefined/null/empty string, so it's "smaller"

  return date1.localeCompare(date2);
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createColumnDefinitions = (t) => {
  return [
    {
      id: "gtm_category",
      sortingField: "gtm_category",
      minWidth: 180,
      header: t("solutions:SolutionDetails.tabs-content.gtmCategory"),
      sortingComparator: gtmCategorySortingComparator,
      cell: (item) => item.category?.label,
    },
    {
      id: "gtm_name",
      sortingField: "gtm_name",
      minWidth: 270,
      header: t("solutions:SolutionDetails.tabs-content.gtmName"),
      sortingComparator: gtmNameSortingComparator,
      cell: (item) => item.name,
    },
    {
      id: "gtm_action",
      minWidth: 180,
      header: t("solutions:SolutionDetails.tabs-content.gtmAction"),
      cell: (item) => {
        if (item.downloadable) {
          if (item.fileKey) {
            return (
              <>
                <SpaceBetween key={`SpaceBetween-${item.id}`} size="s" direction="horizontal">
                  {item.url && (
                    <a href={getFileUrl(item.url)} target="_blank" rel="noopener noreferrer">
                      <Button iconName="external" iconAlign="right"></Button>
                    </a>
                  )}
                  <DownloadButton url={getFileUrl(item.fileKey)} filename={item.name}></DownloadButton>
                </SpaceBetween>
              </>
            );
          } else {
            return (
              <>
                {item.url && (
                  <DownloadButton url={item.url} filename={item.name}>
                    Download
                  </DownloadButton>
                )}
              </>
            );
          }
        } else {
          return (
            <>
              {item.url && (
                <a key={`a-${item.url}`} href={getFileUrl(item.url)} target="_blank" rel="noopener noreferrer">
                  <Button iconName="external" iconAlign="right"></Button>
                </a>
              )}
            </>
          );
        }
      },
    },
    {
      id: "gtm_update_date",
      sortingField: "gtm_update_date",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.gtmUpdateDate"),
      sortingComparator: gtmUpdateDateSortingComparator,
      cell: (item) => item.updateDate,
    },
  ];
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:SolutionDetails.tabs-content.visibleContentConfigLabel"),
    options: [
      { id: "gtm_category", label: t("solutions:SolutionDetails.tabs-content.gtmCategory") },
      { id: "gtm_name", label: t("solutions:SolutionDetails.tabs-content.gtmName") },
      { id: "gtm_action", label: t("solutions:SolutionDetails.tabs-content.gtmAction") },
      { id: "gtm_update_date", label: t("solutions:SolutionDetails.tabs-content.gtmUpdateDate") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 10,
  visibleContent: ["gtm_category", "gtm_name", "gtm_action", "gtm_update_date"],
  wrapLines: false,
  stripedRows: false,
});
