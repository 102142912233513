import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

/**
 * https://www.i18next.com/
 */

i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // Put translation resources in /public/locales instead of this file by using http backend 
  // Learn more https://github.com/i18next/i18next-http-backend/tree/master/example/node/locales
  .use(HttpBackend)

  // Automatically detect user language in the browser with support for querystring, cookie, localStorage, etc.
  // Learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ['common', 'solutions', 'casestudies', 'newsletters'],
    defaultNS: 'common',
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false, // Default value is True. Set it to False so that 原封不动显示每个字符原本的样子，例如<img>。
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18next;
