import { Button, StatusIndicator } from "@cloudscape-design/components";

const featureTitleSortingComparator = (item1, item2) => {
  const title1 = item1.title || "";
  const title2 = item2.title || "";

  return title1.localeCompare(title2);
};

const featureContentSortingComparator = (item1, item2) => {
  const content1 = item1.content || "";
  const content2 = item2.content || "";

  return content1.localeCompare(content2);
};

const featureActiveSortingComparator = (item1, item2) => (item1.active ? 1 : 0) - (item2.active ? 1 : 0);
// const featureLikeSortingComparator = (item1, item2) =>
//   (Array.isArray(item1.like) && item1.like.includes(userLogin) ? 1 : 0) - (Array.isArray(item2.like) && item2.like.includes(userLogin) ? 1 : 0);
const featureLikeCountSortingComparator = (item1, item2) => (item1.like ? item1.like.length : 0) - (item2.like ? item2.like.length : 0);

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createColumnDefinitions = (t, likeButtonHandler, releaseId, userLogin) => {
  return [
    {
      id: "feature_title",
      sortingField: "feature_title",
      minWidth: 160,
      header: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableTitle"),
      sortingComparator: featureTitleSortingComparator,
      cell: (item) => item.title,
    },
    {
      id: "feature_content",
      sortingField: "feature_content",
      minWidth: 400,
      header: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableContent"),
      sortingComparator: featureContentSortingComparator,
      cell: (item) => item.content,
    },
    {
      id: "feature_active",
      sortingField: "feature_active",
      minWidth: 190,
      header: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableActive"),
      sortingComparator: featureActiveSortingComparator,
      cell: (item) => <StatusIndicator type={item.active ? "success" : "error"}>{item.active ? "Available" : "Unavailable"}</StatusIndicator>,
    },
    {
      id: "feature_like",
      minWidth: 80,
      header: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableLike"),
      cell: (item) =>
        Array.isArray(item.like) && item.like.includes(userLogin) ? (
          <Button variant="primary" iconName="thumbs-up" onClick={() => likeButtonHandler(releaseId, item.id, userLogin)}></Button>
        ) : (
          <Button variant="normal" iconName="thumbs-up" onClick={() => likeButtonHandler(releaseId, item.id, userLogin)}></Button>
        ),
    },
    {
      id: "feature_like_count",
      sortingField: "feature_like_count",
      minWidth: 80,
      header: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableLikeCount"),
      sortingComparator: featureLikeCountSortingComparator,
      cell: (item) => (item.like ? item.like.length : 0),
    },
  ];
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:SolutionDetails.tabs-content.visibleContentConfigLabel"),
    options: [
      { id: "feature_title", label: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableTitle") },
      { id: "feature_content", label: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableContent") },
      { id: "feature_active", label: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableActive") },
      { id: "feature_like", label: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableLike") },
      { id: "feature_like_count", label: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableLikeCount") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 10,
  visibleContent: ["feature_title", "feature_content", "feature_active", "feature_like", "feature_like_count"],
  wrapLines: false,
  stripedRows: false,
});
