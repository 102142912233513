import React, { createContext, useContext, useState } from "react";
import { FlashbarProps } from "@cloudscape-design/components/flashbar";
import { v4 as uuid4 } from "uuid";
import notificationConfig from "../../config/aws-palette-config.json";

/*+++++++++++++++++++++++++++ 1. Define Interface +++++++++++++++++++++++++++*/
interface NotificationProviderProps {
  children: React.ReactNode;
}

interface NotificationContextType {
  notificationList: FlashbarProps.MessageDefinition[];
  pushNewNotification: (notification: FlashbarProps.MessageDefinition) => void;
}

/*++++++++++++++++++++++++++++ 2. Create Context ++++++++++++++++++++++++++++*/
export const NotificationContext = createContext<NotificationContextType>({
  notificationList: [],
  pushNewNotification: () => {},
});

/*********************** 3. Create the Provider Component ********************/
export const NotificationContextProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const [notificationList, setNotificationList] = useState<FlashbarProps.MessageDefinition[]>([]);

  const onDismissHandler = (id: string) => {
    // 从列表中删除对应的通知
    const updatedList = notificationList.filter((item) => item.id !== id);
    setNotificationList(updatedList);
  };

  const pushNewNotification = (newNotification: FlashbarProps.MessageDefinition) => {
    newNotification.id = uuid4();
    newNotification.onDismiss = (event: any) => onDismissHandler(event.detail.id);
    setNotificationList([...notificationList, newNotification]);

    // 设置3秒后自动关闭通知，假设用户不手动关闭的话。
    if (newNotification.id && notificationConfig.autoCloseNotification === "true") {
      const timer = setTimeout(() => {
        onDismissHandler(newNotification.id as string);
      }, 3 * 1000); // 3秒

      // 下面这个部分是我添加的，也许不需要，要检查一下。
      return () => {
        clearTimeout(timer);
      };
    }
  };

  const contentValue = {
    notificationList: notificationList,
    pushNewNotification: pushNewNotification,
  };

  return <NotificationContext.Provider value={contentValue}>{children}</NotificationContext.Provider>;
};

/*++++++++++++++++++++++++++ 4. Create Custom Hook ++++++++++++++++++++++++++*/
export const useNotification = () => {
  return useContext(NotificationContext);
};
