import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { DataProvider } from "../../api/data-provider";

/*+++++++++++++++++++++++++++ 1. Define Interface +++++++++++++++++++++++++++*/
interface SolutionsContextProviderProps {
  children: React.ReactNode;
}

interface SolutionsContextType {
  solutionsList: any[];
  setSolutionsList: (list: any[]) => void;
  fetched: boolean;
  setFetched: (fetched: boolean) => void;
}

interface Solution {
  [key: string]: any;
}

/*++++++++++++++++++++++++++++ 2. Create Context ++++++++++++++++++++++++++++*/
export const SolutionsContext = createContext<SolutionsContextType>({
  solutionsList: [],
  setSolutionsList: () => {},
  fetched: false,
  setFetched: () => {},
});

/*********************** 3. Create the Provider Component ********************/
export const SolutionsContextProvider: React.FC<SolutionsContextProviderProps> = ({ children }) => {
  const [solutionsList, setSolutionsList] = useState<any[]>([]);
  const [fetched, setFetched] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (!fetched && user) {
      new DataProvider().getAllSolutions().then((solutionsListFetched) => {
        solutionsListFetched.sort((a: Solution, b: Solution) => {
          // 如果tier未定义，将其当作3处理。从dynamodb读出的数据是string类型，不使用类型转换也可以，不过我们还是显式使用
          let tierA = a.tier === undefined ? 3 : Number(a.tier);
          let tierB = b.tier === undefined ? 3 : Number(b.tier);

          // 首先根据'tier'属性排序
          if (tierA !== tierB) {
            return tierA - tierB;
          }

          // 'tier'相同的情况下，根据'solutionName_en'属性排序
          if (a.solutionName_en && b.solutionName_en) {
            return a.solutionName_en.localeCompare(b.solutionName_en);
          } else {
            return 0;
          }
        });
        setSolutionsList(solutionsListFetched);
        setFetched(true);
      });
    }

    const timer = setTimeout(() => {
      setFetched(false);
    }, 12 * 60 * 60 * 1000); // 12 Hours

    return () => {
      clearTimeout(timer);
    };
  }, [user, fetched]);

  const contentValue = {
    solutionsList,
    setSolutionsList,
    fetched,
    setFetched,
  };

  return <SolutionsContext.Provider value={contentValue}>{children}</SolutionsContext.Provider>;
};

/*++++++++++++++++++++++++++ 4. Create Custom Hook ++++++++++++++++++++++++++*/
export const useSolutions = () => {
  return useContext(SolutionsContext);
};
