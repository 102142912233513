import { useState, useEffect } from "react";
import { Button, Form, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import EditUserPreferences from "./edit-user-preferences-panel";
import { DataProvider } from "../../../api/data-provider";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { transformArrays, reverseTransformArrays } from "../../../common/transform-array-for-multiselect";
import { useSolutions, useUserProfile } from "../../../components";
import { useAuthenticator } from "@aws-amplify/ui-react";

export function FormHeader({ loadHelpPanelContent }) {
  const { t } = useTranslation();

  return (
    <Header
      variant="h1"
      info={
        <Link onFollow={loadHelpPanelContent} variant="info">
          {t("UserPreferences.Info")}
        </Link>
      }
      description={t("UserPreferences.HeaderDescription")}
    >
      {t("UserPreferences.HeaderTitle")}
    </Header>
  );
}

function BaseFormContent({ content, errorText = null }) {
  const { t } = useTranslation();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();
  const { user } = useAuthenticator();
  const [preferencesUpdated, setPreferenceUpdated] = useState(false);
  const { uiLanguage, setUiLanguage, contentLanguage, setContentLanguage, fullName, setFullName, setPreferencesFetched } = useUserProfile();
  const [fullNameForForm, setFullNameForForm] = useState(fullName);

  const userLogin = JSON.parse(user.attributes.identities)[0].userId;

  // 监听上传状态，如果已经完成api call，创建了dynamodb的新条目，就立刻发出通知，并重新加载最新的solution list
  useEffect(() => {
    if (preferencesUpdated) {
      setPreferencesFetched(false);
      navigate("/solutions");
      // setNavigateAway(true);
    }
  }, [preferencesUpdated]);

  // useEffect(() => {
  //   if (navigateAway) {
  //     setNavigateAway(false);
  //     navigate("/solutions");
  //   }
  // }, [navigateAway]);

  const onChangeFullNameHandler = (value) => {
    console.log("fullName:", value);
    setFullNameForForm(value);
  };

  const onChangeUiLanguageHandler = (value) => {
    setUiLanguage(value);
  };

  const onChangeContentLanguageHandler = (value) => {
    setContentLanguage(value);
  };

  async function updateUserPreferences() {
    if (userLogin) {
      const data = {
        userLogin,
        fullName: fullNameForForm,
        uiLanguage,
        contentLanguage,
      };

      await new DataProvider().updateUser(data).then((response) => {
        console.log(response);
        setPreferenceUpdated(true);
      });

      const newMessage = {
        type: "success",
        content: t("notification.updateUserPreferences"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "success",
      };
      pushNewNotification(newMessage);
    }
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    console.log(e);
    updateUserPreferences();
  };

  const onCancelClick = () => {
    navigate("/solutions");
  };

  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("UserPreferences.Cancel")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler}>
              {t("UserPreferences.ConfirmOk")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({ onChangeUiLanguageHandler, onChangeContentLanguageHandler, onChangeFullNameHandler, uiLanguage, contentLanguage, fullNameForForm })}
      </Form>
    </form>
  );
}

export function FormContent({ loadHelpPanelContent }) {
  return (
    <BaseFormContent
      content={({ onChangeUiLanguageHandler, onChangeContentLanguageHandler, onChangeFullNameHandler, uiLanguage, contentLanguage, fullNameForForm }) => (
        <SpaceBetween size="l">
          <EditUserPreferences
            loadHelpPanelContent={loadHelpPanelContent}
            onChangeUiLanguageHandler={onChangeUiLanguageHandler}
            onChangeContentLanguageHandler={onChangeContentLanguageHandler}
            onChangeFullNameHandler={onChangeFullNameHandler}
            uiLanguage={uiLanguage}
            contentLanguage={contentLanguage}
            fullNameForForm={fullNameForForm}
          />
        </SpaceBetween>
      )}
    />
  );
}
