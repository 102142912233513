import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ContentLayout, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, LoadingSpinner, Navigation, Notification, useAppManagement } from "../../components";
import ToolsContent from "../solutions/sub-components/ToolsContent";
import { FormHeader } from "./sub-components/FormHeader";
import { BannerImageFormContent } from "./sub-components/tab-banner-image-form-content";
import { FrequentlyUsedLinksFormContent } from "./sub-components/tab-frequently-used-link-form-content";
import { OtherUpdatesFormContent } from "./sub-components/tab-other-updates-form-content";
import { BannersFormContent } from "./sub-components/tab-banners-form-content";
import paletteConfig from "../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function AppManagementStarting() {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.App"),
      href: "/app",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const tabs = [
    {
      label: t("App.header_containerBanners"),
      id: "banners",
      content: <BannersFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: t("App.header_containerBannerImages"),
      id: "banner-images",
      content: <BannerImageFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: t("App.header_containerFrequentlyUsedLinks"),
      id: "frequently-used-links",
      content: <FrequentlyUsedLinksFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: t("App.header_containerOtherUpdates"),
      id: "other-updates",
      content: <OtherUpdatesFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
  ];

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      contentType="form"
      content={
        <ContentLayout disableOverlap header={<FormHeader loadHelpPanelContent={loadHelpPanelContent} />}>
          <SpaceBetween size="l">
            <Tabs tabs={tabs} />
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/app" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, this component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default AppManagementStarting;
