import { useState, useEffect } from "react";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import RoadmapPanel from "./tab-roadmap-panel";
import { DataProvider } from "../../../api/data-provider";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useLocation, useNavigate, useBeforeUnload } from "react-router-dom";
import { LoadingSpinner, useSolutions } from "../../../components";
import cloneDeep from "lodash/cloneDeep";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function FormInputBaseFormContent({ content, selectedSolution, errorText = null }) {
  const { t } = useTranslation();
  const { setFetched } = useSolutions();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();

  // 先初始化为null，然后通过useEffect来监控formattedData是否已经被赋值成功，从而保证页面渲染的时候formattedData已经有需要的初始值。
  const [formattedData, setFormattedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [isSolutionUpdateCompleted, setIsSolutionUpdateCompleted] = useState(false);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    if (selectedSolution) {
      setFormattedData({
        solutionNameAbbr: selectedSolution.solutionNameAbbr,
        solutionName_en: selectedSolution.solutionName_en,
        solutionName_cn: selectedSolution.solutionName_cn,
        roadmap: cloneDeep(selectedSolution.roadmap),
      });
    }
  }, [selectedSolution]);

  useEffect(() => {
    if (formattedData) {
      setLoading(false);
      DEBUG && console.log("%cformattedData (used to call API to create DynamoDB items):", "color: red; font-weight: bold;", formattedData);
    }
  }, [formattedData]);

  // 监听上传状态，如果已经完成api call，创建了dynamodb的新条目，就立刻发出通知，并重新加载最新的solution list
  useEffect(() => {
    if (isSolutionUpdateCompleted) {
      setFetched(false);
      navigate("/solutions");
    }
  }, [isSolutionUpdateCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();

    /**————————————————————————————————————————————————————————————————————————————————————————
     * 为了能简化产品经理的录入过程，当他录入任意一种语言的版本和功能信息时，即使没有切换到另一个语言进行录入。
     * 在提交api写后端数据库之前来比较中英文对应的title和content字段，发现一方为空，且另一方有值。则用现有的值替换空字符串。
     */
    // 创建formattedData的深拷贝
    let copyOfFormattedData = cloneDeep(formattedData);

    // 遍历releaseHistory
    copyOfFormattedData.roadmap.releaseHistory.forEach((release) => {
      // 确保features_en和features_cn都存在
      if (release.features_en && release.features_cn) {
        // 遍历features_en
        release.features_en.forEach((feature_en) => {
          // 查找对应的features_cn元素
          const feature_cn = release.features_cn.find((feature) => feature.id === feature_en.id);

          // 如果找到了对应的features_cn元素，并且满足复制条件，进行复制
          if (feature_cn) {
            if (feature_en.title === "" && feature_cn.title !== "") {
              feature_en.title = feature_cn.title;
            }

            if (feature_en.content === "" && feature_cn.content !== "") {
              feature_en.content = feature_cn.content;
            }
          }
        });

        // 类似地，遍历features_cn并复制对应的features_en的值
        release.features_cn.forEach((feature_cn) => {
          const feature_en = release.features_en.find((feature) => feature.id === feature_cn.id);

          if (feature_en) {
            if (feature_cn.title === "" && feature_en.title !== "") {
              feature_cn.title = feature_en.title;
            }

            if (feature_cn.content === "" && feature_en.content !== "") {
              feature_cn.content = feature_en.content;
            }
          }
        });
      }
    });

    DEBUG && console.log("%c*** copyOfFormattedData (before ApiCall):", "color: blue; font-weight: bold;", copyOfFormattedData);

    /*—————————————————————————————————结束上面的代码段——————————————————————————————*/

    setSubmitting(true);
    try {
      await new DataProvider().updateSolution(copyOfFormattedData);
      setIsSolutionUpdateCompleted(true);
      const newMessage = {
        type: "success",
        content: t("notification.updateSolution"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "success",
      };
      pushNewNotification(newMessage);
    } catch (error) {
      console.error("Update Solution failed:", error);
      const newMessage = {
        type: "error",
        content: t("notification.createOrUpdateSolutionApiError"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const onCancelClick = () => {
    navigate("/solutions");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("common:Button.cancel")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler} loading={submitting} disabled={!formattedData.solutionNameAbbr || submitting}>
              {submitting ? t("common:Button.submitting") : t("common:Button.submit")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({
          formattedData,
          setFormattedData,
        })}
      </Form>
    </form>
  );
}

/*****************************************************************************/
export function RoadmapFormContent({ loadHelpPanelContent, selectedSolution }) {
  return (
    <FormInputBaseFormContent
      selectedSolution={selectedSolution}
      content={({ formattedData, setFormattedData }) => (
        <SpaceBetween size="l">
          <RoadmapPanel loadHelpPanelContent={loadHelpPanelContent} formattedData={formattedData} setFormattedData={setFormattedData} />
        </SpaceBetween>
      )}
    />
  );
}
