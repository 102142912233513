/* Here comes the options variables used in the Multiselect components. */

export const solutionNameOptions_en = [
  {
    label: "AI Solution Kit",
    value: "ASK",
  },
  {
    label: "CloudFront Extensions",
    value: "CFE",
  },
  {
    label: "Clickstream Analytics on AWS",
    value: "CLA",
  },
  {
    label: "Clickhouse on AWS",
    value: "CLH",
  },
  {
    label: "Centralized Logging with OpenSearch",
    value: "CLO",
  },
  {
    label: "Distributed Load Testing",
    value: "DLT",
  },
  {
    label: "Data Transfer Hub",
    value: "DTH",
  },
  {
    label: "AWS Instance Scheduler",
    value: "ISC",
  },
  {
    label: "KeyCloak on AWS",
    value: "KCK",
  },
  {
    label: "Live Streaming on AWS",
    value: "LSM",
  },
  {
    label: "Quantum Computing Exploration for Drug Discovery on AWS",
    value: "QCE",
  },
  {
    label: "Apache RocketMQ on AWS",
    value: "ROK",
  },
  {
    label: "Serverless Alert Notifier",
    value: "SAN",
  },
  {
    label: "SaaS Boost",
    value: "SAS",
  },
  {
    label: "Extension for Stable Diffusion on AWS",
    value: "SDE",
  },
  {
    label: "Sensitive Data Protection",
    value: "SDP",
  },
  {
    label: "Serverless Image Handler",
    value: "SIH",
  },
  {
    label: "Apache Superset on AWS",
    value: "SPS",
  },
  {
    label: "Service Workbench on AWS",
    value: "SWB",
  },
  {
    label: "Video on Demand on AWS",
    value: "VOD",
  },
  {
    label: "AI Video Super Resolution",
    value: "VSR",
  },
  {
    label: "Video Transcriber",
    value: "VTB",
  },
  {
    label: "Security Automations for AWS WAF",
    value: "WAF",
  },
];

export const solutionNameOptions_cn = [
  {
    label: "人工智能方案合集",
    value: "ASK",
  },
  {
    label: "CloudFront功能扩展",
    value: "CFE",
  },
  {
    label: "点击流数据分析",
    value: "CLA",
  },
  {
    label: "Clickhouse的快速部署",
    value: "CLH",
  },
  {
    label: "日志通",
    value: "CLO",
  },
  {
    label: "分布式负载测试",
    value: "DLT",
  },
  {
    label: "数据传输中心",
    value: "DTH",
  },
  {
    label: "AWS实例调度器",
    value: "ISC",
  },
  {
    label: "KeyCloak的快速部署",
    value: "KCK",
  },
  {
    label: "视频直播解决方案",
    value: "LSM",
  },
  {
    label: "药物研发的量子计算探索",
    value: "QCE",
  },
  {
    label: "RocketMQ的快速部署",
    value: "ROK",
  },
  {
    label: "无服务器告警通知",
    value: "SAN",
  },
  {
    label: "SaaS快速构建平台",
    value: "SAS",
  },
  {
    label: "Stable Diffusion 亚马逊云科技插件解决方案",
    value: "SDE",
  },
  {
    label: "敏感数据保护",
    value: "SDP",
  },
  {
    label: "无服务器图像处理程序",
    value: "SIH",
  },
  {
    label: "Superset的快速部署",
    value: "SPS",
  },
  {
    label: "云上服务工作台",
    value: "SWB",
  },
  {
    label: "视频点播解决方案",
    value: "VOD",
  },
  {
    label: "基于人工智能的视频超分辨率方案",
    value: "VSR",
  },
  {
    label: "视频字幕提取方案",
    value: "VTB",
  },
  {
    label: "AWS WAF的安全自动化",
    value: "WAF",
  },
];

export const categoryOptions_en = [
  {
    label: "Technology",
    value: "Technology",
  },
  {
    label: "Industry",
    value: "Industry",
  },
  {
    label: "Cross-Industry",
    value: "Cross-Industry",
  },
];

export const categoryOptions_cn = [
  {
    label: "技术",
    value: "技术",
  },
  {
    label: "行业",
    value: "行业",
  },
  {
    label: "跨行业",
    value: "跨行业",
  },
];

export const domainOptions_en = [
  {
    label: "Analytics",
    value: "Analytics",
  },
  {
    label: "App Development & DevOps",
    value: "App Development & DevOps",
  },
  {
    label: "Blockchain",
    value: "Blockchain",
  },
  {
    label: "Cloud Foundations",
    value: "Cloud Foundations",
  },
  {
    label: "Cloud Operations",
    value: "Cloud Operations",
  },
  {
    label: "Compute",
    value: "Compute",
  },
  {
    label: "CDN",
    value: "CDN",
  },
  {
    label: "Database",
    value: "Database",
  },
  {
    label: "Digital Workplace",
    value: "Digital Workplace",
  },
  {
    label: "ERP",
    value: "ERP",
  },
  {
    label: "IoT",
    value: "IoT",
  },
  {
    label: "AI/ML",
    value: "AI/ML",
  },
  {
    label: "Migration & Modernization",
    value: "Migration & Modernization",
  },
  {
    label: "Networking",
    value: "Networking",
  },
  {
    label: "Resilience",
    value: "Resilience",
  },
  {
    label: "Security & Compliance",
    value: "Security & Compliance",
  },
  {
    label: "Spatial Computing",
    value: "Spatial Computing",
  },
  {
    label: "Storage",
    value: "Storage",
  },
  {
    label: "Advertising & Marketing",
    value: "Advertising & Marketing",
  },
  {
    label: "Aerospace & Satellite",
    value: "Aerospace & Satellite",
  },
  {
    label: "Agriculture",
    value: "Agriculture",
  },
  {
    label: "Automotive",
    value: "Automotive",
  },
  {
    label: "Consumer Package Goods",
    value: "Consumer Package Goods",
  },
  {
    label: "Education",
    value: "Education",
  },
  {
    label: "Energy & Utilities",
    value: "Energy & Utilities",
  },
  {
    label: "Engineering, Construction & Real Estate",
    value: "Engineering, Construction & Real Estate",
  },
  {
    label: "Financial Services",
    value: "Financial Services",
  },
  {
    label: "Game Tech",
    value: "Game Tech",
  },
  {
    label: "Healthcare, Life Science, and Genomics",
    value: "Healthcare, Life Science, and Genomics",
  },
  {
    label: "Hi-Tech Electronics & Semiconductor",
    value: "Hi-Tech Electronics & Semiconductor",
  },
  {
    label: "Manufacturing & Industrial",
    value: "Manufacturing & Industrial",
  },
  {
    label: "Media & Entertainment",
    value: "Media & Entertainment",
  },
  {
    label: "Mining & Minerals",
    value: "Mining & Minerals",
  },
  {
    label: "Nonprofits",
    value: "Nonprofits",
  },
  {
    label: "Retail",
    value: "Retail",
  },
  {
    label: "Telecommunications",
    value: "Telecommunications",
  },
  {
    label: "Travel & Hospitality",
    value: "Travel & Hospitality",
  },
  {
    label: "Customer Experience",
    value: "Customer Experience",
  },
  {
    label: "Finance",
    value: "Finance",
  },
  {
    label: "Human Resources",
    value: "Human Resources",
  },
  {
    label: "Intelligent Automation",
    value: "Intelligent Automation",
  },
  {
    label: "Supply Chain",
    value: "Supply Chain",
  },
  {
    label: "Sustainability",
    value: "Sustainability",
  },
];

export const domainOptions_cn = [
  {
    label: "数据分析",
    value: "数据分析",
  },
  {
    label: "应用开发",
    value: "应用开发",
  },
  {
    label: "区块链",
    value: "区块链",
  },
  {
    label: "云基础",
    value: "云基础",
  },
  {
    label: "云运营",
    value: "云运营",
  },
  {
    label: "计算",
    value: "计算",
  },
  {
    label: "内容分发网络",
    value: "内容分发网络",
  },
  {
    label: "数据库",
    value: "数据库",
  },
  {
    label: "数字工作场所",
    value: "数字工作场所",
  },
  {
    label: "企业资源计划",
    value: "企业资源计划",
  },
  {
    label: "物联网",
    value: "物联网",
  },
  {
    label: "人工智能",
    value: "人工智能",
  },
  {
    label: "迁移和现代化",
    value: "迁移和现代化",
  },
  {
    label: "网络",
    value: "网络",
  },
  {
    label: "韧性",
    value: "韧性",
  },
  {
    label: "安全合规",
    value: "安全合规",
  },
  {
    label: "空间计算",
    value: "空间计算",
  },
  {
    label: "存储",
    value: "存储",
  },
  {
    label: "广告营销",
    value: "广告营销",
  },
  {
    label: "航天与卫星",
    value: "航天与卫星",
  },
  {
    label: "农业",
    value: "农业",
  },
  {
    label: "汽车",
    value: "汽车",
  },
  {
    label: "消费品",
    value: "消费品",
  },
  {
    label: "教育",
    value: "教育",
  },
  {
    label: "能源与公用事业",
    value: "能源与公用事业",
  },
  {
    label: "工程、建筑与房地产",
    value: "工程、建筑与房地产",
  },
  {
    label: "金融服务",
    value: "金融服务",
  },
  {
    label: "游戏",
    value: "游戏",
  },
  {
    label: "医疗保健、生命科学和基因组学",
    value: "医疗保健、生命科学和基因组学",
  },
  {
    label: "高科技电子与半导体",
    value: "高科技电子与半导体",
  },
  {
    label: "制造与工业",
    value: "制造与工业",
  },
  {
    label: "媒体娱乐",
    value: "媒体娱乐",
  },
  {
    label: "采矿与矿产",
    value: "采矿与矿产",
  },
  {
    label: "非营利组织",
    value: "非营利组织",
  },
  {
    label: "零售",
    value: "零售",
  },
  {
    label: "电信",
    value: "电信",
  },
  {
    label: "旅游与酒店业",
    value: "旅游与酒店业",
  },
  {
    label: "顾客体验",
    value: "顾客体验",
  },
  {
    label: "财务",
    value: "财务",
  },
  {
    label: "人力资源",
    value: "人力资源",
  },
  {
    label: "智能自动化",
    value: "智能自动化",
  },
  {
    label: "供应链",
    value: "供应链",
  },
  {
    label: "可持续发展",
    value: "可持续发展",
  },
];

export const keyServicesOptions = [
  {
    label: "API Gateway",
    value: "API Gateway",
  },
  {
    label: "Athena",
    value: "Athena",
  },
  {
    label: "Amazon Braket",
    value: "Amazon Braket",
  },
  {
    label: "Amazon Translate",
    value: "Amazon Translate",
  },
  {
    label: "Amazon Transcribe",
    value: "Amazon Transcribe",
  },
  {
    label: "AWS Amplify",
    value: "AWS Amplify",
  },
  {
    label: "AWS AppSync",
    value: "AWS AppSync",
  },
  {
    label: "Batch",
    value: "Batch",
  },
  {
    label: "CloudFront",
    value: "CloudFront",
  },
  {
    label: "DynamoDB",
    value: "DynamoDB",
  },
  {
    label: "EC2",
    value: "EC2",
  },
  {
    label: "ECS",
    value: "ECS",
  },
  {
    label: "EFS",
    value: "EFS",
  },
  {
    label: "ElastiCache",
    value: "ElastiCache",
  },
  {
    label: "EMR",
    value: "EMR",
  },
  {
    label: "EKS",
    value: "EKS",
  },
  {
    label: "Glue",
    value: "Glue",
  },
  {
    label: "Kinesis",
    value: "Kinesis",
  },
  {
    label: "Lambda",
    value: "Lambda",
  },
  {
    label: "OpenSearch",
    value: "OpenSearch",
  },
  {
    label: "QuickSight",
    value: "QuickSignt",
  },
  {
    label: "RDS",
    value: "RDS",
  },
  {
    label: "Redshift",
    value: "Redshift",
  },
  {
    label: "SageMaker",
    value: "SageMaker",
  },
  {
    label: "S3",
    value: "S3",
  },
];

export const yearOptions = [
  { label: "2020", value: "2020" },
  { label: "2021", value: "2021" },
  { label: "2022", value: "2022" },
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
];

export const productManagerOptions = [
  {
    label: "Fan Su",
    value: "fansu",
  },
  {
    label: "Robin Luo",
    value: "luorobin",
  },
  {
    label: "Eva Liu",
    value: "liueva",
  },
  {
    label: "Wenmeng Gao",
    value: "wenmg",
  },
  {
    label: "Paul Li",
    value: "siyuanli",
  },
  {
    label: "WW Solutions Engineering",
    value: "WW Solutions Engineering",
  },
];

export const solutionDevelopmentManagerOptions = [
  {
    label: "Joe Shi",
    value: "qiaoshi",
  },
  {
    label: "Ke Yi",
    value: "kyiamzn",
  },
  {
    label: "Mengxin Zhu",
    value: "mengxinz",
  },
  {
    label: "WW Solutions Engineering",
    value: "WW Solutions Engineering",
  },
];

export const csdcMemberOptions = [
  {
    label: "Fan Su",
    value: "fansu",
  },
  {
    label: "Robin Luo",
    value: "luorobin",
  },
  {
    label: "Eva Liu",
    value: "liueva",
  },
  {
    label: "Wenmeng Gao",
    value: "wenmg",
  },
  {
    label: "Joe Shi",
    value: "qiaoshi",
  },
  {
    label: "Ke Yi",
    value: "kyiamzn",
  },
  {
    label: "Mengxin Zhu",
    value: "mengxinz",
  },
  {
    label: "Ru Xie",
    value: "ruxie",
  },
  {
    label: "Aoyu Zhang",
    value: "aoyuzhan",
  },
  {
    label: "Jie Tang",
    value: "tangjiee",
  },
  {
    label: "Yu Xiao",
    value: "yuxiaox",
  },
  {
    label: "Pu Zhang",
    value: "puzhang",
  },
  {
    label: "Aiden Dai",
    value: "daixb",
  },
  {
    label: "Charles Wei",
    value: "wch",
  },
  {
    label: "Haidong Wang",
    value: "whaidong",
  },
  {
    label: "James Ma",
    value: "taomat",
  },
  {
    label: "Kervin Hu",
    value: "huyikai",
  },
  {
    label: "Magic Chen",
    value: "haiyunc",
  },
  {
    label: "Ming Xu",
    value: "albertxu",
  },
  {
    label: "Owen Chang",
    value: "chenyuc",
  },
  {
    label: "Paul Li",
    value: "siyuanli",
  },
  {
    label: "Yang Qian",
    value: "qianyanc",
  },
  {
    label: "George Gu",
    value: "minggu",
  },
  {
    label: "Hubin Cui",
    value: "cuihubin",
  },
  {
    label: "Junzhong Zhang",
    value: "junzhong",
  },
  {
    label: "Ning Lv",
    value: "lvning",
  },
  {
    label: "Xiaoting He",
    value: "xiaotih",
  },
  {
    label: "Xiujuan Li",
    value: "xiujuali",
  },
  {
    label: "Xu Han",
    value: "icyxu",
  },
  {
    label: "Yan Dai",
    value: "cyanda",
  },
  {
    label: "Yi Yan",
    value: "yiyanz",
  },
  {
    label: "Min Li",
    value: "liwmin",
  },
  {
    label: "Mingtong Deng",
    value: "mingtong",
  },
  {
    label: "Mingfei Que",
    value: "mingfeiq",
  },
  {
    label: "Wei Qiao",
    value: "wqqiao",
  },
  {
    label: "Xiaowei Zhu",
    value: "xiaoweii",
  },
  {
    label: "Yong Liu",
    value: "yonmzn",
  },
  {
    label: "WW Solutions Engineering",
    value: "WW Solutions Engineering",
  },
];

export const gtmMaterialsCategoryOptions = [
  {
    label: "First Call Deck",
    value: "First Call Deck",
  },
  {
    label: "Implementation Guide",
    value: "Implementation Guide",
  },
  {
    label: "TrainingTalk",
    value: "TrainingTalk",
  },
  {
    label: "Workshop",
    value: "Workshop",
  },
  {
    label: "Hands-on Tutorial",
    value: "Hands-on Tutorial",
  },
  {
    label: "Blog",
    value: "Blog",
  },
  {
    label: "FAQ",
    value: "FAQ",
  },
  {
    label: "Application",
    value: "Application",
  },
  {
    label: "Demo/Preview",
    value: "Demo/Preview",
  },
  {
    label: "Others",
    value: "Others",
  },
];

export const tierOptions = [
  {
    label: "Tier 1",
    value: "1",
  },
  {
    label: "Tier 2",
    value: "2",
  },
  {
    label: "Tier 3",
    value: "3",
  },
];
