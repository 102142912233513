import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, Header, Link, SpaceBetween } from "@cloudscape-design/components";
import { getCounter } from "../../../common/tableCounterStrings";
import { DataProvider } from "../../../api/data-provider";
import { useCasestudies, useNotification, useUserProfile } from "../../../components";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
export const PageHeader = (props) => {
  const { t } = useTranslation();
  const { pushNewNotification } = useNotification();
  const { casestudiesFetched, setCasestudiesFetched } = useCasestudies();
  const { userLogin, isAdmin, contentLanguage, setContentLanguage } = useUserProfile();
  const navigate = useNavigate();

  const isOnlyOneSelected = props.selectedItems.length === 1;
  const noSelectedItems = props.selectedItems.length === 0;
  const headerTitle = t("PageHeader.headerTitleCasestudies");

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // function viewDetailsCasestudyHandler() {
  //   const selectedCasestudy = props.selectedItems[0];
  //   navigate(`/casestudies/details?solutionNameAbbr=${selectedCasestudy.solutionNameAbbr}&customerNameAbbr=${selectedCasestudy.customerNameAbbr}`);
  // }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function editCasestudiesHandler() {
    const selectedCasestudy = props.selectedItems[0];
    navigate(`/casestudies/edit?solutionNameAbbr=${selectedCasestudy.solutionNameAbbr}&customerNameAbbr=${selectedCasestudy.customerNameAbbr}`);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function createCasestudiesHandler() {
    navigate("/casestudies/create");
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function deleteCasestudiesHandler() {
    await new DataProvider()
      .deleteCasestudies(props.selectedItems.map((item) => ({ solutionNameAbbr: item.solutionNameAbbr, customerNameAbbr: item.customerNameAbbr })))
      .then((response) => {
        DEBUG && console.log("%cResponse from Api (delete casestudies)", "color: blue; font-weight: bold;", response);
      });

    const newMessage = {
      type: "success",
      content: t("notification.deleteCasestudies"),
      dismissible: true,
      dismissLabel: "Dismiss message",
      statusIconAriaLabel: "success",
    };

    pushNewNotification(newMessage);

    // 在更新完了后端dynamodb数据库后，我们用下面这行代码触发前端重新拉取最新的solutionsList。
    setCasestudiesFetched(false);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function publishCasestudiesHandler() {
    const eventBody = {
      solutionNameAbbr: props.selectedItems[0].solutionNameAbbr,
      customerNameAbbr: props.selectedItems[0].customerNameAbbr,
    };

    const config = {
      params: {
        action: "toggle-publish-case-study",
        userLogin: userLogin,
        solutionNameAbbr: props.selectedItems[0].solutionNameAbbr,
        customerNameAbbr: props.selectedItems[0].customerNameAbbr,
      },
    };

    try {
      const response = await new DataProvider().updateCasestudy(eventBody, config); // 传入config的action，利用action修改parsedBody
      DEBUG && console.log("%cApiCallCompleted, isPublished status updated.", "color: green; font-weight: bold;", response);
      const newMessage = {
        type: "success",
        content: t("notification.updateCasestudy"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "success",
      };
      pushNewNotification(newMessage);

      // 在更新完了后端dynamodb数据库后，我们用下面这行代码触发前端重新拉取最新的solutionsList。
      setCasestudiesFetched(false);
    } catch (error) {
      console.error("Failed to toggle the status of isPublished:", error);
      const newMessage = {
        type: "error",
        content: t("notification.createOrUpdateCasestudyApiError"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const goBackToSolutionsPageHandler = () => {
    navigate(-1);
  };

  const changeContentLanguage = () => {
    setContentLanguage(contentLanguage === "en" ? "cn" : "en");
  };

  return (
    <Header
      variant="awsui-h1-sticky"
      counter={getCounter(props)}
      info={
        props.loadHelpPanelContent && (
          <Link onFollow={props.loadHelpPanelContent} variant="info">
            {t("PageHeader.linkInfo")}
          </Link>
        )
      }
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="refresh" loading={!casestudiesFetched} onClick={() => setCasestudiesFetched(false)} />
          <Button data-testid="header-btn-go-back" onClick={changeContentLanguage}>
            {contentLanguage === "en" ? t("PageHeader.buttonChangeLanguageCN") : t("PageHeader.buttonChangeLanguage")}
          </Button>
          <Button data-testid="header-btn-go-back" onClick={goBackToSolutionsPageHandler}>
            {t("PageHeader.buttonGoBack")}
          </Button>
          {isAdmin && (
            <Button data-testid="header-btn-publish" variant="primary" disabled={!isOnlyOneSelected || !isAdmin} onClick={publishCasestudiesHandler}>
              {t("PageHeader.buttonPublish")}
            </Button>
          )}
          {/* <Button data-testid="header-btn-view-details" disabled={!isOnlyOneSelected} onClick={viewDetailsCasestudyHandler}>
            {t("PageHeader.buttonViewDetails")}
          </Button> */}
          <Button
            data-testid="header-btn-edit"
            disabled={!isOnlyOneSelected || (!isAdmin && (userLogin !== props.selectedItems[0].createdBy || props.selectedItems[0].isPublished))}
            onClick={editCasestudiesHandler}
          >
            {t("PageHeader.buttonEdit")}
          </Button>

          <Button
            data-testid="header-btn-delete"
            disabled={
              noSelectedItems ||
              !props.selectedItems.every((item) => item.isPublished === false) ||
              (!props.selectedItems.every((item) => item.createdBy === userLogin) && !isAdmin)
            }
            onClick={deleteCasestudiesHandler}
          >
            {t("PageHeader.buttonDelete")}
          </Button>

          <Button data-testid="header-btn-create" variant="primary" onClick={createCasestudiesHandler}>
            {t("PageHeader.buttonCreate")}
          </Button>
        </SpaceBetween>
      }
    >
      {headerTitle}
    </Header>
  );
};

export default PageHeader;
