import { IconDots } from "@tabler/icons-react";
import { FC } from "react";

interface Props {}

export const ChatLoader: FC<Props> = () => {
  return (
    <div className="tw-flex tw-flex-col tw-flex-start">
      <div
        className={`tw-flex tw-items-center tw-bg-neutral-200 tw-text-neutral-900 tw-rounded-2xl tw-px-4 tw-py-2 tw-w-fit`}
        style={{ overflowWrap: "anywhere" }}
      >
        <IconDots className="tw-animate-pulse" />
      </div>
    </div>
  );
};
