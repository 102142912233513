import { useEffect } from 'react';
import { Authenticator, useAuthenticator, View } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { useNavigate, useLocation } from 'react-router';
import { Auth } from 'aws-amplify';
import { Spinner } from '@cloudscape-design/components';

export function LoggedOut() {
   
    return (
        <center>
            <h1>
                The Palette was built to provide you with the latest solution
                information and support.
            </h1>
            <h3>Click the "Login" button to sign in as an Amazon Employee!</h3>
        </center>
    );
}

export default LoggedOut;
