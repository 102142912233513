import React from 'react';
import Flashbar from '@cloudscape-design/components/flashbar';
import { useNotification } from '../Context/NotificationContext';

export const Notification: React.FC = () => {
  const { notificationList } = useNotification();

  return <Flashbar items={notificationList} />;
};

export default Notification;
