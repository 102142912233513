import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ContentLayout } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, LoadingSpinner, Navigation, Notification, useCasestudies, useUserProfile } from "../../components";
import ToolsContent from "../casestudies/sub-components/ToolsContent";
import { FormContent, FormHeader } from "./sub-components/form-content";
import paletteConfig from "../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function CasestudiesEditStarting() {
  const { t } = useTranslation();
  const { casestudiesList, setCasestudiesFetched } = useCasestudies();
  const { userLogin, isAdmin } = useUserProfile();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [theCasestudyToEdit, setTheCasestudyToEdit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [noCasestudyFound, setNoCasestudyFound] = useState(false); // 新的状态变量
  const [unauthorized, setUnauthorized] = useState(false); // 新的状态变量
  const [inPublishedStage, setInPublishedStage] = useState(false); // 新的状态变量
  const appLayout = useRef();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const solutionNameAbbr = searchParams.get("solutionNameAbbr");
  const customerNameAbbr = searchParams.get("customerNameAbbr");
  DEBUG && console.log("%clocation object:", "color: green; font-weight: bold;", location, solutionNameAbbr, customerNameAbbr);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 根据solutionNameAbbr和customerNameAbbr从solutionsList中获取对应的solution对象
  useEffect(() => {
    if (casestudiesList) {
      const selectedCasestudy = casestudiesList.find(
        (casestudy) => casestudy.solutionNameAbbr === solutionNameAbbr && casestudy.customerNameAbbr === customerNameAbbr
      );

      if (selectedCasestudy) {
        if (selectedCasestudy.isPublished) {
          setInPublishedStage(true);
        } else if (isAdmin || selectedCasestudy.createdBy === userLogin) {
          setTheCasestudyToEdit(selectedCasestudy);
        } else {
          setUnauthorized(true); // 如果用户无权编辑，设置 unauthorized 为 true
        }
      } else {
        setNoCasestudyFound(true); // casestudy没有找到
      }
    }
    setLoading(false); // 移动到这里，确保只调用一次
  }, [solutionNameAbbr, customerNameAbbr, casestudiesList]);

  useEffect(() => {
    if (theCasestudyToEdit) {
      DEBUG && console.log("%ctheCasestudyToEdit", "color: blue; font-weight: bold;", theCasestudyToEdit);
      setLoading(false);
    }
  }, [theCasestudyToEdit]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Casestudies"),
      href: "/casestudies",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 下面是4种不同情况下的渲染。后面3种情况如果用户是通过我们提供的link和button浏览，都是不可能出现的。只有用户自己在浏览器中输入地址，或者黑客攻击才可能出现。

  // Case 1: Loading
  if (loading) {
    return <LoadingSpinner />;
  }

  // Case 2: Not Found
  if (noCasestudyFound) {
    return (
      <center>
        <h1>The case study you are trying to modify was not found. Are you a hacker? </h1>
        <h3>Why are you attempting to search for case studies using keywords? You should browse the webpage through the link provided to you.</h3>
      </center>
    ); // 如果没有找到案例研究，显示这个信息
  }

  // Case 3: isPublished === true, Not allowed to Modify content
  if (inPublishedStage) {
    return (
      <center>
        <h1>A published case study is not allowed to be modified, even if you are an administrator or the creator.</h1>
        <h3>Please ask the administrator to change the status of this case study from "published" to "draft" first.</h3>
      </center>
    ); // 如果没有找到案例研究，显示这个信息
  }

  // Case 4: Not Authorized
  if (unauthorized) {
    return (
      <center>
        <h1>You are not authorized to edit this case study. </h1>
        <h3>You are neither an administrator nor the creator of the case study.</h3>
      </center>
    ); // 如果用户无权编辑，显示这个信息
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 缺省情况
  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      contentType="form"
      content={
        <ContentLayout header={<FormHeader loadHelpPanelContent={loadHelpPanelContent} />}>
          <FormContent selectedCasestudy={theCasestudyToEdit} loadHelpPanelContent={loadHelpPanelContent} />
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/casestudies" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, this component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default CasestudiesEditStarting;
