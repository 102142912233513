import awsConfig from "./aws-exports-auth-imported";

/******************************************************************************
 * The following code is to check if our website is running in localhost or not. We have 3 different
 * signIn/signOut redirect URIs (Local or Production p.xuhi or palette.xuhi). Based on where we are running the website,
 * we update the redirect URI to the right one.
 * https://docs.amplify.aws/lib/auth/social/q/platform/js/#full-sample
 */

let websiteName = "localhost";
let redirectSignInHostName = "";
let redirectSignOutHostName = "";

const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

if (!isLocalhost) {
  websiteName = window.location.hostname.startsWith("palette") ? "palette" : "p";
}

/**
 * Assuming you have 3 redirect URIs, and the first is for localhost
 * second is for production(CDK deployed p.xuhi), third is for production(Amplify hosted palette.xuhi)
 * const [localRedirectSignIn, productionRedirectSignInCDK, productionRedirectSignInAmplify] = awsConfig.oauth.redirectSignIn.split(",");
 * const [localRedirectSignOut, productionRedirectSignOutCDK, productionRedirectSignOutCDKAmplify] = awsConfig.oauth.redirectSignOut.split(",");
 * 
 * Now, we only have 2 redirect URIs, and the first is for localhost, the 2nd is palette.on.gcr.solutions.aws.dev
 */

const [localRedirectSignIn, productionRedirectSignInCDK] = awsConfig.oauth.redirectSignIn.split(",");
const [localRedirectSignOut, productionRedirectSignOutCDK] = awsConfig.oauth.redirectSignOut.split(",");

switch (websiteName) {
  case "localhost":
    redirectSignInHostName = localRedirectSignIn;
    redirectSignOutHostName = localRedirectSignOut;
    break;
  case "palette":
    redirectSignInHostName = productionRedirectSignInCDK;
    redirectSignOutHostName = productionRedirectSignOutCDK;
}

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: redirectSignInHostName,
    redirectSignOut: redirectSignOutHostName,
  },
};

export default updatedAwsConfig;

