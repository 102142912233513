import { useState, useEffect } from "react";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import GtmPanel from "./tab-gtm-panel";
import { DataProvider } from "../../../api/data-provider";
import { Uploader } from "../../../api/upload-multipart";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { LoadingSpinner, useSolutions, useUserProfile } from "../../../components";
import { getFileExtension, generateRandomId } from "../../../common/utils";
import cloneDeep from "lodash/cloneDeep";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function FormInputBaseFormContent({ content, selectedSolution, errorText = null }) {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();
  const { setFetched } = useSolutions();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();
  const { userLogin, today } = useUserProfile();

  // 先初始化为null，然后通过useEffect来监控formattedData是否已经被赋值成功，从而保证页面渲染的时候formattedData已经有需要的初始值。
  const [formattedData, setFormattedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [isSolutionUpdateCompleted, setIsSolutionUpdateCompleted] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false); // 文件不符合要求的时候，disable submit button

  const [gtmFiles, setGtmFiles] = useState([]); // 记录gtm表单上需要上传的所有文件的状态信息
  const [gtmFilesUpdated, setGtmFilesUpdated] = useState(false); // True表示更新完毕
  const [allUploadTasksSuccessfullyStarted, setAllUploadTasksSuccessfullyStarted] = useState(null); // 在上传前的判断逻辑中没有出错，已经在上传，但是不是指传输完成

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    if (selectedSolution) {
      setFormattedData({
        solutionNameAbbr: selectedSolution.solutionNameAbbr,
        solutionName_en: selectedSolution.solutionName_en,
        solutionName_cn: selectedSolution.solutionName_cn,
        gtmMaterials_en: cloneDeep(selectedSolution.gtmMaterials_en),
        gtmMaterials_cn: cloneDeep(selectedSolution.gtmMaterials_cn),
      });
    }
  }, [selectedSolution]);

  useEffect(() => {
    if (formattedData) {
      setLoading(false);
      DEBUG && console.log("%cformattedData (used to call API to create DynamoDB items):", "color: red; font-weight: bold;", formattedData);
    }
  }, [formattedData]);

  useEffect(() => {
    DEBUG && console.log("%cgtmFiles", "color: red; font-weight: bold;", gtmFiles);
  }, [gtmFiles]);

  /*-------------------------------------------------------------------------*/
  // 第二步：上传文件并记录进度
  useEffect(() => {
    const uploadFiles = async () => {
      const filePath = `store/solutions/${formattedData.solutionNameAbbr}`;
      const newFormattedData = cloneDeep(formattedData);

      // 用于更新newFormattedData中的fileKey属性值，其保存着文件在s3上的key，就是相对路径。
      const updateNewFormattedData = (id, value) => {
        DEBUG && console.log("updateNewFormattedData id:", id);
        // fileKey需要同时在两个语言的对象中创建，因为它和语言无关; 使用findIndex而不是find，这样才能保证两个语言的属性都能加上，否则只有一个语言能加上。
        const itemIndex = newFormattedData["gtmMaterials_" + contentLanguage].findIndex((item) => item.id === id);

        newFormattedData.gtmMaterials_en[itemIndex].fileKey = value;
        newFormattedData.gtmMaterials_en[itemIndex].downloadable = true; // 自动在页面渲染的时候提供下载图标
        newFormattedData.gtmMaterials_cn[itemIndex].fileKey = value;
        newFormattedData.gtmMaterials_cn[itemIndex].downloadable = true; // 自动在页面渲染的时候提供下载图标

        // if (itemToUpdate_en) {
        //   itemToUpdate_en.fileKey = value;
        //   itemToUpdate_en.downloadable = true; // 自动在页面渲染的时候提供下载图标
        // }
        // const itemToUpdate_cn = newFormattedData.gtmMaterials_cn.find((item) => item.id === id);
        // if (itemToUpdate_cn) {
        //   itemToUpdate_cn.fileKey = value;
        //   itemToUpdate_en.downloadable = true; // 自动在页面渲染的时候提供下载图标
        // }
      };

      const fileUploadPromises = gtmFiles.map(async (fileObj) => {
        try {
          const randomId = generateRandomId();
          const ext = getFileExtension(fileObj.file[0].name);
          const newFileName = `file-${today}-${randomId}.${ext}`;
          DEBUG && console.log("newFormattedData:", newFormattedData);
          updateNewFormattedData(fileObj.id, `${filePath}/${newFileName}`);

          // 上传方式一：如果文件小于等于5MB，就直接通过apigateway来上传文件
          if (fileObj.file[0].size <= 5 * 1024 * 1024) {
            DEBUG && console.log("%cUse DataProvider().uploadToS3, FileSize(MB)=", "color: green; font-weight: bold;", fileObj.file[0].size / 1024 / 1024);
            await new DataProvider().uploadToS3(
              userLogin,
              fileObj.file[0],
              filePath,
              (progress) => {
                // 更新进度
                setGtmFiles((prevGtmFiles) =>
                  prevGtmFiles.map((prevFileObj) => {
                    if (prevFileObj.id === fileObj.id) {
                      return { ...prevFileObj, progress };
                    }
                    return prevFileObj;
                  })
                );
              },
              (isCompleted) => {
                // 设置complete属性为true
                setGtmFiles((prevGtmFiles) =>
                  prevGtmFiles.map((prevFileObj) => {
                    if (prevFileObj.id === fileObj.id) {
                      return { ...prevFileObj, complete: isCompleted, errorText: "" };
                    }
                    return prevFileObj;
                  })
                );
              },
              newFileName
            );
          } else {
            // 上传方式二：如果文件大于5MB，就直接通过Multipart+PresignURL来上传文件
            DEBUG && console.log("%cUse Uploader(multipart), FileSize(MB)=", "color: green; font-weight: bold;", fileObj.file[0].size / 1024 / 1024);

            const uploaderOptions = {
              file: fileObj.file[0],
              fileName: `${filePath}/${newFileName}`,
              chunkSize: 4, // 4MB, 不提供的情况下，会自动使用缺省值 5MB
              threadsQuantity: 5, // 可以在5到15之间选择一个数字，不提供的情况下，会自动使用缺省值是5
            };

            let percentage = undefined;

            const setProgress = (progress) => {
              setGtmFiles((prevGtmFiles) =>
                prevGtmFiles.map((prevFileObj) => {
                  if (prevFileObj.id === fileObj.id) {
                    DEBUG && console.log("%cUploadProgress(%):", "color: green; font-weight: bold;", progress);
                    return { ...prevFileObj, progress };
                  }
                  return prevFileObj;
                })
              );
            };

            const setComplete = (isCompleted) => {
              // 设置complete属性为true
              setGtmFiles((prevGtmFiles) =>
                prevGtmFiles.map((prevFileObj) => {
                  if (prevFileObj.id === fileObj.id) {
                    DEBUG && console.log("prevFileObj.complete:", prevFileObj.complete);
                    DEBUG && console.log("%cgtmFiles(before setting it to true):", "color: green; font-weight: bold;", prevFileObj.complete);
                    return { ...prevFileObj, complete: isCompleted, errorText: "" };
                  }
                  return prevFileObj;
                })
              );
            };

            const setFileArrayEmpty = () => {
              setGtmFiles((prevGtmFiles) =>
                prevGtmFiles.map((prevFileObj) => {
                  if (prevFileObj.id === fileObj.id) {
                    return { ...prevFileObj, file: [] };
                  }
                  return prevFileObj;
                })
              );
            };

            const uploader = new Uploader(uploaderOptions);
            uploader
              .onProgress(({ percentage: newPercentage }) => {
                // to avoid the same percentage to be logged twice
                DEBUG && console.log("%cOnProgress", "color: green; font-weight: bold;");
                if (newPercentage !== percentage) {
                  percentage = newPercentage;
                  setProgress(percentage);
                }
              })
              .onComplete((isCompleted) => {
                setComplete(isCompleted);
              })
              .onError((error) => {
                setFileArrayEmpty();
                console.error(error);
              });

            DEBUG && console.log("%c===before uploader.start", "color: green; font-weight: bold;");
            uploader.start();
          }
        } catch (error) {
          console.error("File upload failed:", error);
          setAllUploadTasksSuccessfullyStarted(false);
          setGtmFiles((prevGtmFiles) =>
            prevGtmFiles.map((prevFileObj) => {
              if (prevFileObj.id === fileObj.id) {
                return {
                  ...prevFileObj,
                  errorText: "Upload failed, and stop modifying the database.",
                };
              }
              return prevFileObj;
            })
          );
          setSubmitting(false);
          throw error; // 抛出错误，以便Promise.all的.catch()块被执行
        }
      });

      await Promise.all(fileUploadPromises)
        .then(() => {
          // 所有文件上传成功后的处理逻辑
          setFormattedData(newFormattedData);
          setAllUploadTasksSuccessfullyStarted(true);
        })
        .catch((error) => {
          // 如果有一个或多个文件上传失败，处理逻辑
          console.log(
            "%cAt least one file has failed to upload during the process, the update to the database will be abandoned. You can try again by clicking the Submit button.",
            "color: green; font-weight: bold;"
          );
          alert(
            "At least one file has failed to upload during the process, the update to the database will be abandoned. You can try again by clicking the Submit button."
          );
        });
    };

    // 当gtmFilesUpdated=true的时候，就进一步在检查一下gtmFiles数据是否没有任何元素的file是空[]。
    if (gtmFilesUpdated) {
      const gtmFilesCleaned = gtmFiles.every((fileObj) => fileObj.file.length > 0);

      if (gtmFilesCleaned) {
        // 在这里调用函数上传文件，此时可以确保gtmFiles的数据已经更新完毕。
        DEBUG && console.log("%cStart uploading files, total Number is:", "color: green; font-weight: bold;", gtmFiles.length);
        setGtmFilesUpdated(false); // 这样在文件上传期间，gtmFiles改变的时候，这个监控就不会再重复执行uploadFiles。
        if (gtmFiles.length > 0) {
          uploadFiles();
        } else {
          setAllUploadTasksSuccessfullyStarted(true);
        }
      }
    }
  }, [gtmFiles, gtmFilesUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  /*-------------------------------------------------------------------------*/
  // 第三步，监控状态，一旦所有文件上传成功，并且上传完毕，就执行对DynamoDB的写操作，更新数据库。
  useEffect(() => {
    async function updateTheSolution() {
      /**
       * 在通过api更新数据库之前，先检查formattedData.gtmMaterials_en和formattedData.gtmMaterials_cn这两个数组，检查其每一个对象元素的name字段，
       * 如果值为空，就看另一个数组的对应index元素的name是否不为空字符串。如果不为空字符串，就复制其值，从而使得两种语言下的name值不会一个有，一个无。
       */
      // const copyOfFormattedData = {
      //   solutionNameAbbr: formattedData.solutionNameAbbr,
      //   gtmMaterials_en: [...formattedData.gtmMaterials_en],
      //   gtmMaterials_cn: [...formattedData.gtmMaterials_en],
      // };

      let copyOfFormattedData = cloneDeep(formattedData);

      for (let i = 0; i < copyOfFormattedData.gtmMaterials_en.length; i++) {
        // 如果两个语言中，有一个语言的name值是空，就使用另一个语言的name值补全。（有胜于无）
        if (copyOfFormattedData.gtmMaterials_en[i].name === "" && copyOfFormattedData.gtmMaterials_cn[i].name !== "") {
          copyOfFormattedData.gtmMaterials_en[i].name = copyOfFormattedData.gtmMaterials_cn[i].name;
        }
        if (copyOfFormattedData.gtmMaterials_cn[i].name === "" && copyOfFormattedData.gtmMaterials_en[i].name !== "") {
          copyOfFormattedData.gtmMaterials_cn[i].name = copyOfFormattedData.gtmMaterials_en[i].name;
        }
        // 如果updateDate是空字符串，使用当前日期。注意两个语言的updateDate只会同时为空字符串，不会出现一个是空，一个非空的情况。
        if (copyOfFormattedData.gtmMaterials_en[i].updateDate === "") {
          const currentDate = new Date();
          const newUpdateDate = `${currentDate.getFullYear()}-${("0" + (currentDate.getMonth() + 1)).slice(-2)}-${("0" + currentDate.getDate()).slice(-2)}`;
          copyOfFormattedData.gtmMaterials_en[i].updateDate = newUpdateDate;
          copyOfFormattedData.gtmMaterials_cn[i].updateDate = newUpdateDate;
        }
      }

      DEBUG && console.log("%c*** copyOfFormattedData (before ApiCall):", "color: blue; font-weight: bold;", copyOfFormattedData);

      const config = {
        params: {
          action: "solution-edit-gtm-materials",
        },
      };

      try {
        const response = await new DataProvider().updateSolution(copyOfFormattedData, config);
        DEBUG && console.log("%cApiCallCompleted, set 'isSolutionUpdateCompleted' to 'true' now.", "color: green; font-weight: bold;", response);
        setIsSolutionUpdateCompleted(true);
        const newMessage = {
          type: "success",
          content: t("notification.updateSolution"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "success",
        };
        pushNewNotification(newMessage);
      } catch (error) {
        console.error("Create Solution failed:", error);
        const newMessage = {
          type: "error",
          content: t("notification.createOrUpdateSolutionApiError"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "error",
        };
        pushNewNotification(newMessage);
      } finally {
        setGtmFilesUpdated(false); // 重置更新完成标记
        setAllUploadTasksSuccessfullyStarted(false);
        setSubmitting(false);
      }
    }

    // 检查是否所有的文件都已经上传成功
    if (allUploadTasksSuccessfullyStarted) {
      // 当gtmFiles为空[]，意味着没有文件需要上传。特别提醒：此时`gtmFiles.every((fileObj) => fileObj.complete === true`的返回值为true.
      let allFilesUploadCompleted = false;

      if (gtmFiles !== null && gtmFiles.length > 0) {
        // 当gtmFiles有数据的时候说明有至少1个文件需要上传，此时要检查是否文件都上传完毕
        if (gtmFiles.every((fileObj) => fileObj.complete === true)) {
          allFilesUploadCompleted = true;
        }
      } else {
        // gtmFiles为null或者空[]的时候说明没有文件需要上传，无需判断文件是否上传完毕，直接可以写dynamodb。
        allFilesUploadCompleted = true;
      }

      if (allFilesUploadCompleted) {
        updateTheSolution();
      }
    }
  }, [gtmFiles, allUploadTasksSuccessfullyStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*-------------------------------------------------------------------------*/
  // 第四步，监听写dynamodb的状态，如果已经成功完成api call，创建了dynamodb的新条目，并重新加载最新的solution list(setFetched)，并重定向网页
  useEffect(() => {
    if (isSolutionUpdateCompleted) {
      setFetched(false);
      navigate("/solutions");
    }
  }, [isSolutionUpdateCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      // 第一步：清理没有文件的元素，下一步是上传文件。
      const newGtmFiles = gtmFiles.filter((fileObj) => fileObj.file.length > 0); // 删除gtmFiles里面没有文件需要上传的元素
      DEBUG && console.log("%cnewGtmFiles", "color: green; font-weight: bold;", newGtmFiles);
      setGtmFiles(newGtmFiles);
      setGtmFilesUpdated(true);
    } catch (error) {
      console.error("An error was encountered when we clean the gtmMaterials array.");
      const newMessage = {
        type: "error",
        content: t("notification.errorBeforeUploadingFiles"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
    }
  };

  const onCancelClick = () => {
    navigate("/solutions");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("common:Button.cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={onSubmitHandler}
              loading={submitting}
              disabled={!formattedData?.solutionNameAbbr || submitting || submitDisabled}
            >
              {submitting ? t("common:Button.submitting") : t("common:Button.submit")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({
          formattedData,
          setFormattedData,
          gtmFiles,
          setGtmFiles,
          setSubmitDisabled,
        })}
      </Form>
    </form>
  );
}

/*****************************************************************************/
export function GtmFormContent({ loadHelpPanelContent, selectedSolution }) {
  return (
    <FormInputBaseFormContent
      selectedSolution={selectedSolution}
      content={({ formattedData, setFormattedData, gtmFiles, setGtmFiles, setSubmitDisabled }) => (
        <SpaceBetween size="l">
          <GtmPanel
            loadHelpPanelContent={loadHelpPanelContent}
            formattedData={formattedData}
            setFormattedData={setFormattedData}
            gtmFiles={gtmFiles}
            setGtmFiles={setGtmFiles}
            setSubmitDisabled={setSubmitDisabled}
          />
        </SpaceBetween>
      )}
    />
  );
}
