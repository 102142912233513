import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';
import paletteConfig from '../config/aws-palette-config.json';

// 创建axios实例，并设置默认的baseURL
export const apiClient: AxiosInstance = axios.create({
  baseURL: paletteConfig.apiBaseUrl, // e.g., "https://28moy1eklb.execute-api.ap-northeast-1.amazonaws.com/api"
  timeout: 60000, // 设置请求超时时间（可选）
  headers: {
    'Content-Type': 'application/json', // 设置请求头（可选）
  },
});

// 请求拦截器 - 添加token到请求头
apiClient.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
  }
);



export default apiClient;
