import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ContentLayout, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { useLocation } from "react-router-dom";
import CustomAppLayout from "../../components/Layout/CustomAppLayout";
import { Breadcrumb, LoadingSpinner, Navigation, Notification, useSolutions, useUserProfile } from "../../components";
import ToolsContent from "./sub-components/ToolsContent";
import PageHeader from "./sub-components/PageHeader";
import SolutionSummary from "./sub-components/SolutionSummary";
import { TabOverview } from "./sub-components/tab-overview";
import { TabTechDetails } from "./sub-components/tab-techdetails";
import { TabRoadmap } from "./sub-components/tab-roadmap";
import { TabGtmPackages } from "./sub-components/tab-gtm-materials";
import { TabCasestudies } from "./sub-components/tab-casestudies";
import { TabFeatureRequests } from "./sub-components/tab-feature-requests";

/*****************************************************************************/
const SolutionDetailsTabStarting = () => {
  const { t } = useTranslation();
  const { solutionsList } = useSolutions();
  const { contentLanguage } = useUserProfile();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [oneSolution, setOneSolution] = useState(null);
  const [selectedTab, setSelectedTab] = useState(null);
  const [loading, setLoading] = useState(true);
  const appLayout = useRef();

  // 当我们使用/solutions/details/xxx来跳转是可以通过下面这行代码获得xxx的值，并赋给solutionNameAbbr。
  // const { solutionNameAbbr } = useParams();
  // 但是我们在项目中使用SearchParams的方法传参数。URL的结构是/solutions/details?solutionNameAbbr=xxx
  // 下面这段代码的作用是获取当前页面的查询参数中名为solutionNameAbbr的值。如果该查询参数不存在，则solutionNameAbbr的值将为null。
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const solutionNameAbbr = searchParams.get("solutionNameAbbr");
  // DEBUG && console.log("%clocation object:", "color: green; font-weight: bold;", location);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 根据solutionNameAbbr从solutionsList中获取对应的solution对象
  useEffect(() => {
    if (solutionNameAbbr && solutionsList) {
      const selectedSolution = solutionsList.find((solution) => solution.solutionNameAbbr === solutionNameAbbr);
      setOneSolution(selectedSolution);
    }
  }, [solutionNameAbbr, solutionsList]);

  useEffect(() => {
    if (oneSolution) {
      setLoading(false);
    }
  }, [oneSolution]);

  useEffect(() => {
    // 当组件加载时，检查本地存储并显示上次查看的选项卡，不管是使用localStorage还是sessionStorage，都会在navigate(-1)的过程中自动将lastSelectedSolutionsDetailsTab的值变为undefined.
    const lastSelectedSolutionsDetailsTab = localStorage.getItem("lastSelectedSolutionsDetailsTab");
    if (lastSelectedSolutionsDetailsTab) {
      setSelectedTab(lastSelectedSolutionsDetailsTab);
    } else {
      // 如果没有存储的选项卡索引，显示第一个选项卡
      setSelectedTab("overview");
    }
  }, []);

  useEffect(() => {
    // 当选定的选项卡更改时，更新本地存储
    if (selectedTab) {
      localStorage.setItem("lastSelectedSolutionsDetailsTab", selectedTab);
    }
  }, [selectedTab]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Solutions"),
      href: "/solutions",
    },
    {
      text: oneSolution?.[`solutionName_${contentLanguage}`],
      href: `/solutions/details?solutionNameAbbr=${solutionNameAbbr}`,
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const tabs = [
    {
      label: t("solutions:SolutionDetails.tabs.overview"),
      id: "overview",
      content: <TabOverview oneSolution={oneSolution} loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: t("solutions:SolutionDetails.tabs.techDetails"),
      id: "tech-details",
      content: <TabTechDetails oneSolution={oneSolution} />,
    },
    {
      label: t("solutions:SolutionDetails.tabs.roadmap"),
      id: "roadmap",
      content: <TabRoadmap oneSolution={oneSolution} />,
    },
    {
      label: t("solutions:SolutionDetails.tabs.gtmPackages"),
      id: "gtm-packages",
      content: <TabGtmPackages oneSolution={oneSolution} />,
    },
    {
      label: t("solutions:SolutionDetails.tabs.caseStudies"),
      id: "case-studies",
      content: <TabCasestudies oneSolution={oneSolution} />,
    },
    {
      label: t("solutions:SolutionDetails.tabs.featureRequests"),
      id: "ask-pm",
      content: <TabFeatureRequests oneSolution={oneSolution} />,
    },
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      content={
        <ContentLayout header={<PageHeader headerTitle={oneSolution[`solutionName_${contentLanguage}`]} solutionNameAbbr={solutionNameAbbr} />}>
          <SpaceBetween size="l">
            <SolutionSummary oneSolution={oneSolution} />
            <Tabs
              tabs={tabs}
              activeTabId={selectedTab}
              onChange={({ detail }) => {
                setSelectedTab(detail.activeTabId);
              }}
            />
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/solutions" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
};

export default SolutionDetailsTabStarting;
