import { IconRobot, IconUserCircle } from "@tabler/icons-react";
import { FC, memo } from "react";
import { Message } from "../types";
import { CodeBlock } from "../Markdown/CodeBlock";
import { MemoizedReactMarkdown } from "../Markdown/MemoizedReactMarkdown";

import rehypeMathjax from "rehype-mathjax";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

export interface Props {
  message: Message;
}

export const ChatMessage: FC<Props> = memo(({ message }) => {
  return (
    <div
      className={`group md:px-4 ${
        message.role === "assistant"
          ? "tw-border-b tw-border-black/10 tw-bg-gray-50 tw-text-gray-800 tw-dark:border-gray-900/50 tw-dark:bg-[#444654] tw-dark:text-gray-100"
          : "tw-border-b tw-border-black/10 tw-bg-white tw-text-gray-800 tw-dark:border-gray-900/50 tw-dark:bg-[#343541] tw-dark:text-gray-100"
      }`}
      style={{ overflowWrap: "anywhere" }}
    >
      <div className="tw-relative tw-m-auto tw-flex tw-p-4 tw-text-base tw-md:max-w-2xl tw-md:gap-6 tw-md:py-6 tw-lg:max-w-2xl tw-lg:px-0 tw-xl:max-w-3xl">
        <div className="tw-min-w-[40px] tw-text-right tw-font-bold">
          {message.role === "assistant" ? <IconRobot size={30} /> : <IconUserCircle size={30} />}
        </div>

        <div className="tw-prose tw-mt-[-2px] tw-w-full tw-dark:prose-invert">
          {message.role === "assistant" && (
            <div className="tw-flex tw-flex-row">
              <MemoizedReactMarkdown
                className="tw-prose tw-dark:prose-invert tw-flex-1"
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    if (children.length) {
                      if (children[0] == "▍") {
                        return <span className="tw-animate-pulse tw-cursor-default tw-mt-1">▍</span>;
                      }

                      children[0] = (children[0] as string).replace("`▍`", "▍");
                    }

                    const match = /language-(\w+)/.exec(className || "");

                    return !inline ? (
                      <CodeBlock key={Math.random()} language={(match && match[1]) || ""} value={String(children).replace(/\n$/, "")} {...props} />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                  table({ children }) {
                    return <table className="tw-border-collapse tw-border tw-border-black tw-px-3 tw-py-1 tw-dark:border-white">{children}</table>;
                  },
                  th({ children }) {
                    return (
                      <th className="tw-break-words tw-border tw-border-black tw-bg-gray-500 tw-px-3 tw-py-1 tw-text-white tw-dark:border-white">{children}</th>
                    );
                  },
                  td({ children }) {
                    return <td className="tw-break-words tw-border tw-border-black tw-px-3 tw-py-1 tw-dark:border-white">{children}</td>;
                  },
                }}
              >
                {`${message.content}`}
              </MemoizedReactMarkdown>
            </div>
          )}
          {message.role === "user" && message.content}
        </div>
      </div>
    </div>
  );
});
ChatMessage.displayName = "ChatMessage";

/* 下面是简略版本的ChatMessage 组件代码 */

// import { Message } from "../types";
// import { FC } from "react";

// interface Props {
//   message: Message;
// }

// export const ChatMessage: FC<Props> = ({ message }) => {
//   return (
//     <div className={`tw-flex tw-flex-col ${message.role === "assistant" ? "tw-items-start" : "tw-items-end"}`}>
//       <div
//         className={`tw-flex tw-items-center ${
//           message.role === "assistant" ? "tw-bg-neutral-200 tw-text-neutral-900" : "tw-bg-blue-500 tw-text-white"
//         } tw-rounded-2xl tw-px-3 tw-py-2 tw-max-w-[67%] tw-whitespace-pre-wrap`}
//         style={{ overflowWrap: "anywhere" }}
//       >
//         {message.content}
//       </div>
//     </div>
//   );
// };
