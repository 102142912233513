import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, ContentLayout, SpaceBetween } from "@cloudscape-design/components";
import { useLocation } from "react-router-dom";
import CustomAppLayout from "../../components/Layout/CustomAppLayout";
import { Breadcrumb, LoadingSpinner, Navigation, Notification, useCasestudies, useUserProfile } from "../../components";
import ToolsContent from "./sub-components/ToolsContent";
import PageHeader from "./sub-components/PageHeader";
import CasestudyHighlights from "./sub-components/CasestudyHighlights";
import CasestudyDetails from "./sub-components/CasestudyDetails";
import paletteConfig from "../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

const CasestudiesDetailsStarting = () => {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const [oneCasestudy, setOneCasestudy] = useState({});
  const [loading, setLoading] = useState(true);
  const { casestudiesList } = useCasestudies();
  const { contentLanguage } = useUserProfile();
  const [noCasestudyFound, setNoCasestudyFound] = useState(false); // 新的状态变量
  const appLayout = useRef();

  // 当我们使用/solutions/details/xxx来跳转是可以通过下面这行代码获得xxx的值，并赋给solutionNameAbbr。
  // const { solutionNameAbbr } = useParams();
  // 但是我们在项目中使用SearchParams的方法传参数。URL的结构是/solutions/details?solutionNameAbbr=xxx
  // 下面这段代码的作用是获取当前页面的查询参数中名为solutionNameAbbr的值。如果该查询参数不存在，则solutionNameAbbr的值将为null。
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const solutionNameAbbr = searchParams.get("solutionNameAbbr");
  const customerNameAbbr = searchParams.get("customerNameAbbr");
  DEBUG && console.log("%csolutionNameAbbr of the selected casestudy is: ", "color: blue; font-weight: bold;", solutionNameAbbr);
  DEBUG && console.log("%ccustomerNameAbbr of the selected casestudy is: ", "color: blue; font-weight: bold;", customerNameAbbr);

  // 根据solutionNameAbbr和customerNameAbbr从casestudiesList中获取对应的casestudy对象
  useEffect(() => {
    if (casestudiesList) {
      const selectedCasestudy = casestudiesList.find(
        (casestudy) => casestudy.solutionNameAbbr === solutionNameAbbr && casestudy.customerNameAbbr === customerNameAbbr
      );

      if (selectedCasestudy) {
        // 只要selectedCasestudy能查询到，那么100%是允许view的，因为后台在getCasestudies的时候已经过滤过，只把前端能看的数据发过来。
        setOneCasestudy(selectedCasestudy);
      } else {
        // 此时没有查询到，表示用户没有通过导航链接来浏览，而是直接在浏览器中输入了一个地址。此时应该拒绝访问，提示NotFound。
        setNoCasestudyFound(true); // casestudy没有找到
      }
    }
    setLoading(false); // 移动到这里，确保只调用一次
  }, [solutionNameAbbr, customerNameAbbr, casestudiesList]);

  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Casestudies"),
      href: "/casestudies",
    },
    {
      text: oneCasestudy?.[`customerName_${contentLanguage}`],
      href: `/casestudies/details?solutionNameAbbr=${solutionNameAbbr}&customerNameAbbr=${customerNameAbbr}`,
    },
  ];

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 只有可能出现下面是2种不同情况下的渲染。第2种情况是用户没有通过我们提供的link和button浏览，比如用户自己在浏览器中输入地址，或者黑客攻击才可能出现。

  // Case 1: Loading
  if (loading) {
    return <LoadingSpinner />;
  }

  // Case 2: Not Found
  if (noCasestudyFound) {
    return (
      <center>
        <h1>The case study you are trying to view was not found! </h1>
        <h3>Why are you attempting to search for case studies using keywords? You should browse the webpage through the link provided to you.</h3>
      </center>
    ); // 如果没有找到案例研究，显示这个信息
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  // 缺省情况

  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      content={
        <ContentLayout
          disableOverlap
          header={
            <PageHeader
              oneCasestudyObj={oneCasestudy}
              headline={oneCasestudy["headline_" + contentLanguage]}
              subheadline={oneCasestudy["subheadline_" + contentLanguage]}
            />
          }
        >
          <SpaceBetween size="l">
            <Box variant="p"></Box>
            {oneCasestudy["highlights_" + contentLanguage] && oneCasestudy["highlights_" + contentLanguage].length > 0 && (
              <CasestudyHighlights oneCasestudyObj={oneCasestudy} highlightsLength={oneCasestudy["highlights_" + contentLanguage].length} />
            )}
            <CasestudyDetails oneCasestudyObj={oneCasestudy} />
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/casestudies" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
};

export default CasestudiesDetailsStarting;
