/**
 * 这个文件的函数用于添加和删除表单，以及上下移动表单的顺序
 */

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const addForm = (setData, fieldPath, fields) => {
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const lastKey = pathArray.pop();

    console.log("%cprevState", "color: blue; font-weight: bold;", prevState);

    const newState = JSON.parse(JSON.stringify(prevState));

    console.log("%cpathArray", "color: blue; font-weight: bold;", pathArray);
    console.log("%clastKey", "color: blue; font-weight: bold;", lastKey);
    console.log("%cnewState", "color: blue; font-weight: bold;", newState);

    // Initialize targetArray based on pathArray length
    // 如果我们要更新的属性还在Data对象中还不存在，我们就初始化它为一个空的[]
    let targetArray;
    if (pathArray.length === 0) {
      if (!newState[lastKey]) {
        newState[lastKey] = [];
      }
      targetArray = newState[lastKey];
    } else {
      pathArray.reduce((acc, key, idx) => {
        if (idx === pathArray.length - 1) {
          if (!acc[key]) {
            acc[key] = {};
          }
          acc = acc[key];
          if (!acc[lastKey]) {
            acc[lastKey] = [];
          }
          targetArray = acc[lastKey];
        } else {
          if (!acc[key]) {
            acc[key] = {};
          }
          acc = acc[key];
        }
      }, newState);
    }

    console.log("%ctargetArray", "color: blue; font-weight: bold;", targetArray);

    // 接下来为这个空的[]初始化第一个元素对象，初始化的值是传进来的参数fields
    const newItem = { id: Math.max(0, ...targetArray.map((item) => item.id)) + 1, ...fields };
    targetArray.push(newItem);

    console.log("%cnewItem", "color: blue; font-weight: bold;", newItem);
    console.log("%ctargetArray", "color: blue; font-weight: bold;", targetArray);
    console.log("%cnewState", "color: blue; font-weight: bold;", newState);

    return newState;
  });
};

// Usage example:
// 第一个参数是所在组件的data状态的设置函数，需要传入。
// addForm(setData, `benefits_${contentLanguage}`, { headline: "", description: "", vote: 0 });

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const removeForm = (setData, fieldPath, id) => {
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const lastKey = pathArray.pop();

    const newState = JSON.parse(JSON.stringify(prevState));

    const targetArray = pathArray.reduce((acc, key) => acc[key], newState)[lastKey];
    const targetIndex = targetArray.findIndex((item) => item.id === id);

    // 如果没有找到对应id的元素，那么targetIndex = -1。下面处理找到对应元素的情况，从targetArray数组中删除该元素
    if (targetIndex > -1) {
      targetArray.splice(targetIndex, 1);
    }

    return newState;
  });
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const updateForm = (setData, fieldPath, updatedField, value, id) => {
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const lastKey = pathArray.pop();

    const newState = JSON.parse(JSON.stringify(prevState));

    console.log("%cpathArray", "color: blue; font-weight: bold;", pathArray);
    console.log("%clastKey", "color: blue; font-weight: bold;", lastKey);
    console.log("%cnewState", "color: blue; font-weight: bold;", newState);
    console.log("%cid", "color: blue; font-weight: bold;", id);
    console.log("%cupdateField", "color: blue; font-weight: bold;", updatedField);
    console.log("%cvalue", "color: blue; font-weight: bold;", value);

    const targetArray = pathArray.reduce((acc, key) => acc[key], newState)[lastKey];
    const targetIndex = targetArray.findIndex((item) => item.id === id);

    console.log("%ctargetArray", "color: blue; font-weight: bold;", targetArray);
    console.log("%ctargetIndex", "color: blue; font-weight: bold;", targetIndex);

    if (targetIndex > -1) {
      targetArray[targetIndex] = { ...targetArray[targetIndex], [updatedField]: value };
    }

    return newState;
  });
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const moveForm = (setData, fieldPath, direction, index) => {
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const lastKey = pathArray.pop();

    const newState = JSON.parse(JSON.stringify(prevState));

    const targetArray = pathArray.reduce((acc, key) => acc[key], newState)[lastKey];

    if ((direction === "up" && index === 0) || (direction === "down" && index === targetArray.length - 1)) {
      // Do nothing if trying to move the first element up or the last element down
      return prevState;
    }

    const newIndex = direction === "up" ? index - 1 : index + 1;
    const temp = targetArray[newIndex];
    targetArray[newIndex] = targetArray[index];
    targetArray[index] = temp;

    return newState;
  });
};
