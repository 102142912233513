import { useState, useEffect } from "react";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import OtherUpdatesPanel from "./tab-other-updates-panel";
import { DataProvider } from "../../../api/data-provider";
import { Uploader } from "../../../api/upload-multipart";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { LoadingSpinner, useAppManagement, useUserProfile } from "../../../components";
import { getFileExtension, generateRandomId } from "../../../common/utils";
import cloneDeep from "lodash/cloneDeep";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function FormInputBaseFormContent({ content, errorText = null }) {
  const { t } = useTranslation();
  const { homepage, setHomepageFetched } = useAppManagement();
  const { userLogin, today } = useUserProfile();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();

  // 先初始化为null，然后通过useEffect来监控formattedData是否已经被赋值成功，从而保证页面渲染的时候formattedData已经有需要的初始值。
  const [formattedData, setFormattedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [isOtherUpdateCompleted, setIsOtherUpdateCompleted] = useState(false);

  const [submitDisabled, setSubmitDisabled] = useState(false); // 文件不符合要求的时候，disable submit button

  const [toUploadFiles, setToUploadFiles] = useState([]); // 记录gtm表单上需要上传的所有文件的状态信息
  const [toUploadFilesUpdated, setToUploadFilesUpdated] = useState(false); // True表示更新完毕
  const [allUploadTasksSuccessfullyStarted, setAllUploadTasksSuccessfullyStarted] = useState(null); // 在上传前的判断逻辑中没有出错，已经在上传，但是不是指传输完成

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    DEBUG && console.log("%chomepage", "color: green; font-weight: bold;", homepage);
    if (homepage) {
      setFormattedData({
        itemName: homepage.itemName,
        otherUpdates: cloneDeep(homepage.otherUpdates),
      });
    }
  }, [homepage]);

  useEffect(() => {
    if (formattedData) {
      setLoading(false);
      DEBUG && console.log("%cformattedData (used to call API to create DynamoDB items):", "color: red; font-weight: bold;", formattedData);
    }
  }, [formattedData]);

  useEffect(() => {
    DEBUG && console.log("%ctoUploadFiles", "color: red; font-weight: bold;", toUploadFiles);
  }, [toUploadFiles]);

  /*-------------------------------------------------------------------------*/
  // 第二步：上传文件并记录进度
  useEffect(() => {
    const uploadFiles = async () => {
      const filePath = `store/app/${formattedData.itemName}`;
      const newFormattedData = cloneDeep(formattedData);

      // 用于更新newFormattedData中的fileKey属性值，其保存着文件在s3上的key，就是相对路径。
      const updateNewFormattedData = (id, value) => {
        DEBUG && console.log("updateNewFormattedData id:", id);
        const itemIndex = newFormattedData.otherUpdates.findIndex((item) => item.id === id);

        newFormattedData.otherUpdates[itemIndex].fileKey = value;
        // newFormattedData.otherUpdates[itemIndex].downloadable = true;
      };

      const fileUploadPromises = toUploadFiles.map(async (fileObj) => {
        try {
          const randomId = generateRandomId();
          const ext = getFileExtension(fileObj.file[0].name);
          const newFileName = `other-updates-${today}-${randomId}.${ext}`;
          DEBUG && console.log("newFileName:", newFileName);
          DEBUG && console.log("today:", today);
          updateNewFormattedData(fileObj.id, `${filePath}/${newFileName}`);

          // 上传方式一：如果文件小于等于5MB，就直接通过apigateway来上传文件
          if (fileObj.file[0].size <= 5 * 1024 * 1024) {
            DEBUG && console.log("%cUse DataProvider().uploadToS3, FileSize(MB)=", "color: green; font-weight: bold;", fileObj.file[0].size / 1024 / 1024);
            await new DataProvider().uploadToS3(
              userLogin,
              fileObj.file[0],
              filePath,
              (progress) => {
                // 更新进度
                setToUploadFiles((prevGtmFiles) =>
                  prevGtmFiles.map((prevFileObj) => {
                    if (prevFileObj.id === fileObj.id) {
                      return { ...prevFileObj, progress };
                    }
                    return prevFileObj;
                  })
                );
              },
              (isCompleted) => {
                // 设置complete属性为true
                setToUploadFiles((prevGtmFiles) =>
                  prevGtmFiles.map((prevFileObj) => {
                    if (prevFileObj.id === fileObj.id) {
                      return { ...prevFileObj, complete: isCompleted, errorText: "" };
                    }
                    return prevFileObj;
                  })
                );
              },
              newFileName
            );
          } else {
            // 上传方式二：如果文件大于5MB，就直接通过Multipart+PresignURL来上传文件
            DEBUG && console.log("%cUse Uploader(multipart), FileSize(MB)=", "color: green; font-weight: bold;", fileObj.file[0].size / 1024 / 1024);

            const uploaderOptions = {
              file: fileObj.file[0],
              fileName: `${filePath}/${newFileName}`,
              chunkSize: 4, // 4MB, 不提供的情况下，会自动使用缺省值 5MB
              threadsQuantity: 5, // 可以在5到15之间选择一个数字，不提供的情况下，会自动使用缺省值是5
            };

            let percentage = undefined;

            const setProgress = (progress) => {
              setToUploadFiles((prevGtmFiles) =>
                prevGtmFiles.map((prevFileObj) => {
                  if (prevFileObj.id === fileObj.id) {
                    DEBUG && console.log("%cUploadProgress(%):", "color: green; font-weight: bold;", progress);
                    return { ...prevFileObj, progress };
                  }
                  return prevFileObj;
                })
              );
            };

            const setComplete = (isCompleted) => {
              // 设置complete属性为true
              setToUploadFiles((prevGtmFiles) =>
                prevGtmFiles.map((prevFileObj) => {
                  if (prevFileObj.id === fileObj.id) {
                    DEBUG && console.log("prevFileObj.complete:", prevFileObj.complete);
                    DEBUG && console.log("%ctoUploadFiles(before setting it to true):", "color: green; font-weight: bold;", prevFileObj.complete);
                    return { ...prevFileObj, complete: isCompleted, errorText: "" };
                  }
                  return prevFileObj;
                })
              );
            };

            const setFileArrayEmpty = () => {
              setToUploadFiles((prevGtmFiles) =>
                prevGtmFiles.map((prevFileObj) => {
                  if (prevFileObj.id === fileObj.id) {
                    return { ...prevFileObj, file: [] };
                  }
                  return prevFileObj;
                })
              );
            };

            const uploader = new Uploader(uploaderOptions);
            uploader
              .onProgress(({ percentage: newPercentage }) => {
                // to avoid the same percentage to be logged twice
                DEBUG && console.log("%cOnProgress", "color: green; font-weight: bold;");
                if (newPercentage !== percentage) {
                  percentage = newPercentage;
                  setProgress(percentage);
                }
              })
              .onComplete((isCompleted) => {
                setComplete(isCompleted);
              })
              .onError((error) => {
                setFileArrayEmpty();
                console.error(error);
              });

            DEBUG && console.log("%c===before uploader.start", "color: green; font-weight: bold;");
            uploader.start();
          }
        } catch (error) {
          console.error("File upload failed:", error);
          setAllUploadTasksSuccessfullyStarted(false);
          setToUploadFiles((prevGtmFiles) =>
            prevGtmFiles.map((prevFileObj) => {
              if (prevFileObj.id === fileObj.id) {
                return {
                  ...prevFileObj,
                  errorText: "Upload failed, and stop modifying the database.",
                };
              }
              return prevFileObj;
            })
          );
          setSubmitting(false);
          throw error; // 抛出错误，以便Promise.all的.catch()块被执行
        }
      });

      await Promise.all(fileUploadPromises)
        .then(() => {
          // 所有文件上传成功后的处理逻辑
          setFormattedData(newFormattedData);
          setAllUploadTasksSuccessfullyStarted(true);
        })
        .catch((error) => {
          // 如果有一个或多个文件上传失败，处理逻辑
          console.log(
            "%cAt least one file has failed to upload during the process, the update to the database will be abandoned. You can try again by clicking the Submit button.",
            "color: green; font-weight: bold;"
          );
          alert(
            "At least one file has failed to upload during the process, the update to the database will be abandoned. You can try again by clicking the Submit button."
          );
        });
    };

    // 当toUploadFilesUpdated=true的时候，就进一步再检查一下toUploadFiles数据是否没有任何元素的file是空[]。
    if (toUploadFilesUpdated) {
      const toUploadFilesCleaned = toUploadFiles.every((fileObj) => fileObj.file.length > 0);

      if (toUploadFilesCleaned) {
        // 在这里调用函数上传文件，此时可以确保toUploadFiles的数据已经更新完毕。
        DEBUG && console.log("%cStart uploading files, total Number is:", "color: green; font-weight: bold;", toUploadFiles.length);
        setToUploadFilesUpdated(false); // 这样在文件上传期间，toUploadFiles改变的时候，这个监控就不会再重复执行uploadFiles。
        if (toUploadFiles.length > 0) {
          uploadFiles();
        } else {
          setAllUploadTasksSuccessfullyStarted(true);
        }
      }
    }
  }, [toUploadFiles, toUploadFilesUpdated]); // eslint-disable-line react-hooks/exhaustive-deps

  /*-------------------------------------------------------------------------*/
  // 第三步，监控状态，一旦所有文件上传成功，并且上传完毕，就执行对DynamoDB的写操作，更新数据库。
  useEffect(() => {
    async function updateFrequentlyUsedLinks() {
      /**
       * 在通过api更新数据库之前，先检查formattedData.gtmMaterials_en和formattedData.gtmMaterials_cn这两个数组，检查其每一个对象元素的name字段，
       * 如果值为空，就看另一个数组的对应index元素的name是否不为空字符串。如果不为空字符串，就复制其值，从而使得两种语言下的name值不会一个有，一个无。
       */
      // const copyOfFormattedData = {
      //   solutionNameAbbr: formattedData.solutionNameAbbr,
      //   gtmMaterials_en: [...formattedData.gtmMaterials_en],
      //   gtmMaterials_cn: [...formattedData.gtmMaterials_en],
      // };

      let copyOfFormattedData = cloneDeep(formattedData);

      for (let i = 0; i < copyOfFormattedData.otherUpdates.length; i++) {
        // 如果updateDate是空字符串，使用当前日期。注意两个语言的updateDate只会同时为空字符串，不会出现一个是空，一个非空的情况。
        if (copyOfFormattedData.otherUpdates[i].updateDate === "") {
          copyOfFormattedData.otherUpdates[i].updateDate = today;
        }
      }

      DEBUG && console.log("%c*** copyOfFormattedData (before ApiCall):", "color: blue; font-weight: bold;", copyOfFormattedData);

      // 增加config参数，以知道后端api去删除S3上不需要的文件
      const config = {
        params: {
          action: "update-other-updates",
        },
      };

      try {
        const response = await new DataProvider().updateApp(copyOfFormattedData, config);
        DEBUG && console.log("%cApiCallCompleted, set 'isOtherUpdateCompleted' to 'true' now.", "color: green; font-weight: bold;", response);
        setIsOtherUpdateCompleted(true);
        const newMessage = {
          type: "success",
          content: t("notification.updateApp"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "success",
        };
        pushNewNotification(newMessage);
      } catch (error) {
        console.error("Update App Homepage data failed:", error);
        const newMessage = {
          type: "error",
          content: t("notification.createOrUpdateAppApiError"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "error",
        };
        pushNewNotification(newMessage);
      } finally {
        setToUploadFilesUpdated(false); // 重置更新完成标记
        setAllUploadTasksSuccessfullyStarted(false);
        setSubmitting(false);
      }
    }

    // 检查是否所有的文件都已经上传成功
    if (allUploadTasksSuccessfullyStarted) {
      // 当toUploadFiles为空[]，意味着没有文件需要上传。特别提醒：此时`toUploadFiles.every((fileObj) => fileObj.complete === true`的返回值为true.
      let allFilesUploadCompleted = false;

      if (toUploadFiles !== null && toUploadFiles.length > 0) {
        // 当toUploadFiles有数据的时候说明有至少1个文件需要上传，此时要检查是否文件都上传完毕
        if (toUploadFiles.every((fileObj) => fileObj.complete === true)) {
          allFilesUploadCompleted = true;
        }
      } else {
        // toUploadFiles为null或者空[]的时候说明没有文件需要上传，无需判断文件是否上传完毕，直接可以写dynamodb。
        allFilesUploadCompleted = true;
      }

      if (allFilesUploadCompleted) {
        updateFrequentlyUsedLinks();
      }
    }
  }, [toUploadFiles, allUploadTasksSuccessfullyStarted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*-------------------------------------------------------------------------*/
  // 第四步，监听写dynamodb的状态，如果已经成功完成api call，创建了dynamodb的新条目，并重新加载最新的solution list(setFetched)，并重定向网页
  useEffect(() => {
    if (isOtherUpdateCompleted) {
      setHomepageFetched(false);
      navigate("/");
    }
  }, [isOtherUpdateCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      // 第一步：清理没有文件的元素，下一步是上传文件。
      const newBannerImageFiles = toUploadFiles.filter((fileObj) => fileObj.file.length > 0); // 删除toUploadFiles里面没有文件需要上传的元素
      DEBUG && console.log("%cnewBannerImageFiles", "color: green; font-weight: bold;", newBannerImageFiles);
      setToUploadFiles(newBannerImageFiles);
      setToUploadFilesUpdated(true);
    } catch (error) {
      console.error("An error was encountered when we clean the gtmMaterials array.");
      const newMessage = {
        type: "error",
        content: t("notification.errorBeforeUploadingFiles"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
    }
  };

  const onCancelClick = () => {
    navigate("/");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("common:Button.cancel")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler} loading={submitting} disabled={!formattedData?.itemName || submitting || submitDisabled}>
              {submitting ? t("common:Button.submitting") : t("common:Button.submit")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({
          formattedData,
          setFormattedData,
          toUploadFiles,
          setToUploadFiles,
          setSubmitDisabled,
        })}
      </Form>
    </form>
  );
}

/*****************************************************************************/
export function OtherUpdatesFormContent({ loadHelpPanelContent, selectedSolution }) {
  return (
    <FormInputBaseFormContent
      selectedSolution={selectedSolution}
      content={({ formattedData, setFormattedData, toUploadFiles, setToUploadFiles, setSubmitDisabled }) => (
        <SpaceBetween size="l">
          <OtherUpdatesPanel
            loadHelpPanelContent={loadHelpPanelContent}
            formattedData={formattedData}
            setFormattedData={setFormattedData}
            toUploadFiles={toUploadFiles}
            setToUploadFiles={setToUploadFiles}
            setSubmitDisabled={setSubmitDisabled}
          />
        </SpaceBetween>
      )}
    />
  );
}
