import { useState, useEffect } from "react";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import JsonInputNewSolutionPanel from "./tab-json-input-new-solution-panel";
import { DataProvider } from "../../../api/data-provider";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { useSolutions } from "../../../components";
import { solutionNameOptions_en, solutionNameOptions_cn } from "../../../common/formOptions";
import { categoryDictionary, domainDictionary } from "../../../common/chinese-english-dictionary";
import { v4 as uuidv4 } from "uuid";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
function isJsonDataValid_solutionName(parsedData) {
  const { solutionNameAbbr, solutionName_en, solutionName_cn } = parsedData;
  return (
    solutionNameOptions_en.some((option) => {
      return option.value === solutionNameAbbr && option.label === solutionName_en;
    }) &&
    solutionNameOptions_cn.some((option) => {
      return option.value === solutionNameAbbr && option.label === solutionName_cn;
    })
  );
}

function isJsonDataValid_category(parsedData) {
  const { category_en, category_cn } = parsedData;
  return (
    category_en.every((cat_en, index) => {
      const cat_cn = category_cn[index];
      return categoryDictionary[cat_en] === cat_cn;
    }) &&
    category_cn.every((cat_cn, index) => {
      const cat_en = category_en[index];
      return categoryDictionary[cat_cn] === cat_en;
    })
  );
}

function isJsonDataValid_domain(parsedData) {
  const { domain_en, domain_cn } = parsedData;
  return (
    domain_en.every((d_en, index) => {
      const d_cn = domain_cn[index];
      return domainDictionary[d_en] === d_cn;
    }) &&
    domain_cn.every((d_cn, index) => {
      const d_en = domain_en[index];
      return domainDictionary[d_cn] === d_en;
    })
  );
}

/*****************************************************************************/
function JsonInputBaseFormContent({ content, errorText = null }) {
  const { t } = useTranslation();
  const { setFetched } = useSolutions();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [isSolutionCreateCompleted, setIsSolutionCreateCompleted] = useState(false);

  // It is an object of casestudy containing all attributes except 'createdBy' which will be automatically generated by the backend lambda before inserting the item into Dynamodb tables.
  const [data, setData] = useState(`{\n\t"solutionNameAbbr": "DTH"\n}`);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    DEBUG && console.log("%cdata", "color: blue; font-weight: bold;", data);
  }, [data]);

  useEffect(() => {
    if (isSolutionCreateCompleted) {
      const newMessage = {
        type: "success",
        content: t("notification.createCasestudy"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "success",
      };
      pushNewNotification(newMessage);

      setFetched(false);
      setSubmitting(false);
      navigate("/solutions");
    }
  }, [isSolutionCreateCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onChangeSolutionHandler = (newData) => {
    setData(newData);
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  async function createNewSolution() {
    const parsedData = JSON.parse(data || "{}");
    DEBUG && console.log("%cparsedData (an object of casestudy):", "color: blue; font-weight: bold;", parsedData);

    /*--------------- Step 1: Validate Json Data -------------------*/
    if (!isJsonDataValid_solutionName(parsedData)) {
      const newMessage = {
        type: "warning",
        content: t("notification.createCasestudyJsonDataWarningSolutionName"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "warning",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
      return;
    }

    if (!isJsonDataValid_category(parsedData)) {
      const newMessage = {
        type: "warning",
        content: t("notification.createCasestudyJsonDataWarningCategory"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "warning",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
      return;
    }

    if (!isJsonDataValid_domain(parsedData)) {
      const newMessage = {
        type: "warning",
        content: t("notification.createCasestudyJsonDataWarningDomain"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "warning",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
      return;
    }

    /*--------------- Step 2: Update the DynamoDB -------------------*/
    parsedData.id = uuidv4();
    try {
      const response = await new DataProvider().createSolution(parsedData);
      DEBUG && console.log("%cApiCallCompleted, set 'isSolutionCreateCompleted' to 'true' now.", "color: green; font-weight: bold;", response);
      setIsSolutionCreateCompleted(true);
    } catch (error) {
      console.error("Create Solution failed:", error);
      throw error; // 抛出错误，让外部的 onSubmitHandler 的 catch 块捕获并统一处理，不在这里进行处理
    }
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    try {
      await createNewSolution();
    } catch (error) {
      console.error("Error encountered while executing `await createNewSolution();` ", error);
      const newMessage = {
        type: "error",
        content: t("notification.createOrUpdateSolutionApiError"),
        dismissible: true,
        dismissLabel: "Dismiss message",
        statusIconAriaLabel: "error",
      };
      pushNewNotification(newMessage);
      setSubmitting(false);
    }
  };

  const onCancelClick = () => {
    navigate("/solutions");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("common:Button.cancel")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler} disabled={submitting}>
              {submitting ? t("common:Button.submitting") : t("common:Button.submit")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({ onChangeSolutionHandler, data })}
      </Form>
    </form>
  );
}

export function JsonInputFormContent({ loadHelpPanelContent }) {
  return (
    <JsonInputBaseFormContent
      content={({ onChangeSolutionHandler, data }) => (
        <SpaceBetween size="l">
          <JsonInputNewSolutionPanel loadHelpPanelContent={loadHelpPanelContent} onChangeSolutionHandler={onChangeSolutionHandler} data={data} />
        </SpaceBetween>
      )}
    />
  );
}
