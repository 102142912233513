import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, CollectionPreferences, Pagination, Popover, Table, TextFilter, StatusIndicator, SpaceBetween } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState, TableNoMatchState, TableHeader } from "./registration-status-table-empty-nomatch-state";
import { LoadingSpinner, useAppManagement, useUserProfile } from "../../../components";
import { paginationLabels } from "../../../common/labels";
import { createColumnDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./registration-status-tableconfig";
import { getFilterCounterText } from "../../../common/tableCounterStrings";
import { addColumnSortLabels } from "../../../common/labels";
import { useColumnWidths } from "../../../common/use-column-widths";
import { useLocalStorage } from "../../../common/use-local-storage";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
export function TabRegistration() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { userLogin, isAdmin } = useUserProfile();
  const { modernAppCourse, modernAppCourseFetched } = useAppManagement();

  useEffect(() => {
    if (modernAppCourseFetched) {
      setLoading(false);
    }
  }, [modernAppCourseFetched]);

  const COLUMN_DEFINITIONS = addColumnSortLabels(createColumnDefinitions(t));
  const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
  const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
  const DEFAULT_PREFERENCES = createDefaultPreferences();

  const [columnDefinitions, saveWidths] = useColumnWidths("React-Tab-Registration-Widths", COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage("React-Tab-Registration-Preferences", DEFAULT_PREFERENCES);

  const [selectedItems, setSelectedItems] = useState([]);

  const isOnlyOneSelected = selectedItems.length === 1;
  // 当用户既不是Registration的创建者，也不是管理员身份，直接禁用Delete按钮
  const noRightToDeleteTheRegistration = selectedItems.length > 0 && selectedItems[0].login !== userLogin && !isAdmin;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(modernAppCourse.registration, {
    filtering: {
      empty: <TableEmptyState resourceName={t("BuildingClass.filteringResourceName_Registration")} />,
      noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
    },
    pagination: { pageSize: 10 },
    sorting: { defaultState: { sortingColumn: columnDefinitions[1], isDescending: false } },
    selection: {},
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const getCountSum = (registration) => {
    if (registration) {
      let sum = 0;
      if (registration) {
        for (let i = 0; i < registration.length; i++) {
          const countNumber = parseInt(registration[i].count, 10);
          if (!isNaN(countNumber)) {
            sum += countNumber;
          }
        }
      }
      return sum;
    } else {
      return 0;
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onCopyLoginsHandler = () => {
    if (modernAppCourse && modernAppCourse.registration) {
      const loginsArray = modernAppCourse.registration.map((item) => item.login);
      const logins = [...new Set(loginsArray)]; // 去重

      // 将每个 login 转换为 {login}@amazon.com 的形式，并用 ; 和空格连接起来
      const loginsString = logins.map((login) => `${login}@amazon.com`).join("; ");

      // 将 loginsString 复制到剪贴板
      navigator.clipboard.writeText(loginsString).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onCopyEntireListHandler = () => {
    // Check if selectedSolution and selectedSolution.featureRequests exist
    if (modernAppCourse && modernAppCourse.registration) {
      // 创建列名称
      const columnNames = "FullName\tLogin\tCategory\tCount\tId";

      // 将每个 opportunity 对象的属性用制表符连接起来
      const listString = modernAppCourse.registration.map((item) => `${item.fullName}\t${item.login}\t${item.category}\t${item.count}\t${item.id}`).join("\n");

      // 将列名称和 opportunitiesString 复制到剪贴板
      navigator.clipboard.writeText(`${columnNames}\n${listString}`).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    }
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <Table
      {...collectionProps}
      loading={loading}
      loadingText={t("loadingTextCasestudies")} /* the String that will be shown behind the Spinner */
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      resizableColumns={true}
      onColumnWidthsChange={saveWidths}
      wrapLines={preferences.wrapLines}
      stripedRows={preferences.stripedRows}
      items={items}
      selectionType="single"
      /**
       * 一旦在代码里面使用onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)},
       * 被选择的数据就会更新到selectedItems这个状态中，而不会更新到collectionProps.selectedItems中，后者就会
       * 一直保持为空数组，我建议使用const [selectedItems, setSelectedItems] = useState([]);和
       * onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}的组合方式。
       */
      onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
      selectedItems={selectedItems}
      header={
        <TableHeader
          title={t("BuildingClass.tableHeader_title")}
          selectedItems={selectedItems}
          totalItems={modernAppCourse.registration}
          actionButtons={
            <SpaceBetween direction="horizontal" size="xs">
              <Popover
                size="small"
                position="top"
                triggerType="custom"
                content={<StatusIndicator type="success">{t("solutions:SolutionDetails.tabs-content.copyLogins")}</StatusIndicator>}
              >
                <Button iconName="copy" onClick={() => onCopyLoginsHandler()}>
                  {t("BuildingClass.tableHeader_button_mail")}
                </Button>
              </Popover>
              <Popover
                size="small"
                position="top"
                triggerType="custom"
                content={<StatusIndicator type="success">{t("solutions:SolutionDetails.tabs-content.copyLogins")}</StatusIndicator>}
              >
                <Button iconName="copy" onClick={() => onCopyEntireListHandler()}>
                  {getCountSum(modernAppCourse?.registration)}{" "}
                  {t(`BuildingClass.tableHeader_button_${getCountSum(modernAppCourse?.registration) === 1 ? "count_singular" : "count_plural"}`)}
                </Button>
              </Popover>
            </SpaceBetween>
          }
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel="Find registration"
          filteringPlaceholder={t("solutions:useCollection.findCasestudies")}
          countText={getFilterCounterText(filteredItemsCount)}
          disabled={loading}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title={t("solutions:CollectionPreferences.title")}
          confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
          cancelLabel={t("solutions:CollectionPreferences.cancel")}
          disabled={loading}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: t("solutions:CollectionPreferences.pageSize"),
            options: PAGE_SIZE_OPTIONS,
          }}
          visibleContentPreference={{
            title: t("solutions:CollectionPreferences.selectVisibleColumns"),
            options: VISIBLE_CONTENT_OPTIONS,
          }}
          wrapLinesPreference={{
            label: t("solutions:CollectionPreferences.wrapLinesLabel"),
            description: t("solutions:CollectionPreferences.wrapLinesDescription"),
          }}
          stripedRowsPreference={{
            label: t("solutions:CollectionPreferences.stripedRowsLabel"),
            description: t("solutions:CollectionPreferences.stripedRowsDescription"),
          }}
        />
      }
      ariaLabels={{
        itemSelectionLabel: (data, row) => `select ${row.name}`,
        allItemsSelectionLabel: () => "select all",
        selectionGroupLabel: "Casestudies selection",
      }}
    />
  );
}
