import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContentLayout, SpaceBetween } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, Navigation, Notification } from "../../components";
import PageHeader from "./sub-components/PageHeader";
import ToolsContent from "./sub-components/ToolsContent";
// import Chatgpt from "./sub-components/chatgpt";
import OurOwnBot from "./sub-components/ourownbot";

/*****************************************************************************/
function AskPalette() {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.AskPalette"),
      href: "/ask-palette",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      // contentType="default"
      content={
        <ContentLayout disableOverlap header={<PageHeader />}>
          <SpaceBetween size="l">
            <OurOwnBot loadHelpPanelContent={loadHelpPanelContent} />
            {/* <Chatgpt loadHelpPanelContent={loadHelpPanelContent} /> */}
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/ask-palette" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default AskPalette;
