import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CodeEditor, Container, Header, FormField, SpaceBetween } from "@cloudscape-design/components";
// Import all necessary CSS for the themes you are using:
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/dawn.css";
import "ace-builds/css/theme/tomorrow_night_bright.css";

export const CODE_EDITOR_I18N_STRINGS = {
  loadingState: "Loading code editor",
  errorState: "There was an error loading the code editor.",
  errorStateRecovery: "Retry",

  editorGroupAriaLabel: "Code editor",
  statusBarGroupAriaLabel: "Status bar",

  cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
  errorsTab: "Errors",
  warningsTab: "Warnings",
  preferencesButtonAriaLabel: "Preferences",

  paneCloseButtonAriaLabel: "Close",

  preferencesModalHeader: "Preferences",
  preferencesModalCancel: "Cancel",
  preferencesModalConfirm: "Confirm",
  preferencesModalWrapLines: "Wrap lines",
  preferencesModalTheme: "Theme",
  preferencesModalLightThemes: "Light themes",
  preferencesModalDarkThemes: "Dark themes",
};

/*****************************************************************************/
export default function JsonInputNewSolutionPanel({ onChangeSolutionHandler, data }) {
  const { t } = useTranslation();
  const [ace, setAce] = useState();
  const [codeEditorLoading, setCodeEditorLoading] = useState(true);
  const [codeEditorPreferences, setCodeEditorPreferences] = useState({});

  useEffect(() => {
    async function loadAce() {
      const ace = await import("ace-builds");
      await import("ace-builds/webpack-resolver");
      ace.config.set("useStrictCSP", true);

      return ace;
    }

    loadAce()
      .then((ace) => setAce(ace))
      .finally(() => setCodeEditorLoading(false));
  }, []);

  const onCodeEditorChange = (e) => {
    onChangeSolutionHandler(e.detail.value);
  };

  return (
    <Container header={<Header variant="h2">{t("solutions:SolutionCreateForm.newSolutionSettings")}</Header>}>
      <SpaceBetween size="l">
        <FormField
          label={t("solutions:SolutionCreateForm.createJsonObject")}
          description={t("solutions:SolutionCreateForm.createJsonObject_Des")}
          stretch={true}
        >
          <CodeEditor
            ace={ace}
            language="json"
            value={data}
            onDelayedChange={onCodeEditorChange}
            preferences={codeEditorPreferences}
            onPreferencesChange={(e) => setCodeEditorPreferences(e.detail)}
            loading={codeEditorLoading}
            i18nStrings={CODE_EDITOR_I18N_STRINGS}
            themes={{ dark: ["dawn"], light: ["tomorrow_night_bright"] }}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}
