import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button, CollectionPreferences, Pagination, SpaceBetween, Table, TextFilter } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { TableEmptyState, TableNoMatchState, TableHeader } from "./tab-casestudies-table-empty-nomatch-state";
import { LoadingSpinner, useCasestudies, useUserProfile } from "../../../components";
import { paginationLabels } from "../../../common/labels";
import { createColumnDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./tab-casestudies-tableconfig";
import { getFilterCounterText } from "../../../common/tableCounterStrings";
import { addColumnSortLabels } from "../../../common/labels";
import { useColumnWidths } from "../../../common/use-column-widths";
import { useLocalStorage } from "../../../common/use-local-storage";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
export function TabCasestudies({ oneSolution }) {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { casestudiesList, casestudiesFetched } = useCasestudies();

  const filteredCasestudies = useMemo(() => {
    return (casestudiesList || []).filter((item) => item.solutionNameAbbr === oneSolution?.solutionNameAbbr);
  }, [casestudiesList, oneSolution]);

  const COLUMN_DEFINITIONS = addColumnSortLabels(createColumnDefinitions(t, navigate, contentLanguage));
  const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
  const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
  const DEFAULT_PREFERENCES = createDefaultPreferences();

  const [columnDefinitions, saveWidths] = useColumnWidths("React-Tab-Casestudies-Widths", COLUMN_DEFINITIONS);
  const [preferences, setPreferences] = useLocalStorage("React-Tab-Casestudies-Preferences", DEFAULT_PREFERENCES);

  const [selectedItems, setSelectedItems] = useState([]);
  const isOnlyOneSelected = selectedItems.length === 1;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(filteredCasestudies, {
    filtering: {
      empty: <TableEmptyState resourceName={t("casestudies:filteringResourceName")} selectedSolution={oneSolution} />,
      noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
    },
    pagination: { pageSize: 10 },
    sorting: { defaultState: { sortingColumn: columnDefinitions[2], isDescending: false } },
    selection: {},
  });

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    if (!casestudiesFetched || !oneSolution) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [casestudiesFetched, oneSolution]);

  useEffect(() => {
    DEBUG && console.log("%cpreferences", "color: blue; font-weight: bold;", preferences);
  }, [preferences]);

  useEffect(() => {
    DEBUG && console.log("%cselectedItems", "color: blue; font-weight: bold;", selectedItems);
  }, [selectedItems]);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function viewButtonHandler() {
    const item = selectedItems[0];
    navigate(`/casestudies/details?solutionNameAbbr=${item.solutionNameAbbr}&customerNameAbbr=${item.customerNameAbbr}`);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function createCasestudiesHandler(selectedSolution) {
    navigate("/casestudies/create", { state: { selectedSolution } });
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <Table
      {...collectionProps}
      loading={loading}
      loadingText={t("loadingTextCasestudies")} /* the String that will be shown behind the Spinner */
      columnDefinitions={columnDefinitions}
      visibleColumns={preferences.visibleContent}
      resizableColumns={true}
      onColumnWidthsChange={saveWidths}
      wrapLines={preferences.wrapLines}
      stripedRows={preferences.stripedRows}
      items={items}
      selectionType="single"
      /**
       * 一旦在代码里面使用onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)},
       * 被选择的数据就会更新到selectedItems这个状态中，而不会更新到collectionProps.selectedItems中，后者就会
       * 一直保持为空数组，我建议使用const [selectedItems, setSelectedItems] = useState([]);和
       * onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}的组合方式。
       */
      onSelectionChange={(event) => setSelectedItems(event.detail.selectedItems)}
      selectedItems={selectedItems}
      header={
        <TableHeader
          title={t("breadCrumb.Casestudies")}
          selectedItems={selectedItems}
          totalItems={filteredCasestudies}
          actionButtons={
            <SpaceBetween direction="horizontal" size="xs">
              <Button disabled={!isOnlyOneSelected} onClick={viewButtonHandler}>
                {t("PageHeader.buttonViewDetails")}
              </Button>
              <Button onClick={() => createCasestudiesHandler(oneSolution)}>{t("PageHeader.buttonCreate")}</Button>
            </SpaceBetween>
          }
        />
      }
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel="Find casestudies"
          filteringPlaceholder={t("solutions:useCollection.findCasestudies")}
          countText={getFilterCounterText(filteredItemsCount)}
          disabled={loading}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
      preferences={
        <CollectionPreferences
          title={t("solutions:CollectionPreferences.title")}
          confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
          cancelLabel={t("solutions:CollectionPreferences.cancel")}
          disabled={loading}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: t("solutions:CollectionPreferences.pageSize"),
            options: PAGE_SIZE_OPTIONS,
          }}
          visibleContentPreference={{
            title: t("solutions:CollectionPreferences.selectVisibleColumns"),
            options: VISIBLE_CONTENT_OPTIONS,
          }}
          wrapLinesPreference={{
            label: t("solutions:CollectionPreferences.wrapLinesLabel"),
            description: t("solutions:CollectionPreferences.wrapLinesDescription"),
          }}
          stripedRowsPreference={{
            label: t("solutions:CollectionPreferences.stripedRowsLabel"),
            description: t("solutions:CollectionPreferences.stripedRowsDescription"),
          }}
        />
      }
      ariaLabels={{
        itemSelectionLabel: (data, row) => `select ${row.name}`,
        allItemsSelectionLabel: () => "select all",
        selectionGroupLabel: "Casestudies selection",
      }}
    />
  );
}
