import { Button } from "@cloudscape-design/components";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/* 测试发现在第一列写的sortingComparator会默认为整个table的排序函数，所以如果其他列也是类似的排序逻辑，就不要再写 */

const getOpportunitySum = (opportunities) => {
  let sum = 0;
  if (opportunities) {
    for (let i = 0; i < opportunities.length; i++) {
      const arrNumber = parseInt(opportunities[i].arr, 10);
      if (!isNaN(arrNumber)) {
        sum += arrNumber;
      }
    }
  }
  return sum;
};

const fullnameSortingComparator = (item1, item2) => {
  if (!item1 || !item2) {
    return 0; // or whichever value you deem appropriate in this case
  }

  const name1 = item1.fullName || "";
  const name2 = item2.fullName || "";

  return name1.localeCompare(name2);
};

const loginSortingComparator = (item1, item2) => {
  if (!item1 || !item2) {
    return 0; // or whichever value you deem appropriate in this case
  }

  const name1 = item1.login || "";
  const name2 = item2.login || "";

  return name1.localeCompare(name2);
};

const opportunityCountSortingComparator = (item1, item2) =>
  (item1.opportunities ? item1.opportunities.length : 0) - (item2.opportunities ? item2.opportunities.length : 0);
const opportunitiesSumSortingComparator = (item1, item2) => getOpportunitySum(item1.opportunities) - getOpportunitySum(item2.opportunities);
const featureLikeCountSortingComparator = (item1, item2) => (item1.like ? item1.like.length : 0) - (item2.like ? item2.like.length : 0);

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createColumnDefinitions = (t, likeButtonHandler, userLogin) => {
  return [
    {
      id: "fullname",
      sortingField: "fullname",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.userFullName"),
      sortingComparator: fullnameSortingComparator,
      cell: (item) => item.fullName,
    },
    {
      id: "login",
      sortingField: "login",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.userLogin"),
      sortingComparator: loginSortingComparator,
      cell: (item) => item.login,
    },
    {
      id: "title",
      sortingField: "title",
      minWidth: 140,
      header: t("solutions:SolutionDetails.tabs-content.featureName"),
      cell: (item) => item.title,
    },
    {
      id: "content",
      sortingField: "content",
      minWidth: 300,
      header: t("solutions:SolutionDetails.tabs-content.featureDescription"),
      cell: (item) => item.content,
    },
    {
      id: "opportunities_count",
      sortingField: "opportunities_count",
      minWidth: 80,
      header: t("solutions:SolutionDetails.tabs-content.opportunityCount"),
      sortingComparator: opportunityCountSortingComparator,
      cell: (item) => (item.opportunities ? item.opportunities.length : 0),
    },
    {
      id: "opportunities_sum",
      sortingField: "opportunities_sum",
      minWidth: 80,
      header: t("solutions:SolutionDetails.tabs-content.opportunitySum"),
      sortingComparator: opportunitiesSumSortingComparator,
      cell: (item) => {
        let sum = 0;
        if (item.opportunities) {
          for (let i = 0; i < item.opportunities.length; i++) {
            const arrNumber = parseInt(item.opportunities[i].arr);
            if (!isNaN(arrNumber)) {
              sum += arrNumber;
            }
          }
        }
        return sum;
      },
    },
    {
      id: "feature_request_like",
      minWidth: 80,
      header: t("solutions:SolutionDetails.tabs-content.featureRequestsTableLike"),
      cell: (item) =>
        Array.isArray(item.like) && item.like.includes(userLogin) ? (
          <Button variant="primary" iconName="thumbs-up" onClick={() => likeButtonHandler(item.id, userLogin)}></Button>
        ) : (
          <Button variant="normal" iconName="thumbs-up" onClick={() => likeButtonHandler(item.id, userLogin)}></Button>
        ),
    },
    {
      id: "feature_request_like_count",
      sortingField: "feature_like_count",
      minWidth: 80,
      header: t("solutions:SolutionDetails.tabs-content.roadmapFeatureTableLikeCount"),
      sortingComparator: featureLikeCountSortingComparator,
      cell: (item) => (item.like ? item.like.length : 0),
    },
  ];
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:SolutionDetails.tabs-content.visibleContentConfigLabel"),
    options: [
      { id: "fullname", label: t("solutions:SolutionDetails.tabs-content.userFullName") },
      { id: "login", label: t("solutions:SolutionDetails.tabs-content.userLogin") },
      { id: "title", label: t("solutions:SolutionDetails.tabs-content.featureName") },
      { id: "content", label: t("solutions:SolutionDetails.tabs-content.featureDescription") },
      { id: "opportunities_count", label: t("solutions:SolutionDetails.tabs-content.opportunityCount") },
      { id: "opportunities_sum", label: t("solutions:SolutionDetails.tabs-content.opportunitySum") },
      { id: "feature_request_like", label: t("solutions:SolutionDetails.tabs-content.featureRequestsTableLike") },
      { id: "feature_request_like_count", label: t("solutions:SolutionDetails.tabs-content.featureRequestsTableLikeCount") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 10,
  visibleContent: ["fullname", "login", "title", "content", "opportunities_count", "opportunities_sum", "feature_request_like", "feature_request_like_count"],
  wrapLines: false,
  stripedRows: false,
});
