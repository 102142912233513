/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-northeast-1",
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: 'ap-northeast-1_sP8Yb5qoU',
  aws_user_pools_web_client_id: '160kgavh12h2olm53m43tp7191',
  aws_cognito_identity_pool_id: "ap-northeast-1:d6ea40f8-e51f-4765-b982-26205ad02abf",
  oauth: {
    domain: "xuhifedtest.auth.ap-northeast-1.amazoncognito.com",
    scope: ["aws.cognito.signin.user.admin", "email", "openid", "phone", "profile"],
    redirectSignIn: "http://localhost:3000/,https://palette.on.gcr.solutions.aws.dev/",
    redirectSignOut: "http://localhost:3000/,https://palette.on.gcr.solutions.aws.dev/",
    responseType: "code",
  },
  federationTarget: "COGNITO_USER_POOLS",
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: ["REQUIRES_LOWERCASE", "REQUIRES_UPPERCASE", "REQUIRES_NUMBERS", "REQUIRES_SYMBOLS"],
  },
  aws_cognito_verification_mechanisms: ["EMAIL"],
};

export default awsmobile;
