import { Header } from "@cloudscape-design/components";
import React from "react";

export interface FeatureItemType {
  type: "image" | "text";
  name?: string;
  description?: string;
  imageUrl?: string;
}

interface CoreFeatureItemProps {
  item: FeatureItemType;
}

const CoreFeatureItem: React.FC<CoreFeatureItemProps> = (props: CoreFeatureItemProps) => {
  const { item } = props;
  return (
    <div className="home-core-feat-item home-box">
      {item.type === "image" && <div className="image-item" style={{ backgroundImage: `url(${item.imageUrl})` }}></div>}
      {item.type === "text" && (
        <div className="text-item">
          <Header variant="h3">{item.name}</Header>
          <p>{item.description}</p>
        </div>
      )}
    </div>
  );
};

export default CoreFeatureItem;
