import { Button, Container, FormField, Grid, Header, Input, Select, SpaceBetween } from "@cloudscape-design/components";
import { useAppManagement, useUserProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import { addFormNested, removeFormNested, updateFormNested, moveFormNested } from "../../../common/utils";
import { v4 as uuidv4 } from "uuid";
import paletteConfig from "../../../config/aws-palette-config.json";
import { useState } from "react";
import { analyticsLogger } from "../../../common/utils/analyticsLogger";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
export default function RegistrationPanel({ formattedData, setFormattedData }) {
  const { modernAppCourse } = useAppManagement();
  const { userLogin, fullName } = useUserProfile();
  const { t } = useTranslation();
  // const [bannerImageOptions, setBannerImageOptions] = useState(homepage.bannerImages.map(({ fileKey, name }) => ({ value: fileKey, label: name })));
  const [categoryOptions, setCategoryOptions] = useState([
    { label: "Employee", value: "Employee" },
    { label: "Customer", value: "Customer" },
    { label: "Partner", value: "Partner" },
  ]);

  const [countOptions, setCountOptions] = useState([
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
    { label: "7", value: "7" },
    { label: "8", value: "8" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
  ]);

  return (
    <>
      <SpaceBetween size="l">
        {(formattedData.registration || []).map((form, index) => (
          <div key={form.id}>
            <Container
              header={
                <Header variant="h2" description={`<${index + 1}>`}>
                  {t("BuildingClass.header_containerAttendee")}
                </Header>
              }
            >
              <SpaceBetween size="l">
                <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                  <FormField label={t(`BuildingClass.formfield_name`)} description={t(`BuildingClass.formfield_name_Des`)}>
                    <Input value={form.fullName} readOnly />
                  </FormField>
                  <FormField label={t(`BuildingClass.formfield_category`)} description={t(`BuildingClass.formfield_category_Des`)}>
                    <Select
                      selectedOption={{
                        label:
                          (categoryOptions.find((option) => option.value === form.category) || {}).label || t("BuildingClass.formfield_category_Placeholder"),
                        value: form.category | "",
                      }}
                      onChange={({ detail: { selectedOption } }) => updateFormNested(setFormattedData, `registration`, "category", selectedOption.value, index)}
                      options={categoryOptions}
                      selectedAriaLabel="Selected"
                    />
                  </FormField>
                  <FormField label={t(`BuildingClass.formfield_count`)} description={t(`BuildingClass.formfield_count_Des`)}>
                    <Select
                      selectedOption={{
                        label: (countOptions.find((option) => option.value === form.count) || {}).label || t("BuildingClass.formfield_count_Placeholder"),
                        value: form.count | "",
                      }}
                      onChange={({ detail: { selectedOption } }) => updateFormNested(setFormattedData, `registration`, "count", selectedOption.value, index)}
                      options={countOptions}
                      selectedAriaLabel="Selected"
                    />
                  </FormField>

                  <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des_Short`)}>
                    <SpaceBetween size="s" direction="horizontal">
                      <Button
                        iconName="angle-up"
                        onClick={(event) => {
                          event.preventDefault();
                          moveFormNested(setFormattedData, `registration`, "up", index);
                        }}
                      />
                      <Button
                        iconName="angle-down"
                        onClick={(event) => {
                          event.preventDefault();
                          moveFormNested(setFormattedData, `registration`, "down", index);
                        }}
                      />
                      <Button
                        iconName="close"
                        onClick={(event) => {
                          event.preventDefault();
                          removeFormNested(setFormattedData, `registration`, index);
                        }}
                      ></Button>
                    </SpaceBetween>
                  </FormField>
                </Grid>
              </SpaceBetween>
            </Container>
          </div>
        ))}
        <Button
          onClick={(event) => {
            event.preventDefault();
            const randomId = uuidv4();
            addFormNested(setFormattedData, `registration`, {
              category: "",
              count: "",
              // Below are automatically generated
              id: randomId,
              updateOn: "",
              login: userLogin,
              fullName: fullName,
            });
            analyticsLogger.log('registration_add_click', {
              id: randomId,
            })
          }}
        >
          {t(`FormButton.addButton`)}
        </Button>
      </SpaceBetween>
    </>
  );
}
