/* generateRandomId用于生成一个指定长度的随机字符串，如果不带参数调用的话，默认长度为10 */

export function generateRandomId(length = 10) {
  // 只允许部分字母和小写字母，禁止使用数字0，1和字母o，i，l。
  const allowedChars = "23456789abcdefghjkmnpqrstuvwxyz";
  let result = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allowedChars.length);
    result += allowedChars[randomIndex];
  }

  return result;
}

// const allowedChars = "23456789abcdefghjkmnpqrstuvwxyzABCDEFGHJKMNPQRSTUVWXYZ";
// const randomString = generateRandomString(8);
// console.log(randomString);

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

export function generateRandomId_old() {
  return Math.random().toString(36).slice(2);
}

// const randomId = Math.random().toString(36).substring(7);
