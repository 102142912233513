import { Box, Header, SpaceBetween, Button } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../../components";

export const PageHeader = ({ oneCasestudyObj, headline, subheadline }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userLogin, isAdmin, contentLanguage, setContentLanguage } = useUserProfile();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function editCasestudiesHandler() {
    navigate(`/casestudies/edit?solutionNameAbbr=${oneCasestudyObj.solutionNameAbbr}&customerNameAbbr=${oneCasestudyObj.customerNameAbbr}`);
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  function goBackToCasestudiesPageHandler() {
    navigate(-1);
  }

  const changeContentLanguage = () => {
    setContentLanguage(contentLanguage === "en" ? "cn" : "en");
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <Header
        variant="h1"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button data-testid="header-btn-go-back" onClick={changeContentLanguage}>
              {contentLanguage === "en" ? t("PageHeader.buttonChangeLanguageCN") : t("PageHeader.buttonChangeLanguage")}
            </Button>

            <Button data-testid="header-btn-go-back" onClick={goBackToCasestudiesPageHandler}>
              {t("PageHeader.buttonGoBack")}
            </Button>
            {!oneCasestudyObj.isPublished && (userLogin === oneCasestudyObj.createdBy || isAdmin) && (
              <Button onClick={editCasestudiesHandler}>{t("PageHeader.buttonEdit")}</Button>
            )}
          </SpaceBetween>
        }
      >
        {headline && headline}
        {subheadline && <Box variant="p">{subheadline}</Box>}
      </Header>
    </>
  );
};

export default PageHeader;
