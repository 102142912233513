import { Badge, Link } from "@cloudscape-design/components";
import { getFileUrl, generateRandomId, isValidURL } from "../../common/utils";
import { analyticsLogger } from "../../common/utils/analyticsLogger";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createCardDefinitions = (t, navigate, contentLanguage) => {
  return {
    header: (item) => (
      // 在<Link>里可以使用href={`/solutions/details?solutionNameAbbr=${item.solutionNameAbbr}来跳转到details网页，但是性能不好，有延迟。
      // 这里我们使用navigate来实现跳转，用法和./sub-components/FullPageHeader里面的用法是一样的，只是这里用onFollow来实现。
      <div key={item.solutionNameAbbr && item.solutionNameAbbr}>
        <div key={item.solutionNameAbbr ? item.solutionNameAbbr : generateRandomId()}></div>
        <Link
          fontSize="heading-m"
          onFollow={(e) => {
            e.preventDefault();
            analyticsLogger.log('solution_click', {
              solutionName: item[`solutionName_${contentLanguage}`],
              solutionNameAbbr: item.solutionNameAbbr,
              category: item[`category_${contentLanguage}`]?.join(", ") ?? "",
              domain: item[`domain_${contentLanguage}`]?.join(", ") ?? "",
              productManager: item.productManager?.label ?? "",
              isPublished: item.isPublished,
            })
            navigate(`/solutions/details?solutionNameAbbr=${item.solutionNameAbbr}`);
          }}
        >
          <div className="text-ellipsis">{item[`solutionName_${contentLanguage}`]}</div>
        </Link>
      </div>
    ),
    sections: [
      {
        id: "solutionLogo",
        // header: t("solutions:CollectionPreferences.optionsLable.solutionLogo"),
        content: (item) => {
          return (
            <div
              style={{
                textAlign: "center",
                // padding: "20px",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${getFileUrl(item.solutionLogo)})`,
                width: "100%",
                paddingBottom: "70%",
              }}
            />
          );
        },
      },
      {
        id: "solutionName",
        header: t("solutions:CollectionPreferences.optionsLable.solutionName"),
        content: (item) => item[`solutionName_${contentLanguage}`],
      },
      {
        id: "solutionNameAbbr",
        header: t("solutions:CollectionPreferences.optionsLable.solutionNameAbbr"),
        content: (item) => item.solutionNameAbbr,
      },
      {
        id: "category",
        header: t("solutions:CollectionPreferences.optionsLable.category"),
        content: (item) => item[`category_${contentLanguage}`]?.join(", ") ?? "",
      },
      {
        id: "domain",
        header: t("solutions:CollectionPreferences.optionsLable.domain"),
        content: (item) => item[`domain_${contentLanguage}`]?.join(", ") ?? "",
      },
      {
        id: "keyServices",
        header: t("solutions:CollectionPreferences.optionsLable.keyServices"),
        // content: (item) => item.keyServices?.join(", ") ?? "",
        content: (item) =>
          item.keyServices
            ? item.keyServices
                .map((service, index) => (
                  <span key={index} className="awsui_badge_key_services awsui_badge-color-blue">
                    {service}
                  </span>
                ))
                .reduce((prev, curr, index) => {
                  return index === 0 ? [curr] : [...prev, " ", curr];
                }, [])
            : "",
      },
      {
        id: "useCase",
        header: t("solutions:CollectionPreferences.optionsLable.useCase"),
        content: (item) => item[`useCase_${contentLanguage}`] ?? "",
      },
      {
        id: "currentVersion",
        header: t("solutions:CollectionPreferences.optionsLable.currentVersion"),
        content: (item) => item.currentVersion ?? "",
      },
      {
        id: "releaseDate",
        header: t("solutions:CollectionPreferences.optionsLable.releaseDate"),
        content: (item) => item.releaseDate ?? "",
      },
      {
        id: "nextReleaseDate",
        header: t("solutions:CollectionPreferences.optionsLable.nextReleaseDate"),
        content: (item) => item.nextReleaseDate ?? "",
      },
      {
        id: "productManager",
        header: t("solutions:CollectionPreferences.optionsLable.productManager"),
        content: (item) => item.productManager?.label ?? "",
      },
      {
        id: "solutionDevelopmentManager",
        header: t("solutions:CollectionPreferences.optionsLable.solutionDevelopmentManager"),
        content: (item) => item.solutionDevelopmentManager?.label ?? "",
      },
      {
        id: "externalURL",
        header: t("solutions:CollectionPreferences.optionsLable.externalURL"),
        content: (item) => {
          const links = [];

          if (item.cnLink) {
            links.push(
              <Link external href={item.cnLink}>
                CN
              </Link>
            );
          }

          if (item.wwLink) {
            if (links.length > 0) {
              links.push(", ");
            }
            links.push(
              <Link external href={item.wwLink}>
                WW
              </Link>
            );
          }

          return links.length > 0 ? links : "";
        },
      },
      {
        id: "sourceCode",
        header: t("solutions:CollectionPreferences.optionsLable.sourceCode"),

        content: (item) => {
          if (item.sourceCode && isValidURL(item.sourceCode)) {
            try {
              const parsedUrl = new URL(item.sourceCode);
              const displayedUrl = parsedUrl.hostname + "/" + parsedUrl.pathname.split("/")[1];
              return (
                <Link external href={item.sourceCode}>
                  {displayedUrl}
                </Link>
              );
            } catch (error) {
              console.error("Invalid URL:", error.message);
              return "Invalid URL";
            }
          } else {
            return "";
          }
        },
      },
      {
        id: "isPublished",
        header: t("casestudies:CollectionPreferences.optionsLable.isPublished"),
        content: (item) =>
          item.isPublished ? (
            <Badge color="green">{t("casestudies:CollectionPreferences.alreadyPublished")}</Badge>
          ) : (
            <Badge>{t("casestudies:CollectionPreferences.notPublished")}</Badge>
          ),
      },
    ],
  };
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:CollectionPreferences.optionsLable.topLabel"),
    /**
     * id需要和CARD_DEFINITIONS.sections里面的id保持一致，这样才能关联起来。
     * lable就是点选了Preference设置的弹出框后，在里面显示的内容。
     */
    options: [
      { id: "solutionLogo", label: t("solutions:CollectionPreferences.optionsLable.solutionLogo") },
      { id: "solutionName", label: t("solutions:CollectionPreferences.optionsLable.solutionName") },
      { id: "solutionNameAbbr", label: t("solutions:CollectionPreferences.optionsLable.solutionNameAbbr") },
      { id: "category", label: t("solutions:CollectionPreferences.optionsLable.category") },
      { id: "domain", label: t("solutions:CollectionPreferences.optionsLable.domain") },
      { id: "keyServices", label: t("solutions:CollectionPreferences.optionsLable.keyServices") },
      { id: "useCase", label: t("solutions:CollectionPreferences.optionsLable.useCase") },
      { id: "currentVersion", label: t("solutions:CollectionPreferences.optionsLable.currentVersion") },
      { id: "releaseDate", label: t("solutions:CollectionPreferences.optionsLable.releaseDate") },
      { id: "nextReleaseDate", label: t("solutions:CollectionPreferences.optionsLable.nextReleaseDate") },
      { id: "productManager", label: t("solutions:CollectionPreferences.optionsLable.productManager") },
      { id: "solutionDevelopmentManager", label: t("solutions:CollectionPreferences.optionsLable.solutionDevelopmentManager") },
      { id: "externalURL", label: t("solutions:CollectionPreferences.optionsLable.externalURL") },
      { id: "sourceCode", label: t("solutions:CollectionPreferences.optionsLable.sourceCode") },
      { id: "isPublished", label: t("casestudies:CollectionPreferences.optionsLable.isPublished") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 10,
  visibleContent: ["solutionName", "solutionLogo", "category", "domain", "keyServices", "currentVersion", "productManager", "isPublished"],
});
