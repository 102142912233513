import { Message } from "../types";
import { FC } from "react";
import { ChatInput } from "./ChatInput";
import { ChatLoader } from "./ChatLoader";
import { ChatMessage } from "./ChatMessage";
import { ResetChat } from "./ResetChat";

interface Props {
  messages: Message[];
  loading: boolean;
  onSend: (message: Message) => void;
  onReset: () => void;
}

export const Chat: FC<Props> = ({ messages, loading, onSend, onReset }) => {
  return (
    <>
      <div className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-mb-4 sm:tw-mb-8">
        <ResetChat onReset={onReset} />
      </div>

      <div className="tw-flex tw-flex-col tw-rounded-lg tw-px-2 sm:tw-p-4 sm:tw-border tw-border-neutral-300">
        {messages.map((message, index) => (
          <div key={index} className="tw-my-1 sm:tw-my-1.5">
            <ChatMessage message={message} />
          </div>
        ))}

        {loading && (
          <div className="tw-my-1 sm:tw-my-1.5">
            <ChatLoader />
          </div>
        )}

        <div className="tw-mt-4 sm:tw-mt-8 tw-bottom-[56px] tw-left-0 tw-w-full">
          <ChatInput onSend={onSend} />
        </div>
      </div>
    </>
  );
};
