import { Box, Button, SpaceBetween } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { getFileUrl } from "../../../common/utils";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

const ModernAppCourse = ({ loadHelpPanelContent }) => {
  const { t } = useTranslation();
  const [images, setImages] = useState([
    "store/app/building-class/building_modern_applications_workshop_1.webp",
    "store/app/building-class/building_modern_applications_workshop_2.webp",
  ]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPrevPage = () => {
    setCurrentImageIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const goToNextPage = () => {
    setCurrentImageIndex((prevIndex) => Math.min(prevIndex + 1, images.length - 1));
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <>
      <Box variant="p"></Box>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <SpaceBetween size="l">
          <SpaceBetween direction="horizontal" size="xs">
            <Button iconName="angle-left" onClick={goToPrevPage}>
              {t("Button.prev")}
            </Button>
            <Button variant="primary">
              {currentImageIndex + 1}/{images.length}
            </Button>
            <Button iconName="angle-right" onClick={goToNextPage} iconAlign="right">
              {t("Button.next")}
            </Button>
          </SpaceBetween>
          <img src={getFileUrl(images[currentImageIndex])} alt="building-class" />
        </SpaceBetween>
      </div>
    </>
  );
};

export default ModernAppCourse;
