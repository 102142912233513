import { useState } from "react";
import { Header, Box } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useAppManagement, useUserProfile } from "../../../components";
import { getFileUrl } from "../../../common/utils";
import { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

const Banners = () => {
  const { t } = useTranslation();
  const { uiLanguage } = useUserProfile(); // 提醒：只有在Homepage渲染的时候，使用uiLanguage而不是contentLanguage来渲染内容。
  const { homepage } = useAppManagement();
  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {
    swiper?.autoplay?.stop();
    DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
  };

  const handleMouseLeave = () => {
    swiper?.autoplay?.start();
    DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
  };

  return (
    <Box variant="div">
      <Header variant="h2">{t("Home.news-and-events")}</Header>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Swiper
          autoHeight={true}
          onSwiper={setSwiper}
          className="home-swiper"
          autoplay={true}
          slidesPerView={1}
          loop={true}
          touchStartForcePreventDefault={false}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          touchMoveStopPropagation={false}
        >
          {homepage.banners.map((item, index) => {
            return (
              <SwiperSlide key={item.id} style={index === 0 ? { position: "relative" } : {}}>
                <img alt="" width="100%" src={getFileUrl(item.fileKey)} />
                <div className="slide-text-container">
                  <div className={`headline-text ${uiLanguage === "en" ? "headline-text-en" : "headline-text-cn"}`}>{item[`headline_` + uiLanguage]}</div>
                  <div className={`subheadline-text ${uiLanguage === "en" ? "subheadline-text-en" : "subheadline-text-cn"}`}>
                    {item[`subheadline_` + uiLanguage]}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
          {/* <SwiperSlide style={{ position: "relative" }}>
            <img alt="" width="100%" src={SWIPER_IMG_1} />
            <div className="slide-text-container">
              <div className="slide-text">We are coming soon</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img alt="" width="100%" src={SWIPER_IMG_2} />
            <div className="slide-text-container">
              <div className="slide-text">We are coming soon</div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <img alt="" width="100%" src={SWIPER_IMG_3} />
            <div className="slide-text-container">
              <div className="slide-text">演出开始了</div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </Box>
  );
};

export default Banners;

/*************  下面是带前后翻页和play/pause按钮的 代码   ***************/

// import React from "react";
// import { useEffect, useState } from "react";
// import { Header, Box, Button, SpaceBetween } from "@cloudscape-design/components";
// import SWIPER_IMG_1 from "../../../styles/images/event1.webp";
// import SWIPER_IMG_2 from "../../../styles/images/event2.webp";
// import SWIPER_IMG_3 from "../../../styles/images/event3.webp";
// import { Navigation, Pagination, Autoplay } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
// import "swiper/css/autoplay";
// import { Swiper as SwiperClass } from "swiper";

// import paletteConfig from "../../../config/aws-palette-config.json";

// const DEBUG = paletteConfig.debugMode;

// const UpComing: React.FC = () => {
//   const [swiper, setSwiper] = useState<SwiperClass | null>(null);
//   const [isPlaying, setIsPlaying] = useState(true); // 追踪播放/暂停状态的新 state

//   const handlePrev = () => {
//     swiper?.slidePrev();
//     DEBUG && console.log("%cswiper?.autoplay.paused", "color: green; font-weight: bold;", swiper?.autoplay?.paused);
//     DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
//   };

//   const handleNext = () => {
//     swiper?.slideNext();
//     DEBUG && console.log("%cswiper?.autoplay.paused", "color: green; font-weight: bold;", swiper?.autoplay?.paused);
//     DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
//   };

//   const handlePlayPause = () => {
//     if (swiper?.autoplay.running) {
//       swiper?.autoplay?.stop();
//       setIsPlaying(false); // 更新状态
//     } else {
//       swiper?.autoplay.start();
//       setIsPlaying(true); // 更新状态
//     }
//     DEBUG && console.log("%cswiper?.autoplay.paused", "color: green; font-weight: bold;", swiper?.autoplay?.paused);
//     DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
//   };

//   const handleMouseEnter = () => {
//     swiper?.autoplay?.stop();
//     setIsPlaying(false); // 更新状态
//     DEBUG && console.log("%cswiper?.autoplay.paused", "color: green; font-weight: bold;", swiper?.autoplay?.paused);
//     DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
//   };

//   const handleMouseLeave = () => {
//     swiper?.autoplay.start();
//     setIsPlaying(true); // 更新状态
//     DEBUG && console.log("%cswiper?.autoplay.paused", "color: green; font-weight: bold;", swiper?.autoplay?.paused);
//     DEBUG && console.log("%cswiper?.autoplay.running", "color: green; font-weight: bold;", swiper?.autoplay?.running);
//   };

//   return (
//     <Box variant="div">
//       <Header variant="h3">Upcoming activities</Header>
//       <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
//         <Swiper
//           onSwiper={setSwiper}
//           className="home-swiper"
//           autoplay={true}
//           slidesPerView={1}
//           loop={true}
//           pagination={{
//             clickable: true,
//           }}
//           navigation={true}
//           modules={[Pagination, Navigation, Autoplay]}
//         >
//           <SwiperSlide style={{ position: "relative" }}>
//             <img alt="" width="100%" src={SWIPER_IMG_1} />
//             <div className="slide-text-container">
//               <div className="slide-text">We are coming soon</div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <img alt="" width="100%" src={SWIPER_IMG_2} />
//             <div className="slide-text-container">
//               <div className="slide-text">We are coming soon</div>
//             </div>
//           </SwiperSlide>
//           <SwiperSlide>
//             <img alt="" width="100%" src={SWIPER_IMG_3} />
//             <div className="slide-text-container">
//               <div className="slide-text">演出开始了</div>
//             </div>
//           </SwiperSlide>
//         </Swiper>
//       </div>
//       <div className="button-container">
//         <Button iconName="angle-left" iconAlign="left" onClick={handlePrev}>
//           Prev
//         </Button>
//         <Button onClick={handlePlayPause}>{isPlaying ? "Pause" : "Play"}</Button>
//         <Button iconName="angle-right" iconAlign="right" onClick={handleNext}>
//           Next
//         </Button>
//       </div>
//     </Box>
//   );
// };

// export default UpComing;
