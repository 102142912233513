import { useState, useEffect } from "react";
import { Box, ColumnLayout, SpaceBetween } from "@cloudscape-design/components";
import { LoadingSpinner, useUserProfile } from "../../../components";

export const CasestudyHighlights = ({ oneCasestudyObj, highlightsLength }) => {
  const { contentLanguage } = useUserProfile();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (oneCasestudyObj) {
      setLoading(false);
    }
  }, [oneCasestudyObj]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <ColumnLayout columns={highlightsLength} variant="text-grid">
      {oneCasestudyObj["highlights_" + contentLanguage] &&
        oneCasestudyObj["highlights_" + contentLanguage].map((highlight, index) => (
          <SpaceBetween size="xxs" key={index}>
            <Box variant="h3">{highlight.keyword}</Box>
            <Box variant="p">{highlight.description}</Box>
          </SpaceBetween>
        ))}
    </ColumnLayout>
  );
};

export default CasestudyHighlights;
