import { useNavigate } from "react-router-dom";
import { Box, ColumnLayout, Container, Header, Link } from "@cloudscape-design/components";
import { useCasestudies, useSolutions } from "../../../components";
import { useTranslation } from "react-i18next";

export default function Overview() {
  const { solutionsList } = useSolutions();
  const { casestudiesList } = useCasestudies();
  const navigate = useNavigate();
  const { t } = useTranslation();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <Box>
      <Header variant="h2">{t("Home.overview")}</Header>
      <Container className="custom-dashboard-container">
        <ColumnLayout columns="4" variant="text-grid">
          <div>
            <Box variant="awsui-key-label">{t("nav.Solutions")}</Box>
            <Link
              variant="awsui-value-large"
              onFollow={(e) => {
                navigate(`/solutions`);
              }}
            >
              {solutionsList.length || 0}
            </Link>
          </div>
          <div>
            <Box variant="awsui-key-label">{t("nav.CaseStudies")}</Box>
            <Link
              variant="awsui-value-large"
              onFollow={(e) => {
                navigate(`/casestudies`);
              }}
            >
              {casestudiesList.length || 0}
            </Link>
          </div>
          <div>
            <Box variant="awsui-key-label">{t("nav.AskPalette")}</Box>
            <Link
              variant="awsui-value-large"
              onFollow={(e) => {
                navigate(`/ask-palette`);
              }}
            >
              1
            </Link>
          </div>
          <div>
            <Box variant="awsui-key-label">{t("nav.BuildingClass")}</Box>
            <Link
              variant="awsui-value-large"
              onFollow={(e) => {
                navigate(`/building-class`);
              }}
            >
              1
            </Link>
          </div>
        </ColumnLayout>
      </Container>
    </Box>
  );
}
