import FrequentlyUsedLinks from "./right-1-frequently-used-links";
import { Grid, Header, SpaceBetween } from "@cloudscape-design/components";
import LatestUpdateItem from "./right-2-other-updates";
import { useAppManagement } from "../../../components";
import { useTranslation } from "react-i18next";

const RightSide = () => {
  const { t } = useTranslation();
  const { homepage } = useAppManagement();
  // const LATEST_UPDATE_ITEM = [
  //   {
  //     isVideo: true,
  //     videoUrl: "https://www.youtube.com/watch?v=RfvL_423a-I",
  //     imageUrl: LATESTUPDATE_VIDEO,
  //     title: "Amazon Culture Video Series: Invent and Simplify with Elaine Chang",
  //   },
  //   {
  //     isVideo: false,
  //     imageUrl: LATESTUPDATE_IMG_1,
  //     title: "CSDC×合作伙伴联合解决方案案例第一批发布",
  //     content:
  //       "大中华区解决方案研发中心 (CSDC) 亚马逊云科技合作伙伴密切合作，为中国客户量身打造一系列安全可靠、弹性高效、快速部署、成本优化的联合解决方案，满足业务需求，提升运营效率，节省整体成本，从而让客户有更多资源和时间聚焦于业务创新，享有云计算带来的价值。",
  //     readmoreUrl: "https://www.baidu.com",
  //   },
  //   {
  //     imageUrl: LATESTUPDATE_IMG_2,
  //     title: "为Stable Diffusion打造加速利器",
  //     content:
  //       "Stable Diffusion Extension on AWS解决方案帮助客户将现有的Stable Diffusion (SD) 模型训练、推理和调优等任务负载从本地服务器迁移至Amazon SageMaker，利用云上弹性资源加速模型迭代，避免单机部署所带来的性能瓶颈。",
  //     readmoreUrl: "https://www.google.com",
  //   },
  // ];
  return (
    <Grid gridDefinition={[{ colspan: { l: 12, m: 12, default: 12 } }, { colspan: { l: 12, m: 12, default: 12 } }]}>
      <FrequentlyUsedLinks />
      <div>
        <Header variant="h2">{t("Home.latest-updates")}</Header>
        <SpaceBetween direction="vertical" size="l">
          {homepage.otherUpdates.map((item) => {
            return <LatestUpdateItem key={item.id} item={item} />;
          })}
        </SpaceBetween>
      </div>
    </Grid>
  );
};

export default RightSide;
