import React, { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { DataProvider } from "../../api/data-provider";

/*+++++++++++++++++++++++++++ 1. Define Interface +++++++++++++++++++++++++++*/
interface CasestudiesContextProviderProps {
  children: React.ReactNode;
}

interface CasestudiesContextType {
  casestudiesList: any[];
  setCasestudiesList: (list: any[]) => void;
  casestudiesFetched: boolean;
  setCasestudiesFetched: (fetched: boolean) => void;
}

/*++++++++++++++++++++++++++++ 2. Create Context ++++++++++++++++++++++++++++*/
export const CasestudiesContext = createContext<CasestudiesContextType>({
  casestudiesList: [],
  setCasestudiesList: () => {},
  casestudiesFetched: false,
  setCasestudiesFetched: () => {},
});

/*********************** 3. Create the Provider Component ********************/
export const CasestudiesContextProvider: React.FC<CasestudiesContextProviderProps> = ({ children }) => {
  const [casestudiesList, setCasestudiesList] = useState<any[]>([]);
  const [casestudiesFetched, setCasestudiesFetched] = useState(false);
  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    if (!casestudiesFetched && user) {
      new DataProvider().getCasestudies().then((casestudiesList) => {
        setCasestudiesList(casestudiesList);
        setCasestudiesFetched(true);
      });
    }

    const timer = setTimeout(() => {
      setCasestudiesFetched(false);
    }, 12 * 60 * 60 * 1000); // 12 Hours

    return () => {
      clearTimeout(timer);
    };
  }, [user, casestudiesFetched]);

  const contentValue = {
    casestudiesList: casestudiesList,
    setCasestudiesList: setCasestudiesList,
    casestudiesFetched: casestudiesFetched,
    setCasestudiesFetched: setCasestudiesFetched,
  };

  return <CasestudiesContext.Provider value={contentValue}>{children}</CasestudiesContext.Provider>;
};

/*++++++++++++++++++++++++++ 4. Create Custom Hook ++++++++++++++++++++++++++*/
export const useCasestudies = () => {
  return useContext(CasestudiesContext);
};
