import { useState, useEffect } from "react";
import { Button, Form, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import BannersPanel from "./tab-banners-panel";
import { DataProvider } from "../../../api/data-provider";
import { useNotification } from "../../../components/Context/NotificationContext";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { LoadingSpinner, useAppManagement } from "../../../components";
import cloneDeep from "lodash/cloneDeep";
import paletteConfig from "../../../config/aws-palette-config.json";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
function FormInputBaseFormContent({ content, errorText = null }) {
  const { t } = useTranslation();
  const { homepage, setHomepageFetched } = useAppManagement();
  const { pushNewNotification } = useNotification();
  const navigate = useNavigate();

  // 先初始化为null，然后通过useEffect来监控formattedData是否已经被赋值成功，从而保证页面渲染的时候formattedData已经有需要的初始值。
  const [formattedData, setFormattedData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false); // 提交期间，（1）禁用button；（2）禁止离开页面；
  const [apiCallSuccessfullyCompleted, setApiCallSuccessfullyCompleted] = useState(false);

  const [startCallingApi, setStartCallingApi] = useState(false);

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  useEffect(() => {
    DEBUG && console.log("%chomepage", "color: green; font-weight: bold;", homepage);
    if (homepage) {
      setFormattedData({
        itemName: homepage.itemName,
        banners: cloneDeep(homepage.banners),
      });
    }
  }, [homepage]);

  useEffect(() => {
    if (formattedData) {
      setLoading(false);
      DEBUG && console.log("%cformattedData (used to call API to create DynamoDB items):", "color: red; font-weight: bold;", formattedData);
    }
  }, [formattedData]);

  /*-------------------------------------------------------------------------*/
  // 第三步，监控状态，一旦所有文件上传成功，并且上传完毕，就执行对DynamoDB的写操作，更新数据库。
  useEffect(() => {
    async function updateBanners() {
      try {
        const response = await new DataProvider().updateApp(formattedData); //因为不需要删除文件等，就用一个参数就可以，不需要第二个参数。
        DEBUG && console.log("%cApiCallCompleted, set 'apiCallSuccessfullyCompleted' to 'true' now.", "color: green; font-weight: bold;", response);
        setApiCallSuccessfullyCompleted(true);
        const newMessage = {
          type: "success",
          content: t("notification.updateApp"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "success",
        };
        pushNewNotification(newMessage);
      } catch (error) {
        console.error("Update App failed:", error);
        const newMessage = {
          type: "error",
          content: t("notification.createOrUpdateAppApiError"),
          dismissible: true,
          dismissLabel: "Dismiss message",
          statusIconAriaLabel: "error",
        };
        pushNewNotification(newMessage);
      } finally {
        setSubmitting(false);
      }
    }

    // 检查是否所有的文件都已经上传成功
    if (startCallingApi) {
      updateBanners();
    }
  }, [startCallingApi]); // eslint-disable-line react-hooks/exhaustive-deps

  /*-------------------------------------------------------------------------*/
  // 第四步，监听写dynamodb的状态，如果已经成功完成api call，创建了dynamodb的新条目，并重新加载最新的solution list(setFetched)，并重定向网页
  useEffect(() => {
    if (apiCallSuccessfullyCompleted) {
      setHomepageFetched(false);
      navigate("/");
    }
  }, [apiCallSuccessfullyCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setStartCallingApi(true);
  };

  const onCancelClick = () => {
    navigate("/");
  };

  // 点击按钮提交期间，阻止刷新和关闭
  useBeforeUnload((event) => {
    if (submitting) {
      event.preventDefault();
      event.returnValue = t("useBeforeUnload");
    }
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <form>
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelClick}>
              {t("common:Button.cancel")}
            </Button>
            <Button variant="primary" onClick={onSubmitHandler} loading={submitting} disabled={!formattedData?.itemName || submitting}>
              {submitting ? t("common:Button.submitting") : t("common:Button.submit")}
            </Button>
          </SpaceBetween>
        }
        errorText={errorText}
        errorIconAriaLabel="Error"
      >
        {content({
          formattedData,
          setFormattedData,
        })}
      </Form>
    </form>
  );
}

/*****************************************************************************/
export function BannersFormContent({ loadHelpPanelContent, selectedSolution }) {
  return (
    <FormInputBaseFormContent
      selectedSolution={selectedSolution}
      content={({ formattedData, setFormattedData }) => (
        <SpaceBetween size="l">
          <BannersPanel loadHelpPanelContent={loadHelpPanelContent} formattedData={formattedData} setFormattedData={setFormattedData} />
        </SpaceBetween>
      )}
    />
  );
}
