import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ContentLayout } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, Navigation, Notification } from "../../components";
import ToolsContent from "./sub-components/ToolsContent";
import { FormContent, FormHeader } from "./sub-components/form-content";
import "../../styles/base.scss";

/*****************************************************************************/
function UserPreferences() {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Preferences"),
      href: "/preferences",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      contentType="form"
      content={
        <ContentLayout header={<FormHeader loadHelpPanelContent={loadHelpPanelContent} />}>
          <FormContent loadHelpPanelContent={loadHelpPanelContent} />
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/solutions" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, this component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default UserPreferences;
