import { ColumnLayout } from "@cloudscape-design/components";

const BenefitsBlock = ({ benefits = [] }) => {
  return (
    <ColumnLayout columns={benefits.length} variant="text-grid">
      {benefits.map((benefit, index) => (
        <div key={index} className="benefit">
          <h3>{benefit.title}</h3>
          <p>{benefit.content}</p>
        </div>
      ))}
    </ColumnLayout>
  );
};

export default BenefitsBlock;
