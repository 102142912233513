import { Button, Container, FormField, Header, Input, Select, SpaceBetween } from "@cloudscape-design/components";
import { useAppManagement, useUserProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import { addFormNested, removeFormNested, updateFormNested, moveFormNested } from "../../../common/utils";
import { v4 as uuidv4 } from "uuid";
import paletteConfig from "../../../config/aws-palette-config.json";
import { useState } from "react";

const DEBUG = paletteConfig.debugMode;

/*****************************************************************************/
export default function BannersPanel({ formattedData, setFormattedData }) {
  const { homepage } = useAppManagement();
  const { t } = useTranslation();
  const [bannerImageOptions, setBannerImageOptions] = useState(homepage.bannerImages.map(({ fileKey, name }) => ({ value: fileKey, label: name })));

  return (
    <>
      <SpaceBetween size="l">
        {(formattedData.banners || []).map((form, index) => (
          <div key={form.id}>
            <Container
              header={
                <Header variant="h2" description={`<${index + 1}>`}>
                  {t("App.header_containerBanner")}
                </Header>
              }
            >
              <SpaceBetween size="l">
                <FormField label={t(`App.formfield_bannerImage`)} description={t(`App.formfield_bannerImage_Des`)}>
                  <Select
                    selectedOption={{
                      label: (bannerImageOptions.find((option) => option.value === form.fileKey) || {}).label || t("App.formfield_bannerSelectedPlaceholder"),
                      value: form.fileKey | "",
                    }}
                    onChange={({ detail: { selectedOption } }) => updateFormNested(setFormattedData, `banners`, "fileKey", selectedOption.value, index)}
                    options={bannerImageOptions || []}
                    selectedAriaLabel="Selected"
                  />
                </FormField>
                <FormField label={t(`App.formfield_headline_en`)} description={t(`App.formfield_headline_en_Des`)}>
                  <Input
                    value={form.headline_en || ""}
                    onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `banners`, "headline_en", value, index)}
                  />
                </FormField>
                <FormField label={t(`App.formfield_headline_cn`)} description={t(`App.formfield_headline_cn_Des`)}>
                  <Input
                    value={form.headline_cn || ""}
                    onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `banners`, "headline_cn", value, index)}
                  />
                </FormField>
                <FormField label={t(`App.formfield_subheadline_en`)} description={t(`App.formfield_subheadline_en_Des`)}>
                  <Input
                    value={form.subheadline_en || ""}
                    onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `banners`, "subheadline_en", value, index)}
                  />
                </FormField>
                <FormField label={t(`App.formfield_subheadline_cn`)} description={t(`App.formfield_subheadline_cn_Des`)}>
                  <Input
                    value={form.subheadline_cn || ""}
                    onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `banners`, "subheadline_cn", value, index)}
                  />
                </FormField>

                <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des_Short`)}>
                  <SpaceBetween size="s" direction="horizontal">
                    <Button
                      iconName="angle-up"
                      onClick={(event) => {
                        event.preventDefault();
                        moveFormNested(setFormattedData, `banners`, "up", index);
                      }}
                    />
                    <Button
                      iconName="angle-down"
                      onClick={(event) => {
                        event.preventDefault();
                        moveFormNested(setFormattedData, `banners`, "down", index);
                      }}
                    />
                    <Button
                      iconName="close"
                      onClick={(event) => {
                        event.preventDefault();
                        removeFormNested(setFormattedData, `banners`, index);
                      }}
                    ></Button>
                  </SpaceBetween>
                </FormField>
              </SpaceBetween>
            </Container>
          </div>
        ))}
        <Button
          onClick={(event) => {
            event.preventDefault();
            const randomId = uuidv4();
            addFormNested(setFormattedData, `banners`, {
              fileKey: "",
              headline_en: "",
              headline_cn: "",
              subheadline_en: "",
              subheadline_cn: "",
              // Below are automatically generated
              id: randomId,
            });
          }}
        >
          {t(`FormButton.addButton`)}
        </Button>
      </SpaceBetween>
    </>
  );
}
