import { useTranslation } from "react-i18next";
import { getFileUrl } from "../../../common/utils";
import { Box, Button, Container, Header } from "@cloudscape-design/components";
import { DownloadButton, useAppManagement, useUserProfile } from "../../../components";

const FrequentlyUsedLinks = () => {
  const { t } = useTranslation();
  const { uiLanguage } = useUserProfile(); // 提醒：只有在Homepage渲染的时候，使用uiLanguage而不是contentLanguage来渲染内容。
  const { homepage } = useAppManagement();

  return (
    <Box variant="div">
      <Header variant="h2">{t("Home.frequently-used-links")}</Header>
      <Container>
        {homepage.frequentlyUsedLinks?.map((item) => (
          <div key={item.id}>
            {item.downloadable ? (
              <DownloadButton url={getFileUrl(item.fileKey)} filename={item[`name_` + uiLanguage]} iconAlign="left" variant="link">
                {item[`name_` + uiLanguage]}
              </DownloadButton>
            ) : (
              <a key={`a-${item.url}`} href={getFileUrl(item.url)} target="_blank" rel="noopener noreferrer">
                <Button iconName="external" iconAlign="left" variant="link">
                  {item[`name_` + uiLanguage]}
                </Button>
              </a>
            )}
          </div>
        ))}
      </Container>
    </Box>
  );
};

export default FrequentlyUsedLinks;

// import { Box, Button, Container, Header, Link } from "@cloudscape-design/components";
// import React from "react";
// import { useTranslation } from "react-i18next";

// const FrequentlyUsedLinks: React.FC = () => {
//   const { t } = useTranslation();

//   const links = ["test1", "test2"];

//   return (
//     <Box variant="div">
//       <Header variant="h3">{t("Home.frequently-used-links")}</Header>
//       <Container>
//         {links.map((link, index) => (
//           <Box key={index} padding={{ vertical: "xs" }}>
//             <Button iconName="download"></Button>
//             <Link href="#">{t(link)}</Link>
//           </Box>
//         ))}
//       </Container>
//     </Box>
//   );
// };

// export default FrequentlyUsedLinks;
