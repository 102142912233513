import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { ContentLayout, SpaceBetween, Tabs } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, Navigation, Notification } from "../../components";
import ToolsContent from "../solutions/sub-components/ToolsContent";
import { FormHeader } from "./sub-components/FormHeader";
import { JsonInputFormContent } from "./sub-components/tab-json-input-form-content";
import { FormInputFormContent } from "./sub-components/tab-form-input-form-content";
import "../../styles/base.scss";

/*****************************************************************************/
function CasestudiesCreateStarting() {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Casestudies"),
      href: "/casestudies",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const tabs = [
    {
      label: t("casestudies:CasestudyCreateForm.formToCreate"),
      id: "formToCreate",
      content: <FormInputFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: t("casestudies:CasestudyCreateForm.jsonToCreate"),
      id: "jsonToCreate",
      content: <JsonInputFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
  ];

  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      contentType="form"
      content={
        <ContentLayout disableOverlap header={<FormHeader loadHelpPanelContent={loadHelpPanelContent} />}>
          <SpaceBetween size="l">
            <Tabs tabs={tabs} />
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/casestudies" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, this component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default CasestudiesCreateStarting;
