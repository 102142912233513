import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, ContentLayout, SpaceBetween, Tabs } from "@cloudscape-design/components";
import CustomAppLayout from "../../components/Layout/CustomAppLayout";
import { Breadcrumb, LoadingSpinner, Navigation, Notification } from "../../components";
import ToolsContent from "./sub-components/ToolsContent";
import PageHeader from "./sub-components/PageHeader";
import Newsletter202305 from "./sub-components/newsletter-2023-05";
import Newsletter202306 from "./sub-components/newsletter-2023-06";
import Newsletter202308 from "./sub-components/newsletter-2023-08";
import { analyticsLogger } from "../../common/utils/analyticsLogger";

const NewslettersStarting = () => {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Newsletters"),
      href: "/newsletters",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const tabs = [
    {
      label: "2023-08",
      id: "newsletter08",
      content: <Newsletter202308 loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: "2023-06",
      id: "newsletter06",
      content: <Newsletter202306 loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: "2023-05",
      id: "newsletter05",
      content: <Newsletter202305 loadHelpPanelContent={loadHelpPanelContent} />,
    },
  ];

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      content={
        <ContentLayout disableOverlap header={<PageHeader />}>
          <SpaceBetween size="l">
            <Tabs
              tabs={tabs}
              onChange={(tab) => {
                analyticsLogger.log("newsletters_tab", {
                  tabId: tab.detail.activeTabId,
                });
              }}
            />
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/newsletters" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
};

export default NewslettersStarting;
