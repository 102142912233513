import { Box, ColumnLayout, Container, Header, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { CopyText } from "../../../components";

export const SolutionSummary = ({ oneSolution }) => {
  const { t } = useTranslation();

  return (
    <Container header={<Header variant="h2">{t("solutions:SolutionDetails.summary.summary")}</Header>}>
      <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.currentVersion")}</Box>
            <div>{oneSolution?.currentVersion}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.release")}</Box>
            <div>{oneSolution?.releaseDate}</div>
          </div>
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.nextReleaseDate")}</Box>
            <div>{oneSolution?.nextReleaseDate ? oneSolution?.nextReleaseDate : "To be determined(TBD)"}</div>
          </div>
        </SpaceBetween>

        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.productManager")}</Box>
            <CopyText
              showText={oneSolution?.productManager?.label}
              copyText={oneSolution?.productManager?.value}
              copyButtonLabel="Copy beta url"
              successText="URL copied"
              errorText="URL failed to copy"
            />
          </div>
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.developmentManager")}</Box>
            <CopyText
              showText={oneSolution?.solutionDevelopmentManager?.label}
              copyText={oneSolution?.solutionDevelopmentManager?.value}
              copyButtonLabel="Copy beta url"
              successText="URL copied"
              errorText="URL failed to copy"
            />
          </div>
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.technicalWriter")}</Box>
            <CopyText
              showText={oneSolution?.technicalWriter && oneSolution?.technicalWriter.map((tw) => tw.label).join(", ")}
              copyText={oneSolution?.technicalWriter && oneSolution?.technicalWriter.map((tw) => tw.value).join(", ")}
              copyButtonLabel="Copy beta url"
              successText="URL copied"
              errorText="URL failed to copy"
            />
          </div>
        </SpaceBetween>

        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.builders")}</Box>
            <div>{oneSolution?.builders && oneSolution?.builders.map((builder) => builder.label).join(", ")}</div>
          </div>
          {oneSolution?.contributors && oneSolution.contributors.length > 0 && (!oneSolution?.campaignId || !oneSolution?.ttLink) && (
            <div>
              <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.contributors")}</Box>
              <div>{oneSolution.contributors.map((contributor) => contributor.name).join(", ")}</div>
            </div>
          )}
          {oneSolution?.campaignId && (
            <div>
              <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.campaignId")}</Box>
              <CopyText
                showText={oneSolution?.campaignId}
                copyText={oneSolution?.campaignId}
                copyButtonLabel="Copy beta url"
                successText="URL copied"
                errorText="URL failed to copy"
              />
            </div>
          )}
          {oneSolution?.ttLink && (
            <div>
              <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.ttLink")}</Box>
              <div>
                <a href={oneSolution?.ttLink} target="_blank" rel="noopener noreferrer">
                  {t("solutions:SolutionDetails.summary.submitTT")}
                </a>
              </div>
            </div>
          )}
        </SpaceBetween>

        <SpaceBetween size="s">
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.externalUrl")}</Box>
            <div>
              <a href={oneSolution?.cnLink} target="_blank" rel="noopener noreferrer">
                CN_SolutionsLibrary
              </a>
            </div>
            {oneSolution?.wwLink && (
              <div>
                <a href={oneSolution?.wwLink} target="_blank" rel="noopener noreferrer">
                  WW_SolutionsLibrary
                </a>
              </div>
            )}
          </div>
          <div>
            <Box variant="awsui-key-label">{t("solutions:SolutionDetails.summary.sourceCode")}</Box>
            <div>
              <a href={oneSolution?.sourceCode} target="_blank" rel="noopener noreferrer">
                {oneSolution?.sourceCode?.split("/").slice(4).join("/")}
              </a>
            </div>
          </div>
          {oneSolution?.demo && (
            <div>
              <Box variant="awsui-key-label">
                {t("solutions:SolutionDetails.summary.demo")}{" "}
                <a href={oneSolution?.demo} target="_blank" rel="noopener noreferrer">
                  Go!
                </a>
              </Box>
            </div>
          )}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
};

export default SolutionSummary;
