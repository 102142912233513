import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, ContentLayout, SpaceBetween, Tabs } from "@cloudscape-design/components";
import CustomAppLayout from "../../components/Layout/CustomAppLayout";
import { Breadcrumb, LoadingSpinner, Navigation, Notification } from "../../components";
import ToolsContent from "./sub-components/ToolsContent";
import PageHeader from "./sub-components/PageHeader";
import ModernAppCourse from "./sub-components/modern-app-course";
import { RegistrationFormContent } from "./sub-components/registration";
import { analyticsLogger } from "../../common/utils/analyticsLogger";

const BuildingClassStarting = () => {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.BuildingClass"),
      href: "/building-class",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const tabs = [
    {
      label: t("BuildingClass.course"),
      id: "course",
      content: <ModernAppCourse loadHelpPanelContent={loadHelpPanelContent} />,
    },
    {
      label: t("BuildingClass.registration"),
      id: "registration",
      content: <RegistrationFormContent loadHelpPanelContent={loadHelpPanelContent} />,
    },
  ];

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      content={
        <ContentLayout disableOverlap header={<PageHeader />}>
          <SpaceBetween size="l">
            <Tabs tabs={tabs} onChange={(tab)=>{
              analyticsLogger.log('building_class_tab', {
                tabId: tab.detail.activeTabId,
              })
            }}/>
          </SpaceBetween>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/building-class" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
};

export default BuildingClassStarting;
