import React from "react";
import { Spinner, SpinnerProps } from "@cloudscape-design/components";

interface LoadingSpinnerProps {
  size?: SpinnerProps.Size;
  variant?: SpinnerProps.Variant;
  text?: string;
  alignment?: "center" | "top";
}

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = "normal", variant = "normal", text = "Loading...", alignment = "top" }) => {
  return (
    <div style={{ display: "flex", alignItems: alignment === "top" ? "flex-start" : "center", justifyContent: "center", height: "100vh", margin: "0.5em" }}>
      <Spinner size={size} variant={variant} />
      <span style={{ marginLeft: "0.5em" }}>{text}</span>
    </div>
  );
};

export default LoadingSpinner;
