import { solutionNameOptions_en, solutionNameOptions_cn } from "./formOptions";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

function generateSolutionNameDictionary(enOptions, cnOptions) {
  const dictionary = {};

  enOptions.forEach((enOption) => {
    const enLabel = enOption.label;
    const value = enOption.value;

    const cnOption = cnOptions.find((option) => option.value === value);
    const cnLabel = cnOption.label;

    dictionary[enLabel] = cnLabel;
    dictionary[cnLabel] = enLabel;
  });

  return dictionary;
}

export const solutionNameDictionary = generateSolutionNameDictionary(solutionNameOptions_en, solutionNameOptions_cn);

// the result of solutionNameDictionary will look like:
/*
const solutionNameDictionary = {
  "Video on Demand on AWS": "视频点播解决方案",
  "视频点播解决方案": "Video on Demand on AWS",
};
 */

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
const originalCategoryDictionary = [
  {
    en: "Technology",
    cn: "技术",
  },
  {
    en: "Industry",
    cn: "行业",
  },
  {
    en: "Cross-Industry",
    cn: "跨行业",
  },
];

export const categoryDictionary = originalCategoryDictionary.reduce((acc, item) => {
  acc[item.en] = item.cn;
  acc[item.cn] = item.en;
  return acc;
}, {});

// the result of categoryDictionary will look like:
/*
const categoryDictionary = {
  "Technology": "技术",
  "Industry": "行业",
  "Cross-Industry": "跨行业",
  "技术": "Technology",
  "行业": "Industry",
  "跨行业": "Cross-Industry",
};

 */

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/

const originalDomainDictionary = [
  {
    en: "Analytics",
    cn: "数据分析",
  },
  {
    en: "App Development & DevOps",
    cn: "应用开发",
  },
  {
    en: "Blockchain",
    cn: "区块链",
  },
  {
    en: "Cloud Foundations",
    cn: "云基础",
  },
  {
    en: "Cloud Operations",
    cn: "云运营",
  },
  {
    en: "Compute",
    cn: "计算",
  },
  {
    en: "CDN",
    cn: "内容分发网络",
  },
  {
    en: "Database",
    cn: "数据库",
  },
  {
    en: "Digital Workplace",
    cn: "数字工作场所",
  },
  {
    en: "ERP",
    cn: "企业资源计划",
  },
  {
    en: "IoT",
    cn: "物联网",
  },
  {
    en: "AI/ML",
    cn: "人工智能",
  },
  {
    en: "Migration & Modernization",
    cn: "迁移和现代化",
  },
  {
    en: "Networking",
    cn: "网络",
  },
  {
    en: "Resilience",
    cn: "韧性",
  },
  {
    en: "Security & Compliance",
    cn: "安全合规",
  },
  {
    en: "Spatial Computing",
    cn: "空间计算",
  },
  {
    en: "Storage",
    cn: "存储",
  },
  {
    en: "Advertising & Marketing",
    cn: "广告营销",
  },
  {
    en: "Aerospace & Satellite",
    cn: "航天与卫星",
  },
  {
    en: "Agriculture",
    cn: "农业",
  },
  {
    en: "Automotive",
    cn: "汽车",
  },
  {
    en: "Consumer Package Goods",
    cn: "消费品",
  },
  {
    en: "Education",
    cn: "教育",
  },
  {
    en: "Energy & Utilities",
    cn: "能源与公用事业",
  },
  {
    en: "Engineering, Construction & Real Estate",
    cn: "工程、建筑与房地产",
  },
  {
    en: "Financial Services",
    cn: "金融服务",
  },
  {
    en: "Game Tech",
    cn: "游戏",
  },
  {
    en: "Healthcare, Life Science, and Genomics",
    cn: "医疗保健、生命科学和基因组学",
  },
  {
    en: "Hi-Tech Electronics & Semiconductor",
    cn: "高科技电子与半导体",
  },
  {
    en: "Manufacturing & Industrial",
    cn: "制造与工业",
  },
  {
    en: "Media & Entertainment",
    cn: "媒体娱乐",
  },
  {
    en: "Mining & Minerals",
    cn: "采矿与矿产",
  },
  {
    en: "Nonprofits",
    cn: "非营利组织",
  },
  {
    en: "Retail",
    cn: "零售",
  },
  {
    en: "Telecommunications",
    cn: "电信",
  },
  {
    en: "Travel & Hospitality",
    cn: "旅游与酒店业",
  },
  {
    en: "Customer Experience",
    cn: "顾客体验",
  },
  {
    en: "Finance",
    cn: "财务",
  },
  {
    en: "Human Resources",
    cn: "人力资源",
  },
  {
    en: "Intelligent Automation",
    cn: "智能自动化",
  },
  {
    en: "Supply Chain",
    cn: "供应链",
  },
  {
    en: "Sustainability",
    cn: "可持续发展",
  },
];

export const domainDictionary = originalDomainDictionary.reduce((acc, item) => {
  acc[item.en] = item.cn;
  acc[item.cn] = item.en;
  return acc;
}, {});
