import React from "react";
import { Button } from "@cloudscape-design/components";
import { getFileUrl } from "../../common/utils";

function downloadFile(url, filename) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(blobUrl);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
}

export function DownloadButton({ url, filename, variant, iconName, iconAlign, children }) {
  const cleanFilename = filename.replace(/[^a-zA-Z0-9]/g, "_"); // 把名字中出了字母和数字的其他任何字符全部用下划线替代，避免出错。
  const fileName = cleanFilename ? `${cleanFilename}.${url.split("/").pop().split(".").pop()}` : url.split("/").pop(); // 从URL中提取文件名，可根据实际情况调整

  return (
    <Button
      iconName={iconName || "download"}
      iconAlign={iconAlign || "left"}
      variant={variant || "normal"}
      onClick={() => {
        const fileUrl = getFileUrl(url);
        downloadFile(fileUrl, fileName);
      }}
    >
      {children}
    </Button>
  );
}

export default DownloadButton;
