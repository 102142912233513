export function getFileExtension(fileName) {
  if (fileName === "") {
    return "";
  }
  const lastDotIndex = fileName.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return ""; // 如果文件名中没有点，则认为它没有扩展名
  }
  return fileName.slice(lastDotIndex + 1);
}

// const fileName1 = 'index.html';
// const fileName2 = 'app.ts';
// const fileName3 = 'README';

// console.log(getFileExtension(fileName1)); // 输出 'html'
// console.log(getFileExtension(fileName2)); // 输出 'ts'
// console.log(getFileExtension(fileName3)); // 输出 ''
// 在这个示例中，我们定义了一个名为getFileExtension的函数，它接受一个文件名作为参数，并返回该文件的扩展名。
// 首先，我们使用lastIndexOf函数找到文件名中最后一个.字符的索引。如果文件名中没有.，则lastIndexOf返回-1，
// 我们将扩展名设置为空字符串。否则，我们使用slice函数从文件名中提取.后面的部分，并返回它作为扩展名。
