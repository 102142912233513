// 一套专门处理嵌套数组的函数。这里是一套基于索引（index）的 addFormNested, removeFormNested, updateFormNested, 和 moveFormNested 函数

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const addFormNested = (setData, fieldPath, fields) => {
  setData((prevState) => {
    const pathArray = fieldPath.split("."); // ["roadmap", "releaseHistory"]
    const newState = JSON.parse(JSON.stringify(prevState));

    console.log("%cpathArray", "color: blue; font-weight: bold;", pathArray);

    // 最后，targetObj = data.roadmap.releaseHistory，下面代码兼容嵌套数组
    const targetObj = pathArray.reduce((acc, key, index) => {
      if (key.includes("[")) {
        const [arrayKey, arrayIndex] = key.split(/[[\]]/).filter((str) => str);
        if (!acc[arrayKey]) {
          acc[arrayKey] = [];
        }
        if (!acc[arrayKey][parseInt(arrayIndex)]) {
          acc[arrayKey][parseInt(arrayIndex)] = {};
        }
        return acc[arrayKey][parseInt(arrayIndex)];
      } else {
        if (!acc[key]) {
          if (index === pathArray.length - 1) {
            acc[key] = [];
          } else {
            acc[key] = {};
          }
        }
        return acc[key];
      }
    }, newState);

    console.log("%ctargetObj", "color: blue; font-weight: bold;", targetObj);

    // fields是用于初始化的一个元素
    const newItem = { ...fields };
    targetObj.push(newItem);

    return newState;
  });
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const removeFormNested = (setData, fieldPath, index) => {
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const newState = JSON.parse(JSON.stringify(prevState));

    const targetArray = pathArray.reduce((acc, key) => {
      if (key.includes("[")) {
        const [arrayKey, index] = key.split(/[[\]]/).filter((str) => str);
        return acc[arrayKey][parseInt(index)];
      }
      return acc[key];
    }, newState);

    if (index > -1 && index < targetArray.length) {
      targetArray.splice(index, 1);
    }

    return newState;
  });
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const updateFormNested = (setData, fieldPath, updatedField, value, lastIndex) => {
  // 这个函数接受的fieldPath一定是一个array对象，而updateField一定是一个可以修改的最终属性，例如字符串。
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const newState = JSON.parse(JSON.stringify(prevState));

    console.log("%cpathArray", "color: blue; font-weight: bold;", pathArray);

    // 执行下面的reduce代码是希望得到tagetObj=formattedData.fieldpath的值，例如roadmap.releaseHistory它应该是一个Array
    const targetObj = pathArray.reduce((acc, key) => {
      if (key.includes("[")) {
        const [arrayKey, index] = key.split(/[[\]]/).filter((str) => str);
        return acc[arrayKey][parseInt(index)];
      }
      return acc[key];
    }, newState);

    /**
     * newState 是通过对 prevState 进行深拷贝（JSON.parse(JSON.stringify(prevState))）得到的。
     * 这样做是为了避免直接修改原始状态，从而遵循 React 状态更新的不可变性原则。
     * targetObj 是在 newState 结构中找到的一个子对象。通过使用 reduce 方法，我们可以遍历 pathArray，找到正确的子对象。
     * 因为 targetObj 是 newState 的一个子对象，所以对 targetObj 进行的任何更改都会影响 newState。最终return newState。
     */

    console.log("%ctargetObj", "color: blue; font-weight: bold;", targetObj);

    targetObj[lastIndex] = { ...targetObj[lastIndex], [updatedField]: value };

    return newState;
  });
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const moveFormNested = (setData, fieldPath, direction, index) => {
  setData((prevState) => {
    const pathArray = fieldPath.split(".");
    const newState = JSON.parse(JSON.stringify(prevState));

    const targetArray = pathArray.reduce((acc, key) => {
      if (key.includes("[")) {
        const [arrayKey, index] = key.split(/[[\]]/).filter((str) => str);
        return acc[arrayKey][parseInt(index)];
      }
      return acc[key];
    }, newState);

    if ((direction === "up" && index === 0) || (direction === "down" && index === targetArray.length - 1)) {
      // Do nothing if trying to move the first element up or the last element down, 设定边界
      return prevState;
    }

    const newIndex = direction === "up" ? index - 1 : index + 1;
    const temp = targetArray[newIndex];
    targetArray[newIndex] = targetArray[index];
    targetArray[index] = temp;

    return newState;
  });
};
