import { Badge, Link, StatusIndicator } from "@cloudscape-design/components";
import { getFileUrl, generateRandomId } from "../../common/utils";
import paletteConfig from "../../config/aws-palette-config.json";
import { analyticsLogger } from "../../common/utils/analyticsLogger";

const DEBUG = paletteConfig.debugMode;

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createCardDefinitions = (t, navigate, contentLanguage) => {
  DEBUG && console.log("[Dev] contentLanguage", contentLanguage);
  DEBUG && console.log(`[Dev] solutionName_${contentLanguage}`);
  return {
    header: (item) => (
      // 在<Link>里可以使用href={`/solutions/details?solutionNameAbbr=${item.solutionNameAbbr}来跳转到details网页，但是性能不好，有延迟。
      // 这里我们使用navigate来实现跳转，用法和./sub-components/FullPageHeader里面的用法是一样的，只是这里用onFollow来实现。
      <div key={item.solutionNameAbbr && item.customerNameAbbr ? `${item.solutionNameAbbr}-${item.customerNameAbbr}` : generateRandomId()}>
        <Link
          fontSize="heading-m"
          onFollow={(e) => {
            e.preventDefault();
            analyticsLogger.log('case_studies_click', {
              solutionName: item[`solutionName_${contentLanguage}`],
              solutionNameAbbr: item.solutionNameAbbr,
              customerName: item[`customerName_${contentLanguage}`],
              customerNameAbbr: item.customerNameAbbr,
              category: item[`category_${contentLanguage}`]?.join(", "),
              domain: item[`domain_${contentLanguage}`]?.join(", "),
              publishReference: item.publicReference,
              year: item.year,
              isPublished: item.isPublished,
            })
            navigate(`/casestudies/details?solutionNameAbbr=${item.solutionNameAbbr}&customerNameAbbr=${item.customerNameAbbr}`);
          }}
        >
          <div className="text-ellipsis">{item["solutionName_" + contentLanguage] + " | " + item["customerNameAbbr"]}</div>
        </Link>
      </div>
    ),
    sections: [
      {
        id: "customerLogo",
        // header: t("casestudies:CollectionPreferences.optionsLable.customerLogo"),
        content: (item) => {
          return (
            <div
              style={{
                textAlign: "center",
                // padding: "20px",
                backgroundPosition: "center",
                backgroundSize: "cover", // option is 'contain' or 'cover'
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${getFileUrl(item.customerLogo)})`,
                width: "100%",
                height: "70%", // 设置固定高度，新增的
                overflow: "hidden", // 隐藏超出容器范围的内容，新增的
                paddingBottom: "70%",
              }}
            />
          );
        },
      },
      {
        id: "customerName",
        header: t("casestudies:CollectionPreferences.optionsLable.customerName"),
        content: (item) => item[`customerName_${contentLanguage}`],
      },
      {
        id: "customerNameAbbr",
        header: t("casestudies:CollectionPreferences.optionsLable.customerNameAbbr"),
        content: (item) => item.customerNameAbbr,
      },
      {
        id: "solutionName",
        header: t("casestudies:CollectionPreferences.optionsLable.solutionName"),
        content: (item) => item[`solutionName_${contentLanguage}`],
      },
      {
        id: "solutionNameAbbr",
        header: t("casestudies:CollectionPreferences.optionsLable.solutionNameAbbr"),
        content: (item) => item.solutionNameAbbr,
      },
      {
        id: "category",
        header: t("casestudies:CollectionPreferences.optionsLable.category"),
        content: (item) => item[`category_${contentLanguage}`]?.join(", "),
      },
      {
        id: "domain",
        header: t("casestudies:CollectionPreferences.optionsLable.domain"),
        content: (item) => item[`domain_${contentLanguage}`]?.join(", "),
      },
      {
        id: "publicReference",
        header: t("casestudies:CollectionPreferences.optionsLable.publicReference"),
        content: (item) => <StatusIndicator type={item.publicReference ? "success" : "error"}>{item.publicReference ? "Yes" : "No"}</StatusIndicator>,
      },
      {
        id: "salesSegment",
        header: t("casestudies:CollectionPreferences.optionsLable.salesSegment"),
        content: (item) => item.salesSegment,
      },
      {
        id: "year",
        header: t("casestudies:CollectionPreferences.optionsLable.year"),
        content: (item) => item.year,
      },
      {
        id: "isPublished",
        header: t("casestudies:CollectionPreferences.optionsLable.isPublished"),
        content: (item) =>
          item.isPublished ? (
            <Badge color="green">{t("casestudies:CollectionPreferences.alreadyPublished")}</Badge>
          ) : (
            <Badge>{t("casestudies:CollectionPreferences.notPublished")}</Badge>
          ),
      },
    ],
  };
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:CollectionPreferences.optionsLable.topLabel"),
    /**
     * id需要和CARD_DEFINITIONS.sections里面的id保持一致，这样才能关联起来。
     * lable就是点选了Preference设置的弹出框后，在里面显示的内容。
     */
    options: [
      { id: "customerLogo", label: t("casestudies:CollectionPreferences.optionsLable.customerLogo") },
      { id: "customerName", label: t("casestudies:CollectionPreferences.optionsLable.customerName") },
      { id: "customerNameAbbr", label: t("casestudies:CollectionPreferences.optionsLable.customerNameAbbr") },
      { id: "solutionName", label: t("casestudies:CollectionPreferences.optionsLable.solutionName") },
      { id: "solutionNameAbbr", label: t("casestudies:CollectionPreferences.optionsLable.solutionNameAbbr") },
      { id: "category", label: t("casestudies:CollectionPreferences.optionsLable.category") },
      { id: "domain", label: t("casestudies:CollectionPreferences.optionsLable.domain") },
      { id: "publicReference", label: t("casestudies:CollectionPreferences.optionsLable.publicReference") },
      { id: "salesSegment", label: t("casestudies:CollectionPreferences.optionsLable.salesSegment") },
      { id: "year", label: t("casestudies:CollectionPreferences.optionsLable.year") },
      { id: "isPublished", label: t("casestudies:CollectionPreferences.optionsLable.isPublished") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 10,
  visibleContent: ["customerLogo", "customerName", "category", "domain", "publicReference", "salesSegment", "year", "isPublished"],
});
