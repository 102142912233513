import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Header, SpaceBetween, Button } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import { LoadingSpinner, useUserProfile } from "../../../components";

export const PageHeader = ({ headerTitle, solutionNameAbbr }) => {
  const { t } = useTranslation();
  const { contentLanguage, setContentLanguage, isAdmin } = useUserProfile();
  const navigate = useNavigate();

  function editSolutionHandler() {
    navigate(`/solutions/edit?solutionNameAbbr=${solutionNameAbbr}`);
  }

  const goBackToSolutionsPageHandler = () => {
    navigate(-1);
  };

  const changeContentLanguage = () => {
    setContentLanguage(contentLanguage === "en" ? "cn" : "en");
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <Header
      variant="h1"
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button data-testid="header-btn-go-back" onClick={changeContentLanguage}>
            {contentLanguage === "en" ? t("PageHeader.buttonChangeLanguageCN") : t("PageHeader.buttonChangeLanguage")}
          </Button>
          <Button data-testid="header-btn-go-back" onClick={goBackToSolutionsPageHandler}>
            {t("PageHeader.buttonGoBack")}
          </Button>
          {isAdmin && (
            <Button data-testid="header-btn-edit" disabled={!isAdmin} onClick={editSolutionHandler}>
              {t("PageHeader.buttonEdit")}
            </Button>
          )}
        </SpaceBetween>
      }
    >
      {headerTitle}
    </Header>
  );
};

export default PageHeader;
