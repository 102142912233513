import { ContentLayout, Grid, Box } from "@cloudscape-design/components";
import HomeHeader from "./sub-components/HomeHeader";
import { useState, useRef } from "react";
import ToolsContent from "./sub-components/ToolsContent";
import { CustomAppLayout, Navigation, Notification } from "../../components";
import LeftSide from "./sub-components/left-side-definition";
import RightSide from "./sub-components/right-side-definition";

const Content = () => {
  return (
    <Grid gridDefinition={[{ colspan: { l: 8, m: 8, default: 12 } }, { colspan: { l: 4, m: 4, default: 12 } }]}>
      <LeftSide />
      <RightSide />
    </Grid>
  );
};

const HomeStarting = () => {
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(false);
  const appLayout = useRef();

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  /*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
  return (
    <CustomAppLayout
      ref={appLayout}
      content={
        <ContentLayout
          disableOverlap
          header={
            <div className="home-top-bg">
              <HomeHeader loadHelpPanelContent={loadHelpPanelContent} />
            </div>
          }
        >
          <Box variant="div" margin={{ top: "l" }}>
            <Content />
          </Box>
        </ContentLayout>
      }
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => {
        setToolsOpen(detail.open);
      }}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
};

export default HomeStarting;
