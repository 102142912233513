import { Button, Container, DatePicker, FormField, Header, Input, SpaceBetween, Textarea, Toggle } from "@cloudscape-design/components";
import { useUserProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { addFormNested, removeFormNested, updateFormNested, moveFormNested } from "../../../common/utils";

/*****************************************************************************/
export default function RoadmapPanel({ formattedData, setFormattedData }) {
  const { contentLanguage } = useUserProfile();
  const { t } = useTranslation();

  return (
    <>
      <SpaceBetween size="l">
        {/* The 1st container: Basic Information ++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("solutions:SolutionForm.containerBasicInformation")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`solutions:SolutionForm.solutionName`)} description={t(`solutions:SolutionForm.solutionName_Des`)}>
              <Input value={formattedData["solutionName_" + contentLanguage]} readOnly />
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 2nd container: Roadmap ReleaseHistory ++++++++++++++++++++++++++++++++++++++++++++++ */}
        <>
          {(formattedData.roadmap?.releaseHistory || []).map((release, releaseIndex) => (
            <div key={releaseIndex}>
              <Container
                header={
                  <Header variant="h2" description={`Release ${releaseIndex + 1}`}>
                    {t("solutions:SolutionForm.containerReleaseInHistory")}
                  </Header>
                }
              >
                <SpaceBetween size="l">
                  <SpaceBetween size="l" direction="horizontal">
                    <FormField label={t(`solutions:SolutionForm.version`)} description={t(`solutions:SolutionForm.version_Des`)}>
                      <Input
                        value={release.version || ""}
                        placeholder={t(`solutions:SolutionForm.version_Placeholder`)}
                        onChange={({ detail: { value } }) => {
                          console.log("%cprimaryIndex", "color: blue; font-weight: bold;", releaseIndex);
                          console.log("%cvalue", "color: blue; font-weight: bold;", value);
                          updateFormNested(setFormattedData, `roadmap.releaseHistory`, "version", value, releaseIndex);
                        }}
                      />
                    </FormField>
                    <FormField
                      label={t(`solutions:SolutionForm.releaseDate`)}
                      description={t(`solutions:SolutionForm.releaseDate_Des`)}
                      constraintText={t(`solutions:SolutionForm.releaseDate_ConstrainText`)}
                    >
                      <DatePicker
                        placeholder="YYYY/MM/DD"
                        value={release.releaseDate}
                        onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `roadmap.releaseHistory`, "releaseDate", value, releaseIndex)}
                      />
                    </FormField>
                  </SpaceBetween>
                  {/* Add this loop for rendering features_en and features_cn */}
                  {(release["features_" + contentLanguage] || []).map((feature, featureIndex) => (
                    <div key={featureIndex}>
                      <SpaceBetween size="l" direction="horizontal">
                        <FormField label={t(`solutions:SolutionForm.roadmapFeaturesTitle`)} description={t(`solutions:SolutionForm.roadmapFeaturesTitle_Des`)}>
                          <Input
                            value={feature.title || ""}
                            placeholder={t(`solutions:SolutionForm.roadmapFeaturesTitle_Placeholder`)}
                            // 对于嵌套的数组，我们应该始终使用索引而不是ID。在之前的例子中，我们处理的是非嵌套的顶层数组，所以使用ID是合适的。但是，在这个例子中，我们处理的是嵌套的数组，所以我们应该使用索引。
                            onChange={({ detail: { value } }) =>
                              updateFormNested(
                                setFormattedData,
                                `roadmap.releaseHistory[${releaseIndex}].features_${contentLanguage}`,
                                "title",
                                value,
                                featureIndex
                              )
                            }
                          />
                        </FormField>
                        <FormField
                          label={t(`solutions:SolutionForm.roadmapFeaturesContent`)}
                          description={t(`solutions:SolutionForm.roadmapFeaturesContent_Des`)}
                        >
                          <Textarea
                            value={feature.content || ""}
                            placeholder={t(`solutions:SolutionForm.roadmapFeaturesContent_Placeholder`)}
                            // 对于嵌套的数组，我们应该始终使用索引而不是ID。在之前的例子中，我们处理的是非嵌套的顶层数组，所以使用ID是合适的。但是，在这个例子中，我们处理的是嵌套的数组，所以我们应该使用索引。
                            onChange={({ detail: { value } }) =>
                              updateFormNested(
                                setFormattedData,
                                `roadmap.releaseHistory[${releaseIndex}].features_${contentLanguage}`,
                                "content",
                                value,
                                featureIndex
                              )
                            }
                          />
                        </FormField>
                        <FormField
                          label={t(`solutions:SolutionForm.roadmapFeaturesActive`)}
                          description={t(`solutions:SolutionForm.roadmapFeaturesActive_Des`)}
                        >
                          <Toggle
                            onChange={({ detail: { checked } }) => {
                              updateFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_en`, "active", checked, featureIndex);
                              updateFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_cn`, "active", checked, featureIndex);
                            }}
                            checked={feature.active || false}
                          />
                        </FormField>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des_Short`)}>
                            <SpaceBetween size="s" direction="horizontal">
                              <Button
                                iconName="angle-up"
                                onClick={(event) => {
                                  event.preventDefault();
                                  moveFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_en`, "up", featureIndex);
                                  moveFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_cn`, "up", featureIndex);
                                }}
                              />
                              <Button
                                iconName="angle-down"
                                onClick={(event) => {
                                  event.preventDefault();
                                  moveFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_en`, "down", featureIndex);
                                  moveFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_cn`, "down", featureIndex);
                                }}
                              />
                              <Button
                                iconName="close"
                                onClick={(event) => {
                                  event.preventDefault();
                                  removeFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_en`, featureIndex);
                                  removeFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_cn`, featureIndex);
                                }}
                              ></Button>
                            </SpaceBetween>
                          </FormField>
                        </div>
                      </SpaceBetween>
                    </div>
                  ))}
                  {/* Add this button for adding new features */}
                  <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des`)}>
                    <SpaceBetween size="s" direction="horizontal">
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          const randomId = uuidv4();
                          addFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_en`, {
                            title: "",
                            content: "",
                            active: true,
                            like: [],
                            id: randomId,
                          });
                          addFormNested(setFormattedData, `roadmap.releaseHistory[${releaseIndex}].features_cn`, {
                            title: "",
                            content: "",
                            active: true,
                            like: [],
                            id: randomId,
                          });
                        }}
                      >
                        {t(`solutions:SolutionEditForm.addFeaturesButton`)}
                      </Button>
                      <Button
                        iconName="angle-up"
                        onClick={(event) => {
                          event.preventDefault();
                          moveFormNested(setFormattedData, `roadmap.releaseHistory`, "up", releaseIndex);
                        }}
                      />
                      <Button
                        iconName="angle-down"
                        onClick={(event) => {
                          event.preventDefault();
                          moveFormNested(setFormattedData, `roadmap.releaseHistory`, "down", releaseIndex);
                        }}
                      />
                      <Button
                        onClick={(event) => {
                          event.preventDefault();
                          removeFormNested(setFormattedData, `roadmap.releaseHistory`, releaseIndex);
                        }}
                      >
                        {t(`FormButton.removeReleaseButton`)}
                      </Button>
                    </SpaceBetween>
                  </FormField>
                </SpaceBetween>
              </Container>
            </div>
          ))}
          <Button
            onClick={(event) => {
              event.preventDefault();
              const randomId = uuidv4();
              addFormNested(setFormattedData, `roadmap.releaseHistory`, {
                version: "",
                releaseDate: "",
                features_en: [],
                features_cn: [],
                id: randomId,
              });
            }}
          >
            {t(`solutions:SolutionEditForm.addReleaseButton`)}
          </Button>
        </>
      </SpaceBetween>
    </>
  );
}
