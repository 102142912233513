import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Cards, CollectionPreferences, Pagination, TextFilter } from "@cloudscape-design/components";
import { Breadcrumb, CustomAppLayout, Navigation, Notification, useSolutions, useUserProfile } from "../../components";
import { solutionSelectionLabels, paginationLabels } from "../../common/labels";
import { getFilterCounterText } from "../../common/tableCounterStrings";
import { TableEmptyState, TableNoMatchState } from "./sub-components/table-empty-nomatch-state";
import { useLocalStorage } from "../pages-common/use-local-storage";
import PageHeader from "./sub-components/PageHeader";
import ToolsContent from "./sub-components/ToolsContent";
import { createCardDefinitions, createVisibleContentOptions, createPageSizeOptions, createDefaultPreferences } from "./cards-config";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
function DetailsCards({ loadHelpPanelContent }) {
  const { t } = useTranslation();
  const { solutionsList, fetched } = useSolutions();
  const { uiLanguage, contentLanguage, preferencesFetched } = useUserProfile();
  const [loading, setLoading] = useState(false); //因为App组件在渲染的时候，已经取到了所有的Context，所以任意子组件无需使用Loading来等待Context就绪。
  const navigate = useNavigate();

  const CARD_DEFINITIONS = createCardDefinitions(t, navigate, contentLanguage);
  const VISIBLE_CONTENT_OPTIONS = createVisibleContentOptions(t);
  const PAGE_SIZE_OPTIONS = createPageSizeOptions(t);
  const DEFAULT_PREFERENCES = createDefaultPreferences();

  /**
   * Load value of perferences from LocalStorage with Key="React-Cards-Preferences". If no value in the LocalStorage,
   * then set the preferences with DEFAULT_PREFERENCES, and store DEFAULT_PREFERENCES to the LocalStorage.
   */
  const [preferences, setPreferences] = useLocalStorage("React-Cards-Solutions-Preferences", DEFAULT_PREFERENCES);
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(solutionsList, {
    filtering: {
      empty: <TableEmptyState resourceName={t("solutions:filteringResourceName")} />, // "soluitions"
      noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering("")} />,
    },
    pagination: { pageSize: preferences.pageSize },
    selection: {},
  });

  return (
    <Cards
      {...collectionProps}
      stickyHeader={false}
      cardDefinition={CARD_DEFINITIONS}
      visibleSections={preferences.visibleContent}
      loading={loading}
      loadingText={t("common:loadingTextSolutions")} /* the String that will be shown behind the Spinner */
      items={items}
      selectionType="multi"
      variant="full-page"
      ariaLabels={solutionSelectionLabels}
      header={<PageHeader selectedItems={collectionProps.selectedItems} totalItems={solutionsList} loadHelpPanelContent={loadHelpPanelContent} />}
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel="Filter solutions"
          filteringPlaceholder={t("solutions:useCollection.findSolutions")}
          countText={getFilterCounterText(filteredItemsCount)}
          disabled={loading}
        />
      }
      pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} disabled={loading} />}
      preferences={
        <CollectionPreferences
          title={t("solutions:CollectionPreferences.title")}
          confirmLabel={t("solutions:CollectionPreferences.confirmOk")}
          cancelLabel={t("solutions:CollectionPreferences.cancel")}
          disabled={loading}
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          pageSizePreference={{
            title: t("solutions:CollectionPreferences.pageSize"),
            options: PAGE_SIZE_OPTIONS,
          }}
          visibleContentPreference={{
            title: t("solutions:CollectionPreferences.selectVisibleColumns"),
            options: VISIBLE_CONTENT_OPTIONS,
          }}
        />
      }
    />
  );
}

/*****************************************************************************/
function SolutionStarting() {
  const { t } = useTranslation();
  const [toolsOpen, setToolsOpen] = useState(false);
  const [navigationOpen, setNavigationOpen] = useState(true);
  const appLayout = useRef();

  const breadcrumbItems = [
    {
      text: t("breadCrumb.root"),
      href: "/",
    },
    {
      text: t("breadCrumb.Solutions"),
      href: "/solutions",
    },
  ];

  const loadHelpPanelContent = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  useEffect(() => {
    localStorage.removeItem("lastSelectedSolutionsDetailsTab");
  }, []);

  return (
    <CustomAppLayout
      ref={appLayout}
      breadcrumbs={<Breadcrumb items={breadcrumbItems} />}
      contentType="cards"
      content={<DetailsCards loadHelpPanelContent={loadHelpPanelContent} />}
      headerSelector="#header"
      /* The following 3 lines control the Navigation bar on the left side of the screen */
      navigation={<Navigation activeHref="/solutions" />}
      navigationOpen={navigationOpen}
      onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      /* The following 3 lines control the InfoTool bar on the righ side of the screen */
      tools={<ToolsContent />}
      toolsOpen={toolsOpen}
      onToolsChange={({ detail }) => setToolsOpen(detail.open)}
      /* stickyNotifications={true}: When you scroll the screen, the Notification component will remain in a fixed position on the screen */
      stickyNotifications={true}
      notifications={<Notification />}
    />
  );
}

export default SolutionStarting;
