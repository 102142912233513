/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
/* 测试发现在第一列写的sortingComparator会默认为整个table的排序函数，所以如果其他列也是类似的排序逻辑，就不要再写 */

const fullnameSortingComparator = (item1, item2) => {
  if (!item1 || !item2) {
    return 0; // or whichever value you deem appropriate in this case
  }

  const name1 = item1.fullName || "";
  const name2 = item2.fullName || "";

  return name1.localeCompare(name2);
};

const loginSortingComparator = (item1, item2) => {
  if (!item1 || !item2) {
    return 0; // or whichever value you deem appropriate in this case
  }

  const name1 = item1.login || "";
  const name2 = item2.login || "";

  return name1.localeCompare(name2);
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createColumnDefinitions = (t) => {
  return [
    {
      id: "fullname",
      sortingField: "fullname",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.userFullName"),
      sortingComparator: fullnameSortingComparator,
      cell: (item) => item.fullName,
    },
    {
      id: "login",
      sortingField: "login",
      minWidth: 90,
      header: t("solutions:SolutionDetails.tabs-content.userLogin"),
      sortingComparator: loginSortingComparator,
      cell: (item) => item.login,
    },
    {
      id: "category",
      sortingField: "category",
      minWidth: 140,
      header: t("solutions:SolutionDetails.tabs-content.category"),
      cell: (item) => item.category,
    },
    {
      id: "count",
      sortingField: "count",
      minWidth: 300,
      header: t("solutions:SolutionDetails.tabs-content.count"),
      cell: (item) => item.count,
    },
  ];
};

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createVisibleContentOptions = (t) => [
  {
    label: t("solutions:SolutionDetails.tabs-content.visibleContentConfigLabel"),
    options: [
      { id: "fullname", label: t("solutions:SolutionDetails.tabs-content.userFullName") },
      { id: "login", label: t("solutions:SolutionDetails.tabs-content.userLogin") },
      { id: "category", label: t("solutions:SolutionDetails.tabs-content.category") },
      { id: "count", label: t("solutions:SolutionDetails.tabs-content.count") },
    ],
  },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createPageSizeOptions = (t) => [
  { value: 10, label: t("solutions:CollectionPreferences.pageSizeOption.tenItems") },
  { value: 20, label: t("solutions:CollectionPreferences.pageSizeOption.twentyItems") },
  { value: 30, label: t("solutions:CollectionPreferences.pageSizeOption.thirtyItems") },
];

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createDefaultPreferences = () => ({
  pageSize: 10,
  visibleContent: ["fullname", "login", "category", "count"],
  wrapLines: false,
  stripedRows: false,
});
