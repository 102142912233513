import { Grid, Header } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import Overview from "./left-1-overview";
import Banners from "./left-2-banners";
import CoreFeatureItem from "./left-3-core-features";
import LatestUpdateItem from "./right-2-other-updates";

import CORE_IMG_1 from "../../../styles/images/core1.webp";
import CORE_IMG_2 from "../../../styles/images/core2.webp";
import CORE_IMG_3 from "../../../styles/images/core3.webp";
import CORE_IMG_4 from "../../../styles/images/core4.webp";

const LeftSide = () => {
  const { t } = useTranslation();
  const CORE_FEATURE_ITEMS = [
    { type: "image", imageUrl: CORE_IMG_1 },
    {
      type: "text",
      name: t("Home.core1-explore-solution-readiness") || "Explore Solution Readiness",
      description: t("Home.core1-description") || "",
    },
    {
      type: "text",
      name: t("Home.core2-feedback-for-solutions") || "Feedback for Solutions",
      description: t("Home.core2-description") || "",
    },
    { type: "image", imageUrl: CORE_IMG_2 },
    { type: "image", imageUrl: CORE_IMG_3 },
    {
      type: "text",
      name: t("Home.core3-showcase-your-wins") || "Showcase Your Wins",
      description: t("Home.core3-description") || "",
    },
    {
      type: "text",
      name: t("Home.core4-join-our-building-class") || "Join Our Building Class",
      description: t("Home.core4-description") || "",
    },
    { type: "image", imageUrl: CORE_IMG_4 },
  ];

  const TWO_VIDEOS = [
    {
      isVideo: true,
      videoUrl: "https://broadcast.amazon.com/embed/461588",
      videoType: "broadcast",
      title_en: "Learn about Solutions-Led Selling",
      title_cn: "了解解决方案引导的销售",
    },
    {
      isVideo: true,
      videoUrl: "https://www.youtube.com/embed/dBzCGcwYCJo",
      videoType: "youtube",
      title_en: "Hello World: Meet Generative AI",
      title_cn: "遇见生成式人工智能",
    },
  ];

  return (
    <Grid
      gridDefinition={[
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
        { colspan: { l: 12, m: 12, default: 12 } },
      ]}
    >
      <Overview />
      <Banners />
      <div>
        <Header variant="h2">{t("Home.innovation")}</Header>
        <div style={{ display: "flex", gap: "20px", justifyContent: "space-between" }}>
          {TWO_VIDEOS.map((item) => {
            return (
              <div key={item.videoUrl} style={{ flex: 1 }}>
                <LatestUpdateItem item={item} style={{ width: "100%" }} />
              </div>
            );
          })}
        </div>
      </div>

      <div>
        <Header variant="h2">{t("Home.core-features")}</Header>
        <div className="home-core-feat">
          {CORE_FEATURE_ITEMS.map((item, index) => {
            return <CoreFeatureItem key={index} item={item} />;
          })}
        </div>
      </div>
    </Grid>
  );
};

export default LeftSide;
