import { SideNavigationProps } from "@cloudscape-design/components";

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createNavHeader = (t: (key: string) => string) => ({
  text: t("nav.root"),
  href: "/",
});

/*+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
export const createNavItems = (t: (key: string) => string): SideNavigationProps["items"] => [
  {
    type: "section",
    text: t("nav.awsSolution"),
    items: [
      { type: "link", text: t("nav.Solutions"), href: "/solutions" },
      { type: "link", text: t("nav.CaseStudies"), href: "/casestudies" },
    ],
  },
  {
    type: "section",
    text: t("nav.workEase"),
    items: [
      { type: "link", text: t("nav.AskPalette"), href: "/ask-palette" },
      { type: "link", text: t("nav.BuildingClass"), href: "/building-class" },
      { type: "link", text: t("nav.Newsletters"), href: "/newsletters" },
    ],
  },
];
