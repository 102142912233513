import { BreadcrumbGroup } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";

export const Breadcrumb = ({ items }) => {
  const navigate = useNavigate();

  const onFollowHandler = (e) => {
    e.preventDefault();
    navigate(e.detail.href);
  };

  return <BreadcrumbGroup items={items} onFollow={onFollowHandler} ariaLabel="Breadcrumbs" />;
};

export default Breadcrumb;
