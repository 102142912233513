import { Container, Grid, Header, Button, SpaceBetween } from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "../../../components";
import { getFileUrl } from "../../../common/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import "swiper/css";

SwiperCore.use([Navigation]);

export const TabTechDetails = ({ oneSolution }) => {
  const { t } = useTranslation();
  const { contentLanguage } = useUserProfile();

  const NoResources = () => (
    <center>
      <h3>{t("solutions:SolutionDetails.tabs-content.noResources")}</h3>
    </center>
  );

  return (
    <>
      <SpaceBetween size="l">
        <Container header={<Header variant="h2">{t("solutions:SolutionDetails.tabs-content.Architecture")}</Header>}>
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
            <div style={{ textAlign: "center", padding: "20px" }}>
              <img src={getFileUrl(oneSolution?.techArchitectureImage)} width="100%" alt="architecture Image" />
            </div>
            {oneSolution?.technicalDetails?.[`techSteps_${contentLanguage}`] ? (
              <div>
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  navigation={{
                    nextEl: ".swiper-button-next-xuhi",
                    prevEl: ".swiper-button-prev-xuhi",
                  }}
                >
                  {oneSolution?.technicalDetails[`techSteps_${contentLanguage}`].map((step, index) => (
                    <SwiperSlide key={step.id}>
                      <h3>{step.title}</h3>
                      <p>{step.content}</p>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <SpaceBetween size="l" direction="horizontal">
                  <div className="swiper-button-prev-xuhi">
                    <Button iconName="angle-left">{t("Button.prev")}</Button>
                  </div>
                  <div className="swiper-button-next-xuhi">
                    <Button iconName="angle-right" iconAlign="right">
                      {t("Button.next")}
                    </Button>
                  </div>
                </SpaceBetween>
              </div>
            ) : (
              <NoResources />
            )}
          </Grid>
        </Container>
      </SpaceBetween>
    </>
  );
};

/*
        <Container header={<Header variant="h2">{t("solutions:SolutionDetails.tabs-content.tutorial")}</Header>}>
          {oneSolution?.technicalDetails?.[`techSteps_${contentLanguage}`] ? (
            <BenefitsList benefits={oneSolution?.technicalDetails[`techSteps_${contentLanguage}`]} />
          ) : (
            <NoResources />
          )}
        </Container>

        <Container header={<Header variant="h2">{t("solutions:SolutionDetails.tabs-content.tutorial")}</Header>}></Container>
*/
