import {
  Alert,
  Button,
  Container,
  FileUpload,
  Grid,
  FormField,
  Header,
  Input,
  ProgressBar,
  Select,
  Multiselect,
  SpaceBetween,
  TagEditor,
  Textarea,
  Toggle,
} from "@cloudscape-design/components";
import { i18nStringsTagEditor } from "../../../common/i18nStrings-tagEditor";
import { useUserProfile } from "../../../components";
import { useTranslation } from "react-i18next";
import {
  solutionNameOptions_en,
  solutionNameOptions_cn,
  categoryOptions_en,
  categoryOptions_cn,
  domainOptions_en,
  domainOptions_cn,
  yearOptions,
} from "../../../common/formOptions";
import { addFormNested, removeFormNested, updateFormNested, moveFormNested } from "../../../common/utils";

/*****************************************************************************/
export default function EditSolutionPanel({
  onChangeCasestudyHandler,
  data,
  formattedData,
  setFormattedData,
  renderFileUploader,
  onChangeFileHandler,
  onChangeFileErrorHandler,
  fileLogo,
  fileCasestudyImage,
  fileArchitecture,
  errorTextLogo,
  errorTextCasestudyImage,
  errorTextArchitecture,
  logoUploadProgress,
  casestudyImageUploadProgress,
  architectureUploadProgress,
}) {
  const { contentLanguage } = useUserProfile();
  const { t } = useTranslation();
  // const [loading, setLoading] = useState(false);

  // 下面几行代码是为了在界面切换语言的时候，能动态渲染正确的FormField组件
  // const solutionName = `solutionName_${uiLanguage}`;
  const solutionNameOptions = contentLanguage === "en" ? solutionNameOptions_en : solutionNameOptions_cn;
  const categoryOptions = contentLanguage === "en" ? categoryOptions_en : categoryOptions_cn;
  const domainOptions = contentLanguage === "en" ? domainOptions_en : domainOptions_cn;

  const logo_uploadButtonText = formattedData.customerLogo ? formattedData.customerLogo : t("casestudies:CasestudyCreateForm.fileLogoText");
  const caseStudy_uploadButtonText = formattedData.casestudyImage ? formattedData.casestudyImage : t(`casestudies:CasestudyCreateForm.fileCasestudyImageText`);
  const architecture_uploadButtonText = formattedData.techArchitectureImage
    ? formattedData.techArchitectureImage
    : t(`casestudies:CasestudyCreateForm.fileArchitectureText`);

  return (
    <>
      <SpaceBetween size="l">
        {/* The 1st container: Basic Information +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("casestudies:CasestudyCreateForm.containerBasicInformation")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`casestudies:CasestudyCreateForm.solutionName`)} description={t(`casestudies:CasestudyCreateForm.solutionName_Des`)}>
              <Input value={data.solutionNameAbbr && data.solutionNameAbbr} readOnly />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.solutionsUsed`)} description={t(`casestudies:CasestudyCreateForm.solutionsUsed_Des`)}>
              <Multiselect
                selectedOptions={data["solutionsUsed_" + contentLanguage] || []}
                onChange={({ detail }) => onChangeCasestudyHandler(`solutionsUsed_${contentLanguage}`, detail.selectedOptions)}
                options={solutionNameOptions}
                placeholder={t(`casestudies:CasestudyCreateForm.solutionsUsed_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionEdit.category`)} description={t(`solutions:SolutionEdit.category_Des`)}>
              <Multiselect
                selectedOptions={data["category_" + contentLanguage] || []}
                onChange={({ detail }) => onChangeCasestudyHandler(`category_${contentLanguage}`, detail.selectedOptions)}
                options={categoryOptions}
                placeholder={t(`solutions:SolutionEdit.category_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`solutions:SolutionEdit.domain`)} description={t(`solutions:SolutionEdit.domain_Des`)}>
              <Multiselect
                selectedOptions={data["domain_" + contentLanguage] || []}
                onChange={({ detail }) => onChangeCasestudyHandler(`domain_${contentLanguage}`, detail.selectedOptions)}
                options={domainOptions}
                placeholder={t(`solutions:SolutionEdit.domain_Placeholder`)}
                selectedAriaLabel="Selected"
                deselectAriaLabel={(e) => `Remove ${e.label}`}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.customerNameAbbr`)} description={t(`casestudies:CasestudyCreateForm.customerNameAbbr_Des`)}>
              <Input value={formattedData.customerNameAbbr || ""} readOnly />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.customerName`)} description={t(`casestudies:CasestudyCreateForm.customerName_Des`)}>
              <Input
                value={formattedData["customerName_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.customerName_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`customerName_${contentLanguage}`, value)}
              />
              {(!formattedData.customerName_en || !formattedData.customerName_cn) && (
                <Alert statusIconAriaLabel="Info">{t("casestudies:CasestudyCreateForm.alert_customerName_required")}</Alert>
              )}
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.customerProfile`)} description={t(`casestudies:CasestudyCreateForm.customerProfile_Des`)}>
              <Textarea
                value={formattedData["customerProfile_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.customerProfile_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`customerProfile_${contentLanguage}`, value)}
              />
            </FormField>

            <FormField label={t(`casestudies:CasestudyCreateForm.year`)} description={t(`casestudies:CasestudyCreateForm.year_Des`)}>
              <Select
                selectedOption={data.year && data.year}
                onChange={({ detail }) => onChangeCasestudyHandler(`year`, detail.selectedOption)}
                options={yearOptions}
                selectedAriaLabel="Selected"
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.publicReference`)} description={t(`casestudies:CasestudyCreateForm.publicReference_Des`)}>
              <Toggle onChange={({ detail }) => onChangeCasestudyHandler(`publicReference`, detail.checked)} checked={formattedData.publicReference || false} />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.externalLink`)} description={t(`casestudies:CasestudyCreateForm.externalLink_Des`)}>
              <Input
                value={formattedData.externalLink || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.externalLink_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler("externalLink", value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 2nd container: Sales Team +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("casestudies:CasestudyCreateForm.containerSalesTeam")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`casestudies:CasestudyCreateForm.salesSegment`)} description={t(`casestudies:CasestudyCreateForm.salesSegment_Des`)}>
              <Input
                value={formattedData.salesSegment || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.salesSegment_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler("salesSegment", value)}
              />
            </FormField>

            <FormField label={t(`casestudies:CasestudyCreateForm.salesReps`)} description={t(`casestudies:CasestudyCreateForm.salesReps_Des`)}>
              <>
                <SpaceBetween size="l">
                  {(formattedData.salesReps || []).map((form, index) => (
                    <div key={index}>
                      <SpaceBetween size="l" direction="horizontal">
                        <Grid gridDefinition={[{ colspan: 3 }, { colspan: 3 }, { colspan: 3 }, { colspan: 3 }]}>
                          <FormField label={t(`solutions:SolutionForm.contributorsName`)} description={t(`solutions:SolutionForm.contributorsName_Des`)}>
                            <Input
                              value={form.name || ""}
                              placeholder={t(`solutions:SolutionForm.contributorsName_Placeholder`)}
                              onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `salesReps`, "name", value, index)}
                            />
                          </FormField>
                          <FormField label={t(`solutions:SolutionForm.contributorsLogin`)} description={t(`solutions:SolutionForm.contributorsLogin_Des`)}>
                            <Input
                              value={form.login || ""}
                              placeholder={t(`solutions:SolutionForm.contributorsLogin_Placeholder`)}
                              onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `salesReps`, "login", value, index)}
                            />
                          </FormField>
                          <FormField label={t(`solutions:SolutionForm.contributorsRole`)} description={t(`solutions:SolutionForm.contributorsRole_Des`)}>
                            <Input
                              value={form.role || ""}
                              placeholder={t(`solutions:SolutionForm.contributorsRole_Placeholder`)}
                              onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `salesReps`, "role", value, index)}
                            />
                          </FormField>

                          <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des_Short`)}>
                            <SpaceBetween size="s" direction="horizontal">
                              <Button
                                iconName="angle-up"
                                onClick={(event) => {
                                  event.preventDefault();
                                  moveFormNested(setFormattedData, `salesReps`, "up", index);
                                }}
                              />
                              <Button
                                iconName="angle-down"
                                onClick={(event) => {
                                  event.preventDefault();
                                  moveFormNested(setFormattedData, `salesReps`, "down", index);
                                }}
                              />
                              <Button
                                iconName="close"
                                onClick={(event) => {
                                  event.preventDefault();
                                  removeFormNested(setFormattedData, `salesReps`, index);
                                }}
                              ></Button>
                            </SpaceBetween>
                          </FormField>
                        </Grid>
                      </SpaceBetween>
                    </div>
                  ))}
                  <Button
                    onClick={(event) => {
                      event.preventDefault();
                      addFormNested(setFormattedData, `salesReps`, { name: "", login: "", role: "" }); // change from addForm to addFromNested
                    }}
                  >
                    {t(`FormButton.addButton`)}
                  </Button>
                </SpaceBetween>
              </>
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 3rd container: Specific Content +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("casestudies:CasestudyCreateForm.containerSpecificContent")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`casestudies:CasestudyCreateForm.headline`)} description={t(`casestudies:CasestudyCreateForm.headline_Des`)}>
              <Input
                value={formattedData["headline_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.headline_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`headline_${contentLanguage}`, value)}
              />
              {(!formattedData.headline_en || !formattedData.headline_cn) && (
                <Alert statusIconAriaLabel="Info">{t("casestudies:CasestudyCreateForm.alert_headline_required")}</Alert>
              )}
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.subheadline`)} description={t(`casestudies:CasestudyCreateForm.subheadline_Des`)}>
              <Input
                value={formattedData["subheadline_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.subheadline_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`subheadline_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.overview`)} description={t(`casestudies:CasestudyCreateForm.overview_Des`)}>
              <Textarea
                textarea
                value={formattedData["overview_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.overview_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`overview_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField
              label={t(`casestudies:CasestudyCreateForm.opportunityHeadline`)}
              description={t(`casestudies:CasestudyCreateForm.opportunityHeadline_Des`)}
            >
              <Input
                value={formattedData["opportunityHeadline_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.opportunityHeadline_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`opportunityHeadline_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.opportunity`)} description={t(`casestudies:CasestudyCreateForm.opportunity_Des`)}>
              <Textarea
                value={formattedData["opportunity_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.opportunity_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`opportunity_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.solutionHeadline`)} description={t(`casestudies:CasestudyCreateForm.solutionHeadline_Des`)}>
              <Input
                value={formattedData["solutionHeadline_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.solutionHeadline_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`solutionHeadline_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.solution`)} description={t(`casestudies:CasestudyCreateForm.solution_Des`)}>
              <Textarea
                value={formattedData["solution_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.solution_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`solution_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.outcomeHeadline`)} description={t(`casestudies:CasestudyCreateForm.outcomeHeadline_Des`)}>
              <Input
                value={formattedData["outcomeHeadline_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.outcomeHeadline_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`outcomeHeadline_${contentLanguage}`, value)}
              />
            </FormField>
            <FormField label={t(`casestudies:CasestudyCreateForm.outcome`)} description={t(`casestudies:CasestudyCreateForm.outcome_Des`)}>
              <Textarea
                value={formattedData["outcome_" + contentLanguage] || ""}
                placeholder={t(`casestudies:CasestudyCreateForm.outcome_Placeholder`)}
                onChange={({ detail: { value } }) => onChangeCasestudyHandler(`outcome_${contentLanguage}`, value)}
              />
            </FormField>
          </SpaceBetween>
        </Container>

        {/* The 4th container: Highlights +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container
          header={
            <Header variant="h2" description={t("casestudies:CasestudyCreateForm.containerHighlights_Des")}>
              {t("casestudies:CasestudyCreateForm.containerHighlights")}
            </Header>
          }
        >
          <SpaceBetween size="l">
            <>
              <SpaceBetween size="l">
                {(formattedData["highlights_" + contentLanguage] || []).map((form, index) => (
                  <div key={index}>
                    <SpaceBetween size="l" direction="horizontal">
                      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 5 }, { colspan: 3 }]}>
                        <FormField
                          label={t(`casestudies:CasestudyCreateForm.highlight_keyword`)}
                          description={t(`casestudies:CasestudyCreateForm.highlight_keyword_Des`)}
                        >
                          <Input
                            value={form.keyword || ""}
                            placeholder={t(`casestudies:CasestudyCreateForm.highlight_keyword_Placeholder`)}
                            onChange={({ detail: { value } }) => updateFormNested(setFormattedData, `highlights_${contentLanguage}`, "keyword", value, index)}
                          />
                        </FormField>
                        <FormField
                          label={t(`casestudies:CasestudyCreateForm.highlight_description`)}
                          description={t(`casestudies:CasestudyCreateForm.highlight_description_Des`)}
                        >
                          <Input
                            value={form.description || ""}
                            placeholder={t(`casestudies:CasestudyCreateForm.highlight_description_Placeholder`)}
                            onChange={({ detail: { value } }) =>
                              updateFormNested(setFormattedData, `highlights_${contentLanguage}`, "description", value, index)
                            }
                          />
                        </FormField>

                        <FormField label={t(`FormButton.upDownRemove`)} description={t(`FormButton.upDownRemove_Des_Short`)}>
                          <SpaceBetween size="s" direction="horizontal">
                            <Button
                              iconName="angle-up"
                              onClick={(event) => {
                                event.preventDefault();
                                moveFormNested(setFormattedData, `highlights_en`, "up", index);
                                moveFormNested(setFormattedData, `highlights_cn`, "up", index);
                              }}
                            />
                            <Button
                              iconName="angle-down"
                              onClick={(event) => {
                                event.preventDefault();
                                moveFormNested(setFormattedData, `highlights_en`, "down", index);
                                moveFormNested(setFormattedData, `highlights_cn`, "down", index);
                              }}
                            />
                            <Button
                              iconName="close"
                              onClick={(event) => {
                                event.preventDefault();
                                removeFormNested(setFormattedData, `highlights_en`, index);
                                removeFormNested(setFormattedData, `highlights_cn`, index);
                              }}
                            ></Button>
                          </SpaceBetween>
                        </FormField>
                      </Grid>
                    </SpaceBetween>
                  </div>
                ))}
                <Button
                  onClick={(event) => {
                    event.preventDefault();
                    addFormNested(setFormattedData, `highlights_en`, { keyword: "", description: "" });
                    addFormNested(setFormattedData, `highlights_cn`, { keyword: "", description: "" });
                  }}
                >
                  {t(`FormButton.addButton`)}
                </Button>
              </SpaceBetween>
            </>
          </SpaceBetween>
        </Container>

        {/* The 5th container: Upload Images +++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        <Container header={<Header variant="h2">{t("casestudies:CasestudyCreateForm.containerUploadImages")}</Header>}>
          <SpaceBetween size="l">
            <FormField label={t(`casestudies:CasestudyCreateForm.fileLogoLabel`)} description={t(`casestudies:CasestudyCreateForm.fileLogo_Des`)}>
              <FileUpload
                value={fileLogo}
                onChange={({ detail }) => {
                  onChangeFileHandler("customerLogo", detail.value);
                }}
                errorText={errorTextLogo}
                constraintText={t(`casestudies:CasestudyCreateForm.fileLogo_Constrain`)}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : logo_uploadButtonText), // multiple文件的时候，e=true，否则为false
                  dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
              />
              <ProgressBar value={logoUploadProgress} label={t(`casestudies:CasestudyCreateForm.progressBarLabel`)} />
            </FormField>
            <FormField
              label={t(`casestudies:CasestudyCreateForm.fileCasestudyImageLabel`)}
              description={t(`casestudies:CasestudyCreateForm.fileCasestudyImage_Des`)}
            >
              <FileUpload
                value={fileCasestudyImage}
                onChange={({ detail }) => {
                  onChangeFileHandler("casestudyImage", detail.value);
                }}
                errorText={errorTextCasestudyImage}
                constraintText={t(`casestudies:CasestudyCreateForm.fileCasestudyImage_Constrain`)}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : caseStudy_uploadButtonText), // multiple文件的时候，e=true，否则为false
                  dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
              />
              <ProgressBar value={casestudyImageUploadProgress} label={t(`casestudies:CasestudyCreateForm.progressBarLabel`)} />
            </FormField>
            <FormField
              label={t(`casestudies:CasestudyCreateForm.fileArchitectureLabel`)}
              description={t(`casestudies:CasestudyCreateForm.fileArchitecture_Des`)}
            >
              <FileUpload
                value={fileArchitecture}
                onChange={({ detail }) => {
                  onChangeFileHandler("techArchitectureImage", detail.value);
                }}
                errorText={errorTextArchitecture}
                constraintText={t(`casestudies:CasestudyCreateForm.fileArchitecture_Constrain`)}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? "Choose files" : architecture_uploadButtonText), // multiple文件的时候，e=true，否则为false
                  dropzoneText: (e) => (e ? "Drop files to upload" : "Drop file to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
              />
              <ProgressBar value={architectureUploadProgress} label={t(`casestudies:CasestudyCreateForm.progressBarLabel`)} />
            </FormField>
          </SpaceBetween>
        </Container>
      </SpaceBetween>
    </>
  );
}
